import Blocks from "emg-ui-kit/components/Blocks";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import TableEditor, {
  TableData,
  createTable,
} from "../../common/TableEditorNew/TableEditorNew";
import { FormProps } from "../../common/models";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getDeepValidationProps,
  getValidationProps,
  MAX_TIMING,
  removeEmptyProps,
  removeTouched,
  validateIncorrectFormat,
  validateMax,
  validateMaxLines,
  validateNotEmpty,
  validatePositive,
  validateTimingRelation,
  validateZeroOrMoreZero,
} from "../util";

interface TableSlide {
  tableData: TableData;
  timing: number;
  title: string;
  titleFont: number;
  subtitle: string;
  subtitleFont: number;
  source: string;
  columnIndent: number;
  rowIndent: number;
  blinkStep: number;
  tableVisible: boolean;
}

function createTableSlide(): TableSlide {
  return {
    tableData: createTable(),
    timing: 30,
    title: "",
    titleFont: 59,
    subtitle: "",
    subtitleFont: 40,
    source: "",
    columnIndent: 6,
    rowIndent: 6,
    blinkStep: 8,
    tableVisible: false,
  };
}

function initTableSlides(count = 1) {
  return Array.from(Array(count), createTableSlide);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    tables: (initialFormData?.tables ?? initTableSlides(1)) as TableSlide[],
  };
}

export type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    tables: values.tables.map((table) => ({
      timing:
        validatePositive(table.timing) ?? validateMax(table.timing, MAX_TIMING),
      title: validateNotEmpty(table.title) ?? validateMaxLines(table.title, 2),
      titleFont: validatePositive(table.titleFont),
      subtitle: validateMaxLines(table.subtitle, 2),
      subtitleFont: validatePositive(table.subtitleFont),
      columnIndent: validateZeroOrMoreZero(table.columnIndent),
      rowIndent: validateZeroOrMoreZero(table.rowIndent),
      blinkStep:
        validateZeroOrMoreZero(table.blinkStep) ??
        validateTimingRelation(table.blinkStep, table.timing),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    tables: values.tables.map((table) => ({
      ...table,
      title: table.title.toUpperCase(),
      subtitle: table.subtitle.toUpperCase(),
    })),
  };
}

function calcTotalTiming(values: Values) {
  return values.tables.reduce((acc, table) => acc + table.timing, 0);
}

function M24TableForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  const {
    values,
    errors,
    touched,
    setTouched,
    setFieldValue,
    isValid,
    isSubmitting,
  } = formik;

  usePreview(channel, template, calcTotalTiming(values), values, prepareData);

  const buttonProps = {
    isSubmitting: isSubmitting,
    isValid: isValid,
    prepareData,
    values: values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  function nonNegativeChange(e: any, path: string) {
    let value = e.target.value;
    if (!value || value < 0) {
      setFieldValue(path, 0);
    } else {
      setFieldValue(path, value);
    }
  }

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Blocks
          items={values.tables}
          blockTitle="Таблица"
          defaultItemConstructor={createTableSlide}
          updateItems={(tables) => setFieldValue("tables", tables)}
          canChangeLength
          onDelete={(index) => {
            removeTouched(`tables`, index, touched, setTouched);
          }}
        >
          {(table, index, updateItem) => (
            <>
              <Field
                as={TextField}
                label="Хронометраж (сек)"
                name={`tables.${index}.timing`}
                type="number"
                {...getDeepValidationProps(
                  `tables[${index}].timing`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextArea}
                placeholder="Заголовок"
                name={`tables.${index}.title`}
                textareaStyle={{ textTransform: "uppercase" }}
                {...getDeepValidationProps(
                  `tables[${index}].title`,
                  touched,
                  errors
                )}
                rows={2}
                required
              />
              <Field
                as={TextField}
                type="number"
                label="Размер шрифта заголовка"
                name={`tables.${index}.titleFont`}
                {...getDeepValidationProps(
                  `tables[${index}].titleFont`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextArea}
                placeholder="Подзаголовок"
                name={`tables.${index}.subtitle`}
                textareaStyle={{ textTransform: "uppercase" }}
                {...getDeepValidationProps(
                  `tables[${index}].subtitle`,
                  touched,
                  errors
                )}
                rows={2}
              />
              <Field
                as={TextField}
                type="number"
                label="Размер шрифта подзаголовка"
                name={`tables.${index}.subtitleFont`}
                {...getDeepValidationProps(
                  `tables[${index}].subtitleFont`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextField}
                placeholder="Источник"
                name={`tables.${index}.source`}
              />
              <Field
                as={TextField}
                type="number"
                label="Интервал между столбцами"
                name={`tables.${index}.columnIndent`}
                onChange={(e: any) =>
                  nonNegativeChange(e, `tables.${index}.columnIndent`)
                }
                {...getDeepValidationProps(
                  `tables[${index}].columnIndent`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Интервал между рядами"
                name={`tables.${index}.rowIndent`}
                onChange={(e: any) =>
                  nonNegativeChange(e, `tables.${index}.rowIndent`)
                }
                {...getDeepValidationProps(
                  `tables[${index}].rowIndent`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Интервал волны промаргивания (сек)"
                name={`tables.${index}.blinkStep`}
                {...getDeepValidationProps(
                  `tables[${index}].blinkStep`,
                  touched,
                  errors
                )}
              />
              <div
                style={{
                  marginBottom: "1rem",
                  fontSize: 18,
                  padding: 0,
                  color: "var(--blue)",
                  cursor: "pointer",
                  textAlign: "center",
                }}
                onClick={() =>
                  setFieldValue(`tables.${index}.tableVisible`, true)
                }
              >
                Редактировать таблицу
              </div>
              <TableEditor
                name={`tables.${index}.tableData`}
                tableData={table.tableData}
                visible={table.tableVisible}
                onClose={() =>
                  setFieldValue(`tables.${index}.tableVisible`, false)
                }
              />
            </>
          )}
        </Blocks>

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24TableForm);
