import React from "react";
import { Link } from "react-router-dom";

import styles from "./List.module.css";

interface Props {
  items: { id: string; title: string }[];
  urlBase: string;
}

export default function List({ items, urlBase }: Props) {
  return (
    <section>
      {items.map((item, idx) => (
        <Link
          to={`${urlBase}/${item.id}`}
          key={item.id}
          className={styles.link}
        >
          <div className={styles.title}>{item.title}</div>
        </Link>
      ))}
    </section>
  );
}
