import Button from "emg-ui-kit/components/Button";
import React from "react";

import styles from "./TableEdit.module.scss";

interface TableHeightEditProps {
  dimensions: number[];
  handleChange: (value: number, index: number) => void;
  handleSave: () => void;
}

export default function TableHeightEdit({
  dimensions,
  handleChange,
  handleSave,
}: TableHeightEditProps) {
  const paramsCount = dimensions.length;

  return (
    <div className={styles.editModalContainer}>
      <div className={styles.editModalTitle}>
        Таблица для изменения высоты строк
      </div>
      <table className={styles.editTable} style={{ width: "320px" }}>
        <tbody>
          <tr>
            <td className={styles.tdBold} style={{ width: "120px" }}>
              Строка
            </td>
            <td className={styles.tdBold}>Высота в %</td>
          </tr>
          {dimensions.map((height, index) => (
            <tr key={index}>
              <td className={styles.tdBold}>{index + 1}</td>
              <td className={styles.td} style={{ height: height * 3.55 }}>
                <input
                  type="number"
                  value={height}
                  className={styles.textInput}
                  onChange={(event: any) =>
                    handleChange(parseFloat(event.target.value), index)
                  }
                  disabled={index + 1 === paramsCount}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <Button title="Сохранить и выйти" onClick={handleSave} />
    </div>
  );
}
