import {
  createAction,
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
} from "@reduxjs/toolkit";

import {
  acceptOrder,
  addOrder,
  cancelOrder,
  finishOrder,
  forceUpdateOrder,
  getOrder,
  getOrders,
  updateOrderFeedback,
} from "../../common/ApiService";
import { ForceTaskStatus, Task } from "../../common/models";
import { IFilterState } from "../../tasks/components/Filters/FilterTypes";

export interface TaskEntry extends Task {
  isAccepting?: boolean;
  isCanceling?: boolean;
  isFinishing?: boolean;
}

export interface TasksState {
  byId: Record<string, TaskEntry | undefined>;
  ids: string[];
  isLoading: boolean;
  totalPages: number;
  totalTasks: number;
  isStatusChanging: boolean;
}

const initialState: TasksState = {
  byId: {},
  ids: [],
  isLoading: false,
  totalPages: 1,
  totalTasks: 0,
  isStatusChanging: false,
};

export const getTasksThunk = createAsyncThunk(
  "tasks/get",
  (arg: IFilterState) => {
    return getOrders(arg);
  }
);

export const getTaskByIdThunk = createAsyncThunk(
  "tasks/getById",
  (id: string) => {
    return getOrder(id);
  }
);

export const cancelTaskByIdThunk = createAsyncThunk(
  "tasks/cancelById",
  (id: string) => {
    return cancelOrder(id);
  }
);

export const forceUpdateOrderByIdThunk = createAsyncThunk(
  "tasks/forceUpdateOrderById",
  ({ id, status }: { id: string; status: ForceTaskStatus }) => {
    return forceUpdateOrder(id, status);
  }
);

export const updateOrderFeedbackThunk = createAsyncThunk(
  "tasks/updateOrderFeedback",
  ({ id, feedback }: { id: string; feedback: Task["feedback"] }) => {
    return updateOrderFeedback(id, feedback);
  }
);

export const acceptTaskByIdThunk = createAsyncThunk(
  "tasks/acceptById",
  acceptOrder
);

export const finishTaskByIdThunk = createAsyncThunk(
  "tasks/finishById",
  ({ id, outURL }: { id: string; outURL?: string }) => finishOrder(id, outURL)
);

export const createTaskThunk = createAsyncThunk("tasks/create", addOrder);

export const updateTaskBySocket = createAction("tasks/update");

const tasksReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getTaskByIdThunk.fulfilled, (state) => {
      state.isLoading = false;
    })
    .addCase(cancelTaskByIdThunk.pending, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg]!.isCanceling = true;
      }
    })
    .addCase(cancelTaskByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg] = action.payload;
      }
    })
    .addCase(forceUpdateOrderByIdThunk.pending, (state, action) => {
      state.isStatusChanging = true;
    })
    .addCase(forceUpdateOrderByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg.id]) {
        state.byId[action.meta.arg.id] = action.payload;
        state.isStatusChanging = false;
      }
    })
    .addCase(updateOrderFeedbackThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg.id]) {
        state.byId[action.meta.arg.id] = action.payload;
      }
    })
    .addCase(getTasksThunk.fulfilled, (state, action) => {
      const nextIds = action.payload.orders.map((task) => task._id);
      const { page, perPage } = action.meta.arg;
      const hasRequestedFirstPage = page === 1;
      state.totalPages = Math.ceil(action.payload.total / perPage);
      state.isLoading = false;
      state.totalTasks = action.payload.total;

      if (hasRequestedFirstPage) {
        state.ids = nextIds;
        state.byId = action.payload.orders.reduce<Record<string, Task>>(
          (acc, task) => {
            acc[task._id] = task;
            return acc;
          },
          {}
        );
      } else {
        const fromIndex = (page - 1) * perPage;
        action.payload.orders.forEach((task, index) => {
          state.byId[task._id] = task;
          state.ids[fromIndex + index] = task._id;
        });
      }
    })
    .addCase(acceptTaskByIdThunk.pending, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg]!.isAccepting = true;
      }
    })
    .addCase(acceptTaskByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg]) {
        state.byId[action.meta.arg] = action.payload;
      }
    })
    .addCase(updateTaskBySocket, (state, action: any) => {
      const id = action.payload._id;
      state.byId = {
        ...state.byId,
        [id]: action.payload,
      };
    })
    .addCase(finishTaskByIdThunk.pending, (state, action) => {
      if (state.byId[action.meta.arg.id]) {
        state.byId[action.meta.arg.id]!.isFinishing = true;
      }
    })
    .addCase(finishTaskByIdThunk.fulfilled, (state, action) => {
      if (state.byId[action.meta.arg.id]) {
        state.byId[action.meta.arg.id] = action.payload;
      }
    })
    .addMatcher(
      isFulfilled(getTaskByIdThunk, cancelTaskByIdThunk),
      (state, action) => {
        state.byId[action.payload._id] = action.payload;
        state.isLoading = false;
      }
    )
    .addMatcher(isPending(getTaskByIdThunk, getTasksThunk), (state) => {
      state.isLoading = true;
    });
});

export default tasksReducer;
