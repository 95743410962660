import React from "react";

import { InfoRow } from "../InfoComponents";

export function Geo({ data }: { data: Record<string, any> }) {
  const designOptions: Record<string, string> = {
    standard: "Cтандартное",
    church: "Церковь и мир",
  };
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Оформление" value={designOptions[data.design]} />
      <InfoRow title="Гео" value={data.geo} />
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
    </>
  );
}
