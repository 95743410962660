import Select from "emg-ui-kit/components/Select";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { selectAuth } from "../../../redux/auth/selectors";
import {
  resetFilters,
  setRootFilter,
} from "../../../redux/filters/filters.reducer";
import { RootState, AppDispatch } from "../../../store";
import Period from "../Period/Period";
import AdditionalGroup from "./AdditionalGroup";
import styles from "./Filters.module.css";
import ProjectsWrap from "./ProjectsWrap";
import StatusGroup from "./StatusGroup";

export function FiltersMobile() {
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();

  const PROJECTS_LABEL =
    useSelector(selectAuth).userInfo?.type === "client"
      ? "Программы"
      : "Проекты";
  const orderTypes = [
    {
      id: "desc",
      name: "По дате изменения: сначала новые",
    },
    {
      id: "asc",
      name: "По дате изменения: сначала старые",
    },
  ];

  const Title = ({
    text,
    style,
  }: {
    text: string;
    style?: React.CSSProperties;
  }) => (
    <h4 className={styles.groupTitle} style={style ? style : {}}>
      {text}
    </h4>
  );

  return (
    <>
      <Select
        value={filterState.order}
        placeholder="Сортировать"
        options={orderTypes}
        onChange={({ target: { value } }) =>
          dispatch(setRootFilter({ order: value }))
        }
      />
      <hr />
      <Title text="Период" style={{ marginBottom: "15px" }} />
      <Period />
      <hr />
      <Title text="Статус" />
      <StatusGroup />
      <hr />
      <Title text={PROJECTS_LABEL} style={{ marginBottom: 10 }} />
      <ProjectsWrap />
      <hr />
      <Title text="Дополнительно" />
      <AdditionalGroup />
      <button
        onClick={() => {
          dispatch(resetFilters());
        }}
        className={styles.resetButton}
      >
        Сбросить все
      </button>
    </>
  );
}
