import React from "react";

export default function CheckedCount({ checkedItems }: { checkedItems: any }) {
  return (
    <>
      {checkedItems > 0 && (
        <div style={{ color: "var(--blue)", display: "inline-block" }}>
          ({checkedItems})
        </div>
      )}
    </>
  );
}
