import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function M24QuoteSobyanin({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <ImageRow title="Изображение" src={data.image} />
      {data.equalTimings && (
        <InfoRow title="Общий хронометраж (сек.)" value={data.totalTiming} />
      )}
      {!data.equalTimings && (
        <InfoRow
          title="Общая длительность ролика (сек.)"
          value={data.totalTiming}
        />
      )}
      {(data.blocks as Record<string, any>[]).map((block, id) => (
        <details open key={id}>
          <summary>Цитата №{id + 1}</summary>
          <InfoRow title="Текст цитаты" value={block.text} />
          {!data.equalTimings && (
            <InfoRow title="Хронометраж (сек.)" value={block.timing} />
          )}
        </details>
      ))}
    </>
  );
}
