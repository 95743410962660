import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Wheeze({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Цветовая схема" value={data.colorScheme} />
      <InfoRow title="Вид связи" value={data.communicationType} />
      <InfoRow title="Имя" value={data.name} />
      <InfoRow title="Должность" value={data.position} />
      <ImageRow title="Изображение" src={data.image} />
    </>
  );
}
