import classNames from "classnames";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { filterGroups } from "../../../common/texts";
import { setRootFilter } from "../../../redux/filters/filters.reducer";
import { RootState, AppDispatch } from "../../../store";
import styles from "./Filters.module.css";

export default function StatusGroup() {
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();
  return (
    <>
      {Object.entries(filterGroups.statuses).map(([key, name]) => (
        <button
          key={`${key}_status`}
          onClick={() => {
            dispatch(setRootFilter({ status: key }));
          }}
          className={classNames(styles.filterLink, {
            [styles.filterLinkCurrent]: filterState.status === key,
          })}
        >
          {name}
        </button>
      ))}
    </>
  );
}
