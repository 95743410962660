import { Task } from "../../common/models";
import { RootState } from "../../store";

export const selectDraftsByPage =
  (page: number, perPage: number) => (state: RootState) => {
    const from = (page - 1) * perPage;
    const to = from + perPage;
    return {
      drafts: state.drafts.ids
        .slice(from, to + 1)
        .map((id) => state.drafts.byId[id]) as Task[],
      isLoading: state.drafts.isLoading,
      totalPages: state.drafts.totalPages,
    };
  };

export const selectDrafts = (state: RootState) => ({
  drafts: state.drafts.ids.map((id) => state.drafts.byId[id]) as Task[],
  isLoading: state.drafts.isLoading,
});
