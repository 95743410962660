import { Person } from "../../common/ApiService";
import { RootState } from "../../store";

export const selectPersons = (state: RootState) => ({
  persons: state.persons.ids.map((id) => state.persons.byId[id]) as Person[],
  isLoading: state.persons.isLoading,
});

export const selectVipPersons = (state: RootState) =>
  state.persons.ids
    .filter((id) => !!state.persons.byId[id]?.photoVip)
    .map((id) => state.persons.byId[id]) as Person[];

export const selectBigQuotePersons = (state: RootState) =>
  state.persons.ids
    .filter((id) => !!state.persons.byId[id]?.photoVip)
    .map((id) => state.persons.byId[id])
    .filter((person) => /Песков/.test(person?.name ?? "")) as Person[];

export const selectPersonById = (id: string) => (state: RootState) =>
  state.persons.byId[id] as Person | undefined;
