import React from "react";
import ContentLoader from "react-content-loader";

export default function SearchResultsSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={600}
      height={584}
      viewBox="0 0 600 584"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
    >
      <path d="M 0 0 h 100 v 16 H 0 z M 440 0 h 160 v 17 H 440 z M 0 22 h 220 v 20 H 0 z M 0 46 h 180 v 19 H 0 z M 0 71 h 92 v 21 H 0 z M 0 123 h 100 v 16 H 0 z M 440 123 h 160 v 17 H 440 z M 0 145 h 220 v 20 H 0 z M 0 169 h 180 v 19 H 0 z M 0 194 h 92 v 21 H 0 z M 0 246 h 100 v 16 H 0 z M 440 246 h 160 v 17 H 440 z M 0 268 h 220 v 20 H 0 z M 0 292 h 180 v 19 H 0 z M 0 317 h 92 v 21 H 0 z M 0 369 h 100 v 16 H 0 z M 440 369 h 160 v 17 H 440 z M 0 391 h 220 v 20 H 0 z M 0 415 h 180 v 19 H 0 z M 0 440 h 92 v 21 H 0 z M 0 492 h 100 v 16 H 0 z M 440 492 h 160 v 17 H 440 z M 0 514 h 220 v 20 H 0 z M 0 538 h 180 v 19 H 0 z M 0 563 h 92 v 21 H 0 z" />
    </ContentLoader>
  );
}
