import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { CSSTransition } from "react-transition-group";

import { clearError } from "../redux/error";
import { AppDispatch, RootState } from "../store";
import styles from "./Alert.module.css";
import alertImage from "./alert.svg";

export default function Alert() {
  const error = useSelector<RootState, { message: string } | undefined>(
    (state) => state.errors.error
  );

  const { error: customError } = useSelector((state: RootState) => {
    return state.customErrors;
  });

  const [visible, setVisible] = useState(false);
  const dispatch = useDispatch<AppDispatch>();
  useEffect(() => {
    if (error || customError) {
      setVisible(true);
    }
  }, [error, customError]);

  const timer = useRef<number>();

  useEffect(() => {
    window.clearTimeout(timer.current);
    if (error || customError) {
      timer.current = window.setTimeout(() => {
        setVisible(false);
        dispatch(clearError());
      }, 10000);
    }
  }, [error, customError, dispatch]);

  return (
    <div className={styles.container}>
      <CSSTransition
        in={visible}
        timeout={300}
        unmountOnExit
        classNames={{
          enter: styles.alertEnter,
          enterActive: styles.alertEnterActive,
          exit: styles.alertExit,
          exitActive: styles.alertExitActive,
        }}
      >
        <div className={styles.alert} onClick={() => setVisible(false)}>
          <img src={alertImage} alt="Alert icon" style={{ marginRight: 10 }} />
          {error?.message || customError}
        </div>
      </CSSTransition>
    </div>
  );
}
