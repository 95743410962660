import React from "react";

import { SITES } from "../../../../../order/r24/constants";
import { ImageRow, InfoRow } from "../InfoComponents";

export function SiteQuote({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      <ImageRow title="Изображение" src={data.image} />
      <InfoRow title="Сайт" value={SITES[data.site]} />
      {data.site === "custom" && (
        <InfoRow title="Другой сайт" value={data.customSite} />
      )}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow
        title="Текст"
        value={data.blocks.map(({ text }: { text: string }) => text).join("\n")}
      />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
    </>
  );
}
