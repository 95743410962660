import TextField from "emg-ui-kit/components/TextField";
import { useFormikContext, Field } from "formik";
import React from "react";

import LegendInput from "../../../../common/LegendInput";
import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { thirdLinesColors } from "../../constants";
import { TextItem } from "../../types";

interface Props {
  item: TextItem;
  index: number;
}

export default function TextBlock({ item, index }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );
  const firstLineName = getItemFieldName(index, "firstLine");
  const secondLineName = getItemFieldName(index, "secondLine");
  const thirdLineName = getItemFieldName(index, "thirdLine");

  return (
    <>
      <Field
        as={TextField}
        placeholder="Первая строка"
        name={firstLineName}
        {...validationUtils.getDeepProps(firstLineName)}
        required
      />
      <Field
        as={TextField}
        placeholder="Вторая строка"
        name={secondLineName}
        {...validationUtils.getDeepProps(secondLineName)}
      />
      <LegendInput
        placeholder="Третья строка"
        color={item.thirdLineColor}
        title={item.thirdLine}
        updateColor={(color) =>
          formik.setFieldValue(getItemFieldName(index, "thirdLineColor"), color)
        }
        updateTitle={(title) => {
          formik.setFieldTouched(thirdLineName);
          formik.setFieldValue(thirdLineName, title);
        }}
        colors={thirdLinesColors}
        {...validationUtils.getDeepProps(thirdLineName)}
      />
    </>
  );
}
