import React from "react";
import { useDispatch, useSelector } from "react-redux";

import DetailsGroup from "../../../components/Details/DetailsGroup";
import { countTrueValues } from "../../../order/util";
import { selectAuth } from "../../../redux/auth/selectors";
import { resetFilters } from "../../../redux/filters/filters.reducer";
import { AppDispatch, RootState } from "../../../store";
import Period from "../Period/Period";
import AdditionalGroup from "./AdditionalGroup";
import CheckedCount from "./CheckedCount";
import styles from "./Filters.module.css";
import ProjectsWrap from "./ProjectsWrap";
import StatusGroup from "./StatusGroup";
import { useDetails } from "./useDetails";

export function HeaderComponentWrap({ children }: { children: React.ReactElement }) {
  return (
    <div
      style={{
        fontFamily: '"Futura New Bold", sans-serif',
        marginLeft: 7,
      }}
    >
      {children}
    </div>
  );
}

export function BodyComponentWrap({ children }: { children: React.ReactElement }) {
  return (
    <div
      style={{
        marginTop: 10,
      }}
    >
      {children}
    </div>
  );
}

export function Filters() {
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();

  const { groups, handleDetails } = useDetails({
    groupsList: ["status", "projects", "period", "additional"],
    groupStorageName: "main_details",
  });

  const isClient = useSelector(selectAuth).userInfo?.type === "client";

  const PROJECTS_LABEL = isClient ? "Программы" : "Проекты";

  const checkedTemplates = isClient
    ? filterState.bitrixTemplates.length
    : filterState.templates.length;

  const checkedAdditional = countTrueValues({
    ...filterState.feedback,
    allUsers: filterState.allUsers,
  });

  const periodChecked = filterState.startDate || filterState.endDate ? 1 : 0;

  const statusChecked = filterState.status === "all" ? 0 : 1;

  return (
    <>
      <DetailsGroup
        open={groups.status}
        onClick={(event) => {
          handleDetails("status", event);
        }}
        headerComponent={
          <HeaderComponentWrap>
            <>
              Статус <CheckedCount checkedItems={statusChecked} />
            </>
          </HeaderComponentWrap>
        }
        bodyComponent={
          <BodyComponentWrap>
            <StatusGroup />
          </BodyComponentWrap>
        }
      />
      <DetailsGroup
        open={groups.projects}
        onClick={(event) => {
          handleDetails("projects", event);
        }}
        headerComponent={
          <HeaderComponentWrap>
            <>
              {PROJECTS_LABEL} <CheckedCount checkedItems={checkedTemplates} />
            </>
          </HeaderComponentWrap>
        }
        bodyComponent={
          <BodyComponentWrap>
            <ProjectsWrap />
          </BodyComponentWrap>
        }
      />

      <DetailsGroup
        open={groups.period}
        noBodyPadding
        onClick={(event) => {
          handleDetails("period", event);
        }}
        headerComponent={
          <HeaderComponentWrap>
            <>
              Период <CheckedCount checkedItems={periodChecked} />
            </>
          </HeaderComponentWrap>
        }
        bodyComponent={
          <BodyComponentWrap>
            <Period />
          </BodyComponentWrap>
        }
      />

      <DetailsGroup
        open={groups.additional}
        onClick={(event) => {
          handleDetails("additional", event);
        }}
        headerComponent={
          <HeaderComponentWrap>
            <>
              Дополнительно <CheckedCount checkedItems={checkedAdditional} />
            </>
          </HeaderComponentWrap>
        }
        bodyComponent={
          <BodyComponentWrap>
            <AdditionalGroup />
          </BodyComponentWrap>
        }
      />
      <button
        style={{ marginTop: 30 }}
        onClick={() => {
          dispatch(resetFilters());
        }}
        className={styles.resetButton}
      >
        Сбросить все
      </button>
    </>
  );
}
