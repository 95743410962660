import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
} from "../util";

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    path: (initialFormData?.path ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
    decor: (initialFormData?.decor ?? "News") as string,
    template: (initialFormData?.template ?? "AEMAP") as string,
    manual: false,
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName", "path", "manual", "timing", "template"),
    decor: values.template === "AEMAP_RIK" ? values.decor : undefined,
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
  };
  return removeEmptyProps(errors);
}

function AeMapForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const { values, errors, touched, isSubmitting, isValid } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  const templateOptions = [
    { id: "AEMAP", name: " Общий" },
    { id: "AEMAP_M24", name: "Москва 24" },
    { id: "AEMAP_RIK", name: "РИК" },
  ];

  const decorOptions = [
    { id: "News", name: "Вести" },
    { id: "Facts", name: "Факты" },
    { id: "Alert", name: "Alert" },
    { id: "Economics", name: "Экономика" },
    { id: "Weather", name: "Погода" },
    { id: "Morning-News", name: "Утро-Вести" },
    { id: "Morning-Economics", name: "Утро-Экономика" },
    { id: "Morning-Weather", name: "Утро-Погода" },
    { id: "Prime", name: "Прайм" },
  ];

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          name="template"
          label="Конфигурация"
          options={templateOptions}
        />
        {values.template === "AEMAP_RIK" && (
          <Field
            as={Select}
            name="decor"
            label="Оформление"
            options={decorOptions}
          />
        )}
        <Field
          as={TextField}
          label="Общий хронометраж (сек.)"
          name="timing"
          type="number"
          required
        />
        <Field as={TextField} label="Путь к PSD-файлу" name="path" />

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(AeMapForm);
