import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function D24Informer({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Общий хронометраж (сек.)" value={data.timing} />
      <div style={{ marginBottom: 10 }} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <div style={{ marginLeft: 15 }}>
            <div style={{ marginBottom: 10 }} />
            <InfoRow title="Тип события" value={block.eventType} />
            {block.locationName && (
              <InfoRow title="Место проведения" value={block.locationName} />
            )}
            {block.age && <InfoRow title="Возрастной ценз" value={block.age} />}
            <InfoRow title="Размер шрифта даты" value={block.dateSize} />
            <InfoRow title="Дата" value={block.date} />
            <InfoRow title="Название события" value={block.eventName} />
            {block.imageType === "total" && (
              <ImageRow title="Общее изображение" src={block.image} />
            )}
            {(block.comments as Record<string, any>[]).map((comment, idx) => (
              <details key={idx}>
                <summary>Комментарий №{idx + 1}</summary>
                <div style={{ marginLeft: 15 }}>
                  <div style={{ marginBottom: 10 }} />
                  <InfoRow title="Комментарий" value={comment.comment} />
                  {block.imageType === "separate" && (
                    <ImageRow title="Изображение" src={comment.image} />
                  )}
                </div>
              </details>
            ))}
          </div>
          <hr />
        </details>
      ))}
    </>
  );
}
