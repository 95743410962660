import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";
import { color } from "../constants";

export function D24SlideShow({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      {data.equalTimings && (
        <InfoRow title="Общий хронометраж (сек.)" value={data.totalTiming} />
      )}
      {data.sourceType === "total" && data.source && (
        <>
          <InfoRow title="Общий источник" value={data.source} />
          <InfoRow title="Цвет источника" value={color[data.sourceColor]} />
        </>
      )}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <ImageRow title="Изображение" src={block.image} />
          {block.title && <InfoRow title="Заголовок" value={block.title} />}
          {block.comment && (
            <InfoRow title="Комментарий" value={block.comment} />
          )}
          {data.sourceType === "separate" && block.source && (
            <>
              <InfoRow title="Источник" value={block.source} />
              <InfoRow
                title="Цвет источника"
                value={color[block.sourceColor]}
              />
            </>
          )}
          {!data.equalTimings && (
            <InfoRow title="Хронометраж (сек.)" value={block.timing} />
          )}
        </details>
      ))}
    </>
  );
}
