import "chart.js/auto";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import React, { useMemo, useState } from "react";

import { useIsDesktop } from "../common/utils";
import DashBoardLocationsSkeleton from "../components/Skeletons/DashBoardLocationsSkeleton copy";
import Pagination from "../tasks/pages/TaskTablePage/Pagination";
import { EmptyDataCap } from "./DashBoard";
import styles from "./DashBoard.module.css";
import GearButton from "./GearButton";

const checkDataObjIsEmpty = (data: Record<string, number>) => {
  return Object.keys(data).every((key) => {
    return data[key] === 0;
  });
};

function InfoModule({
  title,
  sentToBroadCast,
  toggleBroadcast,
  data,
  isLoading,
}: {
  title?: string;
  sentToBroadCast: boolean;
  toggleBroadcast: () => void;
  data: Record<string, number>;
  isLoading: boolean;
}) {
  const [gearSelectedOption, setGearSelectedOption] =
    useState<string>("Количество");
  const [frequency, setFrequency] = useState<string>("frequently");
  const [displayedPage, setDisplayedPage] = useState<number>(1);

  const isDesktop = useIsDesktop();
  const PERPAGE = 10;
  const gearOptions = ["Количество", "Проценты"];

  function shortenString(input: string, maxLength: number): string {
    if (input.length <= maxLength) {
      return input;
    } else {
      const shorted = input.substring(0, maxLength);
      return `${shorted}...`;
    }
  }

  function calculatePercentages(data: Record<string, number>) {
    const newObject: Record<string, number> = {};
    let total = 0;
    Object.values(data).forEach((value) => {
      total += value;
    });
    Object.keys(data).forEach((key) => {
      const percentage = Number(((data[key] / total) * 100).toFixed(1));
      newObject[key] = percentage;
    });
    return newObject;
  }

  const isEmptyData = useMemo(() => {
    return checkDataObjIsEmpty(data);
  }, [data]);

  const sortedData = useMemo(() => {
    const entries = Object.entries(data);
    const sortedEntries = entries.sort(([, value1], [, value2]) => {
      if (frequency === "frequently") {
        return value2 - value1;
      } else {
        return value1 - value2;
      }
    });
    setDisplayedPage(1);
    return Object.fromEntries(sortedEntries);
  }, [data, frequency]);

  const perсentData = useMemo(() => {
    return calculatePercentages(sortedData);
  }, [sortedData]);

  const pageCount = Math.ceil(Object.keys(sortedData).length / PERPAGE);

  const handleOptionClick = (option: string) => {
    setGearSelectedOption(option);
  };

  const changePages = (value: number) => {
    setDisplayedPage(value);
  };

  const displayedData = useMemo(() => {
    const start = (displayedPage - 1) * PERPAGE;
    const end = start + PERPAGE;
    const result = Object.keys(sortedData).slice(start, end);
    return result;
  }, [displayedPage, sortedData]);

  const TableBlock = () => (
    <div className={styles.tableContainer}>
      <table className={styles.infoModuleTable}>
        <thead>
          <tr className={styles.infoModuleTableHeader}>
            <th>{`${title ? title : "Локация"}`}</th>
            <th>Частота использования</th>
          </tr>
        </thead>
      </table>
      <table className={styles.infoModuleTable}>
        <tbody>
          {displayedData.map((line: string) => (
            <tr key={line} className={styles.tableHeader}>
              <td>
                {isDesktop ? shortenString(line, 57) : shortenString(line, 46)}
              </td>
              <td>
                {gearSelectedOption === "Проценты"
                  ? `${perсentData[line]}%`
                  : sortedData[line]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );

  const DisplayBlock = () => {
    if (isLoading) {
      return (
        <div style={{ marginTop: 10 }}>
          <DashBoardLocationsSkeleton />
        </div>
      );
    }

    if (!!isEmptyData) {
      return <EmptyDataCap />;
    }

    return <TableBlock />;
  };

  return (
    <section className={styles.infoModuleContainer}>
      <div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>{title}</div>
          <GearButton
            options={gearOptions}
            selectedOption={gearSelectedOption}
            handleOptionClick={handleOptionClick}
          />
        </div>
        <div className={styles.controlsContainer}>
          <Checkbox
            label="Только эфирные"
            checked={sentToBroadCast}
            setChecked={toggleBroadcast}
            style={{ marginTop: 8 }}
          />
          <div className={styles.frequencySelect}>
            <Select
              name="frequency"
              options={[
                { id: "frequently", name: "Самые используемые" },
                { id: "rare", name: "Самые редкие" },
              ]}
              value={frequency}
              onChange={(event) => setFrequency(event.target.value)}
            />
          </div>
        </div>
      </div>
      <DisplayBlock />
      <div>
        <Pagination
          tasks={displayedData}
          isStepCounterDisabled={!isDesktop}
          totalTasks={isDesktop ? Object.keys(sortedData).length : undefined}
          counterTitle={`${title ? title : "Локаций"}`}
          page={displayedPage}
          perPage={PERPAGE}
          totalPages={pageCount}
          changePage={(value) => changePages(value)}
          style={{
            marginTop: "15px",
            float: "none",
            justifyContent: "center",
          }}
        />
      </div>
    </section>
  );
}

export default React.memo(InfoModule);
