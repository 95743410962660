import Button from "emg-ui-kit/components/Button";
import Modal from "emg-ui-kit/components/Modal";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import BackButton from "../common/BackButton";
import FlexContainer from "../common/FlexContainer";
import PageContainer from "../common/PageContainer";
import { convertToSelectOptions, useIsDesktop } from "../common/utils";
import yup from "../common/yup";
import { ValidationPropsUtils } from "../order/util";
import {
  deleteUserByIdThunk,
  getUserByIdThunk,
  updateUserByIdThunk,
} from "../redux/users";
import { selectUserById } from "../redux/users/selectors";
import routes from "../routes";
import { groups, userTypes } from "./userConstants";

const userTypeOptions = convertToSelectOptions(userTypes);
const groupOptions = convertToSelectOptions(groups);

const initialValues = {
  email: "",
  password: "",
  type: "",
  group: "",
  catalogUrl: "",
};

const validationSchema = yup.object().shape({
  type: yup.string().required(),
  group: yup.string().required(),
  catalogUrl: yup.string().url(),
});

export default function UserChangeForm() {
  const isDesktop = useIsDesktop();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const dispatch: (action: any) => Promise<void> = useDispatch();
  const user = useSelector(selectUserById(id));

  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const showDeleteModal = () => setDeleteModalVisible(true);
  const hideDeleteModal = () => setDeleteModalVisible(false);

  const handleDelete = () => {
    hideDeleteModal();
    setTimeout(() => {
      dispatch(deleteUserByIdThunk(id));
      history.push(routes.users);
    }, 100);
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(updateUserByIdThunk({ id, ...values }));
      history.push(routes.users);
    },
  });

  useEffect(() => {
    if (id) {
      dispatch(getUserByIdThunk(id));
    }
  }, [dispatch, id]);

  const { setValues } = formik;
  useEffect(() => {
    if (user) {
      setValues({
        email: user.email,
        password: "*".repeat(8),
        type: user.type,
        group: user.groups[0] ?? "all",
        catalogUrl: user.catalogUrl,
      });
    }
  }, [setValues, user]);

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const buttonStyle = { marginBottom: 10 };
  const buttonContainerStyle: React.CSSProperties = {
    flexDirection: isDesktop ? "row" : "column",
  };

  return (
    <PageContainer>
      <BackButton to={routes.users} />
      <Title text="Редактирование пользователя" />
      <FormikProvider value={formik}>
        <Form>
          <Field
            as={TextField}
            type="email"
            label="Email"
            name="email"
            disabled
          />
          <Field
            as={TextField}
            type="password"
            label="Пароль"
            name="password"
            disabled
          />
          <Field
            as={Select}
            name="type"
            label="Тип пользователя"
            options={userTypeOptions}
          />
          <Field
            as={Select}
            name="group"
            label="Группа"
            options={groupOptions}
          />
          <Field
            as={TextField}
            name="catalogUrl"
            label="Каталог графики"
            {...validationUtils.getProps("catalogUrl")}
          />
          <br />
          <FlexContainer style={buttonContainerStyle}>
            <Button
              title="Применить"
              type="submit"
              status={formik.isSubmitting ? "loading" : "enabled"}
              style={buttonStyle}
            />
            <Button
              color="red"
              title="Удалить"
              onClick={showDeleteModal}
              style={buttonStyle}
            />
          </FlexContainer>
          <Modal visible={deleteModalVisible} close={hideDeleteModal}>
            <div style={{ padding: 20, paddingTop: 0 }}>
              <div>
                Вы точно хотите удалить пользователя{" "}
                <strong>{user?.email}</strong>
              </div>
              <br />
              <FlexContainer style={buttonContainerStyle}>
                <Button
                  title="Назад"
                  onClick={hideDeleteModal}
                  style={buttonStyle}
                />
                <Button
                  title="Удалить"
                  color="red"
                  onClick={handleDelete}
                  style={buttonStyle}
                />
              </FlexContainer>
            </div>
          </Modal>
        </Form>
      </FormikProvider>
    </PageContainer>
  );
}
