import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Reference({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Тип заголовка" value={data.titleType} />

      <InfoRow title="Источник информации" value={data.source} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />

      {data.titles &&
        (data.titles as Record<string, any>[]).map((item, idx) => (
          <details key={idx}>
            <summary>Блок заголовков №{idx + 1}</summary>
            <InfoRow title="Текст" value={item.text} />
          </details>
        ))}
      {data.subtitles &&
        (data.subtitles as Record<string, any>[]).map((item, idx) => (
          <details key={idx}>
            <summary>Блок подзаголовков №{idx + 1}</summary>
            <InfoRow title="Текст" value={item.text} />
          </details>
        ))}

      {(data.textItems as Record<string, any>[]).map((item, idx) => (
        <details key={idx}>
          <summary>Текстовый блок №{idx + 1}</summary>
          <InfoRow title="Текст" value={item.texts.join("\n")} />
        </details>
      ))}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Фото блок №{idx + 1}</summary>
          <ImageRow title="Фото" src={block.image} />
          {block.imageSource && (
            <>
              <InfoRow title="Источник фото" value={block.imageSource} />
              <InfoRow title="Цвет источника" value={block.imageSourceColor} />
            </>
          )}
        </details>
      ))}
    </>
  );
}
