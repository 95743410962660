import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import LegendInput from "../../common/LegendInput";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { m24PiechartColors } from "../../common/texts";
import { useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  getValidationProps,
  MAX_TIMING,
  getDeepValidationProps,
  CLIP_NAME_REGEXP,
  pick,
  IMAGE_TYPES,
  validatePositive,
  validateIncorrectFormat,
  validateNotEmpty,
  validateMax,
  removeEmptyProps,
  validateSumEqualOneHundred,
  validateAspect,
  removeTouched,
} from "../util";

const IMAGE_ASPECT = 1;

type Item = {
  legend: string;
  value: number;
  color: string;
};

function createItem(index: number): Item {
  const colorIndex = index % m24PiechartColors.length;
  return {
    value: 0,
    color: m24PiechartColors[colorIndex],
    legend: "",
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), (_, idx) => createItem(idx));
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    title: (initialFormData?.title ?? "") as string,
    subtitle: (initialFormData?.subtitle ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    pieText: (initialFormData?.pieText ?? "") as string,
    pieTextSize: (initialFormData?.pieTextSize ?? 71) as number,
    percentage: (initialFormData?.percentage ?? "false") as "false" | "true",
    commentSize: (initialFormData?.commentSize ?? 51) as number,
    valueSize: (initialFormData?.valueSize ?? 61) as number,
    timing: (initialFormData?.timing ?? 30) as number,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    title: validateNotEmpty(values.title),
    pieTextSize: validatePositive(values.pieTextSize),
    timing:
      validatePositive(values.timing) ?? validateMax(values.timing, MAX_TIMING),
    commentSize: validatePositive(values.commentSize),
    valueSize: validatePositive(values.valueSize),
    items: values.items.map((item: Item) => ({
      legend: validateNotEmpty(item.legend),
      value:
        values.percentage === "true"
          ? validateSumEqualOneHundred(values.items.map((obj) => +obj.value))
          : validatePositive(item.value),
      color: validateNotEmpty(item.color),
    })),
    image: values.image && validateAspect(values.image, IMAGE_ASPECT),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: ReturnType<typeof getInitialValues>) {
  return {
    ...pick(
      values,
      "clipName",
      "title",
      "subtitle",
      "source",
      "image",
      "pieText",
      "pieTextSize",
      "commentSize",
      "valueSize",
      "timing",
    ),
    percentage: values.percentage === "true" ? true : false,
    blocks: values.items.map((item) => ({
      ...item,
      value: isNaN(+item.value) ? 0 : +item.value,
    })),
  };
}

function M24PieChartForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
  } = formik;

  usePreview(channel, template, values.timing, values, prepareData);

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          rows={2}
          required
          {...getValidationProps("title", touched, errors)}
        />
        <Field as={TextArea} rows={2} label="Подзаголовок" name="subtitle" />
        <Field as={TextField} label="Источник" name="source" />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={values.image}
          updateImage={(image) => {
            setFieldValue("pieText", "");
            setFieldTouched("image");
            setFieldValue("image", image);
          }}
          style={isDesktop ? { marginLeft: 210 } : {}}
          title="Иконка диаграммы"
          aspect={IMAGE_ASPECT}
          {...getValidationProps("image", touched, errors)}
        />
        <Field
          as={TextArea}
          rows={2}
          label="Текст диаграммы"
          name="pieText"
          type="number"
          {...getValidationProps("pieText", touched, errors)}
          disabled={!!values.image}
        />
        <Field
          as={TextField}
          label="Размер текста внутри диаграммы"
          name="pieTextSize"
          type="number"
          onChange={(event: any) =>
            setFieldValue("pieTextSize", +event.target.value)
          }
          {...getValidationProps("pieTextSize", touched, errors)}
          required
        />
        <Field
          as={Select}
          name="percentage"
          label="Проценты"
          options={[
            { id: "false", name: "Без процентов" },
            { id: "true", name: "С процентами" },
          ]}
        />
        <Field
          as={TextField}
          label="Размер легенды"
          name="commentSize"
          type="number"
          onChange={(event: any) =>
            setFieldValue("commentSize", +event.target.value)
          }
          {...getValidationProps("commentSize", touched, errors)}
          required
        />
        <Field
          as={TextField}
          label="Размер значений"
          name="valueSize"
          type="number"
          onChange={(event: any) =>
            setFieldValue("valueSize", +event.target.value)
          }
          {...getValidationProps("valueSize", touched, errors)}
          required
        />
        <Field
          as={TextField}
          label="Хронометраж (сек)"
          name="timing"
          type="number"
          onChange={(event: any) =>
            setFieldValue("timing", +event.target.value)
          }
          {...getValidationProps("timing", touched, errors)}
          required
        />
        <Blocks
          items={values.items}
          updateItems={(items) => {
            setFieldValue("items", items);
          }}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          defaultItemConstructor={createItem}
          blockTitle="Элемент"
          canChangeLength
          maxBlocks={6}
        >
          {(item, index) => (
            <>
              <Field
                as={TextField}
                placeholder="Значение"
                name={`items[${index}].value`}
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (/[^\-.0-9]/.test(value)) return;
                  const key = `items[${index}].value`;
                  setFieldValue(key, value);
                }}
                {...getDeepValidationProps(
                  `items[${index}].value`,
                  touched,
                  errors,
                )}
                required
              />
              <LegendInput
                placeholder="Легенда"
                color={item.color}
                title={item.legend}
                colors={m24PiechartColors}
                updateColor={(color: any) => {
                  setFieldTouched(`items[${index}].color`);
                  setFieldValue(`items[${index}].color`, color);
                }}
                updateTitle={(title: string) => {
                  setFieldValue(`items[${index}].legend`, title);
                }}
                onBlur={() => {
                  setFieldTouched(`items[${index}].legend`);
                }}
                {...getDeepValidationProps(
                  `items.${index}.legend`,
                  touched,
                  errors,
                )}
                maxLines={2}
                required
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24PieChartForm);
