import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  error: "",
};

const errorSlice = createSlice({
  name: "error",
  initialState,
  reducers: {
    setError(state, action) {
      state.error = action.payload;
    },
    clearError(state) {
      state.error = "";
    },
  },
});

export const { setError, clearError } = errorSlice.actions;
export const customErrors = errorSlice.reducer;
