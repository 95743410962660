import IconButton from "emg-ui-kit/components/IconButton";
import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PageContainer from "../../../common/PageContainer";
import { SubTableItem, Order, Table } from "../../../common/Table";
import { Task } from "../../../common/models";
import { findThemeName, templates } from "../../../common/texts";
import { dateFormatter } from "../../../common/utils";
import TaskTableSkeleton from "../../../components/Skeletons/TaskTableSkeleton";
import { selectUserInfo } from "../../../redux/auth/selectors";
import { setRootFilter } from "../../../redux/filters/filters.reducer";
import { cancelTaskByIdThunk, getTasksThunk } from "../../../redux/tasks";
import { selectTasksByPage } from "../../../redux/tasks/selectors";
import routes from "../../../routes";
import { AppDispatch, RootState } from "../../../store";
import StatusBadge from "../../components/StatusBadge/StatusBadge";
import NewPagination from "./NewPagination";
import SideMenu from "./SideMenu";
import styles from "./TaskTable.module.css";

const getTemplate = (task: Task) =>
  templates[task.channel]?.[task.template] ?? "-";

const getTheme = (task: Task) =>
  task.data.theme?.name ??
  findThemeName(task.data.theme, task.data.background) ??
  "-";

const getDate = (task: Task) =>
  dateFormatter.format(Date.parse(task.creationDate));
const getStatus = (task: Task) => (
  <StatusBadge
    status={task.status}
    progress={task.progress}
    renderProgress={task.renderProgress}
  />
);

const getClipName = (task: Task) => task.data.clipName || task.data.name || "-";

export default function TaskTablePage() {
  const filterState = useSelector((store: RootState) => store.filter);
  const dispatch = useDispatch<AppDispatch>();

  const [cancelDisabled, setCancelDisabled] = useState(false);
  const { tasks, isLoading, totalPages } = useSelector(
    selectTasksByPage(filterState.page, filterState.perPage)
  );
  const userInfo = useSelector(selectUserInfo)!;
  const history = useHistory();

  const handleCancelOrder = useCallback(
    (id: string) => {
      if (window.confirm("Вы уверены, что хотите отменить заказ?")) {
        setCancelDisabled(true);
        return (dispatch(cancelTaskByIdThunk(id)) as any)
          .then(() => {
            setCancelDisabled(false);
          })
          .catch(() => {
            setCancelDisabled(false);
          });
      }
    },
    [dispatch]
  );

  const isR24 = userInfo.groups.includes("r24");

  const subtableList: SubTableItem[] = [
    { m24: "atmosphere" },
    { m24: "atmosphere_info" },
  ];

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    dispatch(getTasksThunk(filterState));
  }, [dispatch, filterState]);

  useEffect(() => {
    if (tasks.length !== 0 && tasks.length < filterState.perPage * 0.7) {
      scrollToTop();
    }
  }, [tasks.length, filterState.perPage]);

  const ComponentToShow = () => {
    if (isLoading) {
      return <TaskTableSkeleton />;
    }
    if (!tasks.length) {
      return <div className={styles.noOrders}>Нет заказов</div>;
    }
    return (
      <Table
        items={tasks}
        displayProps={[
          {
            name: "Шаблон",
            selector: getTemplate,
            className: styles.taskTitle,
          },
          ...(filterState.allUsers
            ? [{ name: "Постановщик задачи", selector: "email" }]
            : []),
          ...(isR24
            ? [
                { name: "Оформление", selector: getTheme },
                { name: "Название", selector: "data.name" },
              ]
            : [{ name: "Название ролика", selector: getClipName }]),
          {
            name: "Статус",
            selector: (task) => (
              <div style={{ display: "flex", alignItems: "baseline" }}>
                {getStatus(task)}
                {userInfo.type === "admin" &&
                  task.status !== "done" &&
                  task.status !== "failed" && (
                    <div style={{ marginLeft: 15 }}>
                      <IconButton
                        onClick={(event) => {
                          event.stopPropagation();
                          handleCancelOrder(task._id);
                        }}
                        design="close"
                        status={cancelDisabled ? "disabled" : "enabled"}
                      />
                    </div>
                  )}
              </div>
            ),
            headerClassName: styles.statusHeader,
          },
          {
            name: "Время заказа",
            selector: getDate,
            headerClassName: styles.dateHeader,
            className: styles.dateCell,
            currentSort: true,
          },
        ]}
        onItemClick={(task) => history.push(`${routes.tasksList}/${task._id}`)}
        order={filterState.order as Order}
        changeOrder={(value) => dispatch(setRootFilter({ order: value }))}
        idPropName="_id"
        subtable={subtableList}
      />
    );
  };

  return (
    <PageContainer className={styles.pageContainer}>
      <SideMenu />
      <section className={styles.workspace}>
        <Title text="Список заказов" />

        <ComponentToShow />
        {tasks.length > 0 && (
          <NewPagination
            tasks={tasks}
            page={filterState.page}
            perPage={filterState.perPage}
            totalPages={totalPages}
            changePage={(value) => dispatch(setRootFilter({ page: value }))}
            pageChangingToolAvailable
          />
        )}
      </section>
    </PageContainer>
  );
}
