import { SelectSearch } from "emg-ui-kit/components/SelectSearch";
import { Field, useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { InfographicsItem } from "../../types";

interface Props {
  item: InfographicsItem;
  index: number;
  formParams?: AtmosphereFormParams;
}

type Option = { id: string; name: string };

export default function InfographicsBlock({ item, index, formParams }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const clipOptions = (formParams?.infographics ?? []).map(({ name }) => ({
    id: name,
    name,
  }));
  const clipName = getItemFieldName(index, "clip");

  const updateInfographics = (option: Option) => {
    const infographic = formParams?.infographics.find(
      (info) => info.name === option.name,
    );
    const nextItem = {
      ...item,
      clip: option.name,
      timing: infographic?.timing ?? "0",
    };

    formik.setFieldValue(`items.${index}`, nextItem);
  };

  return (
    <>
      <Field
        as={SelectSearch}
        placeholder="Выбрать ролик"
        name={clipName}
        options={clipOptions}
        selected={item.clip && { id: item.clip, name: item.clip }}
        {...validationUtils.getDeepProps(clipName)}
        updateSelected={updateInfographics}
      />
      <div style={{ color: "#979797" }}>Хронометраж {item.timing} сек.</div>
    </>
  );
}
