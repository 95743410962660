import { createSlice } from "@reduxjs/toolkit";

import { Feedback } from "../../tasks/components/Filters/FilterTypes";
import {
  addedGroupNewArray,
  addedKeyNewArray,
  arrayContainsAllElements,
  deletedGroupNewArray,
  deletedKeyNewArray,
  getDefaultState,
  restoreFilterState,
} from "../../tasks/components/Filters/filterUtils";

type TemplatesAction = {
  payload: {
    value: string;
  };
};

type BitrixTemplatesAction = {
  payload: {
    value: number;
  };
};

type SetFeedBackFilter = {
  payload: {
    filter: keyof Feedback;
    value: boolean;
  };
};

const initialState = restoreFilterState();

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    setTemplateFilter: (state, action: TemplatesAction) => {
      const { value } = action.payload;
      state.page = 1;
      if (state.templates.includes(value)) {
        state.templates = deletedKeyNewArray(state.templates, value);
      } else {
        state.templates = addedKeyNewArray(state.templates, value) as string[];
      }
    },
    setBitrixTemplateFilter: (state, action: BitrixTemplatesAction) => {
      const { value } = action.payload;
      state.page = 1;
      if (state.bitrixTemplates.includes(value)) {
        state.bitrixTemplates = deletedKeyNewArray(
          state.bitrixTemplates,
          value
        );
      } else {
        state.bitrixTemplates = addedKeyNewArray(
          state.bitrixTemplates,
          value
        ) as number[];
      }
    },
    setBitrixGroupCheckedFilter: (state, action) => {
      const { value } = action.payload;
      state.page = 1;
      const array = Object.keys(value).map(Number);

      if (arrayContainsAllElements(state.bitrixTemplates, array)) {
        state.bitrixTemplates = deletedGroupNewArray(
          state.bitrixTemplates,
          value,
          true
        ) as number[];
      } else {
        state.bitrixTemplates = addedGroupNewArray(
          state.bitrixTemplates,
          value,
          true
        ) as number[];
      }
    },
    setGroupCheckedFilter: (state, action) => {
      const { value } = action.payload;
      state.page = 1;
      const array = Object.keys(value);

      if (arrayContainsAllElements(state.templates, array)) {
        state.templates = deletedGroupNewArray(
          state.templates,
          value
        ) as string[];
      } else {
        state.templates = addedGroupNewArray(
          state.templates,
          value
        ) as string[];
      }
    },
    setFeedbackFilter: (state, action: SetFeedBackFilter) => {
      const { filter, value } = action.payload;
      state.page = 1;
      state.feedback[filter] = value;
    },
    setRootFilter: (state, action) => {
      Object.assign(state, action.payload);
    },
    nextPage: (state) => {
      state.page += 1;
    },
    setStartDate: (state, action) => {
      state.page = 1;
      state.startDate = action.payload.value;
    },
    setEndDate: (state, action) => {
      state.page = 1;
      state.endDate = action.payload.value;
    },
    resetFilters: () => getDefaultState(),
    removeTemplates: (state) => {
      state.templates = [];
    },
    removeBitrixTemplates: (state) => {
      state.bitrixTemplates = [];
    },
  },
});

export const {
  setTemplateFilter,
  setBitrixTemplateFilter,
  setBitrixGroupCheckedFilter,
  setGroupCheckedFilter,
  setFeedbackFilter,
  setRootFilter,
  nextPage,
  setStartDate,
  setEndDate,
  resetFilters,
  removeTemplates,
  removeBitrixTemplates,
} = filterSlice.actions;

const filterReducer = filterSlice.reducer;
export default filterReducer;
