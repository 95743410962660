import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";

import {
  createPerson,
  deletePerson,
  getPerson,
  getPersons,
  Person,
  updatePerson,
} from "../../common/ApiService";

export interface PersonState {
  byId: Record<string, Person | undefined>;
  ids: string[];
  isLoading: boolean;
}

const initialState: PersonState = {
  byId: {},
  ids: [],
  isLoading: false,
};

export const getPersonsThunk = createAsyncThunk("persons/get", getPersons);

export const getPersonByIdThunk = createAsyncThunk(
  "persons/getById",
  getPerson
);

export const createPersonThunk = createAsyncThunk(
  "/persons/create",
  createPerson
);

export const updatePersonByIdThunk = createAsyncThunk(
  "persons/updateById",
  updatePerson
);

export const deletePersonByIdThunk = createAsyncThunk(
  "persons/deleteById",
  deletePerson
);

const personsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(deletePersonByIdThunk.fulfilled, (state, action) => {
      delete state.byId[action.payload.id];
      const deletedIndex = state.ids.indexOf(action.payload.id);
      if (deletedIndex > -1) {
        state.ids.splice(deletedIndex, 1);
      }
    })
    .addCase(getPersonsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getPersonsThunk.fulfilled, (state, action) => {
      const ids = action.payload.map((person) => person.id);
      const byId = action.payload.reduce<Record<string, Person>>(
        (acc, person) => {
          acc[person.id] = person;
          return acc;
        },
        {}
      );
      state.ids = ids;
      state.byId = byId;
      state.isLoading = false;
    })
    .addCase(getPersonsThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addMatcher(
      isFulfilled(createPersonThunk, getPersonByIdThunk, updatePersonByIdThunk),
      (state, action) => {
        state.byId[action.payload.id] = action.payload;
        state.isLoading = false;
      }
    )
    .addMatcher(
      isPending(createPersonThunk, getPersonByIdThunk, updatePersonByIdThunk),
      (state) => {
        state.isLoading = true;
      }
    )
    .addMatcher(
      isRejected(createPersonThunk, getPersonByIdThunk, updatePersonByIdThunk),
      (state) => {
        state.isLoading = false;
      }
    );
});

export default personsReducer;
