import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";

import { deleteDraftByIdThunk } from "../../redux/drafts";
import { AppDispatch } from "../../store";

export default function useDeleteDraft(cb: () => void) {
  const dispatch = useDispatch<AppDispatch>();

  const [isDisabled, setIsDisabled] = useState(false);

  const handleDeleteDraft = useCallback(
    (id: string) => {
      if (window.confirm("Вы уверены, что хотите удалить черновик?")) {
        setIsDisabled(true);
        return (dispatch(deleteDraftByIdThunk(id)) as any)
          .then(() => {
            setIsDisabled(false);
            cb();
          })
          .catch(() => {
            setIsDisabled(false);
          });
      }
    },
    [dispatch, cb]
  );

  return { handleDeleteDraft, isDisabled };
}
