import React, { useState } from "react";

import { PreviewContext } from "./PreviewContext";

export default function PreviewProvider(props: React.PropsWithChildren<{}>) {
  const [data, setData] = useState<Record<string, any>>({});
  const [timing, setTiming] = useState(0);
  const value = { data, setData, timing, setTiming };
  return (
    <PreviewContext.Provider value={value}>
      {props.children}
    </PreviewContext.Provider>
  );
}
