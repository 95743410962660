import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import {
  BitrixCustomerAvailableTemplates,
  getBitrixTemplates,
} from "../../common/ApiService";

type State = {
  isLoading: boolean;
  isFormValid: boolean;
  customerProjects: BitrixCustomerAvailableTemplates[] | null;
};

const initialState: State = {
  isLoading: false,
  isFormValid: true,
  customerProjects: null,
};

export const setBitrixLoading = createAction<boolean>("bitrix/loading");

export const setIsFormValid = createAction<boolean>("bitrix/isFormValid");

export const getBitrixTemplatesThunk = createAsyncThunk(
  "bitrix/projects",
  getBitrixTemplates
);

const bitrixReducer = createReducer(initialState, (builder) => {
  builder.addCase(setBitrixLoading, (state, action) => {
    state.isLoading = action.payload;
  });
  builder.addCase(setIsFormValid, (state, action) => {
    state.isFormValid = action.payload;
  });

  builder.addCase(getBitrixTemplatesThunk.fulfilled, (state, action) => {
    state.customerProjects = action.payload;
    state.isLoading = false;
  });
  builder.addCase(getBitrixTemplatesThunk.pending, (state) => {
    state.isLoading = true;
  });
  builder.addCase(getBitrixTemplatesThunk.rejected, (state, action) => {
    state.isLoading = false;
  });
});

export default bitrixReducer;
