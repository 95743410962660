import { User } from "../../common/ApiService";
import { RootState } from "../../store";

export const selectUsers = (state: RootState) => ({
  users: state.users.ids.map((id) => state.users.byId[id]) as User[],
  isLoading: state.users.isLoading,
});

export const selectUserById = (id: string) => (state: RootState) =>
  state.users.byId[id] as User | undefined;
