import Checkbox from "emg-ui-kit/components/Checkbox";
import React from "react";
import { useDispatch } from "react-redux";

import { AppDispatch } from "../../../store";

export default function CheckboxList({
  items,
  itemsState,
  dispatchFunc,
  isNumericIds = false,
}: {
  items: Record<string | number, string>;
  itemsState: string[] | number[];
  dispatchFunc: any;
  isNumericIds?: boolean;
}) {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <>
      {Object.entries(items).map(([key, name]) => {
        const properKey = isNumericIds ? Number(key) : key;
   
        return (
          <Checkbox
            key={`${key}-checkbox`}
            label={name}
            checked={itemsState.includes(properKey as never)}
            setChecked={() => {
              dispatch(
                dispatchFunc({
                  value: properKey,
                })
              );
            }}
          />
        );
      })}
    </>
  );
}
