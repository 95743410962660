import React from "react";

import {
  AddresOrientation,
  AddressOrient,
} from "../../../../../components/TheCityMap/types";
import { InfoRow } from "../InfoComponents";

export function TheCity({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.clipName && (
        <InfoRow title="Название ролика" value={data.clipName} />
      )}
      <details open>
        <summary>Данные о точках на карте:</summary>
        <div style={{ marginBottom: 10 }} />
        {(data.mapData.points as Record<string, any>[]).map((point, id) => (
          <InfoRow
            key={id}
            title={`Точка №${id + 1}`}
            value={`${point.text}, ${
              AddresOrientation[point.align as AddressOrient]
            }`}
          />
        ))}
      </details>
    </>
  );
}
