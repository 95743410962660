import React from "react";

import { ColorBox, InfoRow } from "../InfoComponents";

export function ChartLinear({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      {data.subtitle && <InfoRow title="Подзаголовок" value={data.subtitle} />}
      {data.lastValue && (
        <InfoRow title="Последнее значение" value={data.lastValue} />
      )}
      {data.delta && <InfoRow title="Дельта" value={data.delta} />}
      {data.leftCaption && (
        <InfoRow title="Подпись слева" value={data.leftCaption} />
      )}
      {data.rightCaption && (
        <InfoRow title="Подпись справа" value={data.rightCaption} />
      )}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>График №{idx + 1}</summary>
          <InfoRow title="Значения" value={block.values} />
          <InfoRow title="Легенда" value={block.legend} />
          <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
        </details>
      ))}
    </>
  );
}
