import Modal from "emg-ui-kit/components/Modal";
import Spinner from "emg-ui-kit/components/Spinner";
import React from "react";

import styles from "./RmBgModal.module.css";

export default function RmBgModal({
  modalVisible,
  onClose,
}: {
  modalVisible: boolean;
  onClose: () => void;
}) {
  return (
    <Modal className={styles.modal} visible={modalVisible} close={onClose}>
      <span className={styles.text}>
        Идет обработка изображения, пожалуйста подождите
      </span>
      <Spinner style={{ display: "block", margin: "20 auto" }} />
    </Modal>
  );
}
