import React from "react";

import styles from "./SubMenu.module.css";

export default function Submenu({
  options,
  selectedOption,
  handleOptionClick,
}: {
  options: string[];
  selectedOption?: string;
  handleOptionClick: (option: string) => void;
}) {
  return (
    <div className={styles.subMenuContainer}>
      <div className={styles.submenu}>
        {options.map((option) => (
          <button
            key={option}
            className={
              selectedOption === option
                ? styles.selectedSubmenuItem
                : styles.submenuItem
            }
            onClick={() => {
              handleOptionClick(option);
            }}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}
