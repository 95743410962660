import React from "react";

import styles from "./Tooltip.module.css";

export interface TooltipProps {
  text: string;
  tooltipVisible: boolean;
}

export default function Tooltip({ text, tooltipVisible }: TooltipProps) {
  return (
    <section
      className={styles.tooltipContainer}
      style={{
        opacity: tooltipVisible ? 1 : 0,
        transition: tooltipVisible ? "0.5s" : "0.1s",
      }}
    >
      <div className={styles.tooltip}>{text}</div>
    </section>
  );
}
