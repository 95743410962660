import React from "react";
import ContentLoader from "react-content-loader";

export default function DraftTableSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={1200}
      height={430}
      viewBox="0 0 1200 430"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
    >
      <path d="M 0 0 h 1200 v 24 H 0 z M 0 42 h 285.917 v 28 H 0 z M 302.923 42 h 331.621 v 28 H 302.923 z M 651.551 42 h 250.841 v 28 H 651.551 z M 919.394 42 h 280.602 v 28 H 919.394 z M 0 202 h 285.917 v 28 H 0 z M 302.923 202 h 331.621 v 28 H 302.923 z M 651.551 202 h 250.841 v 28 H 651.551 z M 919.394 202 h 280.602 v 28 H 919.394 z M 0 82 h 285.917 v 28 H 0 z M 302.923 82 h 331.621 v 28 H 302.923 z M 651.551 82 h 250.841 v 28 H 651.551 z M 919.394 82 h 280.602 v 28 H 919.394 z M 0 242 h 285.917 v 28 H 0 z M 302.923 242 h 331.621 v 28 H 302.923 z M 651.551 242 h 250.841 v 28 H 651.551 z M 919.394 242 h 280.602 v 28 H 919.394 z M 0 122 h 285.917 v 28 H 0 z M 302.923 122 h 331.621 v 28 H 302.923 z M 651.551 122 h 250.841 v 28 H 651.551 z M 919.394 122 h 280.602 v 28 H 919.394 z M 0 282 h 285.917 v 28 H 0 z M 302.923 282 h 331.621 v 28 H 302.923 z M 651.551 282 h 250.841 v 28 H 651.551 z M 919.394 282 h 280.602 v 28 H 919.394 z M 0 362 h 285.917 v 28 H 0 z M 302.923 362 h 331.621 v 28 H 302.923 z M 651.551 362 h 250.841 v 28 H 651.551 z M 919.394 362 h 280.602 v 28 H 919.394 z M 0 162 h 285.917 v 28 H 0 z M 302.923 162 h 331.621 v 28 H 302.923 z M 651.551 162 h 250.841 v 28 H 651.551 z M 919.394 162 h 280.602 v 28 H 919.394 z M 0 322 h 285.917 v 28 H 0 z M 302.923 322 h 331.621 v 28 H 302.923 z M 651.551 322 h 250.841 v 28 H 651.551 z M 919.394 322 h 280.602 v 28 H 919.394 z M 0 402 h 285.917 v 28 H 0 z M 302.923 402 h 331.621 v 28 H 302.923 z M 651.551 402 h 250.841 v 28 H 651.551 z M 919.394 402 h 280.602 v 28 H 919.394 z" />
    </ContentLoader>
  );
}
