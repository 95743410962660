import Checkbox from "emg-ui-kit/components/Checkbox";
import React, { useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import DetailsGroup from "../../../components/Details/DetailsGroup";
import {
  setBitrixTemplateFilter,
  setBitrixGroupCheckedFilter,
} from "../../../redux/filters/filters.reducer";
import { AppDispatch, RootState } from "../../../store";
import CheckboxList from "./CheckboxList";
import { Templates } from "./FilterTypes";
import {
  arrayContainsAllElements,
  arrayContainsSomeElements,
} from "./filterUtils";
import { useDetails } from "./useDetails";

export function useBitrixTemplates() {
  const { customerProjects, isLoading } = useSelector(
    (state: RootState) => state.bitrix
  );
  const programs = useMemo(() => {
    const result: Templates = {};
    if (customerProjects?.length) {
      customerProjects.forEach((prj) => {
        result[prj.name] = {};
        for (const template of prj.graphics) {
          result[prj.name][template.id] = template.name;
        }
      });
    }
    return result;
  }, [customerProjects]);
  return { programs, isLoading };
}

export default function ProjectsGroups() {
  const { programs } = useBitrixTemplates();

  const projects = Object.keys(programs);
  const dispatch = useDispatch<AppDispatch>();
  const filterState = useSelector((store: RootState) => store.filter);

  const { groups, handleDetails } = useDetails({
    groupsList: projects,
    groupStorageName: "bitrix_projects_details",
  });

  return (
    <>
      {projects?.map((project) => {
        const groupKeys = Object.keys(programs[project]).map(Number);

        const allGroupChecked = arrayContainsAllElements(
          filterState.bitrixTemplates,
          groupKeys
        );

        const partChecked = arrayContainsSomeElements(
          groupKeys,
          filterState.bitrixTemplates
        );

        return (
          <DetailsGroup
            key={project}
            style={{ marginTop: 0 }}
            open={groups[project]}
            onClick={(event) => handleDetails(project, event)}
            arrowWithMargin
            noHeaderOnlick
            headerComponent={
              <Checkbox
                label={project}
                checked={allGroupChecked || partChecked}
                setChecked={(val) => {
                  dispatch(
                    setBitrixGroupCheckedFilter({
                      value: programs[project],
                    })
                  );
                }}
                style={{
                  fontFamily: '"Futura New Bold", sans-serif',
                }}
                square={!allGroupChecked && partChecked}
              />
            }
            bodyComponent={
              <CheckboxList
                items={programs[project]}
                itemsState={filterState.bitrixTemplates || []}
                dispatchFunc={setBitrixTemplateFilter}
                isNumericIds
              />
            }
          />
        );
      })}
    </>
  );
}
