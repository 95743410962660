import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { sources } from "../../common/texts";
import { convertToSelectOptions, useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  MAX_TIMING,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateMaxChars,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  validateTimingSum,
  ValidationPropsUtils,
  removeTouched,
} from "../util";
import { THEMES, THEMES_FULL } from "./constants";

type Item = {
  quote: string;
  timing: number | string;
};

function createItem(): Item {
  return { quote: "", timing: 5 };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    title: (initialFormData?.title ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    doc: (initialFormData?.document ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    emblem: (initialFormData?.emblem ?? false) as boolean,
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 60) as number | string,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? initItems()) as Item[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? +values.timing
    : values.items.reduce((acc, item) => acc + +item.timing, 0);
}

function validate(values: Values) {
  const quoteMaxChars =
    (7 + Number(!values.title) * 3 + Number(!values.source)) * 70;

  const errors = {
    name: values.name
      ? validateIncorrectFormat(values.name, CLIP_NAME_REGEXP)
      : undefined,
    theme: validateNotEmpty(values.theme),
    timing:
      values.timingType === "total"
        ? validateNumber(values.timing) ??
          validatePositive(+values.timing) ??
          validateMax(+values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      timing:
        values.timingType === "separate"
          ? validateNumber(item.timing) ??
            validatePositive(+item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
      quote:
        validateNotEmpty(item.quote) ??
        validateMaxChars(item.quote, quoteMaxChars),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    theme: THEMES_FULL[values.theme],
    name: values.name,
    title: values.title,
    document: values.doc,
    source: values.source,
    emblem: values.emblem,
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items.map((block) => ({
      quote: block.quote,
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
  };
}

function StatementForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  usePreview(
    channel,
    template,
    calcTotalTiming(formik.values),
    formik.values,
    prepareData,
  );

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };
  return (
    <FormikProvider value={formik}>
      <Form>
        <Field as={TextField} label="Название ролика" name="name" />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          {...validationUtils.getProps("name")}
          rows={2}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          {...validationUtils.getProps("theme")}
          required
        />
        <Field as={TextField} label="Документ" name="doc" />
        <Field
          as={TextField}
          label="Источник"
          name="source"
          dataList={sources}
        />
        <Checkbox
          checked={formik.values.emblem}
          setChecked={(value) => formik.setFieldValue("emblem", value)}
          label="Герб"
          style={{ marginLeft: isDesktop ? 210 : 0 }}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждого слайда" },
          ]}
        />
        {formik.values.timingType === "total" && (
          <Field
            as={TextField}
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            {...validationUtils.getProps("timing")}
            required
          />
        )}
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, formik.touched, formik.setTouched);
          }}
          defaultItemConstructor={createItem}
          canChangeLength
        >
          {(item, index) => (
            <>
              {formik.values.timingType === "separate" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ marginRight: 20 }}>Хронометраж (сек.)</div>
                  <Field
                    as={TextField}
                    name={`items.${index}.timing`}
                    type="number"
                    {...validationUtils.getDeepProps(`items.${index}.timing`)}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <Field
                as={TextArea}
                placeholder="Цитата"
                name={`items.${index}.quote`}
                {...validationUtils.getDeepProps(`items.${index}.quote`)}
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(StatementForm);
