import React from "react";

const defaultValue = {
  timing: 0,
  data: {} as Record<string, any>,
  setTiming: (val: number) => {},
  setData: (val: Record<string, any>) => {},
};

export const PreviewContext = React.createContext(defaultValue);
