import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Number({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Цифра" value={data.number} />
      {data.units && <InfoRow title="Единица измерения" value={data.units} />}
      {data.caption && <InfoRow title="Подпись" value={data.caption} />}
      <InfoRow title="Пояснение" value={data.description} />
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <ImageRow title="Основное фото" src={data.mainImage} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Изображение №{idx + 1}</summary>
          <ImageRow title="Фото" src={block.image} />
        </details>
      ))}
    </>
  );
}
