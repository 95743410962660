import React from "react";
import { useHistory } from "react-router-dom";

import routes from "../routes";
import styles from "./Header.module.css";
import logo from "./logo.svg";

export default function LogoButton() {
  const history = useHistory();
  const handleClick = () => {
    history.push(routes.index);
  };
  return (
    <img
      src={logo}
      alt="logo"
      role="button"
      className={styles.logo}
      onClick={handleClick}
    />
  );
}
