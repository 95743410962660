import React, { useState } from "react";
import { Link } from "react-router-dom";

import { Task } from "../../../common/models";
import useCallbackOnVisible from "../../../common/useCallbackOnVisible";
import TaskListSkeleton from "../../../components/Skeletons/TaskListSkeleton";
import routes from "../../../routes";
import SubList from "./SubList";
import styles from "./TaskList.module.css";
import TaskRow from "./TaskRow";

interface Props {
  tasks: Task[];
  loadNext: () => void;
  isLoading: boolean;
}

export default function TaskList({ tasks, loadNext, isLoading }: Props) {
  const callbackRef = useCallbackOnVisible(loadNext);
  const [openSubtables, setOpenSubtables] = useState<Set<string>>(new Set());
  const handleToggleSubtable = (itemId: string) => {
    setOpenSubtables((prevOpenSubtables) => {
      const updatedSet = new Set(prevOpenSubtables);
      if (prevOpenSubtables.has(itemId)) {
        updatedSet.delete(itemId);
      } else {
        updatedSet.add(itemId);
      }
      return updatedSet;
    });
  };

  return (
    <>
      {tasks.map((task, idx) => (
        <React.Fragment key={task._id}>
          <Link
            to={`${routes.tasksList}/${task._id}`}
            key={task._id}
            className={styles.taskLink}
          >
            <TaskRow
              task={task}
              onToggleSubtable={handleToggleSubtable}
              ref={idx === tasks.length - 1 ? callbackRef : null}
            />
          </Link>
          {openSubtables.has(task._id) && <SubList task={task} />}
        </React.Fragment>
      ))}

      <div className={styles.listStatusContainer}>
        {isLoading ? (
          <TaskListSkeleton />
        ) : (
          !tasks.length && <span className={styles.noOrders}>Нет заказов</span>
        )}
      </div>
    </>
  );
}
