import React from "react";

import { SITES } from "../../../../../order/r24/constants";
import { ImageRow, InfoRow } from "../InfoComponents";

export function PersonQuote({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      {data.image && <ImageRow title="Фото" src={data.image} />}
      <InfoRow title="Автор" value={data.author} />
      {data.position && <InfoRow title="Должность" value={data.position} />}
      <InfoRow
        title="Цитата"
        value={data.blocks.map((item: any) => item.text).join("\n")}
      />
      <InfoRow title="Сайт" value={SITES[data.site] ?? "-"} />
      {data.site === "custom" && (
        <InfoRow title="Другой сайт" value={data.customSite} />
      )}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
    </>
  );
}
