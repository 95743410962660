import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { PRECIPITATION_MAP_REGIONS } from "../../common/texts";
import { useIsDesktop } from "../../common/utils";
import {
  CLIP_NAME_REGEXP,
  getRegionNameById,
  getValidationProps,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validatePositive,
} from "../util";

type Item = { region: string; thesis: string; edited: boolean };

function createItem(): Item {
  return {
    region: PRECIPITATION_MAP_REGIONS[0].id,
    thesis: PRECIPITATION_MAP_REGIONS[0].name,
    edited: false,
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: (initialFormData?.timing ?? 60) as number,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
    manual: (initialFormData?.manual ?? false) as boolean,
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName", "timing", "manual"),
    blocks: values.items.map((item) => ({
      region: item.region,
      thesis: item.thesis,
    })),
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    timing:
      validatePositive(values.timing) ?? validateMax(values.timing, MAX_TIMING),
    items: values.items.map((item) => ({
      thesis: validateNotEmpty(item.thesis),
    })),
  };
  return removeEmptyProps(errors);
}

function PrecipitationMapForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
  } = formik;
  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={TextField}
          type="number"
          label="Хронометраж (сек.)"
          name="timing"
          {...getValidationProps("timing", touched, errors)}
          required
        />
        <Checkbox
          label="Ручная сборка"
          checked={formik.values.manual}
          setChecked={(value) => formik.setFieldValue("manual", value)}
          style={{ ...(isDesktop && { marginLeft: 210 }) }}
        />
        <Blocks
          blockTitle="Регион"
          items={values.items}
          defaultItemConstructor={createItem}
          updateItems={(items) => setFieldValue("items", items)}
          canChangeLength
          maxBlocks={5}
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={Select}
                placeholder="Регион"
                name={`items.${index}.region`}
                options={PRECIPITATION_MAP_REGIONS}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setFieldValue(`items.${index}.region`, event.target.value);

                  const isThesisChanging =
                    (!item.edited &&
                      item.thesis !== getRegionNameById(event.target.value)) ||
                    !item.thesis;

                  if (isThesisChanging) {
                    setFieldValue(
                      `items.${index}.thesis`,
                      getRegionNameById(event.target.value),
                    );
                    setFieldValue(`items.${index}.edited`, false);
                  }
                }}
              />
              <TextArea
                name="thesis"
                placeholder="Тезис"
                required
                value={item.thesis}
                rows={2}
                onChange={(event) => {
                  updateItem({ thesis: event.target.value, edited: true });
                }}
                onBlur={() => {
                  setFieldTouched(`items.${index}.thesis`);
                }}
                isValid={!(errors as any).items?.[index]?.thesis}
                validationMessage={(errors as any).items?.[index]?.thesis}
              />
            </>
          )}
        </Blocks>
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(PrecipitationMapForm);
