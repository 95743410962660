import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";
import { sourceType, imageType, color } from "../constants";

export function M24InformerTop3({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.clipName && (
        <InfoRow title="Название ролика" value={data.clipName} />
      )}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      <InfoRow title="Название мероприятия" value={data.eventName} />
      <InfoRow title="Маркер Сити" value={data.theCity ? "Да" : "Нет"} />

      {(data.blocks as Record<string, any>[]).map((block, id) => (
        <details key={id} open>
          <summary>Блок №{id + 1}</summary>
          <div style={{ marginLeft: 15 }}>
            <div style={{ marginBottom: 10 }} />
            <InfoRow title="Заголовок" value={block.title} />
            <InfoRow title="Дата" value={block.date} />
            <InfoRow title="Место проведения" value={block.locationName} />
            <InfoRow title="Возрастной ценз" value={block.age} />
            <InfoRow
              title="Тип источника"
              value={sourceType[block.sourceType]}
            />
            {block.sourceType === "total" && block.source && (
              <>
                <InfoRow title="Общий источник" value={block.source} />
                <InfoRow
                  title="Цвет общего источника"
                  value={color[block.sourceColor]}
                />
              </>
            )}
            <InfoRow
              title="Тип изображения"
              value={imageType[block.imageType]}
            />
            {block.imageType === "total" && (
              <ImageRow title="Общее изображение" src={block.image} />
            )}
            {(block.subBlocks as Record<string, any>[]).map((subBlock, idx) => (
              <details key={idx}>
                <summary>Слайд №{idx + 1}</summary>
                <div style={{ marginLeft: 15 }}>
                  <div style={{ marginBottom: 10 }} />
                  <InfoRow title="Комментарий" value={subBlock.comment} />
                  {block.sourceType === "separate" && subBlock.source && (
                    <>
                      <InfoRow title="Источник" value={subBlock.source} />
                      <InfoRow
                        title="Цвет источника"
                        value={color[subBlock.sourceColor]}
                      />
                    </>
                  )}

                  {block.imageType === "separate" && (
                    <ImageRow title="Изображение" src={subBlock.image} />
                  )}
                </div>
              </details>
            ))}
          </div>
          <hr />
        </details>
      ))}
    </>
  );
}
