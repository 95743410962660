import React, { useState } from "react";

import SubTable from "../tasks/pages/TaskTablePage/AtmoSubTable";
import styles from "./Table.module.css";
import arrowSort from "./arrow_sort.svg";
import arrowSortBlue from "./arrow_sort_blue.svg";

export type Order = "asc" | "desc";
export interface SubTableItem {
  [key: string]: string;
}

interface SortArrowProps {
  order: Order;
  active: boolean;
}

interface DisplayProp<T> {
  selector: string | ((item: T) => React.ReactNode);
  name: string;
  className?: string;
  headerClassName?: string;
  currentSort?: boolean;
}

function SortArrow({ order, active }: SortArrowProps) {
  return (
    <img
      style={{
        marginLeft: 5,
        transform: `rotate(${order === "asc" ? 180 : 0}deg)`,
      }}
      src={active ? arrowSortBlue : arrowSort}
      alt="Sort arrow"
    />
  );
}

function deepAccess(item: Record<string, any>, prop: string) {
  const keys = prop.split(".");
  let target: any = item;
  for (const key of keys) {
    target = target?.[key];
  }
  return target;
}

export function Table<T extends Record<string, any>>({
  items,
  displayProps,
  onItemClick,
  order,
  changeOrder,
  idPropName,
  subtable,
}: {
  items: T[];
  displayProps: DisplayProp<T>[];
  onItemClick?: (item: T) => void;
  order: Order;
  changeOrder: (order: Order) => void;
  idPropName: string;
  subtable?: SubTableItem[];
}) {
  const [openSubtables, setOpenSubtables] = useState(new Set());
  const toggleSubtable = (itemId: string) => {
    setOpenSubtables((prevOpenSubtables) => {
      const updatedSet = new Set(prevOpenSubtables);
      if (prevOpenSubtables.has(itemId)) {
        updatedSet.delete(itemId);
      } else {
        updatedSet.add(itemId);
      }
      return updatedSet;
    });
  };
  const switchOrder = () => {
    order === "asc" ? changeOrder("desc") : changeOrder("asc");
  };
  const subtableSearch = (item: T) => {
    if (subtable) {
      return subtable.find(
        (project) => project[item.channel] === item.template,
      );
    }
    return null;
  };

  return (
    <table className={styles.table}>
      <thead>
        <tr className={styles.header}>
          {subtable && <th style={{ width: "10px", padding: 0 }}></th>}
          {displayProps.map(({ name, currentSort, headerClassName }) => (
            <th key={name} className={headerClassName ?? styles.headerTitle}>
              <button
                className={currentSort ? styles.currentSort : styles.sortButton}
                onClick={currentSort ? switchOrder : undefined}
                disabled={!currentSort}
              >
                {name}
                {currentSort && <SortArrow active order={order} />}
              </button>
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {items.map((item) => (
          <React.Fragment key={item[idPropName]}>
            <tr className={styles.rowLink}>
              {subtable &&
                (subtableSearch(item) ? (
                  <td>
                    <details
                      className={
                        openSubtables.has(item[idPropName])
                          ? styles.tableDetailsOpen
                          : undefined
                      }
                    >
                      <summary
                        className={styles.tableSummary}
                        onClick={() => toggleSubtable(item[idPropName])}
                      ></summary>
                    </details>
                  </td>
                ) : (
                  <td></td>
                ))}
              {displayProps.map(({ selector, className, name }) => (
                <td
                  onClick={() => {
                    if (onItemClick) {
                      onItemClick(item);
                    }
                  }}
                  key={name}
                  className={className ?? styles.cell}
                >
                  {typeof selector === "string"
                    ? deepAccess(item, selector)
                    : selector(item)}
                </td>
              ))}
            </tr>
            {openSubtables.has(item[idPropName]) && (
              <tr>
                <td></td>
                <td colSpan={displayProps.length}>
                  <SubTable item={item} />
                </td>
              </tr>
            )}
          </React.Fragment>
        ))}
      </tbody>
    </table>
  );
}
