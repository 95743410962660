import TextField from "emg-ui-kit/components/TextField";
import { Field } from "formik";
import React from "react";

import { atmosphereSources } from "../../../common/texts";
import { getItemFieldName } from "../../util";
import { ItemBase } from "../types";

interface Props {
  item: ItemBase;
  index: number;
}

export default function SourceBlock({ index }: Props) {
  return (
    <Field
      as={TextField}
      placeholder="Источник"
      dataList={atmosphereSources}
      name={getItemFieldName(index, "source")}
    />
  );
}
