import React from "react";

import styles from "./DetailsGroup.module.css";

type DetailsProps = {
  headerComponent?: React.ReactElement;
  bodyComponent?: React.ReactElement;
  arrowWithMargin?: boolean;
  noBodyPadding?: boolean;
  noHeaderOnlick?: boolean;
  style?: React.CSSProperties;
} & React.AllHTMLAttributes<HTMLDetailsElement & HTMLDivElement>;

export default function DetailsGroup({
  open,
  onClick,
  headerComponent,
  bodyComponent,
  arrowWithMargin,
  noBodyPadding,
  noHeaderOnlick,
  style,
}: DetailsProps) {
  return (
    <div style={style ?? {}} className={styles.detailsGroup}>
      <div className={styles.container}>
        <div className={styles.arrowContainer}>
          <div
            className={open ? styles.arrowOpen : styles.arrowClosed}
            onClick={onClick}
            style={arrowWithMargin ? { marginBottom: 9 } : {}}
          ></div>
        </div>

        <div
          className={styles.headerComponent}
          onClick={noHeaderOnlick ? undefined : onClick}
        >
          {headerComponent}
        </div>
      </div>

      <div
        className={open ? styles.bodyComponentOpen : styles.bodyComponent}
        style={noBodyPadding ? {} : { paddingLeft: 40 }}
      >
        {open && <div>{bodyComponent}</div>}
      </div>
    </div>
  );
}
