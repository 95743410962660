import React from "react";

import { InfoRow } from "../InfoComponents";

export function M24Table({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data?.clipName && (
        <InfoRow title="Название ролика" value={data?.clipName} />
      )}
      {(data.tables as Record<string, any>[]).map((table, id) => (
        <details key={id} open>
          <summary>Слайд №{id + 1}</summary>
          <div style={{ marginLeft: 15 }}>
            <div style={{ marginBottom: 10 }} />
            <InfoRow title="Хронометраж (сек.)" value={table.timing} />
            <InfoRow title="Заголовок" value={table.title} />
            <InfoRow title="Размер шрифта заголовка" value={table.titleFont} />
            {table.subtitle && (
              <>
                <InfoRow title="Подзаголовок" value={table.subtitle} />
                <InfoRow
                  title="Размер шрифта подзаголовка"
                  value={table.subtitleFont}
                />
              </>
            )}
            {table.source && <InfoRow title="Источник" value={table.source} />}
            <InfoRow
              title="Интервал между столбцами"
              value={table.columnIndent}
            />
            <InfoRow title="Интервал между рядами" value={table.rowIndent} />
            <InfoRow
              title="Интервал волны промаргивания (сек.)"
              value={table.blinkStep}
            />

            <table style={{ borderCollapse: "collapse" }}>
              <tbody>
                {table.tableData.cells.map(
                  (row: string[], rowIndex: number) => {
                    return (
                      <tr key={rowIndex}>
                        {row.map((cell: any, colIndex: number) => {
                          return (
                            <td
                              style={{
                                border: "1px solid #dadada",
                                minWidth: 50,
                                padding: 5,
                              }}
                            >
                              {cell.text}
                            </td>
                          );
                        })}
                      </tr>
                    );
                  }
                )}
              </tbody>
            </table>
          </div>
        </details>
      ))}
    </>
  );
}
