import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function MiniWheeze({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      <InfoRow title="Тип фона" value={data.background} />
      <InfoRow title="Автор" value={data.author} />
      {data.position && <InfoRow title="Должность" value={data.position} />}
      {data.image && <ImageRow title="Изображение" src={data.image} />}
    </>
  );
}
