import React from "react";

type Props = {
  center?: boolean;
  style?: React.CSSProperties;
  className?: string;
};

const FlexContainer: React.FunctionComponent<Props> = ({
  children,
  style,
  className,
  center,
}) => (
  <div
    style={{
      display: "flex",
      ...(center && { justifyContent: "center" }),
      ...style,
    }}
    className={className}
  >
    {children}
  </div>
);

export default FlexContainer;
