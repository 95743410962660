import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { selectIsAdmin } from "../redux/auth/selectors";
import routes from "../routes";

interface Props {
  path: string;
  component: React.ComponentType;
}

export default function AdmRoute({ path, component }: Props) {
  const isAdmin = useSelector(selectIsAdmin);
  return isAdmin ? (
    <Route path={path} component={component} />
  ) : (
    <Redirect to={routes.index} />
  );
}
