import { templates } from "../common/texts";

export type Templates = Record<string, Record<string, string>>;
export type TransformedTemplate = { id: string; value: string };

export const transformTemplatesToArray = (
  templates: Templates
): TransformedTemplate[] => {
  const result: TransformedTemplate[] = [];

  Object.entries(templates).forEach(([key, nestedObject]) => {
    const prefix = key.toUpperCase();
    Object.entries(nestedObject).forEach(([nestedKey, value]) => {
      result.push({ id: nestedKey, value: `${prefix} ${value}` });
    });
  });

  return result;
};

export const selectData = transformTemplatesToArray(templates);
