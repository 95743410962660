import AlertBar from "emg-ui-kit/components/Alert";
import ChronometerButton from "emg-ui-kit/components/ChronometerPopup/ChronometerButton";
import ChronometerPopup from "emg-ui-kit/components/ChronometerPopup/ChronometerPopup";
import BaseHeader from "emg-ui-kit/components/Header";
import { MenuGroup } from "emg-ui-kit/components/Header/MenuGroup";
import { MenuItem } from "emg-ui-kit/components/Header/MenuItem";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import { useIsDesktop } from "../common/utils";
import { logoutUserThunk } from "../redux/auth";
import { selectAuth, selectIsAdmin } from "../redux/auth/selectors";
import {
  selectNotificationWithLinks,
  selectTroubleNotification,
} from "../redux/notifications/selectors";
import { setTopOffset } from "../redux/viewParams";
import routes from "../routes";
import styles from "./Header.module.css";
import LogoButton from "./LogoButton";
import NewsButton from "./NewsButton";
import SearchBar from "./Search/SearchBar";

interface ActionItem {
  kind: "action";
  title: string;
  action: () => void;
}

export default function Header() {
  const history = useHistory();
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectAuth);
  const isAdmin = useSelector(selectIsAdmin);
  const [isChronVisible, setIsChronVisible] = useState(false);
  const isDesktop = useIsDesktop();

  const initRouteItem = (title: string, url: string): ActionItem => {
    return {
      kind: "action",
      title,
      action: () => history.push(url),
    };
  };

  const isM24user = userInfo?.groups.includes("m24");
  const isR24user = userInfo?.groups.includes("r24");
  const isNogroupUser = userInfo?.groups.length === 0;
  const isDashBoardAvalible =
    userInfo?.type === "employee" || userInfo?.type === "admin";

  const registerItem = initRouteItem("Регистрация", routes.signup);
  const loginItem = initRouteItem("Вход", routes.index);
  const notificationsItem = initRouteItem("Уведомления", routes.notifications);
  const dataItem = initRouteItem("Данные", routes.atmoDataSync);
  const usersItem = initRouteItem("Пользователи", routes.users);
  const personsItem = initRouteItem("Фотографии", routes.persons);
  const newsItem = initRouteItem("Новости", routes.newsArticles);
  const orderItem = initRouteItem("Сделать заказ", routes.tasksNew);
  const taskListItem = initRouteItem("Список заказов", routes.tasksList);
  const draftListItem = initRouteItem("Черновики", routes.drafts);
  const dashBoardItem = initRouteItem("Дэшборд", routes.dashBoard);

  const search = initRouteItem("Поиск", routes.search);
  const logoutItem: ActionItem = {
    kind: "action",
    title: "Выйти",
    action: () => dispatch(logoutUserThunk()),
  };

  const refContainer = useRef<HTMLDivElement | null>(null);

  const menuContents: (MenuGroup | MenuItem)[] = userInfo
    ? [
        orderItem,
        taskListItem,
        draftListItem,
        ...(isDashBoardAvalible ? [dashBoardItem] : []),
        search,
        logoutItem,
      ]
    : [loginItem, registerItem];

  if (isDesktop) {
    const index = menuContents.findIndex((item: any) => {
      return item["title"] === "Поиск";
    });
    if (index !== -1) {
      menuContents.splice(index, 1);
    }
  }

  if (isAdmin) {
    menuContents.unshift({
      title: "Администрирование",
      items: [notificationsItem, dataItem, usersItem, personsItem, newsItem],
    });
  }
  const notification = useSelector(selectNotificationWithLinks);

  const troubleNotification = useSelector(selectTroubleNotification);

  const isAlertVisible =
    userInfo &&
    troubleNotification &&
    ((isM24user && troubleNotification.aeOn) ||
      ((isR24user || isM24user) && troubleNotification.unityOn) ||
      (isNogroupUser && troubleNotification.aeOn) ||
      (isNogroupUser && troubleNotification.unityOn));

  useEffect(() => {
    const cb = () => {
      if (refContainer.current) {
        dispatch(setTopOffset(refContainer.current.clientHeight));
      }
    };
    window.addEventListener("resize", cb);
    return () => {
      window.removeEventListener("resize", cb);
    };
  }, [dispatch]);

  return (
    <div
      className={styles.mainContainer}
      ref={(node) => {
        refContainer.current = node;
        if (refContainer.current) {
          dispatch(setTopOffset(refContainer.current.clientHeight));
        }
      }}
    >
      {isChronVisible && (
        <ChronometerPopup
          onClose={() => {
            setIsChronVisible(false);
          }}
          isDesktop={isDesktop}
        />
      )}
      <BaseHeader
        title={userInfo ? userInfo.email : ""}
        contents={menuContents}
      >
        <div className={styles.container}>
          <LogoButton />
          <div className={styles.buttonsContainer}>
            {!!userInfo && isDesktop && <SearchBar />}
            {!!userInfo && (
              <>
                <ChronometerButton
                  isActive={isChronVisible}
                  onClick={() => {
                    setIsChronVisible(!isChronVisible);
                  }}
                />
                <NewsButton userGroup={userInfo.groups[0]} />
              </>
            )}
          </div>
        </div>
      </BaseHeader>
      {notification && notification.category !== "none" && (
        <AlertBar
          message={notification.message}
          level={notification.category}
        />
      )}
      {isAlertVisible && (
        <AlertBar
          message={`${
            troubleNotification.unityOn
              ? (isR24user || isM24user || isNogroupUser) &&
                troubleNotification.unityMessage
              : ""
          } ${
            troubleNotification.unityOn && troubleNotification.aeOn
              ? "<br>"
              : ""
          } ${
            troubleNotification.aeOn
              ? (isM24user || isNogroupUser) && troubleNotification.aeMessage
              : ""
          }`}
          level={"error"}
        />
      )}
    </div>
  );
}
