import { FormImage } from "emg-ui-kit/components/ImageInput";
import { useDispatch } from "react-redux";

import { removeBackgroundFrom } from "./ApiService";
import { convertImageUrlToFile } from "./utils";

export function useRemoveBackground({
  setModalVisible,
  image,
  handleImageUpdate,
}: {
  setModalVisible: (visible: boolean) => void;
  image: FormImage | undefined;
  handleImageUpdate: () => void;
}) {
  const dispatch = useDispatch();
  return () => {
    setModalVisible(true);

    if (image) {
      removeBackgroundFrom(image)
        .then(convertImageUrlToFile)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "IMAGE_TRANSFORM_ERROR", error: err });
        })
        .finally(() => {
          setModalVisible(false);
        });
    }
  };
}
