import React from "react";
import { Link } from "react-router-dom";

import { Task } from "../../common/models";
import useCallbackOnVisible from "../../common/useCallbackOnVisible";
import routes from "../../routes";
import styles from "../../tasks/pages/TaskListPage/TaskList.module.css";
import SearchTaskRow from "./SearchTaskRow";

interface Props {
  tasks: Task[];
  loadNext: () => void;
  isLoading: boolean;
}

export default function TaskList({ tasks, loadNext, isLoading }: Props) {
  const callbackRef = useCallbackOnVisible(loadNext);
  return (
    <>
      {tasks.map((task, idx) => (
        <Link
          to={`${routes.tasksList}/${task._id}`}
          key={task._id}
          className={styles.taskLink}
        >
          <SearchTaskRow
            task={task}
            ref={idx === tasks.length - 1 ? callbackRef : null}
          />
        </Link>
      ))}
      <div className={styles.listStatusContainer}></div>
    </>
  );
}
