import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";
import { color } from "../constants";

export function M24Informer2({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.clipName && (
        <InfoRow title="Название ролика" value={data.clipName} />
      )}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      <InfoRow title="Заголовок" value={data.title} />
      <InfoRow title="Дата" value={data.date} />
      <InfoRow title="Название мероприятия" value={data.eventName} />
      <InfoRow title="Адрес мероприятия" value={data.locationName} />
      <InfoRow title="Возрастной ценз" value={data.age} />
      <InfoRow title="Маркер Сити" value={data.theCity ? "Да" : "Нет"} />
      {data.imageType === "total" && (
        <ImageRow title="Общее изображение" src={data.image} />
      )}
      {data.sourceType === "total" && data.source && (
        <>
          <InfoRow title="Общий источник" value={data.source} />
          <InfoRow title="Цвет источника" value={color[data.sourceColor]} />
        </>
      )}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Слайд №{idx + 1}</summary>
          {data.imageType === "separate" && (
            <ImageRow title="Изображение" src={block.image} />
          )}
          <InfoRow title="Комментарий" value={block.comment} />
          {data.sourceType === "separate" && block.source && (
            <>
              <InfoRow title="Источник" value={block.source} />
              <InfoRow
                title="Цвет источника"
                value={color[block.sourceColor]}
              />
            </>
          )}
        </details>
      ))}
    </>
  );
}
