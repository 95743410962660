import React from "react";
import ContentLoader from "react-content-loader";

export default function SearchResultSkeletonMobile() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height={276}
      viewBox="0 0 377 276"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
      preserveAspectRatio="none"
    >
      <rect y="73" width="92" height="11" fill="#F2F2F2" />
      <rect x="205" y="73" width="172" height="11" fill="#F2F2F2" />
      <rect y="90" width="140" height="14" fill="#F2F2F2" />
      <rect y="114" width="72" height="16" fill="#F2F2F2" />
      <rect y="146" width="92" height="11" fill="#F2F2F2" />
      <rect x="205" y="146" width="172" height="11" fill="#F2F2F2" />
      <rect y="163" width="140" height="14" fill="#F2F2F2" />
      <rect y="187" width="72" height="16" fill="#F2F2F2" />
      <rect y="219" width="92" height="11" fill="#F2F2F2" />
      <rect x="205" y="219" width="172" height="11" fill="#F2F2F2" />
      <rect y="236" width="140" height="14" fill="#F2F2F2" />
      <rect y="260" width="72" height="16" fill="#F2F2F2" />
      <rect width="92" height="11" fill="#F2F2F2" />
      <rect x="205" width="172" height="11" fill="#F2F2F2" />
      <rect y="17" width="140" height="14" fill="#F2F2F2" />
      <rect y="41" width="72" height="16" fill="#F2F2F2" />
    </ContentLoader>
  );
}
