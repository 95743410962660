import ButtonSimple from "emg-ui-kit/components/ButtonSimple";
import React from "react";

import styles from "./NoticeWindow.module.css";

export interface NoticeWindowProps {
  /** Текст */
  title?: string;
  /** Стили */
  style?: React.CSSProperties;
  /** Функция на нажатие кнопок */
  onNoticeClick: (isAccepted: boolean) => void;
}

export default function NoticeWindow({
  title,
  style,
  onNoticeClick,
}: NoticeWindowProps) {
  return (
    <div className={styles.noticeWindow} style={style}>
      <div className={styles.titleContainer}>
        <div className={styles.noticeIcon} />
        <div className={styles.title}>{title}</div>
      </div>
      <div className={styles.buttonsContainer}>
        <ButtonSimple
          title="Да"
          color="red"
          onClick={() => onNoticeClick(true)}
          style={{ margin: 0 }}
        />
        <ButtonSimple
          title="Нет"
          color="red"
          onClick={() => onNoticeClick(false)}
          style={{ margin: 0 }}
        />
      </div>
    </div>
  );
}
