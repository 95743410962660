import React from "react";
import ContentLoader from "react-content-loader";

export default function PersonListSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={1200}
      height={470}
      viewBox="0 0 1200 470"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
    >
      <path d="M 0 0 h 1200 v 24 H 0 z M 0 42 h 378.388 v 28 H 0 z M 395.394 42 h 401.771 v 28 H 395.394 z M 814.17 42 h 385.828 v 28 H 814.17 z M 0 220 h 378.388 v 28 H 0 z M 395.394 220 h 401.771 v 28 H 395.394 z M 814.17 220 h 385.828 v 28 H 814.17 z M 0 132 h 378.388 v 28 H 0 z M 395.394 132 h 401.771 v 28 H 395.394 z M 814.17 132 h 385.828 v 28 H 814.17 z M 0 310 h 378.388 v 28 H 0 z M 395.394 310 h 401.771 v 28 H 395.394 z M 814.17 310 h 385.828 v 28 H 814.17 z M 0 398 h 378.388 v 28 H 0 z M 395.394 398 h 401.771 v 28 H 395.394 z M 814.17 398 h 385.828 v 28 H 814.17 z M 0 86 h 378.388 v 28 H 0 z M 395.394 86 h 401.771 v 28 H 395.394 z M 814.17 86 h 385.828 v 28 H 814.17 z M 0 264 h 378.388 v 28 H 0 z M 395.394 264 h 401.771 v 28 H 395.394 z M 814.17 264 h 385.828 v 28 H 814.17 z M 0 176 h 378.388 v 28 H 0 z M 395.394 176 h 401.771 v 28 H 395.394 z M 814.17 176 h 385.828 v 28 H 814.17 z M 0 354 h 378.388 v 28 H 0 z M 395.394 354 h 401.771 v 28 H 395.394 z M 814.17 354 h 385.828 v 28 H 814.17 z M 0 442 h 378.388 v 28 H 0 z M 395.394 442 h 401.771 v 28 H 395.394 z M 814.17 442 h 385.828 v 28 H 814.17 z" />
    </ContentLoader>
  );
}
