import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import { CLIP_NAME_REGEXP, getValidationProps, IMAGE_TYPES } from "../util";

const TIMING = 15;
const IMAGE_ASPECT = 752 / 516;

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    comment: (initialFormData?.comment ?? "") as string,
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };
}

const validationSchema = yup.object().shape({
  clipName: yup.string().matches(CLIP_NAME_REGEXP),
  image: yup.mixed().aspect(IMAGE_ASPECT).required(),
});

function MemeForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  channel,
  template,
  onDeleteDraft,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    validationSchema,
    onSubmit,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
  } = formik;

  const isDesktop = useIsDesktop();

  usePreview(channel, template, TIMING, values);

  const buttonProps = {
    isSubmitting,
    isValid,
    values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={values.image}
          updateImage={(image) => {
            setFieldTouched("image");
            setFieldValue("image", image);
          }}
          onBlur={() => {
            setFieldTouched(`image`, true);
          }}
          style={isDesktop ? { marginLeft: 210 } : {}}
          title="Изображение"
          {...getValidationProps("image", touched, errors)}
          aspect={IMAGE_ASPECT}
          required
        />
        <TextArea
          label="Комментарий"
          name="comment"
          value={values.comment}
          onChange={(event) => {
            setFieldValue("comment", event.target.value.toUpperCase());
          }}
          onBlur={handleBlur}
          rows={2}
        />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(MemeForm);
