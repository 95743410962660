import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AtmosphereFormParams } from "../../common/ApiService";
import OrderSavingButtons, {
  ButtonStatus,
} from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import OrderFormSkeleton from "../../components/Skeletons/OrderFormSkeleton";
import OrderFormSkeletonMoblie from "../../components/Skeletons/OrderFormSkeletonMobile";
import { getAtmoParamsThunk } from "../../redux/atmoParams";
import { selectAtmoParams } from "../../redux/atmoParams/selectors";
import Form from "../Form";
import messages from "../messages";
import {
  CLIP_NAME_REGEXP,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  validateText,
  ValidationPropsUtils,
  removeTouched,
} from "../util";
import AtmoBlock from "./blocks/atmo-number/AtmoBlock";
import TotalTimingInfo from "./components/TotalTimingInfo";
import { AtmoItem as Item, EmptyItem } from "./types";
import { calcTotalTiming, findLocation, initItems } from "./utils";

function createItem(): EmptyItem {
  return {
    blockType: "",
    timing: "5",
    source: "",
  };
}

function validateItem(item: Item) {
  const validateLine = (text: string) => validateText(text, 1, 25);

  let validationMessages: Record<string, string | undefined> = {
    ...(item.blockType === ""
      ? { blockType: "Выберите тип блока" }
      : item.mapVariant === "3D"
      ? {
          location: validateNotEmpty(item.location),
          weather: validateNotEmpty(item.weather),
          timeOfDay: validateNotEmpty(item.timeOfDay),
        }
      : item.mapVariant === "2D"
      ? { map: validateNotEmpty(item.map) }
      : item.mapVariant === "interactive" && item.interactiveVariant === "wind"
      ? {
          interactiveWindDirection: validateNotEmpty(
            item.interactiveWindDirection
          ),
        }
      : {}),
  };

  switch (item.blockType) {
    case "text":
      return {
        ...validationMessages,
        firstLine:
          validateNotEmpty(item.firstLine) ?? validateLine(item.firstLine),
        secondLine: validateLine(item.secondLine),
        thirdLine: validateLine(item.thirdLine),
      };
    case "feelsLike":
      return {
        ...validationMessages,
        temperature:
          item.temperature &&
          (/^[+\-−][0-9]+$/.test(item.temperature) || item.temperature === "0")
            ? undefined
            : messages.temperature,
      };
    case "pressure":
      return {
        ...validationMessages,
        pressure: validatePositive(item.pressure),
      };
    case "wind":
      return {
        ...validationMessages,
        windDirection: validateNotEmpty(item.windDirection),
        windSpeed:
          item.windSpeed &&
          (/^[0-9]+$/.test(item.windSpeed) ||
            /^[0-9]+[-–—][0-9]+$/.test(item.windSpeed))
            ? undefined
            : messages.speed,
      };
    case "forecast":
      return {
        ...validationMessages,
        temperature:
          item.temperature &&
          (/^[+\-−][0-9]+$/.test(item.temperature) || item.temperature === "0")
            ? undefined
            : messages.temperature,
        forecastTime:
          validateNotEmpty(item.forecastTime) ??
          validateLine(item.forecastTime),
        forecastDescription:
          validateNotEmpty(item.forecastDescription) ??
          validateLine(item.forecastDescription),
      };
    default:
      return validationMessages;
  }
}

function validate(values: Values) {
  const errors = {
    clipName:
      validateNotEmpty(values.clipName) ??
      validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP),
    sheduleOfBroadcast: validateNotEmpty(values.sheduleOfBroadcast),
    items: values.items.map(validateItem),
  };
  return removeEmptyProps(errors);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    sheduleOfBroadcast: (initialFormData?.sheduleOfBroadcast ?? "") as string,
    manual: (initialFormData?.manual ?? false) as boolean,
    items: (initialFormData?.blocks
      ? initialFormData.blocks.map((block: Record<string, any>) => {
          const copy = { ...block };
          if (block.location) {
            copy.location = block.location.id;
            if (block.videoEffect) {
              copy.videoEffect = block.videoEffect.name;
            }
          } else if (block.map) {
            copy.map = block.map.name;
          }
          return copy;
        })
      : initItems(createItem)) as Item[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values, formParams?: AtmosphereFormParams) {
  return {
    ...pick(values, "clipName", "manual", "sheduleOfBroadcast"),
    blocks: formParams
      ? values.items.map((item) => {
          if ("location" in item && item.location) {
            return {
              ...item,
              ...(item.videoEffect && item.videoEffect !== "empty"
                ? {
                    videoEffect: formParams.videoEffects.find(
                      (effect) => effect.name === item.videoEffect
                    ),
                  }
                : {}),
              location: {
                ...findLocation(item.location, formParams!),
                id: item.location,
              },
            };
          } else if ("map" in item && item.map) {
            return {
              ...item,
              map: formParams.mapTypes.find(({ name }) => name === item.map),
            };
          } else {
            return item;
          }
        })
      : [],
  };
}

function AtmosphereForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
}: FormProps) {
  const { atmoParams: formParams, isLoading } = useSelector(selectAtmoParams);
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values, formParams)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  useEffect(() => {
    dispatch(getAtmoParamsThunk());
  }, [dispatch]);

  const totalTiming = calcTotalTiming(values.items);

  const shedulesOfBroadcast =
    formParams?.shedulesOfBroadcast.map((shedule) => ({
      id: shedule,
      name: shedule,
    })) ?? [];

  const isTotalTimingCorrect = totalTiming === 25;

  const validationUtils = new ValidationPropsUtils(touched, errors);

  const isDesktop = useIsDesktop();

  if (isLoading) {
    return isDesktop ? <OrderFormSkeleton /> : <OrderFormSkeletonMoblie />;
  }

  const buttonStatus: ButtonStatus = isSubmitting
    ? "loading"
    : isTotalTimingCorrect
    ? "enabled"
    : "disabled";

  const buttonProps = {
    isValid: isValid,
    prepareData,
    formParams,
    values: values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
    status: buttonStatus,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          required
          name="clipName"
          {...validationUtils.getProps("clipName")}
        />
        <Field
          as={Select}
          label="Время эфира"
          name="sheduleOfBroadcast"
          options={shedulesOfBroadcast}
          {...validationUtils.getProps("sheduleOfBroadcast")}
          required
        />
        <Checkbox
          label="Сборка скриптом aeDaemon"
          checked={values.manual}
          setChecked={(value) => setFieldValue("manual", value)}
          style={{ ...(isDesktop && { marginLeft: 210 }) }}
        />

        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          canChangeLength
          defaultItemConstructor={createItem}
        >
          {(item, index) => (
            <AtmoBlock item={item} index={index} formParams={formParams} />
          )}
        </Blocks>

        <TotalTimingInfo
          totalTiming={totalTiming}
          isTotalTimingCorrect={isTotalTimingCorrect}
        />

        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(AtmosphereForm);
