export const gearOptions = ["Количество", "Проценты"];

export const doughnutOptions = {
  maintainAspectRatio: false,
  aspectRatio: 1,
  legend: {
    display: false,
  },
};

export const infoData = {
  "Инфографика: Снег хрустит": 8,
  "Инфографика: Иней": 0,
  "Инфографика: Снег не видели 50%": 7,
  "Инфографика: Гололёд и гололедица": 25,
  "Инфографика: Толщина льда": 15,
  "Инфографика: Зимнее солнцестояние": 3,
  "Инфографика: Форма снежинок": 1,
  "Инфографика: Влажность под курткой": 2,
  "Инфографика: Высота сугробов в феврале": 3,
  "Инфографика: Высота сугробов в марте": 5,
  "Инфографика: Сосульки облачно": 4,
  "Инфографика: Сосульки ясно": 6,
  "Инфографика: Снежинка 95% зимой": 14,
  "Инфографика: Вес снега": 12,
  "Инфографика: Измерение снежных осадков": 42,
  "Инфографика: Снеговик": 2,
  "Инфографика: позёмок и метель": 3,
  "Инфографика: Мокрый снег": 6,
  "Инфографика: Скорость весны": 3,
  "Инфографика: Весеннее равноденствие": 14,
  "Инфографика: Весна наступает раньше": 12,
  "Инфографика: осадки в июле": 17,
  "Инфографика: жара — яичница": 8,
  "Инфографика: Роза ветров": 15,
  "Инфографика: Ветер опасен": 0,
  "Инфографика: УФ-индекс высокий": 18,
  "Инфографика: Купание": 20,
  "Инфографика: Поведение в жару в городе": 8,
  "Инфографика: Роса": 0,
  "Инфографика: Влажность летом": 6,
  "Инфографика: Летнее солнцестояние": 8,
  "Инфографика: Радуга круглая": 8,
  "Инфографика: Красный закат": 8,
  "Инфографика: Гигансткая капля": 6,
  "Инфографика: Комфортная погода": 9,
  "Инфографика: листопад": 14,
  "Инфографика: Скорость ветра влияет на ощущение температуры": 13,
  "Инфографика: Бабье лето": 11,
};
