import Radio from "emg-ui-kit/components/Radio";
import Select from "emg-ui-kit/components/Select";
import { Field, useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../common/ApiService";
import FlexContainer from "../../../common/FlexContainer";
import { convertToSelectOptions } from "../../../common/utils";
import { getItemFieldName, ValidationPropsUtils } from "../../util";
import {
  windDirections,
  timeshiftChoice,
  atmoWeatherTypes,
} from "../constants";
import { ExtendedItemBase } from "../types";
import { findLocation } from "../utils";

interface Props {
  item: ExtendedItemBase;
  index: number;
  formParams: AtmosphereFormParams;
  disabled?: boolean;
}

const interactiveMapTypes = [
  { id: "thermo", name: "Термокарта" },
  { id: "cyclone", name: "Циклоны-Антициклоны" },
  { id: "fallout_etr", name: "Осадки ЕТР" },
  { id: "fronts", name: "Фронты ЕТР" },
  { id: "fallout_cfo", name: "Осадки ЦФО" },
  { id: "fronts_cfo", name: "Фронты ЦФО" },
  { id: "wind", name: "Карты с направлением ветра" },
  { id: "gismeteo", name: "Гисметео" },
];

const interactiveDayTimes = [
  { id: "day", name: "День" },
  { id: "evening", name: "Вечер" },
  { id: "night", name: "Ночь" },
];

const windOptions = convertToSelectOptions(windDirections);
const timeshiftOptions = convertToSelectOptions(timeshiftChoice);

export default function MapBlock({ item, index, formParams, disabled }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );
  const mapVariantName = getItemFieldName(index, "mapVariant");
  const mapName = getItemFieldName(index, "map");
  const locationName = getItemFieldName(index, "location");
  const weatherName = getItemFieldName(index, "weather");
  const timeOfDayName = getItemFieldName(index, "timeOfDay");
  const interactiveVariantName = getItemFieldName(index, "interactiveVariant");
  const windDirectionName = getItemFieldName(index, "interactiveWindDirection");
  const timeshiftName = getItemFieldName(index, "timeshift");

  const videoEffectOptions = [
    { id: "empty", name: "Без видеоэффекта" },
    ...formParams.videoEffects.map(({ name }) => {
      return { id: name, name };
    }),
  ];

  const mapOptions = formParams.mapTypes.map(({ name }) => {
    return { id: name, name };
  });

  const locationOptions = Object.entries(formParams.locationTypes).map(
    ([label, options]) => {
      return {
        label,
        options: options.map(({ name }, idx) => ({
          id: `${label}_${idx}`,
          name,
        })),
      };
    }
  );

  const availableVariants = item.location
    ? findLocation(item.location, formParams)?.availableVariants
    : undefined;

  const getTimeOfDayOptions = () => {
    let availableVariantsItem: any[] = [];
    if (availableVariants && item.weather) {
      if (availableVariants[item.weather]) {
        availableVariantsItem = availableVariants[item.weather];
      } else if (!availableVariants[item.weather]) {
        availableVariantsItem =
          availableVariants[Object.keys(availableVariants)[0]];
      }
      return availableVariantsItem
        .filter((variant) => variant in formParams.timesOfDay)
        .map((type) => ({
          id: type,
          name: formParams.timesOfDay[type],
        }));
    }
    return [];
  };

  const weatherOptions = availableVariants
    ? Object.keys(availableVariants).map((type) => ({
        id: type,
        name: atmoWeatherTypes[type],
      }))
    : [];

  const updateWeather = (weather: string) => {
    if (formParams) {
      const { timeOfDay } = item;
      let nextItem: ExtendedItemBase;

      if (
        availableVariants &&
        timeOfDay &&
        availableVariants[weather].includes(timeOfDay)
      ) {
        nextItem = { ...item, weather };
      } else {
        nextItem = { ...item, weather, timeOfDay: "" };
      }

      formik.setFieldValue(`items.${index}`, nextItem);
    }
  };

  const updateLocation = (value: string) => {
    if (formParams) {
      let nextItem: ExtendedItemBase;
      if (
        availableVariants &&
        item.weather &&
        item.weather in availableVariants
      ) {
        if (
          item.timeOfDay &&
          availableVariants[item.weather].includes(item.timeOfDay)
        ) {
          nextItem = { ...item, location: value };
        }
        nextItem = { ...item, location: value };
      } else {
        nextItem = { ...item, location: value, weather: "" };
      }
      formik.setFieldValue(`items.${index}`, nextItem);
    }
  };

  const updateMapVariant = (value: string) => {
    const nextItem = { ...item, mapVariant: value };
    if (value === "2D") {
      delete nextItem.location;
      delete nextItem.weather;
      delete nextItem.timeOfDay;
      delete nextItem.videoEffect;
      delete nextItem.interactiveVariant;
      delete nextItem.interactiveWindDirection;
      nextItem.map = "";
    } else if (value === "3D") {
      delete nextItem.map;
      delete nextItem.interactiveVariant;
      delete nextItem.interactiveWindDirection;
      nextItem.location = "";
      nextItem.weather = "";
      nextItem.timeOfDay = "";
      nextItem.videoEffect = "empty";
    } else if (value === "interactive") {
      delete nextItem.map;
      delete nextItem.location;
      delete nextItem.weather;
      delete nextItem.videoEffect;
      nextItem.source = "";
      nextItem.interactiveVariant = interactiveMapTypes[0].id;
      nextItem.interactiveWindDirection = "";
      nextItem.timeOfDay = interactiveDayTimes[0].id;
    }
    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const updateInteractiveMapVariant = (value: string) => {
    const nextItem = { ...item, interactiveVariant: value };
    if (value === "gismeteo") {
      nextItem.timeshift = timeshiftOptions[0].id;
    } else {
      delete nextItem.timeshift;
    }
    formik.setFieldValue(`items.${index}`, nextItem);
  };

  const mapRadioProps = {
    name: mapVariantName,
    currentValue: item.mapVariant,
    setCurrentValue: updateMapVariant,
    style: { marginTop: "0.5rem", flexBasis: 100 },
    disabled,
  };

  return (
    <>
      <div>Вариант карты</div>
      <FlexContainer>
        <Radio {...mapRadioProps} value="2D" title="2D" />
        <Radio {...mapRadioProps} value="3D" title="3D" />
        <Radio
          {...mapRadioProps}
          style={{ marginTop: "0.5rem", flexBasis: "auto" }}
          value="interactive"
          title="Интерактивная"
        />
      </FlexContainer>

      {item.mapVariant === "2D" && (
        <Field
          as={Select}
          placeholder="Карта"
          name={mapName}
          options={mapOptions}
          {...validationUtils.getDeepProps(mapName)}
        />
      )}

      {item.mapVariant === "3D" && (
        <>
          <Field
            as={Select}
            placeholder="Локация"
            name={locationName}
            options={locationOptions}
            onChange={(event: any) => updateLocation(event.target.value)}
            {...validationUtils.getDeepProps(locationName)}
            disabled={disabled}
            required
          />
          <Field
            as={Select}
            placeholder="Погода"
            name={weatherName}
            options={weatherOptions}
            onChange={(event: any) => updateWeather(event.target.value)}
            {...validationUtils.getDeepProps(weatherName)}
            required
          />
          <Field
            as={Select}
            placeholder="Время суток"
            name={timeOfDayName}
            options={getTimeOfDayOptions()}
            {...validationUtils.getDeepProps(timeOfDayName)}
            disabled={!item.weather ?? disabled}
            required
          />
          <Field
            as={Select}
            placeholder="Видеоэффект"
            name={getItemFieldName(index, "videoEffect")}
            options={videoEffectOptions}
          />
        </>
      )}

      {item.mapVariant === "interactive" && (
        <>
          <Field
            as={Select}
            name={interactiveVariantName}
            options={interactiveMapTypes}
            onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
              updateInteractiveMapVariant(event.target.value);
            }}
          />
          <Field
            as={Select}
            placeholder="Время суток"
            name={timeOfDayName}
            options={interactiveDayTimes}
          />
          {item.interactiveVariant === "wind" && (
            <Field
              as={Select}
              placeholder="Направление ветра"
              name={windDirectionName}
              options={windOptions}
              {...validationUtils.getDeepProps(windDirectionName)}
            />
          )}
          {item.timing === "5" && item.interactiveVariant === "gismeteo" && (
            <Field
              as={Select}
              placeholder="5 секунд"
              name={timeshiftName}
              options={timeshiftOptions}
            />
          )}
        </>
      )}
    </>
  );
}
