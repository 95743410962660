import Button from "emg-ui-kit/components/Button";
import Modal from "emg-ui-kit/components/Modal";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

import PageContainer from "../common/PageContainer";
import { useIsDesktop } from "../common/utils";
import yup from "../common/yup";
import { ValidationPropsUtils } from "../order/util";
import { registerUserThunk } from "../redux/auth";
import styles from "./LoginForm.module.css";

const initialValues = {
  email: "",
  password: "",
  passwordAgain: "",
};

const validationSchema = yup.object().shape({
  email: yup.string().required().email(),
  password: yup.string().required().min(6),
  passwordAgain: yup
    .string()
    .required()
    .when("password", (password, schema) =>
      schema.matches(new RegExp(`^${password}$`), "Пароль не совпадает"),
    ),
});

export default function RegisterForm() {
  const [aboutModalVisible, setAboutModalVisible] = useState(false);
  const isDesktop = useIsDesktop();
  const dispatch = useDispatch();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: ({ email, password }) => {
      return (dispatch(registerUserThunk({ email, password })) as any).unwrap();
    },
  });

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const showAboutModal = () => {
    setAboutModalVisible(true);
  };

  const hideAboutModal = () => {
    setAboutModalVisible(false);
  };

  return (
    <PageContainer>
      <Title
        text="Зарегистрироваться"
        style={{ ...(isDesktop && { marginLeft: 210 }) }}
      />

      <Modal
        visible={aboutModalVisible}
        close={hideAboutModal}
        className={styles.aboutModal}
      >
        <div className={styles.about}>
          <span>
            Сайт предназначен для создания графики по шаблонам. Для тестирования
            работы сервиса, вы можете пройти регистрацию и создать до 5 пробных
            карт.
          </span>
        </div>
      </Modal>

      <FormikProvider value={formik}>
        <Form>
          <Field
            as={TextField}
            type="email"
            label="Электронная почта"
            name="email"
            {...validationUtils.getProps("email")}
          />
          <Field
            as={TextField}
            type="password"
            label="Пароль"
            name="password"
            {...validationUtils.getProps("password")}
          />
          <Field
            as={TextField}
            type="password"
            label="Повторите пароль"
            name="passwordAgain"
            {...validationUtils.getProps("passwordAgain")}
          />
          <Button
            title="Далее"
            type="submit"
            status={formik.isSubmitting ? "loading" : "enabled"}
          />
        </Form>
      </FormikProvider>

      <button
        type="button"
        onClick={showAboutModal}
        className={styles.aboutButton}
      >
        О сайте
      </button>
    </PageContainer>
  );
}
