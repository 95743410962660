import Checkbox from "emg-ui-kit/components/Checkbox";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useState } from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useRemoveBackground } from "../../common/useRemoveBackground";
import { useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import RmBgModal from "../RmBgModal";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
  validateMaxChars,
} from "../util";

const IMAGE_ASPECT = 520 / 540;
const MAX_LINES = 2;

const validationSchema = yup.object().shape({
  clipName: yup.string().matches(CLIP_NAME_REGEXP),
  colorScheme: yup.string().required(),
  communicationType: yup.string().required(),
  name: yup.string().required(),
  timing: yup.number().positive().max(MAX_TIMING).required(),
  position: yup.string().maxLines(MAX_LINES),
  image: yup.mixed().aspect(IMAGE_ASPECT).required(),
});

function WheezeForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const initialValues = {
    clipName: (initialFormData?.clipName ?? "") as string,
    colorScheme: (initialFormData?.colorScheme ?? "news") as string,
    image: initialFormData?.image as FormImage | undefined,
    communicationType: (initialFormData?.communicationType ??
      "ПО ТЕЛЕФОНУ") as string,
    name: (initialFormData?.name ?? "") as string,
    position: (initialFormData?.position ?? "") as string,
    timing: (initialFormData?.timing ?? 10) as number,
    hd: initialFormData?.hd ?? false,
    format: (initialFormData?.format ?? "default") as "default" | "premiere",
  };

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: true,
    onSubmit,
  });
  const [modalVisible, setModalVisible] = useState(false);

  usePreview(channel, template, formik.values.timing, formik.values);

  const positionRecommendation =
    formik.values.position && validateMaxChars(formik.values.position, 55);

  const isDesktop = useIsDesktop();

  const handleImageUpdate = (image?: FormImage) => {
    formik.setFieldValue("image", image);
  };
  const removeBackground = useRemoveBackground({
    setModalVisible,
    image: formik.values.image,
    handleImageUpdate,
  });
  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <RmBgModal
          modalVisible={modalVisible}
          onClose={() => {
            setModalVisible(false);
          }}
        />
        <Checkbox
          label="Отсчитать в HD"
          checked={formik.values.hd}
          setChecked={(value) => formik.setFieldValue("hd", value)}
          style={{ ...(isDesktop && { marginLeft: 210 }) }}
        />
        <TextField
          label="Название ролика"
          name="clipName"
          value={formik.values.clipName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...getValidationProps("clipName", formik.touched, formik.errors)}
        />
        <Field
          as={Select}
          name="format"
          label="Формат"
          options={[
            { id: "default", name: "По умолчанию" },
            { id: "premiere", name: "Premiere" },
          ]}
        />
        <Select
          name="colorScheme"
          label="Цветовая схема"
          options={[
            { id: "news", name: "News" },
            { id: "format", name: "Format/Traur" },
            { id: "alert", name: "Alert" },
            { id: "night", name: "Night" },
          ]}
          value={formik.values.colorScheme}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...getValidationProps("colorScheme", formik.touched, formik.errors)}
          required
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={handleImageUpdate}
          onBlur={() => {
            formik.setFieldTouched(`image`, true);
          }}
          style={isDesktop ? { marginLeft: 210 } : {}}
          title="Изображение"
          {...getValidationProps("image", formik.touched, formik.errors)}
          aspect={IMAGE_ASPECT}
          additionalActions={[{ name: "Убрать фон", action: removeBackground }]}
          required
        />
        <TextField
          label="Вид связи"
          name="communicationType"
          value={formik.values.communicationType}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...getValidationProps(
            "communicationType",
            formik.touched,
            formik.errors
          )}
          required
          dataList={["ПО ТЕЛЕФОНУ", "SKYPE"]}
        />
        <TextField
          label="Имя"
          name="name"
          value={formik.values.name}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...getValidationProps("name", formik.touched, formik.errors)}
          required
        />
        <TextArea
          label="Должность"
          name="position"
          value={formik.values.position}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          {...getValidationProps("position", formik.touched, formik.errors)}
          recommendationMessage={positionRecommendation}
        />
        <TextField
          name="timing"
          type="number"
          label="Хронометраж (сек.)"
          value={formik.values.timing.toString()}
          onChange={(event) => {
            formik.setFieldValue("timing", +event.target.value);
          }}
          onBlur={formik.handleBlur}
          {...getValidationProps("timing", formik.touched, formik.errors)}
          required
        />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(WheezeForm);
