import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import { SelectionData } from "emg-ui-kit/components/ImageSelection/ImageSelection";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React, { useEffect } from "react";

import ColorInput from "../../common/ColorInput";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  IMAGE_TYPES,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  validateTimingSum,
  ValidationPropsUtils,
  removeTouched,
} from "../util";

type Item = {
  title: string;
  text: string;
  source: string;
  timing: number | string;
};

type Values = ReturnType<typeof getInitialValues>;

function createItem(): Item {
  return { text: "", timing: 5, title: "", source: "" };
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    hat: (initialFormData?.hat ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    selection: initialFormData?.selection as SelectionData | undefined,
    backgroundColor: (initialFormData?.backgroundColor ?? "#ffffff") as string,
    headerColor: (initialFormData?.headerColor ?? "#071144") as string,
    timing: (initialFormData?.totalTiming ?? 30) as number | string,
    scrollDur: (initialFormData?.scrollDur ?? 30) as number | string,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    items: (initialFormData?.items?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? []) as Item[],
  };
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? +values.timing
    : values.items.reduce((acc, item) => acc + +item.timing, 0);
}

function prepareData(values: Values) {
  return {
    ...pick(
      values,
      "clipName",
      "hat",
      "image",
      "headerColor",
      "backgroundColor",
      "scrollDur"
    ),
    items: values.items.map((block) => ({
      text: block.text,
      source: block.source,
      title: block.title,
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    image: validateNotEmpty(values.image),
    timing:
      values.timingType === "total"
        ? validateNumber(values.timing) ??
          validatePositive(+values.timing) ??
          validateMax(+values.timing, MAX_TIMING)
        : undefined,
    scrollDur: validatePositive(+values.scrollDur),
    items: values.items.map((item) => ({
      text: validateNotEmpty(item.text),
      timing:
        values.timingType === "separate"
          ? validateNumber(item.timing) ??
            validatePositive(+item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function Site3dForm({
  initialFormData,
  onSubmit,
  channel,
  template,
  onSaveDraft,
  onDeleteDraft,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    validate,
    onSubmit: (values) => onSubmit(prepareData(values)),
  });

  const handleImageUpdate = (image?: FormImage) => {
    formik.setFieldTouched("image");
    formik.setFieldValue("image", image);
  };

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const isDesktopOrLaptop = useIsDesktop();

  useEffect(() => {
    if (formik.values.items.length === 0) {
      formik.setFieldValue("timingType", "total");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.items.length]);

  usePreview(
    channel,
    template,
    calcTotalTiming(formik.values),
    formik.values,
    prepareData
  );

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    values: formik.values,
    prepareData,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...validationUtils.getProps("clipName")}
        />
        <Field
          as={TextField}
          label="Шапка"
          name="hat"
          {...validationUtils.getProps("hat")}
        />
        <Field
          as={TextField}
          label="Длительность прокрутки"
          name="scrollDur"
          type="number"
          {...validationUtils.getProps("scrollDur")}
          required
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={handleImageUpdate}
          onBlur={() => {
            formik.setFieldTouched(`image`, true);
          }}
          style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
          title="Изображение"
          {...validationUtils.getProps("image")}
          required
        />
        <ColorInput
          label="Цвет шапки"
          value={formik.values.headerColor}
          updateValue={(value) => formik.setFieldValue("headerColor", value)}
          style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
        />

        <ColorInput
          label="Цвет фона"
          value={formik.values.backgroundColor}
          updateValue={(value) =>
            formik.setFieldValue("backgroundColor", value)
          }
          style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
        />

        {formik.values.items.length > 0 && (
          <Field
            as={Select}
            label="Хронометраж"
            name="timingType"
            options={[
              { id: "total", name: "Общий" },
              { id: "separate", name: "Для каждого слайда" },
            ]}
          />
        )}

        {formik.values.timingType === "total" && (
          <Field
            as={TextField}
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            {...validationUtils.getProps("timing")}
            required
          />
        )}
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, formik.touched, formik.setTouched);
          }}
          canChangeLength
          defaultItemConstructor={createItem}
          minBlocks={0}
        >
          {(item, index) => (
            <>
              {formik.values.timingType === "separate" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ marginRight: 20 }}>Хронометраж (сек.)</div>
                  <Field
                    as={TextField}
                    name={`items.${index}.timing`}
                    type="number"
                    {...validationUtils.getDeepProps(`items.${index}.timing`)}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <Field
                as={TextArea}
                rows={2}
                placeholder="Заголовок"
                name={`items.${index}.title`}
              />
              <Field
                as={TextArea}
                placeholder="Текст"
                name={`items.${index}.text`}
                {...validationUtils.getDeepProps(`items.${index}.text`)}
                rows={4}
                required
              />
              <Field
                as={TextArea}
                rows={2}
                placeholder="Источник"
                name={`items.${index}.source`}
              />
            </>
          )}
        </Blocks>
        <br />

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(Site3dForm);
