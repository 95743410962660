import classNames from "classnames";
import React, { useState } from "react";

import styles from "./DropdownArea.module.css";

export default function DropdownArea({
  title,
  children,
}: {
  title: string;
  children: React.ReactElement;
}) {
  const [isAreaVisible, setAreaVisible] = useState(false);
  const handleClick = () => {
    setAreaVisible((prev) => !isAreaVisible);
  };
  return (
    <div className={styles.dropdownArea}>
      <button onClick={handleClick} className={styles.dropdownAreaButton}>
        {title}
        <div
          className={classNames(styles.triangleOpen, {
            [styles.triangleClosed]: !isAreaVisible,
          })}
        ></div>
      </button>
      {isAreaVisible && (
        <div className={styles.dropdownAreaContainer}>{children}</div>
      )}
    </div>
  );
}
