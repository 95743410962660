import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function WheezeSpecrep({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Оформление" value={data.design} />
      {data.format && <InfoRow title="Формат" value={data.format} />}
      <InfoRow title="Вид связи" value={data.communicationType} />
      {data.image && <ImageRow title="Изображение" src={data.image} />}
      <InfoRow title="Имя" value={data.name} />
      <InfoRow title="Должность" value={data.position} />
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
    </>
  );
}
