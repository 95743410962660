import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import { ExtendedItemBase } from "../../types";
import MapBlock from "../MapBlock";
import SourceBlock from "../SourceBlock";
import TimingBlock from "../TimingBlock";

interface Props {
  item: ExtendedItemBase;
  index: number;
  formParams: AtmosphereFormParams;
}

export default function ExtendedBlock({ item, index, formParams }: Props) {
  return (
    <>
      <TimingBlock item={item} index={index} />
      <MapBlock item={item} index={index} formParams={formParams} />
      <SourceBlock item={item} index={index} />
    </>
  );
}
