import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import {
  AtmosphereFormParams,
  getAtmosphereParams,
  updateAtmosphereParams,
} from "../../common/ApiService";

export interface AtmoParamsState {
  atmoParams?: AtmosphereFormParams;
  isLoading: boolean;
  isUpdating: boolean;
}

const initialState: AtmoParamsState = {
  isLoading: false,
  isUpdating: false,
};

export const getAtmoParamsThunk = createAsyncThunk(
  "atmoParams/get",
  getAtmosphereParams
);

export const updateAtmoParamsThunk = createAsyncThunk(
  "atmoParams/update",
  updateAtmosphereParams
);

const atmoParamsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getAtmoParamsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getAtmoParamsThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.atmoParams = action.payload;
    })
    .addCase(getAtmoParamsThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(updateAtmoParamsThunk.pending, (state) => {
      state.isUpdating = true;
    })
    .addCase(updateAtmoParamsThunk.fulfilled, (state, action) => {
      state.isUpdating = false;
      state.atmoParams = action.payload;
    })
    .addCase(updateAtmoParamsThunk.rejected, (state) => {
      state.isUpdating = false;
    });
});

export default atmoParamsReducer;
