import Button from "emg-ui-kit/components/Button";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { MultiSearch } from "emg-ui-kit/components/Search";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";

import { PersonData } from "../common/ApiService";
import BackButton from "../common/BackButton";
import PageContainer from "../common/PageContainer";
import yup from "../common/yup";
import { IMAGE_TYPES, ValidationPropsUtils } from "../order/util";
import { createPersonThunk } from "../redux/persons";
import routes from "../routes";
import { AppDispatch } from "../store";
import styles from "./Person.module.css";
import { selectData } from "./PersonUtils";

const initialValues: PersonData = {
  name: "",
  position: "",
  templates: "[]",
};

const validationSchema = yup.object().shape({
  name: yup.string().required(),
});

export default function PersonNewForm() {
  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      dispatch(createPersonThunk(values)).then((result) => {
        if ("error" in result) return;
        history.push(routes.persons);
      });
    },
  });

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  return (
    <PageContainer>
      <BackButton to={routes.persons} />
      <Title text="Новая запись" />
      <FormikProvider value={formik}>
        <Form>
          <Field
            as={TextField}
            label="Имя"
            name="name"
            required
            {...validationUtils.getProps("name")}
          />
          <Field as={TextField} label="Должность" name="position" />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photo}
            updateImage={(image) => formik.setFieldValue("photo", image)}
            label="Фото"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoAlpha}
            updateImage={(image) => formik.setFieldValue("photoAlpha", image)}
            label="Фото (alpha)"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoRect}
            updateImage={(image) => formik.setFieldValue("photoRect", image)}
            label="Фото (прямоугольное)"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoM24}
            updateImage={(image) => formik.setFieldValue("photoM24", image)}
            label="Фото (M24)"
          />
          <ImageInput
            imageTypes={IMAGE_TYPES}
            image={formik.values.photoVip}
            updateImage={(image) => formik.setFieldValue("photoVip", image)}
            label="Фото (VIP)"
          />
          <div className={styles.templatesContainer}>
            <ImageInput
              imageTypes={IMAGE_TYPES}
              image={formik.values.photoTemplates}
              updateImage={(image) =>
                formik.setFieldValue("photoTemplates", image)
              }
              label="Фото для шаблонов"
            />
            <MultiSearch
              label="Выбор шаблонов"
              options={selectData}
              selected={JSON.parse(formik.values.templates)}
              updateSelected={(value) => {
                formik.setFieldValue("templates", JSON.stringify(value));
              }}
            />
          </div>
          <Button
            title="Добавить"
            type="submit"
            status={formik.isSubmitting ? "loading" : "enabled"}
          />
        </Form>
      </FormikProvider>
    </PageContainer>
  );
}
