import classNames from "classnames";
import { InputBase } from "emg-ui-kit/components/InputBase";
import React from "react";

import { ColorButton } from "./ColorPicker";
import styles from "./LegendInput.module.css";

interface Props {
  color: string;
  title: string;
  updateColor: (color: string) => void;
  updateTitle: (title: string) => void;
  onBlur?: () => void;
  colors: string[];
  label?: string;
  placeholder?: string;
  isValid?: boolean;
  validationMessage?: string;
  maxLines?: number;
  required?: boolean;
}

export default function LegendInput(props: Props) {
  const maxLines = props.maxLines || 1;
  const lines = props.title.split("\n").length;
  const inputHeight = lines === 1 ? 40 : "auto";

  const handleUpdateTitle = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    const nextValue = event.target.value;
    const lines = nextValue.split("\n").length;
    if (lines <= maxLines) {
      props.updateTitle(nextValue);
    }
  };

  const requiredForPlaceHolder = !props.label && props.required;
  const placeholderString = props.placeholder
    ? `${props.placeholder} ${requiredForPlaceHolder ? "*" : ""}`
    : undefined;

  return (
    <InputBase
      forwardedRef={null}
      label={props.label}
      isValid={props.isValid}
      validationMessage={props.validationMessage}
      inputComponent={(inputProps) => (
        <div
          className={classNames(inputProps.className, styles.container)}
          style={{ height: inputHeight }}
        >
          <textarea
            value={props.title}
            onChange={handleUpdateTitle}
            className={styles.legendInput}
            placeholder={placeholderString}
            rows={lines}
            onBlur={() => {
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              props.onBlur ? props.onBlur() : undefined;
            }}
          />
          <ColorButton
            color={props.color}
            updateColor={props.updateColor}
            colors={props.colors}
            className={styles.colorButton}
            pickerPosition="right"
          />
        </div>
      )}
    />
  );
}
