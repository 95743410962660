import Button from "emg-ui-kit/components/Button";
import React from "react";

import styles from "./TableEdit.module.scss";

interface TableWidthEditProps {
  dimensions: number[];
  handleChange: (value: number, index: number) => void;
  handleSave: () => void;
}

export default function TableWidthEdit({
  dimensions,
  handleChange,
  handleSave,
}: TableWidthEditProps) {
  const colCount = dimensions.length;

  return (
    <div className={styles.editModalContainer}>
      <div className={styles.editModalTitle}>
        Таблица для изменения ширины столбцов
      </div>
      <table className={styles.editTable}>
        <tbody>
          <tr>
            <td className={styles.tdTitle}>Столбец</td>
            {dimensions.map((width, index) => (
              <td
                key={index}
                className={styles.td}
                style={{ width: `${width * 10}px`, fontWeight: 600 }}
              >
                {index + 1}
              </td>
            ))}
          </tr>
          <tr>
            <td className={styles.tdTitle}>Ширина в %</td>
            {dimensions.map((width, index) => (
              <td
                key={index}
                className={styles.td}
                style={{ width: `${width * 10}px` }}
              >
                <input
                  type="number"
                  value={width}
                  className={styles.textInput}
                  onChange={(event: any) =>
                    handleChange(parseFloat(event.target.value), index)
                  }
                  disabled={index + 1 === colCount}
                />
              </td>
            ))}
          </tr>
        </tbody>
      </table>
      <Button title="Сохранить и выйти" onClick={handleSave} />
    </div>
  );
}
