import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import TableEditor, { TableData } from "../../common/TableEditor";
import { FormProps } from "../../common/models";
import { convertToSelectOptions, useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import { CLIP_NAME_REGEXP, getValidationProps, MAX_TIMING } from "../util";
import { THEMES, THEMES_FULL } from "./constants";
import OrderSavingButtons from "../../common/OrderSavingButtons";

function defaultTableConstructor(cols = 3): TableData {
  return {
    data: [Array(cols).fill(""), Array(cols).fill("")],
    colWidths: Array(cols).fill(100 / cols),
  };
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    title: (initialFormData?.title ?? "") as string,
    subtitle: (initialFormData?.subtitle ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
    fontSize: (initialFormData?.fontSize ?? 43) as number,
    headerFontSize: (initialFormData?.headerFontSize ?? 43) as number,
    tableData: (initialFormData?.tableData ??
      defaultTableConstructor()) as TableData,
    tableVisible: false,
  };
}

function prepareData(values: ReturnType<typeof getInitialValues>) {
  return {
    theme: THEMES_FULL[values.theme],
    name: values.name,
    title: values.title,
    subtitle: values.subtitle,
    source: values.source,
    timing: values.timing,
    fontSize: values.fontSize,
    headerFontSize: values.headerFontSize,
    tableData: values.tableData,
  };
}

const MIN_FONT_SIZE = 20;
const MAX_FONT_SIZE = 100;

const validationSchema = yup.object().shape({
  name: yup.string().matches(CLIP_NAME_REGEXP),
  title: yup.string().required(),
  theme: yup.string().required(),
  timing: yup.number().positive().max(MAX_TIMING),
  fontSize: yup.number().min(MIN_FONT_SIZE).max(MAX_FONT_SIZE),
  headerFontSize: yup.number().min(MIN_FONT_SIZE).max(MAX_FONT_SIZE),
});

function ChartTableForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validationSchema,
  });

  usePreview(
    channel,
    template,
    formik.values.timing,
    formik.values,
    prepareData,
  );

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...getValidationProps("name", formik.touched, formik.errors)}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          {...getValidationProps("theme", formik.touched, formik.errors)}
          required
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          rows={2}
          required
          {...getValidationProps("title", formik.touched, formik.errors)}
        />
        <Field as={TextField} label="Подзаголовок" name="subtitle" />
        <Field as={TextField} label="Источник" name="source" />
        <Field
          as={TextField}
          label="Хронометраж (сек)"
          name="timing"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("timing", +event.target.value)
          }
          {...getValidationProps("timing", formik.touched, formik.errors)}
          required
        />
        <Field
          as={TextField}
          label="Размер шрифта"
          name="fontSize"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("fontSize", +event.target.value)
          }
          {...getValidationProps("fontSize", formik.touched, formik.errors)}
          required
        />
        <Field
          as={TextField}
          label="Размер шрифта названия столбцов"
          name="headerFontSize"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("headerFontSize", +event.target.value)
          }
          {...getValidationProps(
            "headerFontSize",
            formik.touched,
            formik.errors,
          )}
          required
        />
        <button
          type="button"
          style={{
            marginLeft: isDesktop ? 210 : 0,
            marginBottom: "1rem",
            fontSize: 18,
            border: "none",
            padding: 0,
            background: "transparent",
            color: "var(--blue)",
            cursor: "pointer",
          }}
          onClick={() => formik.setFieldValue("tableVisible", true)}
        >
          Редактировать таблицу
        </button>
        <TableEditor
          tableData={formik.values.tableData}
          updateData={(tableData) =>
            formik.setFieldValue("tableData", tableData)
          }
          visible={formik.values.tableVisible}
          close={() => formik.setFieldValue("tableVisible", false)}
          defaultTableConstructor={defaultTableConstructor}
          additionalInfo="Тег цвета и стрелки: [r] - красный, [b] - синий, [g] - зеленый, [+] - стрелка вверх, [-] - стрелка вниз"
        />
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(ChartTableForm);
