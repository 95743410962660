import Blocks from "emg-ui-kit/components/Blocks";
import Button from "emg-ui-kit/components/Button";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateNotEmpty,
  validatePositive,
  validateTimingSum,
  validateMaxLines,
  validateDelaysSum,
  validateZeroOrMoreZero,
  removeTouched,
  removeSerialSpaces,
} from "../util";
import { TimingField } from "./TimingField";

const DEFAULTTIMING = 45;

interface Page {
  thesises: string[];
  texts: string[];
  delays: number[];
  timing: number;
}

type TimingType = "total" | "separate";

function createPage(): Page {
  return {
    thesises: [""],
    texts: [""],
    delays: [0],
    timing: 45,
  };
}

function initPages(count = 1) {
  return Array.from(Array(count), createPage);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    title: (initialFormData?.title ?? "Москва. Коротко") as string,
    align: (initialFormData?.align ?? "left") as "left" | "center",
    subtitle: (initialFormData?.subtitle ??
      "Узнай обо всём первым на t.me/infomoscow24") as string,
    design: (initialFormData?.design ?? "black") as
      | "black"
      | "red"
      | "yellow"
      | "alert",
    timingType:
      (initialFormData?.equalTimings as TimingType) === undefined
        ? "total"
        : (initialFormData?.equalTimings as TimingType)
        ? "total"
        : "separate",
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : DEFAULTTIMING) as number,
    autoCheck: (initialFormData?.autoCheck ?? false) as boolean,
    autoText: (initialFormData?.autoText ?? "") as string,
    pages: (initialFormData?.pages?.map((page: any) => {
      return page.timing ? page : { ...page, timing: 0 };
    }) ?? initPages()) as Page[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    title: validateNotEmpty(values.title),
    timing:
      values.timingType === "total"
        ? validatePositive(values.timing) ??
          validateMax(values.timing, MAX_TIMING)
        : undefined,
    pages: values.pages.map((page, pageIndex) => ({
      texts: page.texts.map(
        (text) =>
          validateNotEmpty(text) ??
          validateMaxLines(text, values.design === "alert" ? 3 : 4)
      ),
      timing:
        values.timingType === "separate"
          ? validatePositive(page.timing) ??
            validateTimingSum(values.timing, MAX_TIMING)
          : undefined,
      delays: page.delays.map(
        (delay) =>
          validateZeroOrMoreZero(delay) ??
          validateDelaysSum(
            maxValue(page.delays) + 5,
            calcTotalPageTiming(values, pageIndex)
          )
      ),
    })),
  };
  return removeEmptyProps(errors);
}

function maxValue(items: number[]): number {
  const maxValue = Math.max(...items);
  return maxValue;
}

function sumTimings(items: { timing: number }[]) {
  return items.reduce((acc, item) => acc + item.timing, 0);
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : sumTimings(values.pages);
}
function calcTotalPageTiming(values: Values, pageIndex: number) {
  return values.timingType === "total"
    ? values.timing
    : values.pages[pageIndex].timing;
}

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName", "title", "subtitle", "design", "align"),
    pages: values.pages.map((page) =>
      values.timingType === "total"
        ? pick(page, "thesises", "texts", "delays")
        : page
    ),
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
  };
}

function ImportantHourForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  function autoСomplete() {
    if (values.autoText) {
      const splitParts = values.autoText.split("+++");
      const parts = splitParts.map((part) => part.trim());

      const page: Page = {
        thesises: [],
        texts: [],
        delays: [],
        timing: 45,
      };

      parts.forEach((part, index) => {
        if (index < 3) {
          const match = part.match(/\(\((.*?)\)\)/);
          if (match) {
            page.thesises.push(match[1].trim());
            page.texts.push(part.replace(match[0], "").trim());
          } else {
            page.thesises.push("");
            page.texts.push(part);
          }
          page.delays.push(index * 5);
        }
      });
      setFieldValue("pages[0]", page);
    }
  }

  usePreview(channel, template, calcTotalTiming(values), values, prepareData);

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={TextArea}
          name="title"
          {...getValidationProps("title", touched, errors)}
          label="Заголовок"
          rows={2}
          required
        />
        <Field
          as={TextField}
          label="Подзаголовок"
          name="subtitle"
          {...getValidationProps("subtitle", touched, errors)}
        />
        <Field
          as={Select}
          name="design"
          label="Оформление"
          options={[
            { id: "black", name: "Черный" },
            { id: "red", name: "Красный" },
            { id: "yellow", name: "Желтый" },
            { id: "alert", name: "Alert" },
          ]}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            const value = event.target.value;
            setFieldValue("design", value);
            value !== "alert" &&
              values.pages.forEach((page, index) => {
                if (page.texts.length === 5) {
                  const limitedTexts = values.pages[index].texts.slice(0, 4);
                  setFieldValue(`pages.${index}.texts`, limitedTexts);
                }
              });
          }}
        />
        <Field
          as={Select}
          name="align"
          label="Выравнивание"
          options={[
            { id: "left", name: "Слева" },
            { id: "center", name: "По центру" },
          ]}
          onChange={(event: React.ChangeEvent<HTMLSelectElement>) => {
            setFieldValue("align", event.target.value);
          }}
        />
        <Field
          as={Select}
          name="timingType"
          label="Хронометраж"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждой страницы" },
          ]}
          disabled={values.autoCheck}
        />
        {values.timingType === "total" && (
          <Field
            as={TextField}
            name="timing"
            type="number"
            label="Общий хронометраж (сек.)"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("timing", +event.target.value)
            }
            {...getValidationProps("timing", touched, errors)}
            disabled={values.autoCheck}
          />
        )}
        <div style={{ marginBottom: 30 }}>
          <Checkbox
            label="Автозаполнение"
            checked={values.autoCheck}
            setChecked={(value) => {
              setFieldValue("timing", DEFAULTTIMING);
              setFieldValue("timingType", "total");
              setFieldValue("autoCheck", value);
            }}
            style={{ marginLeft: isDesktop ? 210 : 0 }}
          />
          {values.autoCheck && (
            <>
              <Field
                as={TextArea}
                name="autoText"
                label="Текст для автозаполнения"
                rows={11}
              />
              <Button title="Автозаполнение" onClick={autoСomplete} />
            </>
          )}
        </div>

        <Blocks
          blockTitle="Страница"
          items={values.pages}
          defaultItemConstructor={createPage}
          updateItems={(pages) => setFieldValue("pages", pages)}
          onDelete={(index) => {
            removeTouched(`pages`, index, touched, setTouched);
          }}
          canChangeLength={!values.autoCheck}
        >
          {(item, index, updateItem) => (
            <>
              {item.texts.map((text, innerIndex) => (
                <React.Fragment key={`fragment_${innerIndex}`}>
                  <Field
                    key={`pages.${index}.thesises.${innerIndex}`}
                    as={TextArea}
                    name={`pages.${index}.thesises.${innerIndex}`}
                    {...getDeepValidationProps(
                      `pages.${index}.thesises.${innerIndex}`,
                      touched,
                      errors
                    )}
                    placeholder="Тезис"
                    rows={1}
                  />
                  <Field
                    key={`pages.${index}.texts.${innerIndex}`}
                    id={`pages.${index}.texts.${innerIndex}`}
                    as={TextArea}
                    value={values.pages[index].texts[innerIndex]}
                    onChange={(event: any) =>
                      setFieldValue(
                        `pages.${index}.texts.${innerIndex}`,
                        removeSerialSpaces(event.target.value)
                      )
                    }
                    {...getDeepValidationProps(
                      `pages.${index}.texts.${innerIndex}`,
                      touched,
                      errors
                    )}
                    placeholder="Текст"
                    required
                    rows={values.design !== "alert" ? 4 : 3}
                  />
                  <Field
                    key={`delay_${innerIndex}`}
                    as={TextField}
                    name={`pages.${index}.delays.${innerIndex}`}
                    label="Задержка"
                    type="number"
                    labelStyle={{ maxWidth: 180 }}
                    {...getDeepValidationProps(
                      `pages.${index}.delays.${innerIndex}`,
                      touched,
                      errors
                    )}
                  />
                  <br />
                </React.Fragment>
              ))}
              <hr />
              {values.timingType === "separate" && (
                <TimingField
                  blocksName="pages"
                  index={index}
                  updateItem={updateItem}
                  touched={touched}
                  errors={errors}
                  defaultTiming={values.pages[index].timing ?? 5}
                />
              )}
              <TextField
                label="Плашки"
                type="number"
                value={item.texts.length.toString()}
                onChange={(event) => {
                  const val = +event.target.value;
                  const len = item.texts.length;
                  if (
                    val === len ||
                    val < 1 ||
                    val > (values.design === "alert" ? 5 : 4)
                  )
                    return;
                  let delays = values.pages[index].delays;
                  let lastDelay = delays[delays.length - 1];
                  if (val > len) {
                    updateItem({
                      texts: item.texts.concat(Array(val - len).fill("")),
                      thesises: item.thesises.concat(Array(val - len).fill("")),
                      delays: item.delays.concat(
                        Array(val - len).fill(lastDelay + 5)
                      ),
                    });
                  } else {
                    updateItem({
                      texts: item.texts.slice(0, val),
                      thesises: item.thesises.slice(0, val),
                      delays: item.delays.slice(0, val),
                      timing: lastDelay,
                    });
                    removeTouched(
                      `pages[${index}].texts`,
                      val,
                      touched,
                      setTouched
                    );
                  }
                }}
                labelStyle={{ maxWidth: 180 }}
              />
            </>
          )}
        </Blocks>
        <br />

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(ImportantHourForm);
