import { Option } from "emg-ui-kit/components/Search/Search";
import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { PhotoTypes } from "../common/ApiService";
import { getPersonsThunk } from "../redux/persons";
import { selectPersons } from "../redux/persons/selectors";

export default function usePersons(photoType?: PhotoTypes) {
  const { persons, isLoading } = useSelector(selectPersons);
  const [person, setPerson] = useState<Option>();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPersonsThunk(photoType));
  }, [dispatch, photoType]);

  const handleUpdatePerson = useCallback((selected?: Option) => {
    setPerson(selected);
  }, []);

  return { persons, isLoading, person, handleUpdatePerson };
}
