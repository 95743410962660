import Checkbox from "emg-ui-kit/components/Checkbox";
import React from "react";
import { useSelector, useDispatch } from "react-redux";

import { channels, templates } from "../../../common/texts";
import DetailsGroup from "../../../components/Details/DetailsGroup";
import { selectAuth } from "../../../redux/auth/selectors";
import {
  setGroupCheckedFilter,
  setTemplateFilter,
} from "../../../redux/filters/filters.reducer";
import { RootState, AppDispatch } from "../../../store";
import CheckboxList from "./CheckboxList";
import {
  arrayContainsAllElements,
  arrayContainsSomeElements,
} from "./filterUtils";
import { useDetails } from "./useDetails";

export default function ProjectsGroups() {
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();
  const { userInfo } = useSelector(selectAuth);
  const projects = Object.keys(templates);

  const { groups, handleDetails } = useDetails({
    groupsList: projects,
    groupStorageName: "projects_details",
  });

  const isGroupVisible = (project: string) =>
    userInfo?.groups.includes(project) || !userInfo?.groups.length;

  return (
    <>
      {projects.map((project) => {
        const groupKeys = Object.keys(templates[project]);

        const allGroupChecked = arrayContainsAllElements(
          filterState.templates,
          groupKeys
        );

        const partChecked = arrayContainsSomeElements(
          groupKeys,
          filterState.templates
        );

        return (
          <div key={`${project}-group`}>
            {isGroupVisible(project) && (
              <DetailsGroup
                style={{ marginTop: 0 }}
                arrowWithMargin
                noHeaderOnlick
                open={groups[project]}
                onClick={(event) => handleDetails(project, event)}
                headerComponent={
                  <Checkbox
                    label={channels[project]}
                    checked={allGroupChecked || partChecked}
                    setChecked={(val) => {
                      dispatch(
                        setGroupCheckedFilter({
                          value: templates[project],
                        })
                      );
                    }}
                    style={{
                      fontFamily: '"Futura New Bold", sans-serif',
                    }}
                    square={!allGroupChecked && partChecked}
                  />
                }
                bodyComponent={
                  <CheckboxList
                    items={templates[project]}
                    itemsState={filterState.templates}
                    dispatchFunc={setTemplateFilter}
                  />
                }
              />
            )}
          </div>
        );
      })}
    </>
  );
}
