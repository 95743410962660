import React from "react";

import { InfoRow } from "../InfoComponents";

export function ImportantHour({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />

      <InfoRow title="Заголовок" value={data.title} />
      <InfoRow title="Подзаголовок" value={data.subtitle} />

      <InfoRow title="Оформление" value={data.design} />
      <InfoRow title="Выравнивание" value={data.align} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />

      {(data.pages as Record<string, any>[]).map((page, idx) => (
        <details key={idx}>
          <summary>Страница №{idx + 1}</summary>
          <InfoRow title="Текст" value={page.texts.join("\n")} />

          <InfoRow title="Тезис" value={page.thesises.join("\n")} />
        </details>
      ))}
    </>
  );
}
