import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import BackButton from "../../common/BackButton";
import PageContainer from "../../common/PageContainer";
import { Task } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import SearchResultSkeletonMobile from "../../components/Skeletons/SearchResultSkeletonMobile";
import SearchResultsSkeleton from "../../components/Skeletons/SearchResultsSkeleton";
import SearchBar from "./SearchBar";
import SearchList from "./SearchList";
import style from "./SearchPage.module.css";
import useSearch from "./useSearch";

export default function SearchPage() {
  const location = useLocation();
  const isDesktop = useIsDesktop();
  const params = new URLSearchParams(location.search);
  const query = params.get("query") ?? "";

  const { setQuery, results, loading } = useSearch();

  const [separateResults, setSeparateResults] = useState<{
    drafts: Task[];
    orders: Task[];
  }>({ drafts: [], orders: [] });

  const separate = useCallback((items: Task[]) => {
    const drafts = items.filter((item) => item.status === "draft");
    const orders = items.filter((item) => item.status !== "draft");
    return { drafts, orders };
  }, []);

  useEffect(() => {
    setQuery(query);
  }, [query, setQuery]);

  useEffect(() => {
    if (results) {
      setSeparateResults(separate(results.orders));
    }
  }, [results, results.orders, separate]);

  const ComponentToDispaly = () => {
    if (loading) {
      return isDesktop ? (
        <SearchResultsSkeleton />
      ) : (
        <SearchResultSkeletonMobile />
      );
    }
    return (
      <>
        {separateResults.orders.length > 0 && (
          <>
            <Title text="Заказы" />
            <SearchList
              tasks={separateResults.orders}
              isLoading={loading}
              loadNext={() => {}}
            />
          </>
        )}
        {separateResults.drafts.length > 0 && (
          <>
            <Title text="Черновики" />

            <SearchList
              tasks={separateResults.drafts}
              isLoading={loading}
              loadNext={() => {}}
            />
          </>
        )}
      </>
    );
  };

  return (
    <PageContainer>
      {!isDesktop && <SearchBar />}
      <div className={style.container}>
        {
          <>
            <div style={{ width: "100%" }}>
              <BackButton />
            </div>
            {query ? (
              <Title text={`Результаты поиска для «${query}»`} />
            ) : (
              <Title text={"Поиск"} />
            )}
            <ComponentToDispaly />
          </>
        }
      </div>
    </PageContainer>
  );
}
