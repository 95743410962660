import React from "react";
import ContentLoader from "react-content-loader";

export default function UsersListSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={1200}
      height={466}
      viewBox="0 0 1200 466"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
    >
      <path d="M 0 0 h 1200 v 24 H 0 z M 0 42 h 289.105 v 28 H 0 z M 306.113 42 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 42 H 901.33 v 28 H 612.225 z M 918.334 42 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 218 h 289.105 v 28 H 0 z M 306.113 218 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 218 H 901.33 v 28 H 612.225 z M 918.334 218 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 130 h 289.105 v 28 H 0 z M 306.113 130 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 130 H 901.33 v 28 H 612.225 z M 918.334 130 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 306 h 289.105 v 28 H 0 z M 306.113 306 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 306 H 901.33 v 28 H 612.225 z M 918.334 306 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 394 h 289.105 v 28 H 0 z M 306.113 394 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 394 H 901.33 v 28 H 612.225 z M 918.334 394 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 86 h 289.105 v 28 H 0 z M 306.113 86 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 86 H 901.33 v 28 H 612.225 z M 918.334 86 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 262 h 289.105 v 28 H 0 z M 306.113 262 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 262 H 901.33 v 28 H 612.225 z M 918.334 262 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 174 h 289.105 v 28 H 0 z M 306.113 174 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 174 H 901.33 v 28 H 612.225 z M 918.334 174 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 350 h 289.105 v 28 H 0 z M 306.113 350 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 350 H 901.33 v 28 H 612.225 z M 918.334 350 h 281.665 v 28 H 918.334 z" />
      <path d="M 0 438 h 289.105 v 28 H 0 z M 306.113 438 h 289.105 v 28 H 306.113 z" />
      <path d="M 612.225 438 H 901.33 v 28 H 612.225 z M 918.334 438 h 281.665 v 28 H 918.334 z" />
    </ContentLoader>
  );
}
