import Button from "emg-ui-kit/components/Button";
import {
  HtmlEditor,
  validateEditorState,
} from "emg-ui-kit/components/HtmlEditor";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import BackButton from "../common/BackButton";
import FlexContainer from "../common/FlexContainer";
import PageContainer from "../common/PageContainer";
import { convertToSelectOptions, useIsDesktop } from "../common/utils";
import {
  deleteNewsArticleThunk,
  getNewsArticleThunk,
  selectNewsArticle,
  updateNewsArticleThunk,
} from "../redux/news";
import routes from "../routes";
import { AppDispatch } from "../store";
import { groups } from "./userConstants";

const groupOptions = convertToSelectOptions(groups);

export default function NewsEdit() {
  const dispatch = useDispatch<AppDispatch>();
  const history = useHistory();
  const { id } = useParams<{ id: string }>();
  const article = useSelector(selectNewsArticle(id));
  const [group, setGroup] = useState(article?.group ?? "all");
  const [content, setContent] = useState(article?.content ?? "");
  const isDesktop = useIsDesktop();

  const contentErrorMessage = validateEditorState(content);

  useEffect(() => {
    if (id) {
      dispatch(getNewsArticleThunk(id));
    }
  }, [dispatch, id]);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    dispatch(updateNewsArticleThunk({ id, content, group })).then((result) => {
      if ("error" in result) return;
      history.push(routes.newsArticles);
    });
  };

  const handleDelete = () => {
    dispatch(deleteNewsArticleThunk(id)).then((result) => {
      if ("error" in result) return;
      history.push(routes.newsArticles);
    });
  };

  if (!article) return null;
  return (
    <PageContainer>
      <BackButton to={routes.newsArticles} />
      <Title text="Редактировать новость" />
      <form onSubmit={handleSubmit}>
        <Select
          name="group"
          label="Группа"
          options={groupOptions}
          value={group}
          onChange={(event) => setGroup(event.target.value)}
        />
        <HtmlEditor
          initialValue={content}
          setValue={(content) => setContent(content)}
        />
        <FlexContainer style={{ flexDirection: isDesktop ? "row" : "column" }}>
          <Button
            type="submit"
            title="Сохранить"
            status={!contentErrorMessage ? "enabled" : "disabled"}
            style={{ marginBottom: 10 }}
          />
          <Button
            type="button"
            color="red"
            title="Удалить"
            onClick={handleDelete}
            style={{ marginBottom: 10 }}
          />
        </FlexContainer>
      </form>
    </PageContainer>
  );
}
