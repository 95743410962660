import React from "react";

import styles from "./TheCity.module.css";

export default function MapControls({
  handleHomeClick,
  handleZoomClick,
}: {
  handleHomeClick: (
    e: React.MouseEvent<HTMLButtonElement> & { target: { name: string } }
  ) => void;
  handleZoomClick: (
    e: React.MouseEvent<HTMLButtonElement> & { target: { name: string } }
  ) => void;
}) {
  return (
    <div className={styles.mapControlsContainer}>
      <button
        className={styles.homeButton}
        type="button"
        name="home"
        onClick={handleHomeClick}
      />
      <div className={styles.zoomButtonsContainer}>
        <button
          className={styles.plusButton}
          type="button"
          name="plus"
          onClick={handleZoomClick}
        />
        <button
          className={styles.minusButton}
          type="button"
          name="minus"
          onClick={handleZoomClick}
        />
      </div>
    </div>
  );
}
