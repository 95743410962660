import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Meme({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Комментарий" value={data.comment} />
      <ImageRow title="Фотография" src={data.image} />
    </>
  );
}
