import {
  createAsyncThunk,
  createReducer,
  isFulfilled,
  isPending,
  isRejected,
} from "@reduxjs/toolkit";

import {
  createUser,
  deleteUser,
  getUser,
  getUsers,
  updateUser,
  User,
} from "../../common/ApiService";

export interface UsersState {
  byId: Record<string, User | undefined>;
  ids: string[];
  isLoading: boolean;
}

const initialState: UsersState = {
  byId: {},
  ids: [],
  isLoading: false,
};

export const getUsersThunk = createAsyncThunk("users/get", getUsers);

export const getUserByIdThunk = createAsyncThunk("users/getById", getUser);

export const createUserThunk = createAsyncThunk("users/create", createUser);

export const updateUserByIdThunk = createAsyncThunk(
  "users/updateById",
  updateUser
);

export const deleteUserByIdThunk = createAsyncThunk(
  "users/deleteById",
  deleteUser
);

const usersReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(deleteUserByIdThunk.fulfilled, (state, action) => {
      delete state.byId[action.payload.id];
      const deletedIndex = state.ids.indexOf(action.payload.id);
      if (deletedIndex > -1) {
        state.ids.splice(deletedIndex, 1);
      }
    })
    .addCase(getUsersThunk.fulfilled, (state, action) => {
      const ids = action.payload.map((user) => user.id);
      const byId = action.payload.reduce<Record<string, User>>((acc, user) => {
        acc[user.id] = user;
        return acc;
      }, {});
      state.ids = ids;
      state.byId = byId;
      state.isLoading = false;
    })
    .addMatcher(
      isFulfilled(getUserByIdThunk, updateUserByIdThunk, createUserThunk),
      (state, action) => {
        state.byId[action.payload.id] = action.payload;
        state.isLoading = false;
      }
    )
    .addMatcher(
      isPending(getUsersThunk, createUserThunk, updateUserByIdThunk),
      (state) => {
        state.isLoading = true;
      }
    )
    .addMatcher(
      isRejected(getUsersThunk, createUserThunk, updateUserByIdThunk),
      (state) => {
        state.isLoading = false;
      }
    );
});

export default usersReducer;
