import Blocks from "emg-ui-kit/components/Blocks";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  removeTouched,
  validateMaxLines,
  validatePositive,
} from "../util";
import { extendedAgeOptions, colorOptions } from "./constants";

type Sources = {
  sourceText: string;
  sourceColor: string;
};

function createSources(): Sources {
  return {
    sourceText: "",
    sourceColor: "white",
  };
}

function createDates(): string {
  return "";
}

function createComments(): string {
  return "";
}

function initSources(count: number) {
  return Array.from(Array(count), createSources);
}

function initDates(count: number) {
  return Array.from(Array(count), createDates);
}

function initComments(count: number) {
  return Array.from(Array(count), createComments);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: (initialFormData?.timing ?? 10) as number,
    title: (initialFormData?.title ?? "") as string,
    eventName: (initialFormData?.eventName ?? "") as string,
    locationName: (initialFormData?.locationName ?? "") as string,
    age: (initialFormData?.age ?? "0+") as string,
    dates: (initialFormData?.dates ?? initDates(1)) as string[],
    sources: (initialFormData?.sources ?? initSources(0)) as Sources[],
    comments: (initialFormData?.comments ?? initComments(1)) as string[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    timing: validatePositive(values.timing),
    title: validateNotEmpty(values.title) ?? validateMaxLines(values.title, 3),
    locationName: validateMaxLines(values.locationName, 2),
    dates: values.dates.map((date) => validateMaxLines(date, 2)),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    ...values,
    title: values.title.toUpperCase(),
    eventName: values.eventName.toUpperCase(),
  };
}

function M24InformerCityForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setTouched,
    isValid,
    isSubmitting,
  } = formik;

  const buttonProps = {
    isValid,
    isSubmitting,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, values.timing, values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={TextField}
          label="Хронометраж"
          name="timing"
          {...getValidationProps("timing", touched, errors)}
          required
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          textareaStyle={{ textTransform: "uppercase" }}
          {...getValidationProps("title", touched, errors)}
          rows={3}
          required
        />
        <Field
          as={TextField}
          label="Название мероприятия"
          name="eventName"
          textFieldStyle={{ textTransform: "uppercase" }}
        />
        <Field
          as={TextArea}
          label="Место проведения"
          name="locationName"
          {...getValidationProps("locationName", touched, errors)}
          rows={2}
        />
        <Field
          as={Select}
          label="Возрастной ценз"
          name="age"
          options={extendedAgeOptions}
        />
        <Blocks
          items={values.dates}
          blockTitle="Дата"
          addButtonTitle="Дату"
          updateItems={(dates) => setFieldValue("dates", dates)}
          onDelete={(index) => {
            removeTouched(`dates`, index, touched, setTouched);
          }}
          defaultItemConstructor={createDates}
          tooltip={`Размер текста: <size=50></size> & Примеры заполнения даты: & "31.12" & "ПРОХОДИТ ДО 31.12" & "00:00 01.01" & "С 31.12 ПО 01.01"`}
          canChangeLength
          minBlocks={0}
        >
          {(item, index, updateItem) => (
            <Field
              as={TextArea}
              name={`dates.${index}`}
              {...getDeepValidationProps(`dates.${index}`, touched, errors)}
              rows={2}
            />
          )}
        </Blocks>
        <Blocks
          items={values.comments}
          blockTitle="Комментарий"
          updateItems={(comments) => setFieldValue("comments", comments)}
          onDelete={(index) => {
            removeTouched(`comments`, index, touched, setTouched);
          }}
          defaultItemConstructor={createComments}
          canChangeLength
          minBlocks={0}
        >
          {(item, index, updateItem) => (
            <Field as={TextField} name={`comments.${index}`} />
          )}
        </Blocks>
        <Blocks
          items={values.sources}
          blockTitle="Источник"
          updateItems={(sources) => setFieldValue("sources", sources)}
          onDelete={(index) => {
            removeTouched(`sources`, index, touched, setTouched);
          }}
          defaultItemConstructor={createSources}
          canChangeLength
          minBlocks={0}
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={TextField}
                name={`sources.${index}.sourceText`}
                placeholder="Текст источника"
                value={item.sourceText}
                {...getDeepValidationProps(
                  `sources[${index}].sourceText`,
                  touched,
                  errors
                )}
              />
              <Field
                as={Select}
                label="Цвет источника"
                name={`sources.${index}.sourceColor`}
                options={colorOptions}
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24InformerCityForm);
