import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function VipQuote({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      <InfoRow title="Тема" value={data.theme.name} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      {data.doc && <InfoRow title="Документ" value={data.doc} />}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <ImageRow title="Фото" src={data.photoUrl} />
      <InfoRow
        title="Цитата"
        value={data.blocks.map((item: any) => item.text).join("\n")}
      />
    </>
  );
}
