import React from "react";

import { ColorBox, InfoRow } from "../InfoComponents";

export function M24Columns({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.clipName && (
        <InfoRow title="Название ролика" value={data.clipName} />
      )}
      {(data.items as Record<string, any>[]).map((item, index) => (
        <details key={index} style={{ marginTop: 10 }}>
          <summary>Слайд №{index + 1}</summary>
          <div style={{ marginLeft: 15, marginTop: 10 }}>
            <InfoRow title="Хронометраж (сек.)" value={item.timing} />
            <InfoRow title="Заголовок" value={item.title} />
            {item.subtitle && (
              <InfoRow title="Подзаголовок" value={item.subtitle} />
            )}
            {item.source && <InfoRow title="Источник" value={item.source} />}
            <InfoRow title="Столбов в категории" value={item.columnCount} />
            <InfoRow title="Мин. значение столба" value={item.minValue} />
            <InfoRow title="Макс. значение столба" value={item.maxValue} />
            {item.isLegendActive && (
              <>
                <hr />
                <details>
                  <summary>Панель легенды</summary>
                  <div style={{ marginLeft: 15, marginTop: 10 }}>
                    {(item.legends as Record<string, any>[]).map(
                      (legend, legendIndex) => (
                        <div key={legendIndex}>
                          <InfoRow
                            title={`Легенда ${legendIndex + 1}`}
                            value={legend.legendText}
                          />
                          <InfoRow
                            title="Цвет"
                            value={<ColorBox color={legend.legendColor} />}
                          />
                        </div>
                      )
                    )}
                  </div>
                </details>
              </>
            )}
            <hr />
            {(item.categories as Record<string, any>[]).map(
              (category, categoryIndex) => (
                <details key={categoryIndex} style={{ marginTop: 10 }}>
                  <summary>{`Категория ${categoryIndex + 1}`}</summary>
                  <div style={{ marginLeft: 15, marginTop: 10 }}>
                    <InfoRow
                      title={`Заголовок категории`}
                      value={category.categoryTitle}
                    />
                    {(category.columns as Record<string, any>[]).map(
                      (column, columnIndex) => (
                        <div key={columnIndex}>
                          <InfoRow
                            title={`Значение столба ${columnIndex + 1}`}
                            value={column.columnValue}
                          />
                          {column.columnText && (
                            <InfoRow
                              title="Подпись столба"
                              value={column.columnText}
                            />
                          )}
                          <InfoRow
                            title="Цвет"
                            value={<ColorBox color={column.columnColor} />}
                          />
                        </div>
                      )
                    )}
                  </div>
                </details>
              )
            )}
            <hr />
          </div>
        </details>
      ))}
    </>
  );
}
