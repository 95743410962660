import React from "react";
import ContentLoader from "react-content-loader";

const OrderFormSkeletonMoblie = () => (
  <ContentLoader
    speed={2}
    width="100%"
    height={410}
    viewBox="0 0 374 410"
    backgroundColor="#f2f2f2"
    foregroundColor="#ffffff"
    preserveAspectRatio="none"
  >
    <path d="M 0 0 h 123 v 22 H 0 z M 0 29 h 374 v 41 H 0 z M 0 85 h 123 v 22 H 0 z M 0 114 h 374 v 41 H 0 z M 0 170 h 123 v 22 H 0 z M 0 199 h 374 v 41 H 0 z M 0 255 h 123 v 22 H 0 z M 0 284 h 374 v 41 H 0 z M 0 340 h 123 v 22 H 0 z M 0 369 h 374 v 41 H 0 z" />
  </ContentLoader>
);

export default OrderFormSkeletonMoblie;
