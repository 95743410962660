import { createAsyncThunk, createReducer } from "@reduxjs/toolkit";

import {
  EmailNotificationParams,
  getEmailNotificationsParams,
  updateEmailNotificationsParams,
} from "../../common/ApiService";

export interface EmailParamsState {
  emailParams?: EmailNotificationParams;
  isLoading: boolean;
  isUpdating: boolean;
}

const initialState: EmailParamsState = {
  isLoading: false,
  isUpdating: false,
};

export const getEmailParamsThunk = createAsyncThunk(
  "emailParams/get",
  getEmailNotificationsParams
);

export const updateEmailParamsThunk = createAsyncThunk(
  "emailParams/update",
  updateEmailNotificationsParams
);

const emailParamsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getEmailParamsThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getEmailParamsThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.emailParams = action.payload;
    })
    .addCase(getEmailParamsThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(updateEmailParamsThunk.pending, (state) => {
      state.isUpdating = true;
    })
    .addCase(updateEmailParamsThunk.fulfilled, (state, action) => {
      state.isUpdating = false;
      state.emailParams = action.payload;
    })
    .addCase(updateEmailParamsThunk.rejected, (state) => {
      state.isUpdating = false;
    });
});

export default emailParamsReducer;
