import { Form as FormikForm } from "formik";
import React from "react";

export default function Form(props: React.PropsWithChildren<{}>) {
  const handleKey = (event: React.KeyboardEvent) => {
    const target = event.target as HTMLElement;

    if (
      event.key === "Enter" &&
      target.tagName !== "TEXTAREA" &&
      !target.isContentEditable
    ) {
      event.preventDefault();
    }
  };
  return <FormikForm onKeyDown={handleKey}>{props.children}</FormikForm>;
}
