import { createAction, createReducer } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export interface ViewParamsState {
  topOffset: number;
}

const initialState: ViewParamsState = { topOffset: 68 };

export const setTopOffset = createAction<number>("viewParams/setTopOffset");

const viewParamsReducer = createReducer(initialState, (builder) => {
  builder.addCase(setTopOffset, (state, action) => {
    state.topOffset = action.payload;
  });
});

export default viewParamsReducer;

export const selectTopOffset = (state: RootState) => state.viewParams.topOffset;
