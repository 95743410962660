import React from "react";

import { Task } from "../../../common/models";
import { channels, templates } from "../../../common/texts";
import { dateFormatter } from "../../../common/utils";
import StatusBadge from "../../components/StatusBadge/StatusBadge";
import styles from "./TaskList.module.css";

interface TaskRowProps {
  task: Task;
  onToggleSubtable: (itemId: string) => void;
}

const TaskRow = React.forwardRef<HTMLDivElement, TaskRowProps>(function (
  { task, onToggleSubtable },
  ref,
) {
  return (
    <article ref={ref} className={styles.task}>
      <div className={styles.taskHeader}>
        <div className={styles.project}>{channels[task.channel]}</div>
        <div className={styles.time}>
          {dateFormatter.format(Date.parse(task.creationDate))}
        </div>
      </div>
      <div className={styles.titleRow}>
        <div className={styles.title}>
          <details className={styles.tableDetails}>
            <summary
              className={styles.tableSummary}
              onClick={(e) => {
                e.stopPropagation();
                onToggleSubtable(task._id);
              }}
            ></summary>
          </details>
          {templates[task.channel]?.[task.template] ?? "-"}
          {task.data.clipName ? ", " + task.data.clipName : ""}
        </div>
        <StatusBadge
          status={task.status}
          progress={task.progress}
          renderProgress={task.renderProgress}
          style={{ marginTop: 4, height: "17px" }}
        />
      </div>
    </article>
  );
});

export default TaskRow;
