import React from "react";
import ContentLoader from "react-content-loader";

export default function FiltersSkeletonLoader() {
  return (
    <ContentLoader
      style={{ marginTop: 20 }}
      speed={2}
      width={280}
      height={731}
      viewBox="0 0 250 731"
      backgroundColor="#F2F2F2"
      foregroundColor="#FFFFFF"
    >
      <path d="M 0 0 h 250 v 24 H 0 z M 0 253 h 250 v 24 H 0 z M 0 506 h 250 v 24 H 0 z M 32 38 h 218 v 22 H 32 z M 32 291 h 218 v 22 H 32 z M 32 544 h 218 v 22 H 32 z M 32 137 h 218 v 22 H 32 z M 32 390 h 218 v 22 H 32 z M 32 643 h 218 v 22 H 32 z M 32 71 h 218 v 22 H 32 z M 32 324 h 218 v 22 H 32 z M 32 577 h 218 v 22 H 32 z M 32 170 h 218 v 22 H 32 z M 32 423 h 218 v 22 H 32 z M 32 676 h 218 v 22 H 32 z M 32 104 h 218 v 22 H 32 z M 32 357 h 218 v 22 H 32 z M 32 610 h 218 v 22 H 32 z M 32 203 h 218 v 22 H 32 z M 32 456 h 218 v 22 H 32 z M 32 709 h 218 v 22 H 32 z" />
    </ContentLoader>
  );
}
