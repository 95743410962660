import Radio from "emg-ui-kit/components/Radio";
import { useFormikContext } from "formik";
import React from "react";

import FlexContainer from "../../../common/FlexContainer";
import { getItemFieldName } from "../../util";
import { ItemBase } from "../types";

interface Props {
  item: ItemBase;
  index: number;
  onChange?: (value: string) => void;
}

export default function TimingBlock({ item, index, onChange }: Props) {
  const formik = useFormikContext();
  const timingName = getItemFieldName(index, "timing");
  const timingRadioProps = {
    name: timingName,
    currentValue: item.timing,
    setCurrentValue: (value: string) =>
      onChange?.(value) ?? formik.setFieldValue(timingName, value),
    style: { marginTop: "0.5rem", flexBasis: 100 },
  };
  return (
    <>
      <div>Хронометраж</div>
      <FlexContainer>
        <Radio {...timingRadioProps} value="5" title="5 сек" />
        <Radio {...timingRadioProps} value="10" title="10 сек" />
      </FlexContainer>
    </>
  );
}
