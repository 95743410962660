import React from "react";

import { ColorBox, InfoRow } from "../InfoComponents";

export function ChartLinearDouble({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      {data.leftCaption && (
        <InfoRow title="Подпись слева" value={data.leftCaption} />
      )}
      {data.rightCaption && (
        <InfoRow title="Подпись справа" value={data.rightCaption} />
      )}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>График №{idx + 1}</summary>
          <InfoRow title="Заголовок" value={block.title} />
          <InfoRow title="Последнее значение" value={block.lastValue} />
          <InfoRow title="Дельта" value={block.delta} />
          <InfoRow title="Значения" value={block.values} />
          <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
        </details>
      ))}
    </>
  );
}
