import classNames from "classnames";
import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";

import { channels, templates } from "../../common/texts";
import { useIsDesktop } from "../../common/utils";
import styles from "./SearchBar.module.css";
import useSearch from "./useSearch";

const MAX_PREVIEW_RESULTS = 6;

export default function SearchBar() {
  const { query, setQuery, results, loading, clearQuery } = useSearch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [active, setActive] = useState(false);

  const isDesktop = useIsDesktop();
  const handleChange = (value: string) => {
    setQuery(value);
  };

  const reset = () => {
    clearQuery();
    setActive(false);
  };

  const handleBlur = (event: React.FocusEvent) => {
    if (
      event.relatedTarget &&
      (event.relatedTarget as HTMLElement).classList.contains(styles.result)
    ) {
      return;
    } else {
      reset();
    }
  };

  const handleKeyUp = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Escape") {
      inputRef.current?.blur();
    }
  };

  return (
    <div className={isDesktop ? styles.container : styles.containerMobile}>
      <input
        ref={inputRef}
        placeholder="Поиск..."
        className={classNames(styles.search, {
          [styles.loading]: loading,
        })}
        value={query}
        onChange={(event) => handleChange(event.target.value)}
        onFocus={() => setActive(true)}
        onBlur={handleBlur}
        onKeyUp={handleKeyUp}
      />
      {results.orders.length > 0 && active && (
        <section className={styles.results}>
          {results.orders.slice(0, MAX_PREVIEW_RESULTS).map((result) => (
            <Link
              onClick={reset}
              tabIndex={0}
              key={result._id}
              to={`/tasks/${result._id}`}
              className={styles.result}
            >
              <span
                dangerouslySetInnerHTML={{
                  __html: `${channels[result.channel]} - ${
                    result.channel === "m24"
                      ? templates.m24[result.template]
                      : templates.r24[result.template]
                  } -${
                    result.data.clipName ?? result.data.name ?? ""
                  } ${new Date(result.creationDate).toLocaleDateString()} `,
                }}
              />
            </Link>
          ))}
          <Link
            onClick={reset}
            tabIndex={0}
            to={`/search?query=${query}`}
            className={styles.loadMoreButton}
          >
            Все результаты
          </Link>
        </section>
      )}
      {!results.orders.length && active && !loading && (
        <section className={styles.results}>
          <div className={styles.result}>Нет результатов</div>
        </section>
      )}
    </div>
  );
}
