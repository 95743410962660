import Radio from "emg-ui-kit/components/Radio";
import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, useFormikContext } from "formik";
import React from "react";

import { AtmosphereFormParams } from "../../../../common/ApiService";
import FlexContainer from "../../../../common/FlexContainer";
import LegendInput from "../../../../common/LegendInput";
import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { iconOptions, twoLinesColors } from "../../constants";
import { TwoLinesItem } from "../../types";
import MapBlock from "../MapBlock";
import SourceBlock from "../SourceBlock";
import TimingBlock from "../TimingBlock";

interface Props {
  item: TwoLinesItem;
  index: number;
  formParams?: AtmosphereFormParams;
}

export default function TwoLinesBlock({ item, index, formParams }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors
  );

  const additionalName = getItemFieldName(index, "additionalField");
  const additionalRadioProps = {
    name: additionalName,
    currentValue: item.additionalField,
    setCurrentValue: (value: string) =>
      formik.setFieldValue(additionalName, value),
    style: { margin: "0.5rem 0 0 0" },
  };

  const iconName = getItemFieldName(index, "icon");
  const firstLineName = getItemFieldName(index, "firstLine");
  const secondLineName = getItemFieldName(index, "secondLine");

  return (
    <>
      <div>Дополнительно</div>
      <FlexContainer style={{ flexDirection: "column" }}>
        <Radio {...additionalRadioProps} value="none" title="Ничего" />
        <Radio
          {...additionalRadioProps}
          value="icon-left"
          title="Иконка слева"
        />
        <Radio
          {...additionalRadioProps}
          value="icon-right"
          title="Иконка справа"
          style={{ margin: "0.5rem 0" }}
        />
      </FlexContainer>

      {item.additionalField !== "none" && (
        <Field
          as={Select}
          placeholder="Иконка"
          name={iconName}
          options={iconOptions}
          {...validationUtils.getDeepProps(iconName)}
        />
      )}
      <Field
        as={TextField}
        placeholder="Первая строка"
        name={firstLineName}
        {...validationUtils.getDeepProps(firstLineName)}
        required
      />
      {item.additionalField === "none" ? (
        <LegendInput
          placeholder="Вторая строка"
          color={item.secondLineColor}
          title={item.secondLine}
          updateColor={(color) =>
            formik.setFieldValue(
              getItemFieldName(index, "secondLineColor"),
              color
            )
          }
          updateTitle={(title) => {
            formik.setFieldValue(secondLineName, title);
          }}
          onBlur={() => formik.setFieldTouched(secondLineName)}
          colors={twoLinesColors}
          {...validationUtils.getDeepProps(secondLineName)}
          required
        />
      ) : (
        <Field
          as={TextField}
          placeholder="Вторая строка"
          name={secondLineName}
          {...validationUtils.getDeepProps(secondLineName)}
          required
        />
      )}

      <TimingBlock item={item} index={index} />
      {!!formParams && (
        <MapBlock item={item} index={index} formParams={formParams} />
      )}
      <SourceBlock item={item} index={index} />
    </>
  );
}
