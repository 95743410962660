import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  pick,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  getDeepValidationProps,
} from "../util";

const IMAGE_ASPECT = 16 / 9;

interface BlockItem {
  comment?: string;
  source?: string;
  sourceColor: string;
  image?: FormImage;
}

function createBlock() {
  return {
    comment: "",
    source: "",
    image: undefined,
    sourceColor: "gray",
  };
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: 30,
    h1: (initialFormData?.h1 ?? "") as string,
    eventName: (initialFormData?.eventName ?? "") as string,
    locationName: (initialFormData?.locationName ?? "") as string,
    date: (initialFormData?.date ?? "") as string,
    dateSize: (initialFormData?.dateSize ?? 90) as number,
    age: (initialFormData?.age ?? "") as string,
    items: (initialFormData?.blocks ??
      Array(3).fill(createBlock())) as BlockItem[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    ...pick(
      values,
      "clipName",
      "h1",
      "eventName",
      "locationName",
      "date",
      "dateSize",
      "age",
    ),
    totalTiming: values.timing,
    blocks: values.items,
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    h1: validateNotEmpty(values.h1),
    eventName: validateNotEmpty(values.eventName),
    locationName: validateNotEmpty(values.locationName),
    date: validateNotEmpty(values.date),
    dateSize: validatePositive(values.dateSize),
    age: validateNotEmpty(values.age),
    items: values.items.map((item) => ({
      comment: validateNotEmpty(item.comment),
      image:
        validateNotEmpty(item.image) ??
        validateAspect(item.image!, IMAGE_ASPECT),
    })),
  };
  return removeEmptyProps(errors);
}

function InformerForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, values.timing, values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
          value={values.clipName}
        />
        <Field
          disabled
          as={TextField}
          name="timing"
          type="number"
          label="Хронометраж (сек.)"
        />
        <Field
          as={TextField}
          label="Название мероприятия"
          name="eventName"
          value={values.eventName}
          {...getValidationProps("eventName", touched, errors)}
          required
        />
        <Field
          as={TextField}
          label="Адрес мероприятия"
          name="locationName"
          value={values.locationName}
          {...getValidationProps("locationName", touched, errors)}
          required
        />
        <Field
          as={TextField}
          label="Возрастной ценз"
          name="age"
          value={values.age}
          {...getValidationProps("age", touched, errors)}
          required
        />
        <Field
          as={TextArea}
          rows={2}
          label="Дата"
          name="date"
          value={values.date}
          {...getValidationProps("date", touched, errors)}
          required
        />
        <Field
          as={TextArea}
          rows={2}
          label="Заголовок"
          name="h1"
          {...getValidationProps("h1", touched, errors)}
          value={values.h1}
          required
          hint="Для 3-х и 4-х строк ставьте дополнительный & Enter после 2-й строки."
        />
        <Field
          as={TextField}
          type="number"
          label="Размер шрифта даты"
          name="dateSize"
          value={values.dateSize}
          {...getValidationProps("dateSize", touched, errors)}
          required
        />
        <Blocks
          items={values.items}
          defaultItemConstructor={createBlock}
          updateItems={(items) => setFieldValue("items", items)}
          blockTitle="Слайд"
          canChangeLength
          maxBlocks={3}
          minBlocks={3}
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={TextArea}
                rows={2}
                name={`items.${index}.comment`}
                placeholder="Комментарий"
                {...getDeepValidationProps(
                  `items[${index}].comment`,
                  touched,
                  errors,
                )}
                required
              />
              <Field
                as={TextField}
                name={`items.${index}.source`}
                placeholder="Источник картинки"
                value={item.source}
              />
              <Select
                name="sourceColor"
                options={[
                  { id: "gray", name: "Серый" },
                  { id: "white", name: "Белый" },
                ]}
                value={item.sourceColor}
                onChange={(event) =>
                  updateItem({ sourceColor: event.target.value })
                }
                onBlur={formik.handleBlur}
                placeholder="Цвет источника"
              />
              <ImageInput
                imageTypes={IMAGE_TYPES}
                image={item.image}
                updateImage={(image) => {
                  setFieldTouched(`items.${index}.image`);
                  updateItem({ image });
                }}
                onBlur={() => {
                  setFieldTouched(`items.${index}.image`, true);
                }}
                {...getDeepValidationProps(
                  `items[${index}].image`,
                  touched,
                  errors,
                )}
                aspect={IMAGE_ASPECT}
                required
              />
            </>
          )}
        </Blocks>
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(InformerForm);
