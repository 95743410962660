import IconButton from "emg-ui-kit/components/IconButton";
import React, { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./TaskTable.module.css";

interface Props {
  tasks: any[];
  isStepCounterDisabled?: boolean;
  totalTasks?: number;
  counterTitle?: string;
  page: number;
  perPage: number;
  totalPages: number;
  changePage: (page: number) => void;
  style?: React.CSSProperties;
  pageChangingToolAvailable?: boolean;
}

const PageNumber = ({
  number,
  onClick,
  isActive,
}: {
  number: number;
  onClick?: () => void;
  isActive: boolean;
}) => {
  return (
    <span
      className={`${styles.pageNumber} ${
        isActive ? styles.pageNumberActive : ""
      }`}
      onClick={() => {
        if (!isActive && onClick) {
          onClick();
        }
      }}
    >
      {number}
    </span>
  );
};

export default function NewPagination({
  tasks,
  isStepCounterDisabled,
  totalTasks,
  counterTitle,
  page,
  perPage,
  totalPages,
  changePage,
  style,
  pageChangingToolAvailable,
}: Props) {
  const nextPage = useCallback(() => {
    changePage(page + 1);
  }, [changePage, page]);

  const prevPage = useCallback(() => {
    changePage(page - 1);
  }, [changePage, page]);

  const [pageInputValue, setPageInputValue] = useState<number | string>(page);

  const isPageValid = useMemo(() => {
    const number = Number(pageInputValue);
    if (number > 0 && number < totalPages + 1) {
      return true;
    }
    return false;
  }, [pageInputValue, totalPages]);

  const changePageByNumber = useCallback(() => {
    if (isPageValid) {
      changePage(Number(pageInputValue));
    }
  }, [changePage, isPageValid, pageInputValue]);

  const handleEnterClickOnPageInput = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        changePageByNumber();
      }
    },
    [changePageByNumber]
  );

  const handleChangePage = useCallback(
    (number: number) => {
      changePage(number);
    },
    [changePage]
  );

  useEffect(() => {
    setPageInputValue(page);
  }, [page]);

  const pages = useMemo(() => {
    if (totalPages <= 5) {
      return [...Array(totalPages).keys()].map((i) => i + 1);
    }
    if (totalPages > 5 && page < 5) {
      return [1, 2, 3, 4, 5];
    }
    if (page > 4 && page < totalPages - 3) {
      return [page - 2, page - 1, page, page + 1, page + 2];
    }
    if (page > totalPages - 4) {
      return [
        totalPages - 4,
        totalPages - 3,
        totalPages - 2,
        totalPages - 1,
        totalPages,
      ];
    }
  }, [totalPages, page]);

  if (!pageChangingToolAvailable && !tasks.length) return null;

  const toShowAtStart = totalPages >= 5 && page > 4;
  const toShowInTheEnd = totalPages > 5 && (page <= totalPages - 4 || page < 5);

  return (
    <div
      style={{ ...style, position: "relative" }}
      className={styles.paginationBlock}
    >
      {!isStepCounterDisabled && (
        <span className={styles.stepCounterTitle}>
          {counterTitle ? counterTitle : "Заказов"}
          {" на странице:"}
          <select disabled className={styles.stepCounter}>
            <option>{tasks.length}</option>
          </select>
        </span>
      )}
      <button
        disabled={page === 1}
        className={styles.prevButton}
        onClick={prevPage}
      />
      <span>
        {toShowAtStart && (
          <>
            <PageNumber
              number={1}
              isActive={page === 1}
              onClick={() => handleChangePage(1)}
            />
            ...
          </>
        )}
        {pages?.map((pg) => (
          <PageNumber
            number={pg}
            isActive={pg === page}
            onClick={() => handleChangePage(pg)}
            key={pg}
          />
        ))}
        {toShowInTheEnd && (
          <>
            ...
            <PageNumber
              number={totalPages}
              isActive={page === totalPages}
              onClick={() => handleChangePage(totalPages)}
            />
          </>
        )}
      </span>
      <button
        disabled={totalPages === page}
        className={styles.nextButton}
        onClick={nextPage}
      />

      {pageChangingToolAvailable && (
        <>
          <input
            type="number"
            value={pageInputValue}
            onChange={(e) => {
              const value = e.target.value;
              if (value !== "" && !(Number(value) >= 0)) return;
              setPageInputValue(value);
            }}
            onKeyDown={handleEnterClickOnPageInput}
            className={styles.paginationInput}
            onWheel={(event) => {
              event.preventDefault();
            }}
          />
          <IconButton
            design="go"
            onClick={changePageByNumber}
            status={
              !isPageValid || page === Number(pageInputValue)
                ? "disabled"
                : "enabled"
            }
          />
        </>
      )}

      {totalTasks && (
        <span className={styles.totalTasksTitle}>
          {counterTitle ? counterTitle : "Заказов"}
          {" всего :"}
          <select disabled className={styles.stepCounter}>
            <option>{totalTasks}</option>
          </select>
        </span>
      )}
      <div className={styles.validationBlock}>
        {!isPageValid && pageInputValue !== "" && (
          <span
            className={styles.pageValidMessage}
          >{`Страницы ${pageInputValue} не существует`}</span>
        )}
      </div>
    </div>
  );
}
