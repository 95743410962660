export const userTypes = {
  client: "Клиент",
  "demo-client": "Демо клиент",
  employee: "Сотрудник",
  admin: "Администратор",
  service: "Технический аккаунт",
};

export const groups: Record<string, string> = {
  all: "Все",
  r24: "Россия 24",
  m24: "Москва 24",
  maps: "Карты",
};
