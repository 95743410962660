import React from "react";
import ContentLoader from "react-content-loader";

const OrderFormSkeleton = () => (
  <ContentLoader
    speed={2}
    width={650}
    height={924}
    viewBox="0 0 679 924"
    backgroundColor="#f2f2f2"
    foregroundColor="#ffffff"
  >
    <path d="M 219 0 h 460 v 41 H 219 z M 0 9 h 172 v 22 H 0 z M 219 76 h 460 v 41 H 219 z M 0 85 h 172 v 22 H 0 z M 219 441 h 460 v 41 H 219 z M 0 450 h 172 v 22 H 0 z M 219 517 h 460 v 110 H 219 z M 0 526 h 172 v 22 H 0 z M 219 662 h 460 v 41 H 219 z M 0 671 h 172 v 22 H 0 z M 219 883 h 460 v 41 H 219 z M 0 892 h 172 v 22 H 0 z M 219 738 h 460 v 110 H 219 z M 0 747 h 172 v 22 H 0 z M 219 152 h 460 v 254 H 219 z" />
  </ContentLoader>
);

export default OrderFormSkeleton;
