import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router-dom";

import AtmoDataSync from "../admin/AtmoDataSync";
import NewsCreate from "../admin/NewsCreate";
import NewsEdit from "../admin/NewsEdit";
import NewsList from "../admin/NewsList";
import NotificationSettings from "../admin/NotificationSettings";
import PersonChangeForm from "../admin/PersonChangeForm";
import PersonList from "../admin/PersonList";
import PersonNewForm from "../admin/PersonNewForm";
import UserChangeForm from "../admin/UserChangeForm";
import UserList from "../admin/UserList";
import UserNewForm from "../admin/UserNewForm";
import Alert from "../common/Alert";
import { useFavicon } from "../common/useFavicon";
import { useShouldRenderTable } from "../common/utils";
import DashBoard from "../dashboard/DashBoard";
import LoginForm from "../login/LoginForm";
import RegisterForm from "../login/RegisterForm";
import OrderForm from "../order/OrderForm";
import { checkAuthenticationThunk } from "../redux/auth";
import { selectAuth } from "../redux/auth/selectors";
import { getBitrixTemplatesThunk } from "../redux/bitrix/bitrix.reducer";
import { getNotificationThunk } from "../redux/notifications";
import "../redux/notifications/actions";
import routes from "../routes";
import { RootState } from "../store";
import { IFilterState } from "../tasks/components/Filters/FilterTypes";
import { saveFilterState } from "../tasks/components/Filters/filterUtils";
import { useSocketTracking } from "../tasks/hooks/useSocketTracking";
import DraftListPage from "../tasks/pages/DraftListPage/DraftListPage";
import DraftTablePage from "../tasks/pages/DraftTablePage/DraftTablePage";
import TaskListPage from "../tasks/pages/TaskListPage";
import Task from "../tasks/pages/TaskPage";
import TaskTablePage from "../tasks/pages/TaskTablePage";
import AdmRoute from "./AdmRoute";
import Header from "./Header";
import News from "./News";
import SearchPage from "./Search/SearchPage";

export default function App() {
  const dispatch = useDispatch();
  const { userInfo } = useSelector(selectAuth);

  const shouldRenderTable = useShouldRenderTable();

  const filterState = useSelector<RootState>((state) => state.filter);

  useSocketTracking();

  useFavicon();

  useEffect(() => {
    dispatch(checkAuthenticationThunk());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getNotificationThunk());
  }, [dispatch]);

  useEffect(() => {
    if (filterState) {
      saveFilterState(filterState as IFilterState);
    }
  }, [filterState]);

  // Bitrix stuff
  useEffect(() => {
    if (userInfo?.type === "client") {
      dispatch(getBitrixTemplatesThunk());
    }
  }, [dispatch, userInfo?.type]);

  return (
    <>
      <Header />
      {userInfo ? (
        <Switch>
          <Redirect from={routes.signup} to={routes.tasksList} />
          <AdmRoute
            path={routes.notifications}
            component={NotificationSettings}
          />
          <AdmRoute path={routes.atmoDataSync} component={AtmoDataSync} />
          <AdmRoute path={routes.usersNew} component={UserNewForm} />
          <AdmRoute path={routes.usersUpdate} component={UserChangeForm} />
          <AdmRoute path={routes.users} component={UserList} />
          <AdmRoute path={routes.personsNew} component={PersonNewForm} />
          <AdmRoute path={routes.personsUpdate} component={PersonChangeForm} />
          <AdmRoute path={routes.persons} component={PersonList} />
          <AdmRoute path={routes.newsArticlesNew} component={NewsCreate} />
          <AdmRoute path={routes.newsArticlesUpdate} component={NewsEdit} />
          <AdmRoute path={routes.newsArticles} component={NewsList} />
          <Route path={routes.newsArticlesView} component={News} />
          <Route path={routes.tasksNew} component={OrderForm} />
          <Route path={routes.tasksView} component={Task} />
          <Route path={routes.draft} component={OrderForm} />
          <Route path={routes.dashBoard} component={DashBoard} />
          <Route path={routes.search} component={SearchPage} />

          <Route
            path={routes.drafts}
            component={shouldRenderTable ? DraftTablePage : DraftListPage}
          />
          <Route
            path={[routes.tasksList, routes.index]}
            component={shouldRenderTable ? TaskTablePage : TaskListPage}
          />
        </Switch>
      ) : (
        <Switch>
          <Route path={routes.signup} component={RegisterForm} />
          <Route path={routes.fallback} component={LoginForm} />
        </Switch>
      )}
      <Alert />
    </>
  );
}
