import { createAction, createReducer } from "@reduxjs/toolkit";

import { RootState } from "../../store";

export interface UsePreviewState {
  usePreviewChecked: boolean;
  usePreviewData: boolean;
}

const initialState: UsePreviewState = {
  usePreviewChecked: false,
  usePreviewData: false,
};

export const setUsePreviewChecked = createAction<boolean>(
  "UsePreview/setUsePreviewChecked"
);
export const setUsePreviewData = createAction<boolean>(
  "UsePreview/setUsePreviewData"
);

const UsePreviewReducer = createReducer(initialState, (builder) => {
  builder.addCase(setUsePreviewChecked, (state, action) => {
    state.usePreviewChecked = action.payload;
  });
  builder.addCase(setUsePreviewData, (state, action) => {
    state.usePreviewData = action.payload;
  });
});

export default UsePreviewReducer;

export const usePreviewChecked = (state: RootState) =>
  state.usePreview.usePreviewChecked;
export const usePreviewData = (state: RootState) =>
  state.usePreview.usePreviewData;
