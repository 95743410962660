import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { Field, useFormikContext } from "formik";
import React from "react";

import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { WindItem } from "../../types";

const directions = [
  "Северный",
  "Северо-Восточный",
  "Восточный",
  "Юго-Восточный",
  "Южный",
  "Юго-Западный",
  "Западный",
  "Северо-Западный",
];
const windOptions = directions.map((name) => ({ id: name, name }));

interface Props {
  item: WindItem;
  index: number;
}

export default function WindBlock({ index }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );
  const windDirectionName = getItemFieldName(index, "windDirection");
  const windSpeedName = getItemFieldName(index, "windSpeed");
  return (
    <>
      <Field
        as={Select}
        placeholder="Направление"
        name={windDirectionName}
        options={windOptions}
        {...validationUtils.getDeepProps(windDirectionName)}
        required
      />
      <Field
        as={TextField}
        placeholder="Скорость"
        name={windSpeedName}
        {...validationUtils.getDeepProps(windSpeedName)}
        required
      />
    </>
  );
}
