import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "../../../common/PageContainer";
import { getDraftsThunk } from "../../../redux/drafts";
import { selectDrafts } from "../../../redux/drafts/selectors";
import { AppDispatch } from "../../../store";
import { restoreFilterState } from "../../components/Filters/filterUtils";
import useDeleteDraft from "../../hooks/useDeleteDraft";
import CreateTaskButton from "../TaskListPage/CreateTaskButton";
import DraftList from "./DraftList";

export default function DraftListPage() {
  const [params, setParams] = useState(restoreFilterState);
  const { drafts, isLoading } = useSelector(selectDrafts);

  const loadNext = useCallback(() => {
    setParams((params) => ({ ...params, page: params.page + 1 }));
  }, []);

  const dispatch = useDispatch<AppDispatch>();

  const { handleDeleteDraft, isDisabled } = useDeleteDraft(() =>
    dispatch(getDraftsThunk(params))
  );

  useEffect(() => {
    dispatch(getDraftsThunk(params));
  }, [dispatch, params]);

  return (
    <PageContainer>
      <Title text="Черновики" />
      <DraftList
        tasks={drafts}
        isLoading={isLoading}
        loadNext={loadNext}
        onDeleteDraftClick={handleDeleteDraft}
        isDisabled={isDisabled}
      />
      <CreateTaskButton />
    </PageContainer>
  );
}
