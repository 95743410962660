import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import styles from "../../common/subBlocks/subBlocks.module.css";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  IMAGE_TYPES,
  pick,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  removeTouched,
} from "../util";

const IMAGE_ASPECT = 16 / 9;

const MAX_SUB_BLOCKS = 3;

interface BlockItem {
  eventType?: string;
  eventName?: string;
  locationName?: string;
  dateSize: number;
  date?: string;
  age?: string;
  imageType: string;
  image?: FormImage;
  comments: {
    comment: string;
    image?: FormImage;
  }[];
}

function createBlock() {
  return {
    eventType: "",
    eventName: "",
    locationName: "",
    dateSize: 57,
    date: "",
    age: "",
    imageType: "separate",
    image: undefined,
    comments: [
      { comment: "", image: undefined },
      { comment: "", image: undefined },
    ],
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), createBlock);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: (initialFormData?.timing ?? 35) as number,
    items: (initialFormData?.blocks ?? initItems(2)) as BlockItem[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName"),
    timing: +values.timing,
    blocks: values.items,
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    items: values.items.map((item) => ({
      eventType: validateNotEmpty(item.eventType),
      eventName: validateNotEmpty(item.eventName),
      dateSize: validatePositive(item.dateSize),
      date: validateNotEmpty(item.date),
      image:
        item.imageType === "total"
          ? validateNotEmpty(item.image) ??
            validateAspect(item.image!, IMAGE_ASPECT)
          : undefined,
      comments: item.comments.map((subItem) => ({
        comment: validateNotEmpty(subItem.comment),
        image:
          item.imageType === "separate"
            ? validateNotEmpty(subItem.image) ??
              validateAspect(subItem.image!, IMAGE_ASPECT)
            : undefined,
      })),
    })),
  };
  return removeEmptyProps(errors);
}

function D24InformerForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, +values.timing, values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
          value={values.clipName}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timing"
          options={[
            { id: 35, name: "35 секунд" },
            { id: 75, name: "75 секунд" },
          ]}
        />
        <Blocks
          items={values.items}
          defaultItemConstructor={createBlock}
          updateItems={(items) => setFieldValue("items", items)}
          blockTitle="Блок"
          canChangeLength
          maxBlocks={3}
          minBlocks={1}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
        >
          {(item, index, updateItem) => (
            <div>
              <Field
                as={TextField}
                name={`items.${index}.eventType`}
                placeholder="Тип события"
                required
                value={item.eventType}
                {...getDeepValidationProps(
                  `items[${index}].eventType`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                name={`items.${index}.locationName`}
                placeholder="Место проведения"
                value={item.locationName}
              />
              <Field
                as={TextArea}
                rows={3}
                name={`items.${index}.age`}
                placeholder="Возрастной ценз"
                value={item.age}
              />
              <Field
                as={TextField}
                type="number"
                label="Размер шрифта даты"
                name={`items.${index}.dateSize`}
                value={item.dateSize}
                {...getDeepValidationProps(
                  `items[${index}].dateSize`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextArea}
                rows={3}
                name={`items.${index}.date`}
                placeholder="Дата"
                required
                value={item.date}
                {...getDeepValidationProps(
                  `items[${index}].date`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                name={`items.${index}.eventName`}
                placeholder="Название события"
                required
                value={item.eventName}
                {...getDeepValidationProps(
                  `items[${index}].eventName`,
                  touched,
                  errors
                )}
              />
              <Field
                as={Select}
                label="Изображение"
                name={`items.${index}.imageType`}
                options={[
                  { id: "total", name: "Общее" },
                  { id: "separate", name: "Для каждого слайда" },
                ]}
              />
              {item.imageType === "total" && (
                <ImageInput
                  title={"Добавить общее изображение"}
                  imageTypes={IMAGE_TYPES}
                  image={item.image}
                  updateImage={(image) => {
                    setFieldValue(`items.${index}.image`, image);
                  }}
                  onBlur={() => {
                    setFieldTouched(`items.${index}.image`, true);
                  }}
                  {...getDeepValidationProps(
                    `items[${index}].image`,
                    touched,
                    errors
                  )}
                  aspect={IMAGE_ASPECT}
                  required
                />
              )}

              {item.comments.map((comment, innerIndex) => {
                const blockTitle = "Комментарий";
                const minSubBlocks = 1;
                const moreThanMin = item.comments.length > (minSubBlocks ?? 1);

                return (
                  <React.Fragment key={`fragment_${innerIndex}`}>
                    <div className={styles.slide}>
                      <section className={styles.titleContainer}>
                        <div className={styles.title}>
                          {blockTitle ?? "Слайд"} {innerIndex + 1}
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {moreThanMin && (
                            <button
                              type="button"
                              className={styles.deleteButton}
                              onClick={() => {
                                removeTouched(
                                  `items[${index}].comments`,
                                  innerIndex,
                                  touched,
                                  setTouched
                                );
                                updateItem({
                                  comments: item.comments.filter(
                                    (_, i) => i !== innerIndex
                                  ),
                                });
                              }}
                            />
                          )}
                        </div>
                      </section>
                      <Field
                        key={`comment_${innerIndex}`}
                        as={TextArea}
                        rows={3}
                        name={`items.${index}.comments.${innerIndex}.comment`}
                        placeholder="Комментарий"
                        required
                        value={item.comments[innerIndex].comment}
                        {...getDeepValidationProps(
                          `items[${index}].comments.${innerIndex}.comment`,
                          touched,
                          errors
                        )}
                      />
                      {item.imageType === "separate" && (
                        <ImageInput
                          style={{ marginBottom: 0 }}
                          required
                          imageTypes={IMAGE_TYPES}
                          image={item.comments[innerIndex].image}
                          updateImage={(image) => {
                            setFieldValue(
                              `items.${index}.comments.${innerIndex}.image`,
                              image
                            );
                          }}
                          onBlur={() => {
                            setFieldTouched(
                              `items.${index}.comments.${innerIndex}.image`,
                              true
                            );
                          }}
                          {...getDeepValidationProps(
                            `items[${index}].comments.${innerIndex}.image`,
                            touched,
                            errors
                          )}
                          aspect={IMAGE_ASPECT}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
              {item.comments.length < MAX_SUB_BLOCKS && (
                <button
                  type="button"
                  onClick={() => {
                    updateItem({
                      comments: item.comments.concat(
                        Array(1).fill({ comment: "", image: undefined })
                      ),
                    });
                  }}
                  className={styles.addButton}
                >
                  Добавить{" комментарий"}
                </button>
              )}
            </div>
          )}
        </Blocks>
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(D24InformerForm);
