import React from "react";
import { useSelector } from "react-redux";

import { selectAuth } from "../../../redux/auth/selectors";
import BitrixProjectsGroups from "./BitrixProjectsGroups";
import ProjectsGroups from "./ProjectsGroups";

export default function ProjectsWrap() {
  const userInfo = useSelector(selectAuth).userInfo;
  const isClient = userInfo?.type === "client";

  const componentToRender = isClient ? (
    <BitrixProjectsGroups />
  ) : (
    <ProjectsGroups />
  );
  return <>{componentToRender}</>;
}
