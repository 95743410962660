interface Theme {
  id: string;
  name: string;
  background: string;
  color: string;
}

export const THEMES_FULL: Record<string, Theme> = {
  eagle: {
    id: "eagle",
    name: "Официальный",
    background: "eagle",
    color: "eagle",
  },
  vesti: { id: "vesti", name: "Вести", background: "vesti", color: "vesti" },
  v23: { id: "v23", name: "Вести 23", background: "v23", color: "v23" },
  weather: {
    id: "weather",
    name: "Погода",
    background: "weather",
    color: "weather",
  },
  alert: { id: "alert", name: "Срочно", background: "alert", color: "alert" },
  sport: { id: "sport", name: "Спорт", background: "sport", color: "sport" },
  utro_news: {
    id: "utro_news",
    name: "Утро Новости",
    background: "utro",
    color: "vesti",
  },
  utro_eco: {
    id: "utro_eco",
    name: "Утро Экономика",
    background: "utro",
    color: "eco",
  },
  utro_sport: {
    id: "utro_sport",
    name: "Утро Спорт",
    background: "utro",
    color: "sport",
  },
  facts: { id: "facts", name: "Факты", background: "facts", color: "facts" },
  eco: { id: "eco", name: "Экономика", background: "eco", color: "eco" },
};

export const THEMES = Object.values(THEMES_FULL).reduce<Record<string, string>>(
  (acc, theme) => {
    acc[theme.id] = theme.name;
    return acc;
  },
  {}
);

export const SITES: Record<string, string> = {
  fb: "Facebook",
  gv: "Правительство России",
  ig: "Instagram",
  kremlin: "Президент России",
  pravo: "Официальный интернет-портал правовой информации",
  tass: "ТАСС",
  tg: "Telegram",
  twitter: "Twitter",
  vk: "Вконтакте",
  youtube: "Youtube",
  mid: "МИД Росии",
  times: "The New York Times",
  wp: "The Washington Post",
  custom: "Пользовательский вариант",
};

export const SOURCES = [
  "facebook.com",
  "government.ru",
  "instagram.com",
  "kremlin.ru",
  "pravo.gov.ru",
  "tass.ru",
  "telegram.org",
  "twitter.com",
  "vk.com",
  "youtube.com",
];

export const designOptionsСhurch = [
  { id: "standard", name: "Cтандартное" },
  { id: "church", name: "Церковь и мир" },
];
