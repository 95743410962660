import { Vector2d } from "konva/lib/types";
import { Dispatch, SetStateAction } from "react";

export const AddresOrientation = {
  l: "Слева",
  r: "Справа",
  t: "Сверху",
  lt: "Сверху Слева",
  rt: "Сверху Справа",
  b: "Снизу",
  lb: "Снизу Слева",
  rb: "Снизу Справа",
};

export type AddressOrient = keyof typeof AddresOrientation;

export type LocationPoint = {
  id: string;
  pos: Vector2d;
  text: string;
  align: AddressOrient;
};

export type Size = { width: number; height: number };

export type MapData = {
  scale: number;
  stagePos: Vector2d;
  points: LocationPoint[];
  frame: [Vector2d, Vector2d] | [];
  safeFrameVisible: boolean;
};

export type MapProps = {
  /**
   * SetState функция для сохранения данных
   */
  saveMapData: Dispatch<SetStateAction<MapData | undefined>>;
  /**
   * Максимальный масштаб
   */
  maxScale?: number;
  /**
   * Минимальный масштаб
   */
  minScale?: number;
  /**
   * Размер холста
   */
  stageSize?: Size;
  /**
   * Изначальные данные, если есть. Например при повторе заказа
   */
  mapData?: MapData;
  /**
   * Коллбэк для закрытия модалки
   */
  handleClose?: () => void;
  /**
   * Коллбэк получение id удаляемой точки на карте
   */
  onDeleteId: (id?: string) => void;
};

export type GeoResponse = {
  input_address: string;
  geocode_address: string;
  error?: string;
  geometry: {
    pixel_coordinates: Vector2d;
  };
};
