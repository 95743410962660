import classNames from "classnames";
import React, { useRef, useState } from "react";

import { useOutsideClick } from "../../hooks/useOutsideClick";
import styles from "./AnimationButton.module.scss";
import Tooltip from "./Tooltip";

interface AnimationButtonProps {
  selectedOption: string;
  handleOptionClick: (option: string) => void;
  style?: React.CSSProperties;
  tooltip?: string;
}

const AnimationButton: React.FC<AnimationButtonProps> = ({
  selectedOption,
  handleOptionClick,
  style,
  tooltip,
}) => {
  const [selectVisible, setSelectVisible] = useState(false);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const buttonRef = useRef<HTMLDivElement>(null!);

  useOutsideClick(buttonRef, () => setSelectVisible(false));

  const handleButtonClick = () => {
    setSelectVisible(!selectVisible);
  };

  const options: { [key: string]: string } = {
    header: "Заголовок",
    text: "Текст",
    number: "Число",
  };

  return (
    <div style={{ position: "relative" }}>
      <div
        ref={buttonRef}
        onClick={handleButtonClick}
        onMouseEnter={() => setTooltipVisible(true)}
        onMouseLeave={() => setTooltipVisible(false)}
        className={classNames(styles.toolbarButton, styles[selectedOption])}
        style={style}
      />
      {tooltip && <Tooltip text={tooltip} tooltipVisible={tooltipVisible} />}

      {selectVisible && (
        <div className={styles.selectContainer}>
          {Object.keys(options).map((option) => (
            <div
              key={option}
              className={
                selectedOption === option
                  ? styles.selectedButton
                  : styles.button
              }
              onClick={() => {
                handleOptionClick(option);
                setSelectVisible(false);
              }}
            >
              {options[option]}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AnimationButton;
