import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import styles from "../../common/subBlocks/subBlocks.module.css";
import { useIsDesktop } from "../../common/utils";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  IMAGE_TYPES,
  pick,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateNotEmpty,
  removeTouched,
  validateMaxLines,
} from "../util";
import {
  ageOptions,
  imageTypeOptions,
  colorOptions,
  sourceTypeOptions,
} from "./constants";

const IMAGE_ASPECT = 16 / 9;

const SUB_BLOCK_TITLE = "Слайд";
const MIN_SUB_BLOCKS = 1;
const MAX_SUB_BLOCKS = 2;

interface SubBlock {
  comment: string;
  source: string;
  sourceColor: string;
  image?: FormImage;
}

interface BlockItem {
  title: string;
  date: string;
  locationName: string;
  age: string;
  sourceType: string;
  source?: string;
  sourceColor: string;
  imageType: string;
  image?: FormImage;
  subBlocks: SubBlock[];
}

function createSubBlock(): SubBlock {
  return {
    comment: "",
    source: "",
    sourceColor: "white",
    image: undefined,
  };
}

function createBlock() {
  return {
    title: "",
    date: "",
    locationName: "",
    age: "0+",
    sourceType: "separate",
    source: "",
    sourceColor: "white",
    imageType: "separate",
    image: undefined,
    subBlocks: [createSubBlock(), createSubBlock()],
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), createBlock);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: (initialFormData?.timing ?? 35) as number,
    eventName: (initialFormData?.eventName ?? "ТОП-3") as string,
    theCity: (initialFormData?.theCity ?? false) as boolean,
    items: (initialFormData?.blocks ?? initItems(3)) as BlockItem[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    eventName: validateNotEmpty(values.eventName),
    items: values.items.map((item) => ({
      title: validateNotEmpty(item.title) ?? validateMaxLines(item.title, 3),
      date: validateNotEmpty(item.date) ?? validateMaxLines(item.date, 2),
      locationName: validateNotEmpty(item.locationName),
      image:
        item.imageType === "total"
          ? validateNotEmpty(item.image) ??
            validateAspect(item.image!, IMAGE_ASPECT)
          : undefined,
      subBlocks: item.subBlocks.map((subItem) => ({
        comment:
          validateNotEmpty(subItem.comment) ??
          validateMaxLines(subItem.comment, 2),
        image:
          item.imageType === "separate"
            ? validateNotEmpty(subItem.image) ??
              validateAspect(subItem.image!, IMAGE_ASPECT)
            : undefined,
      })),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName", "theCity"),
    eventName: values.eventName.toUpperCase(),
    timing: +values.timing,
    blocks: values.items.map((block) => ({
      ...block,
      title: block.title.toUpperCase(),
    })),
  };
}

function M24InformerTop3Form({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  const isDesktop = useIsDesktop();

  usePreview(channel, template, +values.timing, values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          disabled
          as={TextField}
          label="Хронометраж (сек.)"
          name="timing"
          type="number"
        />
        <Field
          as={TextField}
          name="eventName"
          label="Название мероприятия"
          textFieldStyle={{ textTransform: "uppercase" }}
          {...getValidationProps("eventName", touched, errors)}
          required
        />
        <Checkbox
          label="Маркер Сити"
          checked={values.theCity}
          setChecked={(value) => setFieldValue("theCity", value)}
          style={{ marginLeft: isDesktop ? 210 : 0 }}
        />

        <Blocks
          items={values.items}
          defaultItemConstructor={createBlock}
          updateItems={(items) => setFieldValue("items", items)}
          blockTitle="Блок"
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          tooltip={`Размер текста даты: & <size=50></size> & Примеры заполнения даты: & "31.12" & "Пройдёт 31.12" & "00:00 01.01" & "С 31.12 ПО 01.01"`}
        >
          {(item, index, updateItem) => (
            <div key={`items_${index}`}>
              <Field
                as={TextArea}
                name={`items.${index}.title`}
                placeholder="Заголовок"
                rows={3}
                textareaStyle={{ textTransform: "uppercase" }}
                required
                {...getDeepValidationProps(
                  `items[${index}].title`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextArea}
                name={`items.${index}.date`}
                placeholder="Дата"
                rows={2}
                required
                {...getDeepValidationProps(
                  `items[${index}].date`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                name={`items.${index}.locationName`}
                placeholder="Место проведения"
                required
                {...getDeepValidationProps(
                  `items[${index}].locationName`,
                  touched,
                  errors
                )}
              />
              <Field
                as={Select}
                label="Возрастной ценз"
                name={`items.${index}.age`}
                options={ageOptions}
              />
              <Field
                as={Select}
                label="Тип источника"
                name={`items.${index}.sourceType`}
                options={sourceTypeOptions}
              />
              {item.sourceType === "total" && (
                <>
                  <Field
                    as={TextField}
                    placeholder="Общий источник"
                    name={`items.${index}.source`}
                  />
                  <Field
                    as={Select}
                    label="Цвет общего источника"
                    name={`items.${index}.sourceColor`}
                    options={colorOptions}
                    disabled={!item.source}
                  />
                </>
              )}
              <Field
                as={Select}
                label="Тип изображения"
                name={`items.${index}.imageType`}
                options={imageTypeOptions}
              />
              {item.imageType === "total" && (
                <ImageInput
                  title={"Добавить общее изображение"}
                  imageTypes={IMAGE_TYPES}
                  image={item.image}
                  updateImage={(image) => {
                    setFieldValue(`items.${index}.image`, image);
                  }}
                  onBlur={() => {
                    setFieldTouched(`items.${index}.image`, true);
                  }}
                  {...getDeepValidationProps(
                    `items[${index}].image`,
                    touched,
                    errors
                  )}
                  aspect={IMAGE_ASPECT}
                  required
                />
              )}

              {item.subBlocks.map((subBlock, innerIndex) => {
                const moreThanMin =
                  item.subBlocks.length > (MIN_SUB_BLOCKS ?? 0);

                return (
                  <React.Fragment key={`items_${index}.slide_${innerIndex}`}>
                    <div className={styles.slide}>
                      <section className={styles.titleContainer}>
                        <div className={styles.title}>
                          {SUB_BLOCK_TITLE ?? "Слайд"} {innerIndex + 1}
                        </div>
                        <div
                          style={{
                            display: "flex",
                          }}
                        >
                          {moreThanMin && (
                            <button
                              type="button"
                              className={styles.deleteButton}
                              onClick={() => {
                                for (let i = 0; i < values.items.length; i++) {
                                  removeTouched(
                                    `items[${i}].subBlocks`,
                                    innerIndex,
                                    touched,
                                    setTouched
                                  );
                                }
                                const updatedSubBlocks = values.items.map(
                                  (item) => ({
                                    ...item,
                                    subBlocks: item.subBlocks.filter(
                                      (_, i) => i !== innerIndex
                                    ),
                                  })
                                );
                                setFieldValue("items", updatedSubBlocks);
                              }}
                            />
                          )}
                        </div>
                      </section>
                      <Field
                        key={`items.${index}.subBlocks.${innerIndex}.comment`}
                        as={TextArea}
                        rows={2}
                        name={`items.${index}.subBlocks.${innerIndex}.comment`}
                        placeholder="Комментарий"
                        required
                        {...getDeepValidationProps(
                          `items[${index}].subBlocks.${innerIndex}.comment`,
                          touched,
                          errors
                        )}
                      />
                      {item.sourceType === "separate" && (
                        <>
                          <Field
                            as={TextField}
                            placeholder="Источник"
                            name={`items.${index}.subBlocks.${innerIndex}.source`}
                          />
                          <Field
                            as={Select}
                            label="Цвет источника"
                            name={`items.${index}.subBlocks.${innerIndex}.sourceColor`}
                            options={colorOptions}
                            disabled={!subBlock.source}
                          />
                        </>
                      )}
                      {item.imageType === "separate" && (
                        <ImageInput
                          key={`items.${index}.subBlocks.${innerIndex}.image`}
                          style={{ marginBottom: 0 }}
                          required
                          imageTypes={IMAGE_TYPES}
                          image={item.subBlocks[innerIndex].image}
                          updateImage={(image) => {
                            setFieldValue(
                              `items.${index}.subBlocks.${innerIndex}.image`,
                              image
                            );
                          }}
                          onBlur={() => {
                            setFieldTouched(
                              `items.${index}.subBlocks.${innerIndex}.image`,
                              true
                            );
                          }}
                          {...getDeepValidationProps(
                            `items[${index}].subBlocks.${innerIndex}.image`,
                            touched,
                            errors
                          )}
                          aspect={IMAGE_ASPECT}
                        />
                      )}
                    </div>
                  </React.Fragment>
                );
              })}
              {item.subBlocks.length < MAX_SUB_BLOCKS && (
                <button
                  key={`items_${index}`}
                  type="button"
                  onClick={() => {
                    const updatedItems = values.items.map((item) => ({
                      ...item,
                      subBlocks: item.subBlocks.concat(createSubBlock()),
                    }));
                    setFieldValue("items", updatedItems);
                  }}
                  className={styles.addButton}
                >
                  Добавить {"cлайд в каждый блок"}
                </button>
              )}
            </div>
          )}
        </Blocks>
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24InformerTop3Form);
