import Checkbox from "emg-ui-kit/components/Checkbox";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

import { feedbackFilters, filterGroups } from "../../../common/texts";
import { selectAuth } from "../../../redux/auth/selectors";
import {
  setFeedbackFilter,
  setRootFilter,
} from "../../../redux/filters/filters.reducer";
import { AppDispatch, RootState } from "../../../store";
import { Feedback } from "./FilterTypes";
import styles from "./Filters.module.css";

export default function AdditionalGroup() {
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();
  const { userInfo } = useSelector(selectAuth);
  return (
    <div className={styles.filterGroupList}>
      {userInfo?.type === "admin" && (
        <Checkbox
          label={filterGroups.allUsers}
          checked={filterState.allUsers}
          setChecked={(val) => {
            dispatch(setRootFilter({ allUsers: val }));
          }}
        />
      )}
      {Object.entries(feedbackFilters).map(([key, name]) => (
        <Checkbox
          key={`${key}-checkbox`}
          label={name}
          checked={filterState.feedback[key as keyof Feedback]}
          setChecked={(value) => {
            dispatch(
              setFeedbackFilter({ filter: key as keyof Feedback, value })
            );
          }}
        />
      ))}
    </div>
  );
}
