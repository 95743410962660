import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Affiche({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Оформление" value={data.design} />
      <InfoRow title="Заголовок" value={data.title} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Фото блок №{idx + 1}</summary>
          <ImageRow title="Фото" src={block.image} />
          {block.titer && <InfoRow title="Титр" value={block.titer} />}
          {block.source && <InfoRow title="Источник" value={block.source} />}
        </details>
      ))}
    </>
  );
}
