export const templates: Record<string, Record<string, string>> = {
  m24: {
    quote: "Цитата",
    quote_specrep: "Цитата Спецреп",
    m24quote_sobyanin: "Цитата Собянин",
    slideshow: "Слайдшоу",
    affiche: 'Слайдшоу "Зарядье"',
    mezhprom: "Межпром Собянин",
    wheeze: "Хрип",
    wheeze_specrep: "Хрип Спецреп",
    atmosphere: "Атмосфера",
    atmosphere_info: "Атмосфера Инфо",
    meme: "Мем дня",
    number: "Цифра дня",
    reference: "Справка",
    important_hour: "Главное к часу",
    m24piechart: "Круговая диаграмма",
    m24informer2: "Информер",
    m24informer_top_3: "Информер Топ-3",
    m24informer_top_5: "Информер Топ-5",
    m24informer_city: "Справка Сити",
    m24table: "Таблица",
    m24columns: "Столбы",
    d24informer: "Доверие Информер",
    d24slideshow: "Доверие Слайдшоу",
  },
  r24: {
    rslideshow: "Слайдшоу",
    vipquote: "Цитата VIP",
    statement: "Официальное заявление",
    miniwheeze: "Минихрип",
    chart_linear: "График",
    chart_linear_double: "Двойной график",
    chart_pie: "Круговая диаграмма",
    chart_pie_large: "Круговая диаграмма (большая)",
    chart_columns: "Вертикальная столбчатая диаграмма",
    chart_rows: "Горизонтальная столбчатая диаграмма",
    chart_table: "Таблица",
    person_quote: "Цитата",
    site_quote: "Сайт",
    site3d: "Сайт 3D",
    titer: "Титр",
    geo: "Гео",
    precipitation_map: "Карта осадков",
  },
  maps: {
    aemap: "AEMAPs",
    "the-city": "The City",
  },
};

export const allTemplates = Object.values(templates).reduce((acc, names) => {
  return { ...acc, ...names };
}, {});

export const channels: Record<string, string> = {
  m24: "Москва 24",
  r24: "Россия 24",
  maps: "Карты",
};

export const statuses: Record<string, string> = {
  draft: "Черновик",
  done: "Готов",
  created: "Создан",
  inProgress: "Подготовка",
  render: "Рендер",
  failed: "Отменен",
};

export const sideMenuStatuses: Record<string, string> = (() => {
  const newStatuses = { ...statuses };
  delete newStatuses.draft;
  return newStatuses;
})();

export const feedbackFilters: Record<string, string> = {
  sentToBroadcast: "Только эфирные заказы",
  renderError: "Ошибка рендера",
  incorrectData: "Некорректные данные",
};

export const filters: Record<string, string> = {
  all: "Все",
  ...sideMenuStatuses,
  ...feedbackFilters,
  ...templates.maps,
  ...templates.m24,
  ...templates.r24,
};

export interface IFilterGroups {
  statuses: Record<string, string>;
  templates: Record<string, Record<string, string>>;
  feedback: Record<string, string>;
  allUsers: string;
}

export const filterGroups: IFilterGroups = {
  statuses: { all: "Все", ...sideMenuStatuses },
  templates,
  feedback: feedbackFilters,
  allUsers: "Заказы пользователей",
};

export const booleanTypes: Record<string, string> = {
  true: "Да",
  false: "Нет",
};

export const covidInfoTypes: Record<string, string> = {
  world: "Мир",
  moscow: "Москва",
};

type Theme =
  | "alert"
  | "eco"
  | "facts"
  | "kd"
  | "news"
  | "opinion"
  | "osp"
  | "sport"
  | "traur"
  | "v23";

type Background = Theme | "utro" | "weather";

function getThemeObject(theme: Theme, background?: Background) {
  return { theme, background: background ?? theme };
}

export const themeByName: Record<string, ReturnType<typeof getThemeObject>> = {
  Срочно: getThemeObject("alert"),
  Экономика: getThemeObject("eco"),
  Факты: getThemeObject("facts"),
  "Курс Дня": getThemeObject("kd"),
  Новости: getThemeObject("news"),
  Мнение: getThemeObject("opinion"),
  Разбор: getThemeObject("osp"),
  Спорт: getThemeObject("sport"),
  Траур: getThemeObject("traur"),
  "Вести 23": getThemeObject("v23"),
  "Новости Утро": getThemeObject("news", "utro"),
  "Экономика Утро": getThemeObject("eco", "utro"),
  "Спорт Утро": getThemeObject("sport", "utro"),
  Погода: getThemeObject("news", "weather"),
};

export function findThemeName(theme: Theme, background: Background) {
  const entry = Object.entries(themeByName).find(([name, themeObj]) => {
    return themeObj.theme === theme && themeObj.background === background;
  });
  if (entry) {
    return entry[0];
  }
}

export const sources = [
  "Источник: ТАСС",
  "www.ria.ru",
  "publication.pravo.gov.ru",
  "government.ru",
  "kremlin.ru",
];

export const chartColors = [
  "#30CD5B",
  "#1192E8",
  "#244BD5",
  "#747474",
  "#FF0039",
  "#FF8600",
  "#21D8D8",
  "#FF36FB",
];

export const m24PiechartColors = [
  "#497AFC",
  "#45DE84",
  "#DD2A49",
  "#EFDA0C",
  "#4F52BC",
  "#89CF0D",
  "#C6C6C6",
];

export const atmosphereSources = [
  "Прогноз предоставлен Центром «ФОБОС»",
  "По данным openweathermap.org",
];

export const PRECIPITATION_MAP_REGIONS = [
  { id: "@VE+CFO", name: "Восточная Европа и ЦФО" },
  { id: "@SE+CFO", name: "Северная Европа и ЦФО" },
  { id: "@UR", name: "Юг России" },
  { id: "@DV", name: "Дальний Восток" },
  { id: "@PV", name: "Поволжье" },
];
