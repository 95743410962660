import { Chart } from "chart.js";
import Checkbox from "emg-ui-kit/components/Checkbox";
import React, { useMemo, useState } from "react";
import { Doughnut } from "react-chartjs-2";

import { DashBoardData } from "../common/ApiService";
import DashBoardInteractiveMapSkeleton from "../components/Skeletons/DashBoardInteractiveMapSkeleton";
import {
  dashBoardColors,
  dashBoardLightColors,
  interactiveVariants,
} from "../order/atmosphere/constants";
import { EmptyDataCap } from "./DashBoard";
import styles from "./DashBoard.module.css";
import GearButton from "./GearButton";
import { doughnutOptions, gearOptions } from "./constants";
import { checkDataObjIsEmpty, perсentData } from "./utils";

function InteractiveMaps({
  atmoMaps,
  isLoading,
  sentToBroadCast,
  toggleInteractiveBroadcast,
}: {
  atmoMaps: DashBoardData;
  isLoading: boolean;
  sentToBroadCast: boolean;
  toggleInteractiveBroadcast: () => void;
}) {
  const [mapGearSelectedOption, setMapGearSelectedOption] =
    useState<string>("Количество");

  const handleMapOption = (option: string) => {
    setMapGearSelectedOption(option);
  };

  const isAtmoMapsEmpty = useMemo(() => {
    return checkDataObjIsEmpty(atmoMaps);
  }, [atmoMaps]);

  const mapData: Record<string, number> = useMemo(() => {
    return mapGearSelectedOption === "Проценты"
      ? perсentData(atmoMaps)
      : atmoMaps;
  }, [mapGearSelectedOption, atmoMaps]);

  const doughnutData = useMemo(() => {
    return {
      datasets: [
        {
          data: Object.values(mapData),
          backgroundColor: dashBoardLightColors,
          hoverBackgroundColor: dashBoardColors,
          borderWidth: 0,
        },
      ],
    };
  }, [mapData]);

  const mapsCount =
    mapGearSelectedOption === "Количество"
      ? Object.values(mapData)
          .reduce((acc, value) => acc + value, 0)
          .toString()
      : "100%";

  const centerTextPlugin = {
    id: "centerTextPlugin",
    beforeDraw(chart: Chart, args: {}, options: {}) {
      if (mapsCount) {
        const ctx = chart.ctx as CanvasRenderingContext2D;
        const width = chart.width;
        const height = chart.height;
        ctx.restore();
        ctx.font = "700 24px Futura New Bold";
        const textWidth = ctx.measureText(mapsCount).width;
        ctx.textBaseline = "middle";
        ctx.fillText(mapsCount, (width - textWidth) / 2, height / 2 + 6);
        ctx.save();
      }
    },
  };

  const plugins = [centerTextPlugin];

  const DiagramBlock = () => {
    return (
      <>
        <div className={styles.doughnutContainer}>
          <Doughnut
            data={doughnutData}
            options={doughnutOptions}
            plugins={plugins}
          />
        </div>
        <div>
          {Object.keys(mapData).map((item: string, index: number) => (
            <div className={styles.stringContainer} key={item}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <div
                  className={styles.colorCircle}
                  style={{ backgroundColor: dashBoardColors[index] }}
                ></div>
                <div style={{ marginLeft: "7px" }}>
                  {interactiveVariants[item]}
                </div>
              </div>
              <div>
                {mapGearSelectedOption === "Проценты"
                  ? `${mapData[item]}%`
                  : mapData[item]}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  };

  const DisplayBlock = () => {
    if (isLoading) {
      return <DashBoardInteractiveMapSkeleton />;
    }

    if (!!isAtmoMapsEmpty) {
      return <EmptyDataCap />;
    }

    return <DiagramBlock />;
  };

  return (
    <section className={styles.mapContainer}>
      <div>
        <div className={styles.titleContainer}>
          <div className={styles.title}>
            {"Частота использования интерактивных карт"}
          </div>
          <GearButton
            options={gearOptions}
            selectedOption={mapGearSelectedOption}
            handleOptionClick={handleMapOption}
          />
        </div>
        <div className={styles.selectContainer}>
          <Checkbox
            label="Только эфирные"
            checked={sentToBroadCast}
            setChecked={toggleInteractiveBroadcast}
          />
        </div>
      </div>
      <DisplayBlock />
    </section>
  );
}

export default React.memo(InteractiveMaps);
