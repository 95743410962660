import Button from "emg-ui-kit/components/Button";
import IconButton from "emg-ui-kit/components/IconButton";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";

import BackButton from "../../../common/BackButton";
import PageContainer from "../../../common/PageContainer";
import { ForceTaskStatus } from "../../../common/models";
import { allTemplates, channels, statuses } from "../../../common/texts";
import {
  dateFormatter,
  onlyDateFormatter,
  useIsDesktop,
} from "../../../common/utils";
import NoticeWindow from "../../../components/NoticeWindow/NoticeWindow";
import { selectIsAdmin, selectUserInfo } from "../../../redux/auth/selectors";
import {
  cancelTaskByIdThunk,
  forceUpdateOrderByIdThunk,
  getTaskByIdThunk,
  updateOrderFeedbackThunk,
} from "../../../redux/tasks";
import {
  selectIsStatusChanging,
  selectTaskById,
} from "../../../redux/tasks/selectors";
import routes from "../../../routes";
import StatusBadge from "../../components/StatusBadge/StatusBadge";
import styles from "../TaskListPage/TaskList.module.css";
import DownloadButton from "./DownloadButton";
import { Affiche } from "./TaskDataViews/m24/Affiche";
import { Atmosphere } from "./TaskDataViews/m24/Atmosphere";
import { AtmosphereInfo } from "./TaskDataViews/m24/AtmosphereInfo";
import { D24Informer } from "./TaskDataViews/m24/D24Informer";
import { D24SlideShow } from "./TaskDataViews/m24/D24SlideShow";
import { ImportantHour } from "./TaskDataViews/m24/ImportantHour";
import { Informer } from "./TaskDataViews/m24/Informer";
import { M24Columns } from "./TaskDataViews/m24/M24Columns";
import { M24Informer2 } from "./TaskDataViews/m24/M24Informer2";
import { M24InformerCity } from "./TaskDataViews/m24/M24InformerCity";
import { M24InformerTop3 } from "./TaskDataViews/m24/M24InformerTop3";
import { M24InformerTop5 } from "./TaskDataViews/m24/M24InformerTop5";
import { M24PieChart } from "./TaskDataViews/m24/M24PieChart";
import { M24QuoteSobyanin } from "./TaskDataViews/m24/M24QuoteSobyanin";
import { M24Table } from "./TaskDataViews/m24/M24Table";
import { Meme } from "./TaskDataViews/m24/Meme";
import { Mezhprom } from "./TaskDataViews/m24/Mezhprom";
import { Number } from "./TaskDataViews/m24/Number";
import { Quote } from "./TaskDataViews/m24/Quote";
import { QuoteSpecrep } from "./TaskDataViews/m24/QuoteSpecrep";
import { Reference } from "./TaskDataViews/m24/Reference";
import { Slideshow } from "./TaskDataViews/m24/Slideshow";
import { Wheeze } from "./TaskDataViews/m24/Wheeze";
import { WheezeSpecrep } from "./TaskDataViews/m24/WheezeSpecrep";
import { Map } from "./TaskDataViews/maps/Map";
import { PrecipitationMap } from "./TaskDataViews/maps/PrecipitationMap";
import { TheCity } from "./TaskDataViews/maps/TheCity";
import { ChartColumns } from "./TaskDataViews/r24/ChartColumns";
import { ChartLinear } from "./TaskDataViews/r24/ChartLinear";
import { ChartLinearDouble } from "./TaskDataViews/r24/ChartLinearDouble";
import { ChartPie } from "./TaskDataViews/r24/ChartPie";
import { ChartPieLarge } from "./TaskDataViews/r24/ChartPieLarge";
import { ChartRows } from "./TaskDataViews/r24/ChartRows";
import { ChartTable } from "./TaskDataViews/r24/ChartTable";
import { Geo } from "./TaskDataViews/r24/Geo";
import { MiniWheeze } from "./TaskDataViews/r24/MiniWheeze";
import { PersonQuote } from "./TaskDataViews/r24/PersonQuote";
import { RSlideshow } from "./TaskDataViews/r24/RSlideshow";
import { Site3D } from "./TaskDataViews/r24/Site3D";
import { SiteQuote } from "./TaskDataViews/r24/SiteQuote";
import { Statement } from "./TaskDataViews/r24/Statement";
import { Titer } from "./TaskDataViews/r24/Titer";
import { VipQuote } from "./TaskDataViews/r24/VipQuote";
import TaskFeedback, { FeedbackState } from "./TaskFeedback";
import VideoPreview from "./VideoPreview";

function Submenu({
  taskId,
  handleHide,
}: {
  taskId: string;
  handleHide: () => void;
}) {
  const dispatch = useDispatch();
  const handleStatusClick = (status: ForceTaskStatus) => {
    if (window.confirm("Вы уверены, что хотите изменить статус заказа?")) {
      handleHide();
      return dispatch(forceUpdateOrderByIdThunk({ id: taskId, status })) as any;
    }
  };

  return (
    <div className={styles.submenu}>
      <button
        className={styles.submenuItem}
        onClick={() => {
          handleStatusClick("created");
        }}
      >
        {statuses.created}
      </button>
      <button
        className={styles.submenuItem}
        onClick={() => {
          handleStatusClick("done");
        }}
      >
        {statuses.done}
      </button>
      <button
        className={styles.submenuItem}
        onClick={() => {
          handleStatusClick("failed");
        }}
      >
        {statuses.failed}
      </button>
    </div>
  );
}

interface TaskDataViewProps {
  template: string;
  data: Record<string, any>;
}

export function TaskDataView({ template, data }: TaskDataViewProps) {
  switch (template) {
    case "geo":
      return <Geo data={data} />;
    case "titer":
      return <Titer data={data} />;
    case "important_hour":
      return <ImportantHour data={data} />;
    case "affiche":
      return <Affiche data={data} />;
    case "reference":
      return <Reference data={data} />;
    case "wheeze":
      return <Wheeze data={data} />;
    case "wheeze_specrep":
      return <WheezeSpecrep data={data} />;
    case "quote":
      return <Quote data={data} />;
    case "quote_specrep":
      return <QuoteSpecrep data={data} />;
    case "slideshow":
      return <Slideshow data={data} />;
    case "mezhprom":
      return <Mezhprom data={data} />;
    case "atmosphere":
      return <Atmosphere data={data} />;
    case "atmosphere_info":
      return <AtmosphereInfo data={data} />;
    case "meme":
      return <Meme data={data} />;
    case "number":
      return <Number data={data} />;
    case "rslideshow":
      return <RSlideshow data={data} />;
    case "precipitation_map":
      return <PrecipitationMap data={data} />;
    case "vipquote":
      return <VipQuote data={data} />;
    case "statement":
      return <Statement data={data} />;
    case "miniwheeze":
      return <MiniWheeze data={data} />;
    case "chart_linear":
      return <ChartLinear data={data} />;
    case "chart_linear_double":
      return <ChartLinearDouble data={data} />;
    case "chart_pie":
      return <ChartPie data={data} />;
    case "chart_pie_large":
      return <ChartPieLarge data={data} />;
    case "chart_columns":
      return <ChartColumns data={data} />;
    case "chart_rows":
      return <ChartRows data={data} />;
    case "chart_table":
      return <ChartTable data={data} />;
    case "site_quote":
      return <SiteQuote data={data} />;
    case "informer":
      return <Informer data={data} />;
    case "site3d":
      return <Site3D data={data} />;
    case "person_quote":
      return <PersonQuote data={data} />;
    case "m24piechart":
      return <M24PieChart data={data} />;
    case "m24informer2":
      return <M24Informer2 data={data} />;
    case "d24informer":
      return <D24Informer data={data} />;
    case "d24slideshow":
      return <D24SlideShow data={data} />;
    case "map":
      return <Map data={data} />;
    case "the-city":
      return <TheCity data={data} />;
    case "m24quote_sobyanin":
      return <M24QuoteSobyanin data={data} />;
    case "m24table":
      return <M24Table data={data} />;
    case "m24informer_top_3":
      return <M24InformerTop3 data={data} />;
    case "m24informer_top_5":
      return <M24InformerTop5 data={data} />;
    case "m24columns":
      return <M24Columns data={data} />;
    case "m24informer_city":
      return <M24InformerCity data={data} />;

    default:
      return null;
  }
}

const padMonthOrDay = (value: number) => {
  return value.toString().padStart(2, "0");
};

export default function TaskView() {
  const { id } = useParams<{ id: string }>();
  const task = useSelector(selectTaskById(id));
  const userInfo = useSelector(selectUserInfo);
  const isAdmin = useSelector(selectIsAdmin);
  const [isStatusMenuVisible, setIsStatusMenuVisible] = useState(false);
  const dispatch = useDispatch();
  const isStatusChanging = useSelector(selectIsStatusChanging);

  const [copied, setCopied] = useState(false);

  const [noticeIsUp, setNoticeIsUp] = useState(false);

  const [isNoticeVisible, setIsNoticeVisible] = useState(false);

  const timeoutHandle = useRef<number>();

  useEffect(() => () => window.clearTimeout(timeoutHandle.current), []);

  const isR24 = task?.channel === "r24";

  const getR24Outpath = () => {
    if (!task || !userInfo) return "";

    const date = new Date(task.creationDate);
    const year = date.getFullYear();
    const month = padMonthOrDay(date.getMonth() + 1);
    const day = padMonthOrDay(date.getDate());
    const formattedDate = `${year}.${month}.${day}`;

    if (userInfo.groups.includes("r24")) {
      return `//172.27.68.118/Oformlenie/_CloudRenderVIZ/${formattedDate}/${task.filename}.mov`;
    } else {
      return `${task.outpath}/dates/${formattedDate}/${task.filename}.mov`;
    }
  };

  const outpath = !task
    ? ""
    : task.template === "map"
    ? task.outpath
    : isR24
    ? getR24Outpath()
    : `${task.outpath}/${task.filename}.mov`;

  const copyToClipboard = () => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(outpath);
      setCopied(true);
      timeoutHandle.current = window.setTimeout(() => setCopied(false), 3000);
    }
  };

  const noticeTitle = task
    ? `Ролик ${allTemplates[task.template]} ${
        task.data.clipName
      } ${onlyDateFormatter.format(
        Date.parse(task.creationDate)
      )} отправлен на эфир?`
    : "";

  const history = useHistory();

  const redo = () => {
    history.push(`${routes.tasksNew}?redoId=${id}`);
  };

  const cancel = () => {
    if (task) {
      dispatch(cancelTaskByIdThunk(id));
    }
  };

  const toggleMenu = useCallback(() => {
    setIsStatusMenuVisible((prev) => !prev);
  }, []);

  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (!task) {
      dispatch(getTaskByIdThunk(id));
    }
  }, [dispatch, id, task]);

  const sendFeedback = useCallback(
    (newFeedback: FeedbackState) => {
      dispatch(updateOrderFeedbackThunk({ id, feedback: newFeedback }));
    },
    [dispatch, id]
  );

  const handleNoticeClick = (isAccepted: boolean) => {
    if (task?.feedback) {
      setIsNoticeVisible(false);
      if (task.feedback.sentToBroadcast !== isAccepted) {
        const updatedFeedback = {
          ...task.feedback,
          sentToBroadcast: isAccepted,
        };
        sendFeedback(updatedFeedback);
      }
    }
  };

  if (!task) return null;

  const noticeViewCondition =
    ["atmosphere_info", "atmosphere"].includes(task.template) &&
    isNoticeVisible &&
    task.feedback?.sentToBroadcast === false;

  return (
    <PageContainer>
      <BackButton />
      <Title text={allTemplates[task.template]} />
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <div className={styles.statusContainer}>
          <div className={styles.statusElem}>
            <StatusBadge
              status={task.status}
              progress={task.progress}
              renderProgress={task.renderProgress}
            />
          </div>
          {isAdmin && !isStatusChanging && (
            <div className={styles.statusEdit}>
              <IconButton design={"edit"} onClick={toggleMenu} />
            </div>
          )}
          {isAdmin && isStatusMenuVisible && (
            <Submenu taskId={id} handleHide={toggleMenu} />
          )}
        </div>
        <div className={styles.time}>
          {dateFormatter.format(Date.parse(task.creationDate))}
        </div>
      </div>

      <div className={styles.row}>
        <div className={styles.infoTitle}>Канал:</div>
        <div className={styles.infoDescription}>{channels[task.channel]}</div>
      </div>
      <div className={styles.row}>
        <div className={styles.infoTitle}>Постановщик задачи:</div>
        <div className={styles.infoDescription}>{task.email}</div>
      </div>
      {task.feedback &&
        (task.status === "done" || task.status === "failed") && (
          <TaskFeedback state={task.feedback} setState={sendFeedback} />
        )}

      {task.status === "created" && (
        <div className={styles.row}>
          <div className={styles.infoTitle}>Номер в очереди:</div>
          <div className={styles.infoDescription}>{task.queueNumber}</div>
        </div>
      )}

      {task.status === "failed" && task.message && (
        <div className={styles.row} style={{ flexDirection: "column" }}>
          <div className={styles.infoTitle} style={{ color: "var(--red)" }}>
            Причина отмены:
          </div>
          <div
            className={styles.infoDescription}
            style={{
              textAlign: "left",
              marginTop: 5,
              whiteSpace: "pre-wrap",
              overflowWrap: "break-word",
            }}
          >
            {task.message.replace(/\\r/g, "\n")}
          </div>
        </div>
      )}

      {task.status === "done" && (
        <div style={{ marginTop: 18 }}>
          <div className={styles.infoTitle} style={{ marginBottom: 9 }}>
            Путь к готовой графике:
          </div>
          <div
            style={{ display: "flex", alignItems: "center", marginBottom: 10 }}
          >
            <TextField
              name="filename"
              value={outpath}
              onChange={() => {}}
              style={{ flex: 2, margin: 0, marginRight: 10 }}
            />
            <Button
              title={copied ? "Скопировано" : "Скопировать"}
              status={copied ? "done" : "enabled"}
              onClick={copyToClipboard}
            />
          </div>
        </div>
      )}

      <hr />
      <TaskDataView template={task.template} data={task.data} />
      <br />
      <div>
        {task.previewObjectStorageKey && task.status === "done" && (
          <VideoPreview
            taskId={task._id}
            channel={task.channel}
            aeMapConfig={task.data?.template}
            template={task.template}
            onStart={() => setIsNoticeVisible(true)}
            setNoticeIsUp={setNoticeIsUp}
          />
        )}
        {noticeViewCondition && (
          <div
            style={{
              position: "relative",
              margin: isDesktop
                ? noticeIsUp
                  ? "0 53px"
                  : "30px 53px"
                : "20px 0",
              zIndex: 3,
              height: isDesktop ? (noticeIsUp ? 0 : 110) : 150,
              transition: "all 0.8s ease-in-out",
            }}
          >
            <NoticeWindow
              title={noticeTitle}
              onNoticeClick={handleNoticeClick}
              style={{
                position: "absolute",
                top: isDesktop ? (noticeIsUp ? -350 : 0) : 0,
                transition: "all 0.8s ease-in-out",
              }}
            />
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: isDesktop ? "row" : "column",
        }}
      >
        {task.status === "created" && (
          <Button
            title="Отменить"
            color="red"
            onClick={cancel}
            status={task.isCanceling ? "loading" : "enabled"}
            style={{ marginBottom: 10 }}
          />
        )}
        {task.objectStorageKey && task.status === "done" && (
          <DownloadButton
            taskId={task._id}
            status={noticeViewCondition ? "disabled" : "enabled"}
          />
        )}
        <Button
          title="Повторить заказ"
          onClick={redo}
          style={{ marginBottom: 10 }}
          status={noticeViewCondition ? "disabled" : "enabled"}
        />
      </div>
    </PageContainer>
  );
}
