import React from "react";

import { statuses } from "../../../common/texts";
import styles from "./StatusBadge.module.css";

export default function StatusBadge({
  status,
  progress,
  style,
}: {
  status: string;
  progress?: number;
  renderProgress?: number;
  style?: React.CSSProperties;
}) {
  const isProgressing = status === "inProgress" || status === "render";
  let statusText = statuses[status];

  if (status === "inProgress" && progress) {
    statusText = `Подготовка ${Math.floor(progress)}%`;
  } else if (status === "render" && progress) {
    statusText = `Рендер ${Math.floor(progress)}%`;
  }

  return (
    <div
      className={styles[`status--${isProgressing ? "progress" : status}`]}
      style={style}
    >
      {statusText}
    </div>
  );
}
