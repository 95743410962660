import { useCallback, useEffect, useRef, useState } from "react";

interface Props<T extends string> {
  groupsList: T[];
  groupStorageName: string;
}

export function useDetails<T extends string>({
  groupsList,
  groupStorageName,
}: Props<T>) {
  const state = groupsList.reduce((acc: Record<T, boolean>, curr) => {
    acc[curr] = false;
    return acc;
  }, {} as Record<T, boolean>);

  const initialGroupsState = useRef<Record<T, boolean>>(state);
  const stateFromStorage = localStorage.getItem(groupStorageName);
  if (stateFromStorage) {
    initialGroupsState.current = JSON.parse(stateFromStorage);
  }
  const [groups, setGroups] = useState<Record<T, boolean>>(
    initialGroupsState.current
  );

  const handleDetails = useCallback((groupName: T, event: any) => {
    event.preventDefault();
    setGroups((prev: Record<T, boolean>) => ({
      ...prev,
      [groupName]: !prev[groupName],
    }));
  }, []);

  useEffect(() => {
    localStorage.setItem(groupStorageName, JSON.stringify(groups));
  }, [groupStorageName, groups]);

  return { groups, handleDetails };
}
