import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import {
  deleteNewsArticle,
  getNews,
  getNewsArticle,
  NewsArticle,
  updateNewsArticle,
} from "../../common/ApiService";
import { RootState } from "../../store";

export interface NewsState {
  hasUnreadNews: boolean;
  news: NewsArticle[];
}

const initialState: NewsState = { hasUnreadNews: false, news: [] };

export const setHasUnreadNews = createAction<boolean>("news/read");

export const getNewsThunk = createAsyncThunk("news/get", getNews);

export const getNewsArticleThunk = createAsyncThunk(
  "news/getById",
  getNewsArticle
);

export const deleteNewsArticleThunk = createAsyncThunk(
  "news/delete",
  deleteNewsArticle
);

export const updateNewsArticleThunk = createAsyncThunk(
  "news/update",
  updateNewsArticle
);

const newsReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setHasUnreadNews, (state, action) => {
      state.hasUnreadNews = action.payload;
    })
    .addCase(getNewsThunk.fulfilled, (state, action) => {
      state.news = action.payload;
    })
    .addCase(deleteNewsArticleThunk.fulfilled, (state, action) => {
      state.news = state.news.filter(
        (article) => article._id !== action.payload._id
      );
    })
    .addCase(updateNewsArticleThunk.fulfilled, (state, action) => {
      const idx = state.news.findIndex(
        (article) => article._id === action.payload._id
      );
      state.news[idx] = action.payload;
    })
    .addCase(getNewsArticleThunk.fulfilled, (state, action) => {
      const idx = state.news.findIndex(
        (article) => article._id === action.payload._id
      );
      if (idx >= 0) {
        state.news[idx] = action.payload;
      } else {
        state.news.push(action.payload);
      }
    });
});

export default newsReducer;

export const selectHasUnreadNews = (state: RootState) =>
  state.news.hasUnreadNews;

export const selectNews = (state: RootState) => state.news.news;

export const selectNewsArticle = (id: string) => (state: RootState) =>
  state.news.news.find((article) => article._id === id);
