import React from "react";

import styles from "../../TaskListPage/TaskList.module.css";

export function InfoRow({ title, value }: { title: string; value: any }) {
  return (
    <div className={styles.row}>
      <div className={styles.infoTitle}>{title}:</div>
      <div
        className={styles.infoDescription}
        style={{ whiteSpace: "pre-line" }}
      >
        {value}
      </div>
    </div>
  );
}

export function ColorBox({ color }: { color: string }) {
  return <div style={{ background: color, width: 20, height: 20 }} />;
}

export function ImageRow({ title, src }: { title: string; src: string }) {
  return (
    <div>
      <div className={styles.infoTitle}>{title}:</div>
      <img
        alt={title}
        src={src}
        style={{ objectFit: "contain", maxHeight: 350, maxWidth: "100%" }}
      />
    </div>
  );
}
