import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import ImageSelection from "emg-ui-kit/components/ImageSelection";
import { SelectionData } from "emg-ui-kit/components/ImageSelection/ImageSelection";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { useFormik, FormikProvider, Form, Field } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { convertToSelectOptions, useIsDesktop } from "../../common/utils";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  IMAGE_TYPES,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateMaxChars,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  validateTimingSum,
  ValidationPropsUtils,
} from "../util";
import { SITES, SOURCES, THEMES, THEMES_FULL } from "./constants";

type Item = {
  text: string;
  timing: number | string;
};

type Values = ReturnType<typeof getInitialValues>;

function createItem(): Item {
  return { text: "", timing: 5 };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    image: initialFormData?.image as FormImage | undefined,
    selection: initialFormData?.selection as SelectionData | undefined,
    site: (initialFormData?.site in SITES
      ? initialFormData?.site
      : "") as string,
    customSite: (initialFormData?.site === "custom"
      ? initialFormData?.customSite ?? ""
      : "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 30) as number | string,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 5 };
    }) ?? initItems()) as Item[],
  };
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? +values.timing
    : values.items.reduce((acc, item) => acc + +item.timing, 0);
}

function prepareData(values: Values) {
  return {
    ...pick(
      values,
      "name",
      "image",
      "selection",
      "site",
      "customSite",
      "source",
    ),
    theme: THEMES_FULL[values.theme],
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items.map((block) => ({
      text: block.text,
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
  };
}

function validate(values: Values) {
  const errors = {
    name: values.name
      ? validateIncorrectFormat(values.name, CLIP_NAME_REGEXP)
      : undefined,
    theme: validateNotEmpty(values.theme),
    image: validateNotEmpty(values.image),
    selection: validateNotEmpty(values.selection),
    site: validateNotEmpty(values.site),
    customSite:
      values.site === "custom"
        ? validateNotEmpty(values.customSite)
        : undefined,
    timing:
      values.timingType === "total"
        ? validateNumber(values.timing) ??
          validatePositive(+values.timing) ??
          validateMax(+values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      text: item.text
        ? values.source
          ? validateMaxChars(item.text, 200)
          : validateMaxChars(item.text, 225)
        : undefined,
      timing:
        values.timingType === "separate"
          ? validateNumber(item.timing) ??
            validatePositive(+item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function SiteQuoteForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  const isDesktop = useIsDesktop();

  usePreview(
    channel,
    template,
    calcTotalTiming(formik.values),
    formik.values,
    prepareData,
  );

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const handleImageUpdate = (image?: FormImage) => {
    formik.setFieldValue("selection", undefined);
    formik.setFieldTouched("image");
    formik.setFieldValue("image", image);
  };

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field as={TextField} label="Название ролика" name="name" />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          required
          {...validationUtils.getProps("theme")}
        />
        <ImageInput
          imageTypes={IMAGE_TYPES}
          image={formik.values.image}
          updateImage={handleImageUpdate}
          onBlur={() => {
            formik.setFieldTouched(`image`, true);
          }}
          style={isDesktop ? { marginLeft: 210 } : {}}
          title="Изображение"
          {...validationUtils.getProps("image")}
          required
        />
        <ImageSelection
          label="Выделение"
          file={formik.values.image}
          disabled={!formik.values.image}
          selection={formik.values.selection}
          updateSelection={(selection) => {
            formik.setFieldTouched("selection");
            formik.setFieldValue("selection", selection);
          }}
          required
          {...validationUtils.getProps("selection")}
        />
        <Field
          as={Select}
          name="site"
          label="Сайт"
          options={convertToSelectOptions(SITES)}
          required
          {...validationUtils.getProps("site")}
        />
        {formik.values.site === "custom" && (
          <Field
            as={TextField}
            label="Другой сайт"
            name="customSite"
            required
            {...validationUtils.getProps("customSite")}
          />
        )}
        <Field
          as={TextField}
          label="Источник"
          name="source"
          dataList={SOURCES}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждого слайда" },
          ]}
        />
        {formik.values.timingType === "total" && (
          <Field
            as={TextField}
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            {...validationUtils.getProps("timing")}
            required
          />
        )}
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          canChangeLength
          defaultItemConstructor={createItem}
        >
          {(item, index) => (
            <>
              {formik.values.timingType === "separate" && (
                <div style={{ display: "flex", alignItems: "baseline" }}>
                  <div style={{ marginRight: 20 }}>Хронометраж (сек.)</div>
                  <Field
                    as={TextField}
                    name={`items.${index}.timing`}
                    type="number"
                    {...validationUtils.getDeepProps(`items.${index}.timing`)}
                    style={{ width: "100%" }}
                  />
                </div>
              )}
              <Field
                as={TextArea}
                placeholder="Текст"
                name={`items.${index}.text`}
                {...validationUtils.getDeepProps(`items.${index}.text`)}
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(SiteQuoteForm);
