import { useState, useCallback, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMediaQuery } from "react-responsive";
import { useHistory } from "react-router-dom";

import {
  BitrixCustomerAvailableTemplates,
  Graphic,
  getOrder,
} from "../common/ApiService";
import {
  channels,
  templates as availableTemplates,
  templates,
} from "../common/texts";
import { selectUserInfo } from "../redux/auth/selectors";
import {
  selectFormDataBackup,
  setFormData,
  clearFormData,
} from "../redux/backup";
import {
  getBitrixTemplatesThunk,
  setIsFormValid,
} from "../redux/bitrix/bitrix.reducer";
import {
  updateDraftThunk,
  createDraftThunk,
  deleteDraftByIdThunk,
} from "../redux/drafts";
import { setError } from "../redux/error";
import { createTaskThunk } from "../redux/tasks";
import { setUsePreviewChecked, usePreviewData } from "../redux/usePreview";
import routes from "../routes";
import { AppDispatch, RootState } from "../store";
import { substituteImageUrlsWithFiles } from "./util";

const showOverlayChannels = ["reference", "mezhprom"];

export const isValidForm = {
  value: true,
};

function getChannelByTemplateId(template: string) {
  for (const key in templates) {
    if (templates[key].hasOwnProperty(template)) {
      return key;
    }
  }
  throw new Error("Шаблон не найден или недоступен для пользователя");
}

function isPreviewAvailable(channel: string, template: string) {
  const allTemplates = new Set(
    Object.keys(availableTemplates.m24).filter(
      (template) => !/atmosphere/.test(template)
    )
  );
  return (
    (channel === "m24" && allTemplates.has(template)) ||
    (channel === "r24" && template !== "precipitation_map")
  );
}

function findProjectAndGraphicsIdByGraphic(
  projects: BitrixCustomerAvailableTemplates[],
  template: string
) {
  let projectId;
  let graphicId;
  for (const item of projects) {
    for (const graphic of item.graphics) {
      if (graphic.id_cloud_render === template) {
        projectId = item.id;
        graphicId = graphic.id;
        return { projectId, graphicId };
      }
    }
  }

  return undefined;
}

function getChannelAndTemplateFromParams() {
  const channelAndTemplate = {
    channel: "",
    template: "",
  };
  const params = new URLSearchParams(window.location.search);
  const channel = params.get("channel");
  const template = params.get("template");
  if (channel && channel in channels) {
    channelAndTemplate.channel = channel;

    if (template && template in availableTemplates[channel]) {
      channelAndTemplate.template = template;
    }
  }
  return channelAndTemplate;
}

export function useOrderForm() {
  const [channel, setChannel] = useState(
    getChannelAndTemplateFromParams().channel
  );
  const [templates, setTemplates] = useState<Record<string, string>>({});
  const [template, setTemplate] = useState(
    getChannelAndTemplateFromParams().template
  );
  const [showPreview, setShowPreview] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);

  const [selectedProject, setSelectedProject] =
    useState<BitrixCustomerAvailableTemplates>();

  const [bitrix, setBitrix] = useState<{
    projectId: string;
    graphicId: string;
  }>();

  const [isTemplateAvailable, setIsTemplateAvailable] = useState(true);

  const [selectedGraphic, setSelectedGraphic] = useState<Graphic>();

  const projects = useSelector(
    (state: RootState) => state.bitrix.customerProjects
  );

  const history = useHistory();
  const dispatch = useDispatch<AppDispatch>();
  const isPreviewData = useSelector(usePreviewData);
  const userInfo = useSelector(selectUserInfo);
  const formData = useSelector(selectFormDataBackup);
  const [filesLoading, setFilesLoading] = useState(false);

  const isBitrixClientForm = userInfo?.type === "client";

  const shouldSeparatePreview = useMediaQuery({ query: "(min-width: 1200px)" });

  const params = new URLSearchParams(window.location.search);
  const redoId = params.get("redoId");
  const isDraft = params.get("isDraft") === "true";

  const initialFormData = useRef<Record<string, any>>({});

  const initialFormDataLoaded = useRef(false);

  const channelOptions = Object.entries(channels)
    .map(([id, name]) => ({ id, name }))
    .filter(({ id }) => {
      return userInfo && userInfo.groups.length
        ? userInfo.groups.includes(id)
        : true;
    });
  const templateOptions = Object.entries(templates).map(([id, name]) => ({
    id,
    name,
  }));

  const prevChannel = useRef("");

  const isShowOverlayVisible =
    showPreview && showOverlayChannels.includes(template);

  const { isLoading: isBitrixLoading } = useSelector(
    (state: RootState) => state.bitrix
  );

  const isFormLoading = isBitrixLoading || filesLoading;

  const isFormVisible = !isFormLoading && isTemplateAvailable && template;

  const onSubmit = useCallback(
    (data: any) => {
      if (redoId) {
        data = { ...data, status: "draft", draftId: redoId };
      }
      if (isBitrixClientForm) {
        data = {
          ...data,
          bitrix: {
            projectId: selectedProject?.id,
            graphicId: selectedGraphic?.id,
          },
        };
      }

      const orderInfo = { channel, template, data };
      dispatch(setFormData(orderInfo));

      return (dispatch(createTaskThunk(orderInfo)) as any).then(
        (action: any) => {
          if (!action.error) {
            history.push("/");
            dispatch(clearFormData());
          }
        }
      );
    },
    [
      redoId,
      isBitrixClientForm,
      channel,
      template,
      dispatch,
      selectedProject?.id,
      selectedGraphic?.id,
      history,
    ]
  );

  const onSaveDraft = useCallback(
    (data: any) => {
      if (redoId) {
        data = { ...data, status: "draft", draftId: redoId };
      }
      if (isBitrixClientForm) {
        data = {
          ...data,
          bitrix: {
            projectId: selectedProject?.id,
            graphicId: selectedGraphic?.id,
          },
        };
      }

      const orderInfo = { channel, template, data };
      dispatch(setFormData(orderInfo));

      if (redoId && isDraft) {
        return (dispatch(updateDraftThunk(orderInfo)) as any).then(
          (action: any) => {
            if (!action.error) {
              history.push("/drafts");
              dispatch(clearFormData());
            }
          }
        );
      }
      return (dispatch(createDraftThunk(orderInfo)) as any).then(
        (action: any) => {
          if (!action.error) {
            history.push("/drafts");
            dispatch(clearFormData());
          }
        }
      );
    },
    [
      channel,
      dispatch,
      history,
      isBitrixClientForm,
      isDraft,
      redoId,
      selectedGraphic?.id,
      selectedProject?.id,
      template,
    ]
  );

  const onDeleteDraft = useCallback(() => {
    if (window.confirm("Вы уверены, что хотите удалить черновик?")) {
      if (redoId) {
        return (dispatch(deleteDraftByIdThunk(redoId)) as any).then(() => {
          history.push(routes.drafts);
        });
      }
    }
    return false;
  }, [dispatch, history, redoId]);

  const subformProps = {
    initialFormData: initialFormData.current,
    onSubmit,
    onSaveDraft,
    onDeleteDraft: isDraft ? onDeleteDraft : undefined,
    channel,
    template,
  };

  const setUsePreviewCheckedDisp = (value: boolean) =>
    dispatch(setUsePreviewChecked(value));

  const handleTemplateChange = useCallback((event) => {
    setTemplate(event.target.value);
    if (initialFormDataLoaded.current) {
      initialFormData.current = {};
    }
  }, []);

  const handleChannelChange = useCallback((event) => {
    setChannel(event.target.value);
    setTemplate("");
    if (initialFormDataLoaded.current) {
      initialFormData.current = {};
    }
  }, []);

  const handleBitrixProjectChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: string;
      }>
    ) => {
      setSelectedProject(
        projects?.find((project) => String(project.id) === event.target.value)
      );

      const bitrixtTemplate = selectedProject?.graphics.find(
        (graphic) => String(graphic.id) === String(event.target.value)
      );

      setSelectedGraphic(undefined);
      setTemplate("");
      if (bitrixtTemplate) {
        const cloudrenderChannel = getChannelByTemplateId(
          bitrixtTemplate.id_cloud_render
        );
        setChannel(cloudrenderChannel);
      }
    },
    [projects, selectedProject?.graphics]
  );

  const handleBitrixGraphicChange = useCallback(
    (
      event: React.ChangeEvent<{
        value: string;
      }>
    ) => {
      const bitrixtGraphic = selectedProject?.graphics.find(
        (graphic) => String(graphic.id) === String(event.target.value)
      );

      setSelectedGraphic(bitrixtGraphic);
      if (bitrixtGraphic) {
        setChannel(getChannelByTemplateId(bitrixtGraphic.id_cloud_render));
        setTemplate(bitrixtGraphic.id_cloud_render);
      }
      if (initialFormDataLoaded.current) {
        initialFormData.current = {};
      }
    },
    [selectedProject?.graphics]
  );

  useEffect(() => {
    if (channel && !isBitrixClientForm) {
      setTemplates(availableTemplates[channel]);
      if (prevChannel.current) {
        setTemplate("");
      }
      prevChannel.current = channel;
    }
  }, [channel, isBitrixClientForm]);

  useEffect(() => {
    if (formData) {
      initialFormData.current = { ...formData.data };
      setChannel(formData.channel);
      setTemplate(formData.template);
      dispatch(clearFormData());
    } else if (redoId) {
      getOrder(redoId)
        .then((order) => {
          setFilesLoading(true);
          return Promise.all([
            substituteImageUrlsWithFiles(order.data),
            Promise.resolve(order.channel),
            Promise.resolve(order.template),
          ]);
        })
        .then(([orderData, channel, template]) => {
          setFilesLoading(false);
          if (isDraft) {
            initialFormData.current = { ...orderData, draftId: redoId };
          } else {
            initialFormData.current = orderData;
          }
          setChannel(channel);
          setTemplate(template);
          if (orderData.bitrix) {
            setBitrix(orderData.bitrix);
          }
          initialFormDataLoaded.current = true;
        })
        .catch(() => {
          setFilesLoading(false);
        });
    }
  }, [formData, dispatch, redoId, isDraft]);

  useEffect(() => {
    if (!isPreviewAvailable(channel, template)) {
      setShowPreview(false);
    }
  }, [channel, template]);

  useEffect(() => {
    if (!showOverlayChannels.includes(template)) {
      setShowOverlay(false);
    }
  }, [template]);

  // Bitrix stuff

  useEffect(() => {
    if (bitrix && projects?.length) {
      const selectedBitrixProject = projects?.find(
        (project) => String(project.id) === String(bitrix?.projectId)
      );

      const selectedBitrixTemplate = selectedBitrixProject?.graphics.find(
        (graphic) => String(graphic.id) === String(bitrix?.graphicId)
      );

      const foundProjectAndGraphicsId = findProjectAndGraphicsIdByGraphic(
        projects,
        selectedBitrixTemplate?.id_cloud_render || ""
      );

      if (!foundProjectAndGraphicsId) {
        dispatch(setError("Шаблон недоступен для данного пользователя"));
        setIsTemplateAvailable(false);
      }

      setSelectedProject(selectedBitrixProject);
      setSelectedGraphic(selectedBitrixTemplate);
    }
  }, [bitrix, dispatch, projects]);

  useEffect(() => {
    if (isBitrixClientForm) {
      const isFormValid = !!selectedProject?.id && !!selectedGraphic?.id;
      dispatch(setIsFormValid(isFormValid));
    }
  }, [selectedProject, selectedGraphic, dispatch, isBitrixClientForm]);

  useEffect(() => {
    if (isBitrixClientForm) {
      dispatch(getBitrixTemplatesThunk());
    }
  }, [dispatch, isBitrixClientForm]);

  return {
    showPreview,
    shouldSeparatePreview,
    isPreviewData,
    showOverlay,
    isDraft,
    channelOptions,
    channel,
    handleChannelChange,
    templateOptions,
    template,
    handleTemplateChange,
    isPreviewAvailable,
    setUsePreviewCheckedDisp,
    setShowPreview,
    isShowOverlayVisible,
    setShowOverlay,
    subformProps,
    handleBitrixProjectChange,
    handleBitrixGraphicChange,
    projects,
    selectedProject,
    selectedGraphic,
    isBitrixClientForm,
    isFormLoading,
    isFormVisible,
    isRedo: !!redoId,
  };
}
