import { createAction, createReducer } from "@reduxjs/toolkit";

import { RootState } from "../../store";

interface OrderFormData {
  channel: string;
  template: string;
  data: Record<string, any>;
}

export interface BackupState {
  formData?: OrderFormData;
}

const initialState: BackupState = {};

export const setFormData = createAction<OrderFormData>("backup/set");
export const clearFormData = createAction("backup/clear");

const backupReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setFormData, (state, action) => {
      state.formData = action.payload;
    })
    .addCase(clearFormData, (state) => {
      state.formData = undefined;
    });
});

export default backupReducer;

export const selectFormDataBackup = (state: RootState) => state.backup.formData;
