import React from "react";
import ContentLoader from "react-content-loader";

export default function TaskListSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width="100%"
      height="100%"
      viewBox="0 0 355 390"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
      preserveAspectRatio="none"
    >
      <path d="M 0 61 h 94 v 12 H 0 z M 177 61 h 166 v 12 H 177 z M 0 78 h 120 v 16 H 0 z M 0 305 h 94 v 12 H 0 z M 177 305 h 166 v 12 H 177 z M 0 322 h 120 v 16 H 0 z M 0 183 h 94 v 12 H 0 z M 177 183 h 166 v 12 H 177 z M 0 200 h 120 v 16 H 0 z M 0 427 h 94 v 12 H 0 z M 177 427 h 166 v 12 H 177 z M 0 444 h 120 v 16 H 0 z M 0 0 h 94 v 12 H 0 z M 177 0 h 166 v 12 H 177 z M 0 17 h 120 v 16 H 0 z M 0 244 h 94 v 12 H 0 z M 177 244 h 166 v 12 H 177 z M 0 261 h 120 v 16 H 0 z M 0 122 h 94 v 12 H 0 z M 177 122 h 166 v 12 H 177 z M 0 139 h 120 v 16 H 0 z M 0 366 h 94 v 12 H 0 z M 177 366 h 166 v 12 H 177 z M 0 383 h 120 v 16 H 0 z" />
    </ContentLoader>
  );
}
