import React from "react";

import styles from "../../../TaskListPage/TaskList.module.css";
import { ImageRow, InfoRow } from "../InfoComponents";

export function Mezhprom({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      {data.source && <InfoRow title="Источник" value={data.source} />}
      {(data.quotes as Record<string, any>[]).map((quote, idx) => (
        <details key={idx}>
          <summary>Цитата №{idx + 1}</summary>
          {quote.text && <InfoRow title="Текст" value={quote.text} />}
        </details>
      ))}
      {data.blocks &&
        (data.blocks as Record<string, any>[]).map((block, idx) => (
          <details key={idx}>
            <summary>Слайд №{idx + 1}</summary>
            <ImageRow title="Изображение" src={block.image} />
          </details>
        ))}
      {data.video && (
        <details>
          <summary>Видео</summary>
          <video controls className={styles.video} src={data.video} />
        </details>
      )}
    </>
  );
}
