import React from "react";

import { ColorBox, ImageRow, InfoRow } from "../InfoComponents";

export function M24PieChart({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Заголовок 1" value={data.h1} />
      <InfoRow title="Заголовок 2" value={data.h2} />
      {data.source && <InfoRow title="Источник" value={data.source} />}
      {data.image && <ImageRow title="Изображение" src={data.image} />}
      {data.pieText && <InfoRow title="Текст диаграммы" value={data.pieText} />}
      {data.pieTextSize && (
        <InfoRow
          title="Размер текста внутри диаграммы"
          value={data.pieTextSize}
        />
      )}
      <InfoRow title="Проценты" value={data.percentage ? "Да" : "Нет"} />
      <InfoRow title="Размер комментария" value={data.commentSize} />
      <InfoRow title="Размер значений" value={data.valueSize} />
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Значение" value={block.value} />
          <InfoRow title="Легенда" value={block.legend} />
          <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
        </details>
      ))}
    </>
  );
}
