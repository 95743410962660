import TextField from "emg-ui-kit/components/TextField";
import { useFormikContext, Field } from "formik";
import React from "react";

import { ValidationPropsUtils, getItemFieldName } from "../../../util";
import { ForecastItem } from "../../types";

interface Props {
  item: ForecastItem;
  index: number;
}

export default function ForecastBlock({ index }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );
  const forecastTimeName = getItemFieldName(index, "forecastTime");
  const temperatureName = getItemFieldName(index, "temperature");
  const forecastDescriptionName = getItemFieldName(
    index,
    "forecastDescription",
  );
  return (
    <>
      <Field
        as={TextField}
        placeholder="Время прогноза"
        name={forecastTimeName}
        {...validationUtils.getDeepProps(forecastTimeName)}
        required
      />
      <Field
        as={TextField}
        placeholder="Температура"
        name={temperatureName}
        {...validationUtils.getDeepProps(temperatureName)}
        required
      />
      <Field
        as={TextField}
        placeholder="Подпись"
        name={forecastDescriptionName}
        {...validationUtils.getDeepProps(forecastDescriptionName)}
        required
      />
    </>
  );
}
