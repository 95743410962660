import IconButton from "emg-ui-kit/components/IconButton";
import React, { useCallback, useEffect, useState } from "react";

import styles from "./TaskTable.module.css";

interface Props {
  tasks: any[];
  isStepCounterDisabled?: boolean;
  totalTasks?: number;
  counterTitle?: string;
  page: number;
  perPage: number;
  totalPages: number;
  changePage: (page: number) => void;
  style?: React.CSSProperties;
  pageChangingToolAvailable?: boolean;
}

export default function Pagination({
  tasks,
  isStepCounterDisabled,
  totalTasks,
  counterTitle,
  page,
  perPage,
  totalPages,
  changePage,
  style,
  pageChangingToolAvailable,
}: Props) {
  const nextPage = useCallback(() => {
    changePage(page + 1);
  }, [changePage, page]);

  const prevPage = useCallback(() => {
    changePage(page - 1);
  }, [changePage, page]);

  const [pageInputValue, setPageInputValue] = useState<number | string>(page);
  const [pageValidMessage, setPageValidMessage] = useState<null | string>(null);

  const canGoFurther = Number(pageInputValue) < totalPages + 1;

  const changePageByNumber = useCallback(() => {
    if (Number(pageInputValue) > 0 && canGoFurther) {
      changePage(Number(pageInputValue));
      setPageValidMessage(null);
    } else {
      setPageValidMessage(`Страницы ${pageInputValue} не существует!`);
    }
  }, [canGoFurther, changePage, pageInputValue]);

  const handleEnterClickOnPageInput = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === "Enter") {
        changePageByNumber();
      }
    },
    [changePageByNumber]
  );

  useEffect(() => {
    setPageInputValue(page);
  }, [page]);

  const from = (page - 1) * perPage + 1;
  const to = (page - 1) * perPage + tasks.length;

  if (!pageChangingToolAvailable && !tasks.length) return null;
  return (
    <div style={style} className={styles.paginationBlock}>
      {!isStepCounterDisabled && (
        <span className={styles.stepCounterTitle}>
          {counterTitle ? counterTitle : "Заказов"}
          {" на странице:"}
          <select disabled className={styles.stepCounter}>
            <option>{tasks.length}</option>
          </select>
          {pageChangingToolAvailable && (
            <>
              <input
                type="text"
                value={pageInputValue}
                onChange={(e) => {
                  setPageInputValue(e.target.value);
                }}
                onKeyDown={handleEnterClickOnPageInput}
                className={styles.paginationInput}
              />
              <IconButton design="edit" onClick={changePageByNumber} />
              <div style={{ position: "relative" }}>
                <span className={styles.pageValidMessage}>
                  {pageValidMessage}
                </span>
              </div>
            </>
          )}
        </span>
      )}
      <button
        disabled={page === 1}
        className={styles.prevButton}
        onClick={prevPage}
      />
      <span>
        {from}-{to}
      </span>
      <button
        disabled={totalPages === page}
        className={styles.nextButton}
        onClick={nextPage}
      />
      {totalTasks && (
        <span className={styles.totalTasksTitle}>
          {counterTitle ? counterTitle : "Заказов"}
          {" всего :"}
          <select disabled className={styles.stepCounter}>
            <option>{totalTasks}</option>
          </select>
        </span>
      )}
    </div>
  );
}
