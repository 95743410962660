import React from "react";

interface Props {
  children: React.ReactNode;
}

export default function Details({ children }: Props) {
  return (
    <>
      <details open>
        <summary style={{ cursor: "pointer" }}>Детали</summary>
        <br />
        {children}
      </details>
    </>
  );
}
