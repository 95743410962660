import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import FileInput from "emg-ui-kit/components/FileInput";
import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { convertUrlToFile, useIsDesktop } from "../../common/utils";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  IMAGE_TYPES,
  pick,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateNotEmpty,
  removeTouched,
  getDeepValidationProps,
} from "../util";
import { mezhpromSourceOptions } from "./constants";

const IMAGE_ASPECT = 1.05;
const TEXTEDIT_TOOLTIP = "<| ваш текст |>";

type ImageItem = {
  image?: FormImage;
};

type TextItem = {
  text: string;
};

type Preset = "quotes" | "quote-slides" | "quote-video";

function createImageItem(): ImageItem {
  return { image: undefined };
}

function initQuotes(count = 1) {
  return Array.from(Array(count), () => ({ text: "", textIncrease: false }));
}

function initSlides(count = 1) {
  return Array.from(Array(count), () => ({ image: undefined }));
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: 30,
    source: (initialFormData?.source ?? "") as string,
    quotes: (initialFormData?.quotes ?? initQuotes()) as TextItem[],
    items: (initialFormData?.blocks ?? initSlides()) as ImageItem[],
    video: undefined as File | undefined,
    preset: (initialFormData?.preset ?? "quotes") as Preset,
    textIncrease: (initialFormData?.textIncrease ?? false) as boolean,
  };
}

type Values = ReturnType<typeof getInitialValues>;

function prepareData(values: Values) {
  return {
    ...pick(values, "clipName", "source", "preset", "textIncrease"),
    totalTiming: values.timing,
    quotes: values.preset === "quotes" ? values.quotes : [values.quotes[0]],
    blocks: values.preset === "quote-slides" ? values.items : undefined,
    video: values.preset === "quote-video" ? values.video : undefined,
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    quotes: values.quotes.map((item: TextItem) => ({
      text: validateNotEmpty(item.text),
    })),
    items: values.items.map((item: ImageItem) => ({
      image:
        values.preset === "quote-slides"
          ? validateNotEmpty(item.image) ??
            validateAspect(item.image!, IMAGE_ASPECT)
          : undefined,
    })),
    video:
      values.preset === "quote-video"
        ? validateNotEmpty(values.video)
        : undefined,
  };
  return removeEmptyProps(errors);
}

function MezhpromForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  const isDesktopOrLaptop = useIsDesktop();

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  const dispatch = useDispatch();

  useEffect(() => {
    if (initialFormData?.video) {
      convertUrlToFile(initialFormData?.video)
        .then((file) => {
          setFieldValue("video", file);
        })
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err });
        });
    }
  }, [setFieldValue, initialFormData?.video, dispatch]);

  usePreview(channel, template, values.timing, values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          disabled
          as={TextField}
          name="timing"
          type="number"
          label="Хронометраж (сек.)"
        />
        <Field
          as={Select}
          defaultOptionAvailable
          label="Источник"
          name="source"
          options={mezhpromSourceOptions}
        />
        <Field
          as={Checkbox}
          name={`textIncrease`}
          checked={values.textIncrease}
          setChecked={(value: any) =>
            formik.setFieldValue(`textIncrease`, value)
          }
          label="Увеличить выделение"
          style={{ marginLeft: isDesktopOrLaptop ? 210 : 0 }}
        />
        <Field
          as={Select}
          name="preset"
          label="Пресет"
          options={[
            { id: "quotes", name: "Цитаты" },
            { id: "quote-slides", name: "Цитата + Слайды" },
            { id: "quote-video", name: "Цитата + Видео" },
          ]}
        />
        <Blocks
          blockTitle="Цитата"
          items={
            values.preset !== "quotes" ? [values.quotes[0]] : values.quotes
          }
          defaultItemConstructor={() => ({ text: "" })}
          updateItems={(items) => setFieldValue("quotes", items)}
          onDelete={(index) => {
            removeTouched(`quotes`, index, touched, setTouched);
          }}
          canChangeLength
          maxBlocks={values.preset === "quotes" ? 2 : 1}
          tooltip={TEXTEDIT_TOOLTIP}
        >
          {(item, index, updateItem) => (
            <Field
              as={TextArea}
              name={`quotes.${index}.text`}
              rows={3}
              placeholder="Текст цитаты"
              onChange={(event: any) => {
                updateItem({ text: event.target.value });
              }}
              {...getDeepValidationProps(
                `quotes.${index}.text`,
                touched,
                errors,
              )}
              required
            />
          )}
        </Blocks>
        {values.preset === "quote-slides" && (
          <Blocks
            items={values.items}
            updateItems={(items) => setFieldValue("items", items)}
            onDelete={(index) => {
              removeTouched(`items`, index, touched, setTouched);
            }}
            defaultItemConstructor={createImageItem}
            maxBlocks={4}
            canChangeLength
          >
            {(item, index, updateItem) => (
              <ImageInput
                aspect={IMAGE_ASPECT}
                imageTypes={IMAGE_TYPES}
                image={item.image}
                updateImage={(image) => {
                  updateItem({ image });
                }}
                onBlur={() => {
                  setFieldTouched(`items.${index}.image`, true);
                }}
                {...getDeepValidationProps(
                  `items.${index}.image`,
                  touched,
                  errors,
                )}
              />
            )}
          </Blocks>
        )}
        {values.preset === "quote-video" && (
          <FileInput
            title="Видео-файл"
            accept="video/*"
            files={values.video ? [values.video] : []}
            handleAcceptedFiles={(files) => {
              setFieldValue("video", files[files.length - 1]);
              if (!files.length) {
                setFieldValue("video", undefined);
              }
            }}
            showAlert={() => {}}
            style={isDesktopOrLaptop ? { marginLeft: 210 } : {}}
            isValid={!errors["video"]}
            validationMessage={"Видео-файл обязателен"}
          />
        )}
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(MezhpromForm);
