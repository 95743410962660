import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function RSlideshow({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      {data.subtitle && <InfoRow title="Подзаголовок" value={data.subtitle} />}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <ImageRow title="Изображение" src={block.image} />
        </details>
      ))}
    </>
  );
}
