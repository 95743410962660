import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import PageContainer from "../../../common/PageContainer";
import { nextPage } from "../../../redux/filters/filters.reducer";
import { getTasksThunk } from "../../../redux/tasks";
import { selectTasks } from "../../../redux/tasks/selectors";
import { RootState, AppDispatch } from "../../../store";
import DropdownArea from "../../components/DropdownArea/DropdownArea";
import { FiltersMobile } from "../../components/Filters";
import CreateTaskButton from "./CreateTaskButton";
import TaskList from "./TaskList";

export default function TaskListPage() {
  const { tasks, isLoading } = useSelector(selectTasks);
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();

  const loadNext = useCallback(() => {
    dispatch(nextPage());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getTasksThunk(filterState));
  }, [dispatch, filterState]);

  return (
    <PageContainer>
      <Title text="Список заказов" />
      <DropdownArea title="Фильтры">
        <FiltersMobile />
      </DropdownArea>
      <TaskList tasks={tasks} isLoading={isLoading} loadNext={loadNext} />
      <CreateTaskButton />
    </PageContainer>
  );
}
