import { Task } from "../../common/models";
import { RootState } from "../../store";
import { TaskEntry } from "./index";

export const selectTasks = (state: RootState) => ({
  tasks: state.tasks.ids.map((id) => state.tasks.byId[id]) as Task[],
  isLoading: state.tasks.isLoading,
  totalTasks: state.tasks.totalTasks,
});

export const selectIsStatusChanging = (state: RootState) =>
  state.tasks.isStatusChanging;

export const selectTasksByPage =
  (page: number, perPage: number) => (state: RootState) => {
    const from = (page - 1) * perPage;
    const to = from + perPage - 1;
    return {
      tasks: state.tasks.ids
        .slice(from, to + 1)
        .map((id) => state.tasks.byId[id]) as Task[],
      isLoading: state.tasks.isLoading,
      totalPages: state.tasks.totalPages,
      totalTasks: state.tasks.totalTasks,
    };
  };

export const selectTaskById = (id: string) => (state: RootState) =>
  state.tasks.byId[id] as TaskEntry | undefined;
