import { useCallback, useEffect, useRef, useState } from "react";

import { makeSearch } from "../../common/ApiService";
import { Task as Order } from "../../common/models";

export default function useSearch() {
  const [query, setQuery] = useState("");
  const [results, setResults] = useState<{ orders: Order[]; total: number }>({
    orders: [],
    total: 0,
  });
  const [loading, setLoading] = useState(false);

  const timeout = useRef<number>();

  useEffect(() => {
    window.clearTimeout(timeout.current);

    if (query) {
      setLoading(true);
      timeout.current = window.setTimeout(async () => {
        try {
          const searchResults = await makeSearch(query);
          setResults(searchResults);
          setLoading(false);
        } catch (error) {
          setLoading(false);
        }
      }, 1000);
    } else {
      setResults({
        orders: [],
        total: 0,
      });
    }
  }, [query]);

  const clearQuery = useCallback(() => {
    window.clearTimeout(timeout.current);
    setLoading(false);
    setQuery("");
    setResults({
      orders: [],
      total: 0,
    });
  }, []);

  return { query, setQuery, results, loading, clearQuery };
}
