import Blocks from "emg-ui-kit/components/Blocks";
import MapInput from "emg-ui-kit/components/MapInput";
import Modal from "emg-ui-kit/components/Modal";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React, { useCallback, useEffect, useState } from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import TheCityMap from "../../components/TheCityMap";
import {
  AddresOrientation,
  LocationPoint,
  MapData,
} from "../../components/TheCityMap/types";
import {
  CLIP_NAME_REGEXP,
  getDeepValidationProps,
  getValidationProps,
  removeEmptyProps,
  removeTouched,
  validateIncorrectFormat,
  validateNotEmpty,
  validateNotEmptyArray,
} from "../util";
import styles from "./TheCity.module.css";
import { addOffset, removeOffset } from "./util";

type Item = {
  mainText: string;
  subText: string;
};

function createItem(): Item {
  return {
    mainText: "",
    subText: "",
  };
}

function initItems(count: number) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: 60,
    mapData: (initialFormData?.mapData
      ? {
          ...initialFormData?.mapData,
          points: initialFormData?.mapData?.points?.map(
            (point: LocationPoint) => ({
              ...point,
              pos: addOffset(point.pos),
            })
          ),
          frame: initialFormData.mapData?.frame && [
            addOffset(initialFormData.mapData?.frame?.[0] ?? { x: 0, y: 0 }),
            addOffset(initialFormData.mapData?.frame?.[1] ?? { x: 0, y: 0 }),
          ],
        }
      : undefined) as MapData,
    items: (initialFormData?.blocks ?? initItems(0)) as Item[],
    manual: initialFormData?.manual ?? false,
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    mapData: validateNotEmptyArray(values.mapData?.points ?? []),
    items: values.items.map((item) => ({
      mainText: validateNotEmpty(item.mainText),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    mapData: {
      ...values.mapData,
      points: values.mapData?.points?.map((point) => ({
        ...point,
        pos: removeOffset(point.pos),
      })),
      frame: [
        removeOffset(values.mapData?.frame?.[0] ?? { x: 0, y: 0 }),
        removeOffset(values.mapData?.frame?.[1] ?? { x: 0, y: 0 }),
      ],
    },
    blocks: values.items,
    manual: false,
  };
}

type Values = ReturnType<typeof getInitialValues>;

function TheCityForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => {
      onSubmit(prepareData(values));
    },
    validate,
  });
  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    setFieldTouched,
    setFieldValue,
    setTouched,
  } = formik;

  const [isMapVisible, setIsMapVisible] = useState(false);

  const saveMapDataCallback = useCallback(
    (data) => {
      setFieldValue("mapData", data);
    },
    [setFieldValue]
  );

  const mapInputContent: string[] = [];
  if (values.mapData?.points) {
    values.mapData.points.map((point) => {
      const pointString = `${point.text}, ${AddresOrientation[point.align]}`;
      mapInputContent.push(pointString);
      return null;
    });
  }

  const handleDeleteId = (id?: string) => {
    const index = values.mapData?.points.findIndex((point) => point.id === id);
    const updatedData = { ...values };
    updatedData.items.splice(index, 1);
    setFieldValue("items", updatedData.items);
    removeTouched("items", index, touched, setTouched);
  };

  const pointsCount = values.mapData?.points.length || 0;

  useEffect(() => {
    if (pointsCount > values.items.length) {
      setFieldValue("items", [...values.items, createItem()]);
    }
  }, [pointsCount, values.items, setFieldValue]);

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  const handleMapVisible = () => {
    setIsMapVisible(true);
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          disabled
          as={TextField}
          name="timing"
          type="number"
          label="Хронометраж (сек.)"
        />
        <MapInput
          label="Карта"
          content={mapInputContent}
          onMapVisible={handleMapVisible}
          {...getValidationProps("mapData", touched, errors)}
          onBlur={() => {
            setFieldTouched("mapData");
          }}
          required
        />
        <Modal
          className={styles.modal}
          visible={isMapVisible}
          close={() => {
            setIsMapVisible(false);
          }}
        >
          <TheCityMap
            maxScale={1}
            minScale={0.9}
            saveMapData={saveMapDataCallback}
            mapData={values.mapData}
            handleClose={() => setIsMapVisible(false)}
            onDeleteId={handleDeleteId}
          />
        </Modal>
        <Blocks
          blockTitle="Блок"
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          defaultItemConstructor={createItem}
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={TextArea}
                name={`items.${index}.mainText`}
                placeholder="Основной текст"
                rows={2}
                {...getDeepValidationProps(
                  `items.${index}.mainText`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextArea}
                name={`items.${index}.subText`}
                placeholder="Дополнительный текст"
                rows={2}
                {...getDeepValidationProps(
                  `items.${index}.subText`,
                  touched,
                  errors
                )}
              />
            </>
          )}
        </Blocks>

        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(TheCityForm);
