import React, { useRef, useState } from "react";

import { useOutsideClick } from "../hooks/useOutsideClick";
import styles from "./DashBoard.module.css";
import Submenu from "./SubMenu";

export default function GearButton({
  options,
  selectedOption,
  handleOptionClick,
}: {
  options: string[];
  selectedOption?: string;
  handleOptionClick: (option: string) => void;
}) {
  const [isGearOn, setIsGearOn] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null!);

  useOutsideClick(containerRef, () => setIsGearOn(false));

  const toggleGearButton = () => {
    setIsGearOn((prev) => !prev);
  };

  const handleOptionSelect = (option: string) => {
    handleOptionClick(option);
    setIsGearOn(false);
  };

  return (
    <div ref={containerRef}>
      <button
        className={isGearOn ? styles.gearButtonOn : styles.gearButton}
        onClick={toggleGearButton}
      ></button>
      {isGearOn && (
        <Submenu
          options={options}
          selectedOption={selectedOption}
          handleOptionClick={handleOptionSelect}
        />
      )}
    </div>
  );
}
