import { RootState } from "../../store";

const wrapLinks = (text: string) => {
  const out = [];
  for (const word of text.replace(/ {1,}/g, " ").split(" ")) {
    if (/^http(s)?:\/{2}.+\..+$/.test(word)) {
      try {
        const url = new URL(word);
        out.push(`<a href="${word}" target="_blank">${url.host}</a>`);
      } catch {
        out.push(word);
      }
    } else {
      out.push(word);
    }
  }
  return out.join(" ");
};

export const selectNotification = (state: RootState) => state.notifications;

export const selectTroubleNotification = (state: RootState) => {
  let { troubleNotification } = state.notifications;
  if (troubleNotification) {
    const unityMessage = troubleNotification.unityMessage;
    const aeMessage = troubleNotification.aeMessage;
    troubleNotification = {
      ...troubleNotification,
      unityMessage,
      aeMessage,
    };
  }
  return troubleNotification;
};

export const selectNotificationWithLinks = (state: RootState) => {
  let { notification } = state.notifications;
  if (notification) {
    const message = notification.message;
    notification = {
      ...notification,
      message: wrapLinks(message),
    };
  }

  return notification;
};
