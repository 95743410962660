import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Site3D({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <ImageRow title="Изображение" src={data.image} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      {(data.items as Record<string, any>[]).map((item, idx) => (
        <details key={idx}>
          <summary>Текстовый блок №{idx + 1}</summary>
          <InfoRow title="Заголовок" value={item.title} />
          <InfoRow title="Текст" value={item.text} />
          <InfoRow title="Источник" value={item.source} />
        </details>
      ))}
    </>
  );
}
