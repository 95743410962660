import cloneDeep from "lodash/cloneDeep";
import { useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";

import { setUsePreviewData, usePreviewChecked } from "../redux/usePreview";
import { AppDispatch } from "../store";
import { PreviewContext } from "./PreviewContext";

function changeImageUrl(
  file: File,
  objectUrlMap: React.MutableRefObject<Map<File, string>>
) {
  if (objectUrlMap.current.has(file)) {
    return objectUrlMap.current.get(file)!;
  } else {
    const objectUrl = URL.createObjectURL(file);
    objectUrlMap.current.set(file, objectUrl);
    return objectUrl;
  }
}

export default function usePreview(
  channel: string,
  template: string,
  timing: number,
  rawData: Record<string, any>,
  prepareData?: (data: any) => any
) {
  const dispatch = useDispatch<AppDispatch>();
  const { setData, setTiming } = useContext(PreviewContext);
  const isPreviewChecked = useSelector(usePreviewChecked);

  const objectUrlMap = useRef<Map<File, string>>(new Map());

  useEffect(() => {
    function convertDataToPreview(data: any) {
      const dataToSend = cloneDeep(data);

      function replaceFileFields(obj: any) {
        for (let key in obj) {
          if (obj[key] instanceof File) {
            obj[key] = changeImageUrl(obj[key], objectUrlMap);
          } else if (typeof obj[key] === "object") {
            replaceFileFields(obj[key]);
          }
        }
      }
      replaceFileFields(dataToSend);
      return dataToSend;
    }

    if (isPreviewChecked) {
      const previewData = convertDataToPreview({ ...rawData });
      dispatch(setUsePreviewData(true));

      setData({
        channel,
        template,
        data: prepareData?.(previewData) ?? previewData,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [channel, template, rawData, prepareData, setData, isPreviewChecked]);

  useEffect(() => {
    setTiming(timing);
  }, [timing, setTiming]);
}
