import Button from "emg-ui-kit/components/Button";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import List from "../common/List";
import PageContainer from "../common/PageContainer";
import { Table } from "../common/Table";
import { extractTextContent, useIsDesktop } from "../common/utils";
import { getNewsThunk, selectNews } from "../redux/news";
import routes from "../routes";
import styles from "./UserList.module.css";
import { groups } from "./userConstants";

const CONTENT_LEN = 40;

const dateFormatter = new Intl.DateTimeFormat("ru", {
  year: "numeric",
  month: "long",
  day: "numeric",
});

export default function NewsList() {
  const news = useSelector(selectNews);
  const dispatch = useDispatch();
  const history = useHistory();
  const isDesktop = useIsDesktop();

  useEffect(() => {
    dispatch(getNewsThunk());
  }, [dispatch]);

  return (
    <PageContainer className={styles.pageContainer}>
      <Title text="Список новостей" />
      <Button
        title="Добавить"
        style={{ marginLeft: 0, marginBottom: 20 }}
        onClick={() => history.push(routes.newsArticlesNew)}
      />
      {isDesktop ? (
        <Table
          items={news}
          displayProps={[
            {
              name: "Описание новости",
              selector: (article) => extractAndSlice(article.content),
            },
            {
              name: "Дата добавления",
              selector: (article) =>
                dateFormatter.format(new Date(article.date)),
            },
            {
              name: "Группа",
              selector: (article) => groups[article.group],
            },
          ]}
          order="asc"
          changeOrder={() => {}}
          idPropName="id"
          onItemClick={(article) =>
            history.push(`${routes.newsArticles}/${article._id}`)
          }
        />
      ) : (
        <List
          items={news.map((article) => ({
            id: article._id,
            title: extractAndSlice(article.content),
          }))}
          urlBase={routes.newsArticles}
        />
      )}
    </PageContainer>
  );
}

function extractAndSlice(html: string) {
  const text = extractTextContent(html);
  return text.length <= CONTENT_LEN ? text : text.slice(0, CONTENT_LEN) + "...";
}
