import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";

export function Informer({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      <InfoRow title="Заголовок" value={data.h1} />
      <InfoRow title="Название мероприятия" value={data.eventName} />
      <InfoRow title="Адрес мероприятия" value={data.locationName} />
      {data.date && <InfoRow title="Дата" value={data.date} />}
      {data.age && <InfoRow title="Возрастной ценз" value={data.age} />}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Комментарий" value={block.comment} />
          <InfoRow title="Источник" value={block.source} />
          <ImageRow title="Фото" src={block.image} />
        </details>
      ))}
    </>
  );
}
