import DatePeriod from "emg-ui-kit/components/DateTime/DatePeriod";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";

import PageContainer from "../common/PageContainer";
import styles from "./DashBoard.module.css";
import InfoModule from "./InfoModule";
import InteractiveMaps from "./InteractiveMaps";
import { useDashBoardData } from "./useDashBoardData";

export const EmptyDataCap = () => (
  <div className={styles.emptyDataCap}>
    За выбранный период отсутствуют данные
  </div>
);

export default function DashBoard() {
  const {
    period,
    channel,
    users,
    sentToBroadCast,
    preparedAtmoLocations,
    dashBoardState,
    periodDates,
    userInfo,
    setPeriod,
    setPeriodDates,
    setChannel,
    setUsers,
    toggleBroadcast,
  } = useDashBoardData();

  const history = useHistory();

  useEffect(() => {
    if (userInfo?.type !== "employee" && userInfo?.type !== "admin") {
      history.push("/");
    }
  }, [userInfo, history]);

  const SelectUsers = () => {
    return (
      <Select
        className={styles.select}
        name="users"
        placeholder="Выберите группу пользователей"
        options={[
          { id: "all", name: "Все" },
          { id: "client", name: "Клиент" },
          { id: "employee", name: "Сотрудник" },
          { id: "admin", name: "Администратор" },
        ]}
        disabled={userInfo?.type !== "admin"}
        value={users}
        onChange={(event) => {
          setUsers(event.target.value);
        }}
      />
    );
  };

  return (
    <PageContainer className={styles.pageContainer}>
      <Title text="Дэшборд" />
      <div className={styles.selectContainer}>
        <div style={{ minWidth: 300 }}>
          <Select
            name="period"
            options={[
              { id: "1", name: "Последний месяц" },
              { id: "3", name: "Последние 3 месяца" },
              { id: "6", name: "Последние 6 месяцев" },
              { id: "12", name: "Последний год" },
              { id: "-", name: "Весь период" },
              { id: "custom", name: "Произволный" },
            ]}
            value={period}
            onChange={(event) => {
              setPeriod(event.target.value);
              setPeriodDates({ startDate: undefined, endDate: undefined });
            }}
          />
        </div>
        <div style={{ minWidth: 300 }}>
          <DatePeriod
            startDate={periodDates.startDate}
            endDate={periodDates.endDate}
            name="date-period"
            handleStartDateChange={(value) => {
              setPeriod("custom");
              setPeriodDates({ ...periodDates, startDate: value });
            }}
            handleEndDateChange={(value) => {
              setPeriod("custom");
              setPeriodDates({ ...periodDates, endDate: value });
            }}
          />
        </div>
      </div>
      <Select
        className={styles.select}
        name="channel"
        placeholder="Выберите программу"
        options={[
          { id: "atmo", name: "Атмосфера" },
          { id: "m24", name: "Москва 24" },
          { id: "r24", name: "Россия 24" },
          { id: "maps", name: "Карты" },
        ]}
        disabled={!!userInfo?.groups[0]}
        value={channel}
        onChange={(event) => {
          setChannel(event.target.value);
        }}
      />
      {channel === "atmo" && (
        <>
          <span style={{ display: "flex", gap: 7 }}>
            Всего эфирных заказов:{"  "}
            <div className={styles.title}>{dashBoardState.totalBroadcast}</div>
          </span>
          <div className={styles.blocksContainer}>
            <InteractiveMaps
              atmoMaps={dashBoardState.atmoMaps}
              sentToBroadCast={sentToBroadCast.interactive}
              toggleInteractiveBroadcast={() => toggleBroadcast("interactive")}
              isLoading={dashBoardState.isInteractiveLoading}
            />
            <InfoModule
              title="Частота использования локаций"
              sentToBroadCast={sentToBroadCast.locations}
              data={preparedAtmoLocations}
              toggleBroadcast={() => toggleBroadcast("locations")}
              isLoading={dashBoardState.isLocationsLoading}
            />
          </div>
          <div className={styles.blocksContainer}>
            <InfoModule
              title="Частота использования инфографики"
              sentToBroadCast={sentToBroadCast.infographic}
              data={dashBoardState.atmoInfographic}
              toggleBroadcast={() => toggleBroadcast("infographic")}
              isLoading={dashBoardState.isInfographicLoading}
            />
          </div>
        </>
      )}
      {channel === "m24" && (
        <>
          <SelectUsers />
          <InfoModule
            title="Заказы Mосква 24"
            sentToBroadCast={sentToBroadCast.m24Orders}
            data={dashBoardState.m24Orders}
            toggleBroadcast={() => toggleBroadcast("m24Orders")}
            isLoading={dashBoardState.m24Loading}
          />
        </>
      )}
      {channel === "r24" && (
        <>
          <SelectUsers />
          <InfoModule
            title="Заказы Россия 24"
            sentToBroadCast={sentToBroadCast.r24Orders}
            data={dashBoardState.r24Orders}
            toggleBroadcast={() => toggleBroadcast("r24Orders")}
            isLoading={dashBoardState.r24Loading}
          />
        </>
      )}
      {channel === "maps" && (
        <>
          <SelectUsers />
          <InfoModule
            title="Заказы Карты"
            sentToBroadCast={sentToBroadCast.mapsOrders}
            data={dashBoardState.mapsOrders}
            toggleBroadcast={() => toggleBroadcast("mapsOrders")}
            isLoading={dashBoardState.mapsLoading}
          />
        </>
      )}
    </PageContainer>
  );
}
