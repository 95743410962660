import React from "react";

import { ColorBox, InfoRow } from "../InfoComponents";

export function ChartRows({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      {data.subtitle && <InfoRow title="Подзаголовок" value={data.subtitle} />}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      {(data.legend as any[]).map((legend, idx) => (
        <>
          <InfoRow title={`Легенда ${idx + 1}`} value={legend.title} />
          <InfoRow
            title={`Цвет  ${idx + 1}`}
            value={<ColorBox color={legend.color} />}
          />
        </>
      ))}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Название" value={block.title} />
          {(block.values as any[]).map((value, idx) => (
            <InfoRow title={`Значение ${idx + 1}`} value={value} />
          ))}
        </details>
      ))}
    </>
  );
}
