import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import { SingleSearch } from "emg-ui-kit/components/Search";
import { Option } from "emg-ui-kit/components/Search/Search";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React, { useMemo } from "react";
import { useDispatch } from "react-redux";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { convertImageUrlToFile, useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePersons from "../usePersons";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  IMAGE_TYPES,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  removeTouched,
  validateAspect,
  validateMaxLines,
  validateMin,
  filterPersonsByTemplate,
} from "../util";

const IMAGE_ASPECT = 1376 / 800;

type Item = {
  text: string;
  timing: number;
};

function createItem(): Item {
  return {
    text: "",
    timing: 13,
  };
}

function initItems(count: number) {
  return Array.from(Array(count), createItem);
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : values.items.reduce((acc, item) => acc + item.timing, 0) + 4;
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    image: initialFormData?.image as FormImage | undefined,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    timing: (initialFormData?.totalTiming ?? 30) as number,
    items: (initialFormData?.blocks ?? initItems(1)) as Item[],
  };
}

const timingTypeOptions = [
  { id: "total", name: "Общий" },
  { id: "separate", name: "Для каждой цитаты" },
];

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    image:
      validateNotEmpty(values.image) ??
      validateAspect(values.image!, IMAGE_ASPECT),
    timing:
      values.timingType === "total" ? validateMin(values.timing, 4) : undefined,
    items: values.items.map((item) => ({
      text: validateNotEmpty(item.text) ?? validateMaxLines(item.text, 4),
      timing: validatePositive(item.timing),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    image: values.image,
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items,
  };
}

function M24QuoteSobyaninForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isValid,
    isSubmitting,
  } = formik;

  const dispatch = useDispatch();

  const { persons, person, handleUpdatePerson } = usePersons("photoTemplates");

  const personsOptions = useMemo(
    () =>
      filterPersonsByTemplate(persons, "m24quote_sobyanin")
        ?.map((person, id) => ({ id, value: person.name }))
        .sort((a, b) => a.value.localeCompare(b.value)) ?? [],
    [persons]
  );

  const handleImageUpdate = (image?: FormImage) => {
    setFieldTouched("image");
    setFieldValue("image", image);
  };

  const handleSelect = (option?: Option) => {
    handleUpdatePerson(option);
    const person = persons?.find((person) => person.name === option?.value);
    if (person) {
      convertImageUrlToFile(person.photoTemplates)
        .then(handleImageUpdate)
        .catch((err) => {
          dispatch({ type: "CONVERSION_ERROR", error: err });
        });
    } else {
      handleImageUpdate(undefined);
    }
  };

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isValid,
    isSubmitting,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, calcTotalTiming(values), values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          {...getValidationProps("clipName", touched, errors)}
        />
        <SingleSearch
          label="Выбрать из базы"
          selected={person}
          updateSelected={handleSelect}
          options={personsOptions}
        />
        <ImageInput
          label="Изображение"
          title="Добавить изображение"
          imageTypes={IMAGE_TYPES}
          image={values.image}
          aspect={IMAGE_ASPECT}
          updateImage={(image) => {
            setFieldValue("image", image);
          }}
          onBlur={() => {
            setFieldTouched("image");
          }}
          {...getValidationProps("image", touched, errors)}
          required
        />

        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={timingTypeOptions}
        />
        {values.timingType === "total" && (
          <Field
            as={TextField}
            name="timing"
            type="number"
            label="Общий хронометраж (сек.)"
            {...getValidationProps("timing", touched, errors)}
          />
        )}
        {values.timingType === "separate" && (
          <div
            style={{
              ...(isDesktop && { marginLeft: 210 }),
              marginTop: 30,
              marginBottom: 30,
            }}
          >
            {`Общая длительность ролика: ${calcTotalTiming(values)} сек.`}
          </div>
        )}

        <Blocks
          blockTitle="Цитата"
          items={values.items}
          tooltip={
            "Подчёркивание: <|подчёркнутый текст|> & Размер текста: <size=50></size>"
          }
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          defaultItemConstructor={createItem}
          canChangeLength
        >
          {(item, index, updateItem) => (
            <>
              <Field
                as={TextArea}
                name={`items.${index}.text`}
                placeholder="Текст цитаты"
                rows={4}
                {...getDeepValidationProps(
                  `items.${index}.text`,
                  touched,
                  errors
                )}
                required
              />
              {values.timingType === "separate" && (
                <Field
                  as={TextField}
                  label="Хронометраж (сек.)"
                  name={`items.${index}.timing`}
                  type="number"
                  {...getDeepValidationProps(
                    `items.${index}.timing`,
                    touched,
                    errors
                  )}
                  required
                />
              )}
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24QuoteSobyaninForm);
