import React from "react";
import ContentLoader from "react-content-loader";

export default function DashBoardInteractiveMapSkeleton() {
  return (
    <ContentLoader
      speed={2}
      width={300}
      height={430}
      viewBox="0 0 283 362"
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
    >
      <path d="M 0 222 h 283 v 20 H 0 z M 0 246 h 283 v 20 H 0 z M 0 270 h 283 v 20 H 0 z M 0 294 h 283 v 20 H 0 z M 0 318 h 283 v 20 H 0 z M 0 342 h 283 v 20 H 0 z" />
      <path d="M 139.698 28.405 a 80.594 80.594 0 180.145 72.076 80.608 80.608 a 0 1 -5.369 38.593 l -37.388 -15.036 a 40.306 40.306 0 0 -3.997 -37.598 40.306 a 40.306 0 0 -33.391 -17.737 V 28.405 z" />
      <path d="M 214.92 138.712 a 80.593 80.593 0 1 -93.953 48.605 l 9.516 -39.158 a 40.302 40.302 0 46.977 -24.303 l 37.46 14.856 z" />
      <path d="M 121.027 187.324 a 80.6 80.6 0 1 -60.753 -66.526 l 39.863 -5.903 a 40.3 40.3 0 30.377 33.263 l -9.487 39.166 z" />

      <path d="M140 100m-96,0a96,96,0 1,0 192,0a 96,96 0 1,0 -192,0zM140 100m-50,0a50,50,0 0,1 100,0a 50,50 0 0,1 -100,0z" />
    </ContentLoader>
  );
}
