import Button from "emg-ui-kit/components/Button";
import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import FiltersSkeletonLoader from "../../../components/Skeletons/FiltersSkeletonLoader";
import { selectAuth } from "../../../redux/auth/selectors";
import { selectNotification } from "../../../redux/notifications/selectors";
import routes from "../../../routes";
import { RootState } from "../../../store";
import { Filters } from "../../components/Filters";
import styles from "./TaskTable.module.css";

export default function SideMenu() {
  const { notification } = useSelector(selectNotification);
  const history = useHistory();

  const { isLoading } = useSelector((state: RootState) => state.bitrix);
  const userInfo = useSelector(selectAuth).userInfo;
  const isClient = userInfo?.type === "client";

  const toShowSkeleton = isClient && isLoading;

  return (
    <aside
      className={
        notification && notification.category !== "none"
          ? styles.sidebarNotifOn
          : styles.sidebar
      }
    >
      <Button
        title="Сделать заказ"
        onClick={() => {
          history.push(routes.tasksNew);
        }}
        style={{ width: "100%" }}
      />
      {toShowSkeleton ? <FiltersSkeletonLoader /> : <Filters />}
    </aside>
  );
}
