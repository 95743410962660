import IconButton from "emg-ui-kit/components/IconButton";
import Title from "emg-ui-kit/components/Title";
import React, { useCallback, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import PageContainer from "../../../common/PageContainer";
import { Order, Table } from "../../../common/Table";
import { Task } from "../../../common/models";
import { findThemeName, templates } from "../../../common/texts";
import { channels } from "../../../common/texts";
import { dateFormatter } from "../../../common/utils";
import DraftTableSkeleton from "../../../components/Skeletons/DraftTableSkeleton";
import { selectUserInfo } from "../../../redux/auth/selectors";
import { getDraftsThunk } from "../../../redux/drafts";
import { selectDraftsByPage } from "../../../redux/drafts/selectors";
import routes from "../../../routes";
import { AppDispatch } from "../../../store";
import { IFilterState } from "../../components/Filters/FilterTypes";
import { restoreFilterState } from "../../components/Filters/filterUtils";
import useDeleteDraft from "../../hooks/useDeleteDraft";
import Pagination from "../TaskTablePage/Pagination";
import styles from "./../TaskTablePage/TaskTable.module.css";

const getTemplate = (task: Task) =>
  templates[task.channel]?.[task.template] ?? "-";
const getTheme = (task: Task) =>
  task.data.theme?.name ??
  findThemeName(task.data.theme, task.data.background) ??
  "-";
const getDate = (task: Task) =>
  dateFormatter.format(Date.parse(task.creationDate));

const getChannel = (task: Task) => {
  return channels[task.channel];
};

const getClipName = (task: Task) => task.data.clipName || task.data.name || "-";

export default function DraftTablePage() {
  const dispatch = useDispatch<AppDispatch>();
  const [params, setParams] = useState(restoreFilterState);
  const { drafts, totalPages, isLoading } = useSelector(
    selectDraftsByPage(params.page, params.perPage)
  );
  const userInfo = useSelector(selectUserInfo)!;
  const history = useHistory();

  const changeParams = useCallback((params: IFilterState) => {
    setParams(params);
  }, []);

  const changeParam = <T extends keyof IFilterState>(
    param: T,
    value: IFilterState[T]
  ) => {
    const nextParams =
      param === "page"
        ? { ...params, [param]: value }
        : { ...params, [param]: value, page: 1 };
    changeParams(nextParams);
  };

  const { handleDeleteDraft, isDisabled } = useDeleteDraft(() =>
    dispatch(getDraftsThunk(params))
  );

  const handleOpenDraft = (task: Task) => {
    history.push(`${routes.draft}?redoId=${task._id}&isDraft=true`);
  };

  useEffect(() => {
    dispatch(getDraftsThunk(params));
  }, [dispatch, params]);

  const isR24 = userInfo.groups.includes("r24");

  const ComponentToShow = () => {
    if (isLoading) {
      return <DraftTableSkeleton />;
    }
    if (!drafts.length) {
      return <div className={styles.noOrders}>Нет черновиков</div>;
    }
    return (
      <Table
        items={drafts}
        displayProps={[
          {
            name: "Шаблон",
            selector: getTemplate,
            className: styles.taskTitle,
            currentSort: true,
          },
          ...(isR24
            ? [
                { name: "Оформление", selector: getTheme },
                { name: "Название", selector: "data.name" },
              ]
            : [{ name: "Название ролика", selector: getClipName }]),
          {
            name: "Проект",
            selector: getChannel,
            className: styles.dateCell,
          },
          {
            name: "Дата создания",
            selector: getDate,
            className: styles.dateCell,
            currentSort: true,
          },
          {
            name: "",
            selector: (task) => (
              <IconButton
                onClick={(event) => {
                  event.stopPropagation();
                  handleDeleteDraft(task._id);
                }}
                status={isDisabled ? "disabled" : "enabled"}
              />
            ),
            headerClassName: styles.buttonHeader,
          },
        ]}
        onItemClick={handleOpenDraft}
        order={params.order as Order}
        changeOrder={(value) => changeParam("order", value)}
        idPropName="_id"
      />
    );
  };

  return (
    <PageContainer className={styles.pageContainer}>
      <section className={styles.workspace}>
        <Title text="Черновики" />
        <ComponentToShow />
        <Pagination
          tasks={drafts}
          page={params.page}
          perPage={params.perPage}
          totalPages={totalPages}
          changePage={(value) => changeParam("page", value)}
        />
      </section>
    </PageContainer>
  );
}
