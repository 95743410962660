import React from "react";

import { TaskDataView } from "../TaskPage/index";
import styles from "./TaskList.module.css";

const SubList = ({ task }: { task: Record<string, any> }) => {
  return (
    <>
      <div className={styles.subListTop}>
        <TaskDataView template={task.template} data={task.data} />
      </div>
      <div className={styles.subListBottom}></div>
    </>
  );
};

export default SubList;
