import Checkbox from "emg-ui-kit/components/Checkbox";
import React from "react";

export type FeedbackState = {
  sentToBroadcast: boolean;
  renderError: boolean;
  incorrectData: boolean;
};

export type FeedbackProps = {
  state: FeedbackState;
  setState: (newFeedback: FeedbackState) => void;
};

export default function TaskFeedback({ state, setState }: FeedbackProps) {
  return (
    <>
      <Checkbox
        label="Отправлено на эфир"
        checked={state.sentToBroadcast}
        setChecked={(val) => {
          setState({ ...state, sentToBroadcast: val });
        }}
      />
      <Checkbox
        label="Ошибка рендера"
        checked={state.renderError}
        setChecked={(val) => {
          setState({ ...state, renderError: val });
        }}
      />
      <Checkbox
        label="Некорректные данные"
        checked={state.incorrectData}
        setChecked={(val) => {
          setState({ ...state, incorrectData: val });
        }}
      />
    </>
  );
}
