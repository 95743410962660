import React from "react";

import { ColorBox, InfoRow } from "../InfoComponents";

export function ChartPie({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      {data.subtitle && <InfoRow title="Подзаголовок" value={data.subtitle} />}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      {data.legendSize && (
        <InfoRow title="Размер подписей" value={data.legendSize} />
      )}
      {data.valueSize && (
        <InfoRow title="Размер значений" value={data.valueSize} />
      )}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Значение" value={block.value} />
          <InfoRow title="Легенда" value={block.legend} />
          <InfoRow title="Цвет" value={<ColorBox color={block.color} />} />
        </details>
      ))}
    </>
  );
}
