import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { AppDispatch } from "../../store";
import {
  getNewsThunk,
  selectHasUnreadNews,
  selectNews,
  setHasUnreadNews,
} from "./index";

const ARTICLE_DATE_KEY = "lastReadArticleDate";

/**
 * Loads news and sets 'hasUnread' flag
 * */
export function useNewsLoad(userGroup: string) {
  const hasUnread = useSelector(selectHasUnreadNews);
  const dispatch = useDispatch<AppDispatch>();

  useEffect(() => {
    dispatch(getNewsThunk(userGroup)).then((res) => {
      if ("error" in res) return;
      const articles = res.payload;
      const dateStr = localStorage.getItem(ARTICLE_DATE_KEY);
      if (articles.length && articles[0].date !== dateStr) {
        dispatch(setHasUnreadNews(true));
      } else {
        dispatch(setHasUnreadNews(false));
      }
    });
  }, [dispatch, userGroup]);

  return hasUnread;
}

/**
 * Selects news from store and marks them as 'read'
 * */
export function useNewsList() {
  const dispatch = useDispatch<AppDispatch>();
  const news = useSelector(selectNews);

  useEffect(() => {
    if (news.length) {
      localStorage.setItem(ARTICLE_DATE_KEY, news[0].date);
      dispatch(setHasUnreadNews(false));
    }
  }, [dispatch, news]);

  return news;
}
