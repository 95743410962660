export const designOptions = [
  { id: "news", name: "Новости" },
  { id: "specrep", name: "Спецреп" },
];

export const m24QuoteDesignOptions = [
  ...designOptions,
  { id: "aurora", name: "Аврора" },
];

export const afficheDesignOptions = [
  { id: "cinema", name: "Кино" },
  { id: "theatre", name: "Театр" },
  { id: "exhibition", name: "Выставка" },
];
export const mezhpromSourceOptions = [
  { id: "tw", name: "Твиттер" },
  { id: "tg", name: "Телеграм" },
  { id: "tg2", name: "Телеграм@‌blogsobyanin" },
  { id: "vk", name: "ВКонтакте" },
  { id: "ok", name: "Одноклассники" },
  { id: "bs", name: "Блог" },
  { id: "mr", name: "mos.ru" },
];

export const ageOptions = [
  { id: "0+", name: "0+" },
  { id: "6+", name: "6+" },
  { id: "12+", name: "12+" },
  { id: "16+", name: "16+" },
  { id: "18+", name: "18+" },
];

const noneOption = { id: "no", name: "Отсутствует" };

export const extendedAgeOptions = [noneOption, ...ageOptions];

export const imageTypeOptions = [
  { id: "total", name: "Общее" },
  { id: "separate", name: "Для каждого слайда" },
];

export const colorOptions = [
  { id: "gray", name: "Серый" },
  { id: "white", name: "Белый" },
];

export const sourceTypeOptions = [
  { id: "total", name: "Общий" },
  { id: "separate", name: "Для каждого слайда" },
];
