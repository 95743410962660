import React from "react";

import { InfoRow } from "../InfoComponents";
import { color } from "../constants";

export function M24InformerCity({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.clipName && (
        <InfoRow title="Название ролика" value={data.clipName} />
      )}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      <InfoRow title="Заголовок" value={data.title} />
      {data.eventName && (
        <InfoRow title="Название мероприятия" value={data.eventName} />
      )}
      {data.locationName && (
        <InfoRow title="Место проведения" value={data.locationName} />
      )}
      {data.age !== "no" && (
        <InfoRow title="Возрастной ценз" value={data.age} />
      )}
      {(data.dates as string[]).map((date, id) => (
        <InfoRow key={id} title={`Дата ${id + 1}`} value={date} />
      ))}
      {(data.comments as string[]).map((comment, id) => (
        <InfoRow key={id} title={`Комментарий ${id + 1}`} value={comment} />
      ))}

      {(data.sources as Record<string, any>[]).map((source, id) => (
        <details open key={id}>
          <summary>Источник {id + 1}</summary>
          <InfoRow title="Источник" value={source.sourceText} />
          <InfoRow title="Цвет источника" value={color[source.sourceColor]} />
        </details>
      ))}
    </>
  );
}
