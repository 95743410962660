import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { useIsDesktop } from "../../common/utils";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  IMAGE_TYPES,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  removeTouched,
  validateMaxLines,
  validateAspect,
} from "../util";
import {
  ageOptions,
  imageTypeOptions,
  colorOptions,
  sourceTypeOptions,
} from "./constants";

const IMAGE_ASPECT = 16 / 9;

type Item = {
  image?: FormImage;
  comment: string;
  source: string;
  sourceColor: string;
};

function createItem(): Item {
  return {
    image: undefined,
    comment: "",
    source: "",
    sourceColor: "white",
  };
}

function initItems(count: number) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timing: (initialFormData?.timing ?? 11) as number,
    title: (initialFormData?.title ?? "") as string,
    date: (initialFormData?.date ?? "") as string,
    eventName: (initialFormData?.eventName ?? "") as string,
    locationName: (initialFormData?.locationName ?? "") as string,
    age: (initialFormData?.age ?? "0+") as string,
    theCity: (initialFormData?.theCity ?? false) as boolean,
    imageType: (initialFormData?.imageType ?? "separate") as string,
    image: initialFormData?.image as FormImage | undefined,
    sourceType: (initialFormData?.sourceType ?? "separate") as string,
    source: (initialFormData?.source ?? "") as string,
    sourceColor: (initialFormData?.sourceColor ?? "white") as string,
    items: (initialFormData?.blocks ?? initItems(1)) as Item[],
  };
}

const timingOptions = [
  { id: 11, name: "11 секунд" },
  { id: 30, name: "30 секунд" },
];

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    title: validateNotEmpty(values.title) ?? validateMaxLines(values.title, 3),
    date: validateNotEmpty(values.date) ?? validateMaxLines(values.date, 2),
    eventName: validateNotEmpty(values.eventName),
    locationName: validateNotEmpty(values.locationName),
    image:
      values.imageType === "total"
        ? validateNotEmpty(values.image) ??
          validateAspect(values.image!, IMAGE_ASPECT)
        : undefined,
    items: values.items.map((item) => ({
      image:
        values.imageType === "separate"
          ? validateNotEmpty(item.image) ??
            validateAspect(item.image!, IMAGE_ASPECT)
          : undefined,
      comment:
        validateNotEmpty(item.comment) ?? validateMaxLines(item.comment, 2),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    timing: values.timing,
    title: values.title.toUpperCase(),
    date: values.date,
    eventName: values.eventName.toUpperCase(),
    locationName: values.locationName,
    age: values.age,
    theCity: values.theCity,
    imageType: values.imageType,
    ...(values.imageType === "total" && {
      image: values.image,
    }),
    sourceType: values.sourceType,
    ...(values.sourceType === "total" && {
      source: values.source,
      sourceColor: values.sourceColor,
    }),
    blocks: values.items.map((block) => ({
      ...(values.imageType === "separate" && {
        image: block.image,
      }),
      comment: block.comment,
      ...(values.sourceType === "separate" && {
        source: block.source,
        sourceColor: block.sourceColor,
      }),
    })),
  };
}

function M24Informer2Form({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isValid,
    isSubmitting,
  } = formik;

  const buttonProps = {
    isValid,
    isSubmitting,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  const isDesktop = useIsDesktop();

  usePreview(channel, template, values.timing, values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timing"
          options={timingOptions}
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          textareaStyle={{ textTransform: "uppercase" }}
          {...getValidationProps("title", touched, errors)}
          rows={3}
          required
        />
        <Field
          as={TextArea}
          label="Дата"
          name="date"
          hint={`Размер текста: <size=50></size> & Примеры заполнения даты: & "31.12" & "Пройдёт 31.12" & "00:00 01.01" & "С 31.12 ПО 01.01"`}
          {...getValidationProps("date", touched, errors)}
          rows={2}
          required
        />
        <Field
          as={TextField}
          label="Название мероприятия"
          name="eventName"
          textFieldStyle={{ textTransform: "uppercase" }}
          {...getValidationProps("eventName", touched, errors)}
          required
        />
        <Field
          as={TextField}
          label="Адрес мероприятия"
          name="locationName"
          {...getValidationProps("locationName", touched, errors)}
          required
        />
        <Field
          as={Select}
          label="Возрастной ценз"
          name="age"
          options={ageOptions}
        />
        <Checkbox
          label="Маркер Сити"
          checked={values.theCity}
          setChecked={(value) => setFieldValue("theCity", value)}
          style={{ marginLeft: isDesktop ? 210 : 0 }}
        />
        <Field
          as={Select}
          label="Изображение"
          name="imageType"
          options={imageTypeOptions}
        />
        {values.imageType === "total" && (
          <ImageInput
            label="Общее изображение"
            title="Добавить общее изображение"
            imageTypes={IMAGE_TYPES}
            image={values.image}
            aspect={IMAGE_ASPECT}
            updateImage={(image) => {
              setFieldValue("image", image);
            }}
            onBlur={() => {
              setFieldTouched("image");
            }}
            {...getValidationProps("image", touched, errors)}
            required
          />
        )}
        <Field
          as={Select}
          label="Источник"
          name="sourceType"
          options={sourceTypeOptions}
        />
        {values.sourceType === "total" && (
          <>
            <Field
              as={TextField}
              label="Общий источник"
              name="source"
              value={values.source}
              {...getValidationProps("source", touched, errors)}
            />
            <Field
              as={Select}
              label="Цвет источника"
              name="sourceColor"
              options={colorOptions}
            />
          </>
        )}

        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          defaultItemConstructor={createItem}
          canChangeLength
          maxBlocks={3}
        >
          {(item, index, updateItem) => (
            <>
              {values.imageType === "separate" && (
                <ImageInput
                  imageTypes={IMAGE_TYPES}
                  image={item.image}
                  aspect={IMAGE_ASPECT}
                  updateImage={(image) => {
                    updateItem({ image });
                  }}
                  onBlur={() => {
                    setFieldTouched(`items.${index}.image`);
                  }}
                  {...getDeepValidationProps(
                    `items.${index}.image`,
                    touched,
                    errors
                  )}
                  required
                />
              )}
              <Field
                as={TextArea}
                name={`items.${index}.comment`}
                placeholder="Комментарий"
                value={item.comment}
                rows={2}
                {...getDeepValidationProps(
                  `items[${index}].comment`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={Select}
                label="Цвет даты"
                name={`items.${index}.dateColor`}
                options={colorOptions}
              />
              {values.sourceType === "separate" && (
                <>
                  <Field
                    as={TextField}
                    name={`items.${index}.source`}
                    placeholder="Источник"
                    value={item.source}
                    {...getDeepValidationProps(
                      `items[${index}].source`,
                      touched,
                      errors
                    )}
                  />
                  <Field
                    as={Select}
                    label="Цвет источника"
                    name={`items.${index}.sourceColor`}
                    options={colorOptions}
                  />
                </>
              )}
            </>
          )}
        </Blocks>

        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24Informer2Form);
