import DatePeriod from "emg-ui-kit/components/DateTime/DatePeriod";
import Select from "emg-ui-kit/components/Select";
import moment from "moment";
import React, { ChangeEvent, useCallback, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  setEndDate,
  setRootFilter,
  setStartDate,
} from "../../../redux/filters/filters.reducer";
import { RootState, AppDispatch } from "../../../store";

export default function Period() {
  const filterState = useSelector((store: RootState) => store.filter);

  const dispatch = useDispatch<AppDispatch>();

  const handleChangePeriodPreset = useCallback(
    (event: ChangeEvent<{ value: string }>) => {
      const nextPreset = event.target.value;
      let date;
      switch (nextPreset) {
        case "week":
          date = new Date(moment().subtract(1, "weeks").toDate());
          break;
        case "month":
          date = new Date(moment().subtract(1, "months").toDate());
          break;
        case "year":
          date = new Date(moment().subtract(1, "years").toDate());
          break;
      }
      dispatch(setRootFilter({ periodPreset: nextPreset }));
      dispatch(setStartDate({ value: date }));
      dispatch(setEndDate({ value: undefined }));
    },
    [dispatch]
  );

  useEffect(() => {
    if (!filterState.startDate && !filterState.endDate) {
      dispatch(setRootFilter({ periodPreset: "all" }));
    }
  }, [filterState.startDate, filterState.endDate, dispatch]);

  const periodPresets = [
    {
      id: "all",
      name: "За весь период",
    },
    {
      id: "custom",
      name: "Произвольный",
    },
    {
      id: "week",
      name: "Предыдущая неделя",
    },
    {
      id: "month",
      name: "Предыдущий месяц",
    },
    {
      id: "year",
      name: "Предыдущий год",
    },
  ];
  return (
    <>
      <Select
        value={filterState.periodPreset}
        placeholder="Выбрать период"
        options={periodPresets}
        onChange={handleChangePeriodPreset}
      />
      <DatePeriod
        startDate={filterState.startDate}
        endDate={filterState.endDate}
        name="date-period"
        handleStartDateChange={(value) => {
          dispatch(setStartDate({ value }));
          value && dispatch(setRootFilter({ periodPreset: "custom" }));
        }}
        handleEndDateChange={(value) => {
          dispatch(setEndDate({ value }));
          value && dispatch(setRootFilter({ periodPreset: "custom" }));
        }}
      />
    </>
  );
}
