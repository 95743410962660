import React from "react";

import { getRegionNameById } from "../../../../../order/util";
import { InfoRow } from "../InfoComponents";

export function PrecipitationMap({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      <InfoRow title="Ручная сборка" value={data.manual ? "Да" : "Нет"} />
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <InfoRow title="Регион" value={getRegionNameById(block.region)} />
          <InfoRow title="Тезис" value={block.thesis} />
        </details>
      ))}
    </>
  );
}
