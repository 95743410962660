export const checkDataObjIsEmpty = (data: Record<string, number>) => {
  return Object.keys(data).every((key) => {
    return data[key] === 0;
  });
};

export function perсentData(data: Record<string, number>) {
  const newObject: Record<string, number> = {};
  let amount = 0;
  Object.values(data).forEach((value) => {
    amount += value;
  });
  Object.keys(data).forEach((key) => {
    const percentage = Number(((data[key] / amount) * 100).toFixed(1));
    newObject[key] = percentage;
  });
  return newObject;
}

