import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";
import { designOptions, formatOptions } from "../constants";

export function Quote({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Оформление" value={designOptions[data.design]} />
      <InfoRow title="Формат" value={formatOptions[data.format]} />
      <InfoRow title="Имя" value={data.name} />
      <InfoRow title="Должность" value={data.position} />
      {data.audio && (
        <InfoRow
          title="Аудиодорожка"
          value={<a href={data.audio}>Аудиофайл</a>}
        />
      )}
      {data.image && <ImageRow title="Изображение" src={data.image} />}
      {data.equalTimings && (
        <InfoRow title="Общий хронометраж (сек.)" value={data.totalTiming} />
      )}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Слайд №{idx + 1}</summary>
          <div style={{ marginLeft: 15 }}>
            <div style={{ marginBottom: 10 }} />
            <InfoRow title="Цитата" value={block.quote} />
            {!data.equalTimings && (
              <InfoRow title="Хронометраж слайда (сек.)" value={block.timing} />
            )}
          </div>
          <hr />
        </details>
      ))}
    </>
  );
}
