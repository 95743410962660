import Select from "emg-ui-kit/components/Select";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  getValidationProps,
  pick,
  CLIP_NAME_REGEXP,
  validateIncorrectFormat,
  removeEmptyProps,
  validatePositive,
  validateNotEmpty,
} from "../util";
import { designOptionsСhurch } from "./constants";

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    design: (initialFormData?.design ?? "standard") as string,
    geo: (initialFormData?.geo ?? "") as string,
    timing: (initialFormData?.timing ?? 8) as number,
  };
}

function prepareData(values: ReturnType<typeof getInitialValues>) {
  return {
    ...pick(values, "clipName", "design", "geo", "timing"),
  };
}

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    geo: validateNotEmpty(values.geo),
    timing: validatePositive(values.timing),
  };
  return removeEmptyProps(errors);
}

function GeoForm({
  initialFormData,
  onSubmit,
  channel,
  template,
  onSaveDraft,
  onDeleteDraft,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const { errors, touched, isSubmitting, values } = formik;

  usePreview(channel, template, values.timing, values, prepareData);

  const buttonProps = {
    isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          label="Оформление"
          name="design"
          options={designOptionsСhurch}
        />
        <Field
          as={TextField}
          label="Гео"
          name="geo"
          {...getValidationProps("geo", touched, errors)}
          required
        />
        <Field
          as={TextField}
          type="number"
          label="Хронометраж (сек.)"
          name="timing"
          {...getValidationProps("timing", touched, errors)}
        />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(GeoForm);
