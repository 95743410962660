import Button from "emg-ui-kit/components/Button";
import IconButton from "emg-ui-kit/components/IconButton";
import PositionSelect from "emg-ui-kit/components/PositionSelect";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import Title from "emg-ui-kit/components/Title";
import { nanoid } from "nanoid";
import React, { ChangeEvent, useCallback, useState } from "react";

import styles from "./TheCity.module.css";
import { MAP_OFFSET_FIELDS } from "./constants";
import { LocationPoint, AddressOrient } from "./types";
import useGeoPoint from "./useGeoPoint";

function PointsControl({
  points,
  setPoints,
  onDeleteId,
}: {
  points: LocationPoint[];
  setPoints: (value: React.SetStateAction<LocationPoint[]>) => void;
  onDeleteId: (id?: string) => void;
}) {
  const [newPointData, setNewPointData] = useState<{
    newPointAddress: string;
    newPointAddressOrient: AddressOrient;
  }>({
    newPointAddress: "Правды 24",
    newPointAddressOrient: "rb",
  });

  const handleChangeNewPoint = (
    e: React.ChangeEvent<{
      name: string;
      value: string;
      type: string;
    }>
  ) => {
    const { name, value } = e.target;
    setNewPointData((prev) => ({ ...prev, [name]: value }));
  };

  const handleChangePoint = (
    e: ChangeEvent<{ name: string; value: string; type: string; id: string }>
  ) => {
    const { name, value, id } = e.target;
    setPoints((prevPoints) => {
      const pointIndex = prevPoints.findIndex((point) => point.id === id);

      const updatedPoint = {
        ...prevPoints[pointIndex],
        [name]: value,
      };

      const updatedPoints = [...prevPoints];
      updatedPoints[pointIndex] = updatedPoint;

      return updatedPoints;
    });
  };

  const handleChangePosition = (id: string, position: string) => {
    const name: string = "align";
    setPoints((prevPoints) => {
      const pointIndex = prevPoints.findIndex((point) => point.id === id);

      const updatedPoint = {
        ...prevPoints[pointIndex],
        [name]: position,
      };

      const updatedPoints = [...prevPoints];
      updatedPoints[pointIndex] = updatedPoint;

      return updatedPoints;
    });
  };

  const { isFetching, fetchGeoPoint } = useGeoPoint(
    newPointData.newPointAddress
  );

  const addPoint = useCallback(async () => {
    await fetchGeoPoint()
      .then((data) => {
        if (data?.geocode_address === "Москва, Россия") {
          alert("Проверьте правильность адреса");
          return;
        }
        if (data) {
          const pos = {
            x: Math.round(
              data?.geometry?.pixel_coordinates.x + MAP_OFFSET_FIELDS.x / 2
            ),
            y: Math.round(
              -data?.geometry?.pixel_coordinates.y + MAP_OFFSET_FIELDS.y / 2
            ),
          };

          setPoints((prev) => [
            ...prev,
            {
              id: nanoid(),
              pos,
              text: newPointData.newPointAddress,
              align: newPointData.newPointAddressOrient,
            },
          ]);
          setNewPointData((prev) => ({
            newPointAddress: "",
            newPointAddressOrient: "rb",
          }));
        }
      })
      .catch((err) => {
        alert(err.message);
      });
  }, [fetchGeoPoint, setPoints, newPointData]);

  const handleDeletePoint = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent> & {
      target: { id?: string };
    }
  ) => {
    onDeleteId(e.target.id);
    const filteredPoints = points.filter((p) => p.id !== e.target.id);
    setPoints(filteredPoints);
  };

  const isMaxPoints = points.length >= 3;
  const isValid = newPointData.newPointAddress.length > 0 && !isMaxPoints;

  return (
    <div className={styles.container}>
      <Title text="Добавить точку" style={{ fontSize: "1.3rem" }} />

      <div>
        <div className={styles.newAddressTextBlock}>
          <TextField
            label="Адрес точки"
            onChange={handleChangeNewPoint}
            value={newPointData.newPointAddress}
            name="newPointAddress"
            disabled={isMaxPoints}
            placeholder={isMaxPoints ? "Не более 3 точек на карте" : ""}
          />
        </div>
        <div className={styles.newAddressAddButtonBlock}>
          <Button
            title="Добавить"
            onClick={addPoint}
            status={
              isValid ? (!isFetching ? "enabled" : "loading") : "disabled"
            }
          />
        </div>
      </div>

      {points.length > 0 && (
        <table className={styles.controlsTable}>
          <thead>
            <tr>
              <th>Название точки</th>
              <th>Положение подписи</th>
            </tr>
          </thead>
          <tbody>
            {points.map((point) => (
              <tr key={point.id}>
                <td>
                  <TextArea
                    id={point.id}
                    rows={1}
                    name="text"
                    value={point.text}
                    onChange={handleChangePoint}
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                </td>
                <td>
                  <PositionSelect
                    id={point.id}
                    value={point.align}
                    onChange={handleChangePosition}
                  />
                </td>
                <td>
                  <IconButton
                    id={point.id}
                    design="trash-blue"
                    onClick={handleDeletePoint}
                  />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
}

export default React.memo(PointsControl);
