import React from "react";
import { useHistory } from "react-router-dom";
import styles from "./BackButton.module.css";

export default function BackButton({ to }: { to?: string }) {
  const history = useHistory();

  const handleGoBack = () => {
    to ? history.push(to) : history.goBack();
  };

  return (
    <button className={styles.button} onClick={handleGoBack}>
      Назад
    </button>
  );
}
