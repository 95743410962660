export const twoLinesColors = ["transparent", "#3C62FF"];
export const thirdLinesColors = [
  "transparent",
  "#3C62FF",
  "#FD511D",
  "#A500FC",
];

export const threeLinesColors = [
  "transparent",
  "#ffffff",
  "#252525",
  "#3e63fe",
];

export const dashBoardColors = [
  "rgba(48, 205, 91, 0.9)",
  "rgba(255, 0, 57, 0.9)",
  "rgba(17, 146, 232, 0.9)",
  "rgba(255, 134, 0, 0.9)",
  "rgba(36, 75, 213, 0.9)",
  "rgba(255, 54, 251, 0.9)",
  "rgba(255, 241, 87, 0.9)",
  "rgba(12, 23, 69, 0.9)",
];

export const dashBoardLightColors = [
  "rgba(48, 205, 91, 0.7)",
  "rgba(255, 0, 57, 0.7)",
  "rgba(17, 146, 232, 0.7)",
  "rgba(255, 134, 0, 0.7)",
  "rgba(36, 75, 213, 0.7)",
  "rgba(255, 54, 251, 0.7)",
  "rgba(255, 241, 87, 0.7)",
  "rgba(12, 23, 69, 0.7)",
];

export const timeshiftChoice = {
  first: "Первые 5 секунд",
  second: "Вторые 5 секунд",
};

export const windDirections: Record<string, string> = {
  N: "Северный ветер",
  NE: "Северо-восточный ветер",
  E: "Восточный ветер",
  SE: "Юго-восточный ветер",
  S: "Южный ветер",
  SW: "Юго-западный ветер",
  W: "Западный ветер",
  NW: "Северо-западный ветер",
};

export const icons: Record<string, Record<string, string>> = {
  wind: {
    Calm: "Штиль",
    ...windDirections,
  },
  weather: {
    Sun: "Ясно (только для дня)",
    PartlyCloudly: "Переменная облачность (только для дня)",
    Clouds: "Облачно (пасмурно)",
    Rain: "Дождь",
    Hail: "Град",
    Storm: "Гроза",
    SnowRain: "Снег с дождем",
    Snow: "Снег",
    Fog: "Туман",
    Dew: "Роса",
    IceCrust: "Гололедица",
    UV: "UV-лучи",
    Pollen: "Пыльца",
    Magnetic: "Геомагнитные бури",
    O2: "Чистый воздух",
    Moon: "Переменная облачность (ночь)",
  },
};

export const iconTypes = {
  wind: "Ветер",
  weather: "Погода",
};

export const sunriseSunset: Record<string, string> = {
  sunrise: "Восход",
  sunset: "Заход",
};

export const iconOptions = Object.entries(icons).map(([label, options]) => {
  return {
    label: iconTypes[label as "wind" | "weather"],
    options: Object.entries(options).map(([id, name]) => ({
      id: `${label}_${id}`,
      name,
    })),
  };
});

export const atmoNumberBlockTypes: Record<string, string> = {
  text: "Текстовый блок",
  feelsLike: "Ощущается как",
  wind: "Ветер",
  pressure: "Давление",
  forecast: "Прогноз",
};

export const atmoNumberBlockOptions = Object.entries(atmoNumberBlockTypes).map(
  ([id, name]) => ({
    id,
    name,
  })
);

export const atmoInfoBlockTypes: Record<string, string> = {
  "two-lines": "Две строки",
  "three-lines": "Три строки",
  infographics: "Инфографика",
  "sunrise-sunset": "Восход/заход",
};

export const atmoInfoBlockOptions = Object.entries(atmoInfoBlockTypes).map(
  ([id, name]) => ({
    id,
    name,
  })
);

export const timeOfDayTypes: Record<string, string> = {
  D: "День",
  E: "Вечер",
  M: "Морозный день",
  T: "Теплый день",
  N: "Ночь",
  MD: "Морозный день",
  ME: "Морозный вечер",
  TD: "Теплый день",
  TE: "Теплый вечер",
  day: "День",
  evening: "Вечер",
  night: "Ночь",
};

export const atmoWeatherTypes: Record<string, string> = {
  CLR: "Ясно",
  CLD: "Облачно",
  OVC: "Пасмурно",
  RN: "Дождь",
  HRN: "Ливень",
  TH: "Гроза",
  DTH: "Сухая гроза",
  RNB: "Радуга",
  FOG: "Туман",
  SN: "Снег",
  HSN: "Метель",
  RNSN: "Снег с дождем",
};

export const interactiveVariants: Record<string, string> = {
  wind: "Карты с направлением ветра",
  gismeteo: "Гисметео",
  cyclone: "Циклоны-антициклоны",
  fronts: "Фронты ЕТР",
  fronts_cfo: "Фронты ЦФО",
  fallout_etr: "Осадки ЕТР",
  fallout_cfo: "Осадки ЦФО",
  thermo: "Термокарты",
};
