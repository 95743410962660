import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React from "react";

import LegendInput from "../../common/LegendInput";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import RangeInput from "../../common/RangeInput";
import { FormProps } from "../../common/models";
import { chartColors } from "../../common/texts";
import { convertToSelectOptions, useIsDesktop } from "../../common/utils";
import yup from "../../common/yup";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  getValidationProps,
  MAX_TIMING,
  getDeepValidationProps,
  CLIP_NAME_REGEXP,
  pick,
  removeTouched,
} from "../util";
import { THEMES, THEMES_FULL } from "./constants";

const MAX_VALUE_SIZE = 115;
const MAX_LEGEND_SIZE = 100;

type Item = {
  legend: string;
  value: string;
  color: string;
};

function createItem(index: number): Item {
  const colorIndex = index % chartColors.length;
  return {
    value: "",
    color: chartColors[colorIndex],
    legend: "",
  };
}

function initItems(count = 1) {
  return Array.from(Array(count), (_, idx) => createItem(idx));
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    title: (initialFormData?.title ?? "") as string,
    subtitle: (initialFormData?.subtitle ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    timing: (initialFormData?.timing ?? 30) as number,
    legendSize: (initialFormData?.legendSize ?? 42) as number,
    valueSize: (initialFormData?.valueSize ?? 91) as number,
    units: (initialFormData?.units ?? "") as string,
    rotation: (initialFormData?.rotation ?? 0) as number,
    hasArrows: (initialFormData?.hasArrows ?? false) as boolean,
    items: (initialFormData?.blocks ?? initItems()) as Item[],
  };
}

const validationSchema = yup.object().shape({
  name: yup
    .string()
    .matches(CLIP_NAME_REGEXP, "Может содержать только буквы и цифры"),
  title: yup.string().required(),
  theme: yup.string().required(),
  timing: yup.number().positive().max(MAX_TIMING),
  legendSize: yup.number().positive().max(MAX_LEGEND_SIZE),
  valueSize: yup.number().positive().max(MAX_VALUE_SIZE),
  units: yup.string(),
  items: yup.array().of(
    yup.object().shape({
      legend: yup.string().required(),
      value: yup
        .string()
        .required()
        .matches(/^-?\d+(\.\d+)?$/),
      color: yup.string().required(),
    }),
  ),
});

function prepareData(values: ReturnType<typeof getInitialValues>) {
  return {
    theme: THEMES_FULL[values.theme],
    ...pick(
      values,
      "name",
      "title",
      "units",
      "subtitle",
      "source",
      "timing",
      "legendSize",
      "valueSize",
      "rotation",
      "hasArrows",
    ),
    blocks: values.items.map((item) => ({
      ...item,
      value: isNaN(+item.value) ? 0 : +item.value,
    })),
  };
}

function ChartPieForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    validationSchema,
    onSubmit: (values) => onSubmit(prepareData(values)),
  });

  usePreview(
    channel,
    template,
    formik.values.timing,
    formik.values,
    prepareData,
  );

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isSubmitting: formik.isSubmitting,
    isValid: formik.isValid,
    prepareData,
    values: formik.values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...getValidationProps("name", formik.touched, formik.errors)}
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          {...getValidationProps("theme", formik.touched, formik.errors)}
          required
        />
        <Field
          as={TextArea}
          label="Заголовок"
          name="title"
          rows={2}
          required
          {...getValidationProps("title", formik.touched, formik.errors)}
        />
        <Field as={TextField} label="Подзаголовок" name="subtitle" />
        <Field as={TextField} label="Источник" name="source" />
        <Field
          as={TextField}
          label="Размер подписей"
          name="legendSize"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("legendSize", +event.target.value)
          }
          {...getValidationProps("legendSize", formik.touched, formik.errors)}
          required
        />
        <Field
          as={TextField}
          label="Размер значений"
          name="valueSize"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("valueSize", +event.target.value)
          }
          {...getValidationProps("valueSize", formik.touched, formik.errors)}
          required
        />
        <Field
          as={TextField}
          label="Единица измерения"
          name="units"
          {...getValidationProps("units", formik.touched, formik.errors)}
        />
        <Field
          as={TextField}
          label="Хронометраж (сек)"
          name="timing"
          type="number"
          onChange={(event: any) =>
            formik.setFieldValue("timing", +event.target.value)
          }
          {...getValidationProps("timing", formik.touched, formik.errors)}
          required
        />
        <RangeInput
          label="Вращение по часовой стрелке"
          name="rotation"
          value={formik.values.rotation}
          onChange={(value) => formik.setFieldValue("rotation", value)}
          min={-360}
          max={360}
        />
        <Checkbox
          label="Включить стрелки"
          checked={formik.values.hasArrows}
          setChecked={(value) => formik.setFieldValue("hasArrows", value)}
          style={{ marginLeft: isDesktop ? 210 : 0 }}
        />
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, formik.touched, formik.setTouched);
          }}
          defaultItemConstructor={createItem}
          blockTitle="Элемент"
          canChangeLength
          maxBlocks={6}
        >
          {(item, index) => (
            <>
              <Field
                as={TextField}
                placeholder="Значение"
                name={`items[${index}].value`}
                onChange={(event: any) => {
                  const value = event.target.value;
                  if (/[^\-.0-9]/.test(value)) return;
                  const key = `items[${index}].value`;
                  formik.setFieldValue(key, value);
                }}
                {...getDeepValidationProps(
                  `items[${index}].value`,
                  formik.touched,
                  formik.errors,
                )}
                required
              />
              <LegendInput
                placeholder="Легенда"
                color={item.color}
                title={item.legend}
                colors={chartColors}
                updateColor={(color) => {
                  formik.setFieldTouched(`items[${index}].color`);
                  formik.setFieldValue(`items[${index}].color`, color);
                }}
                updateTitle={(title) => {
                  formik.setFieldValue(`items[${index}].legend`, title);
                }}
                onBlur={() => formik.setFieldTouched(`items[${index}].legend`)}
                {...getDeepValidationProps(
                  `items[${index}].legend`,
                  formik.touched,
                  formik.errors,
                )}
                maxLines={2}
                required
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(ChartPieForm);
