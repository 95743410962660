import Button from "emg-ui-kit/components/Button";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";

import FormWarning from "../order/FormWarning";
import { selectUserInfo } from "../redux/auth/selectors";
import { RootState } from "../store";
import FlexContainer from "./FlexContainer";
import { useIsDesktop } from "./utils";

export type ButtonStatus = "loading" | "enabled" | "disabled";

interface OrderSavingButtonsProps {
  isSubmitting?: boolean;
  isValid: boolean;
  prepareData?: (values: any, formParams?: any) => {};
  formParams?: any;
  status?: ButtonStatus;
  values: any;
  onSaveDraft: (values: any) => Promise<void>;
  onDeleteDraft?: () => Promise<void>;
}

export default function OrderSavingButtons({
  isSubmitting,
  isValid,
  prepareData,
  values,
  onSaveDraft,
  onDeleteDraft,
  status,
  formParams,
}: OrderSavingButtonsProps) {
  const isDesktop = useIsDesktop();

  const buttonStatus: ButtonStatus = !status
    ? isSubmitting
      ? "loading"
      : "enabled"
    : status;

  const [isSaveSubmitting, setIsSaveSubmitting] = useState(false);
  const [isDeleteSubmitting, setIsDeleteSubmitting] = useState(false);
  let data: any;
  if (prepareData && formParams) {
    data = { ...prepareData(values, formParams) };
  } else if (prepareData) {
    data = { ...prepareData(values) };
  } else {
    data = { ...values };
  }

  const userInfo = useSelector(selectUserInfo);

  const { isFormValid: isBitrixFormValid } = useSelector((state: RootState) => {
    return state.bitrix;
  });

  const isFormValid = userInfo?.type === "client" ? isBitrixFormValid : true;

  const handleSubmitDraft = useCallback(() => {
    setIsSaveSubmitting(true);
    onSaveDraft(data).catch(() => {
      setIsSaveSubmitting(false);
    });
  }, [data, onSaveDraft]);

  const handleDeleteDraft = useCallback(() => {
    let del;
    setIsDeleteSubmitting(true);
    if (onDeleteDraft) {
      del = onDeleteDraft();
      if (!del) {
        setIsDeleteSubmitting(false);
      }
    }
  }, [onDeleteDraft]);

  const buttonStyle: React.CSSProperties = {
    marginBottom: 10,
    width: 40,
    marginLeft: isDesktop ? (onDeleteDraft ? 20 : 20) : "auto",
    marginRight: isDesktop ? 20 : "auto",
    paddingLeft: 10,
    paddingRight: 10,
  };
  return (
    <>
      <FlexContainer
        style={{
          flexDirection: isDesktop ? "row" : "column",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <Button
          title="Отправить"
          type="submit"
          status={isFormValid ? buttonStatus : "disabled"}
          style={buttonStyle}
        />
        <Button
          type="button"
          title="Сохранить черновик"
          onClick={() => {
            handleSubmitDraft();
          }}
          status={isSaveSubmitting ? "loading" : "enabled"}
          style={buttonStyle}
        />
        {onDeleteDraft && (
          <Button
            title="Удалить черновик"
            onClick={() => {
              handleDeleteDraft();
            }}
            status={isDeleteSubmitting ? "loading" : "enabled"}
            style={buttonStyle}
            color={"red"}
          />
        )}
      </FlexContainer>
      {(!isValid || !isFormValid) && <FormWarning />}
    </>
  );
}
