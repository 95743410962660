export const sourceType: Record<string, string> = {
  total: "Общий",
  separate: "Для каждого слайда",
};

export const imageType: Record<string, string> = {
  total: "Общее",
  separate: "Для каждого слайда",
};

export const color: Record<string, string> = {
  gray: "Серый",
  white: "Белый",
};

export const titleColor: Record<string, string> = {
  black: "Черный",
  white: "Белый",
};

export const designOptions: Record<string, string> = {
  news: "Новости",
  specrep: "Спецреп",
  aurora: "Аврора",
};

export const formatOptions: Record<string, string> = {
  default: "По умолчанию",
  premiere: "Premiere",
};
