import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { FormikProvider, useFormik, Field } from "formik";
import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import { sources } from "../../common/texts";
import { convertToSelectOptions, useIsDesktop } from "../../common/utils";
import { getPersonsThunk } from "../../redux/persons";
import { selectVipPersons } from "../../redux/persons/selectors";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  MAX_TIMING,
  pick,
  removeEmptyProps,
  validateIncorrectFormat,
  validateMax,
  validateMaxChars,
  validateNotEmpty,
  validateNumber,
  validatePositive,
  validateTimingSum,
  ValidationPropsUtils,
  removeTouched,
} from "../util";
import { THEMES, THEMES_FULL } from "./constants";

type Item = {
  text: string;
  timing: number | string;
};

type Values = ReturnType<typeof getInitialValues>;

function createItem(): Item {
  return { text: "", timing: 5 };
}

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    name: (initialFormData?.name ?? "") as string,
    person: (initialFormData?.person ?? "") as string,
    photoUrl: "",
    title: (initialFormData?.title ?? "") as string,
    theme: (initialFormData?.theme?.id in THEMES
      ? initialFormData?.theme?.id
      : "") as string,
    doc: (initialFormData?.document ?? "") as string,
    source: (initialFormData?.source ?? "") as string,
    emblem: (initialFormData?.emblem ?? false) as boolean,
    timing: (initialFormData?.equalTimings
      ? initialFormData.totalTiming
      : 30) as number | string,
    timingType: (initialFormData?.equalTimings === false
      ? "separate"
      : "total") as string,
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 0 };
    }) ?? initItems()) as Item[],
  };
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? +values.timing
    : values.items.reduce((acc, item) => acc + +item.timing, 0);
}

function validate(values: Values) {
  const quoteMaxChars =
    (7 + Number(!values.title) * 3 + Number(!values.source)) * 46;

  const errors = {
    name: values.name
      ? validateIncorrectFormat(values.name, CLIP_NAME_REGEXP)
      : undefined,
    theme: validateNotEmpty(values.theme),
    person: validateNotEmpty(values.person),
    timing:
      values.timingType === "total"
        ? validateNumber(values.timing) ??
          validatePositive(+values.timing) ??
          validateMax(+values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      text:
        validateNotEmpty(item.text) ??
        validateMaxChars(item.text, quoteMaxChars),
      timing:
        values.timingType === "separate"
          ? validateNumber(item.timing) ??
            validatePositive(+item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    ...pick(values, "name", "title", "source", "emblem", "person", "photoUrl"),
    theme: THEMES_FULL[values.theme],
    document: values.doc,
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items.map((block) => ({
      text: block.text,
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
  };
}

function VipQuoteForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  channel,
  template,
}: FormProps) {
  const persons = useSelector(selectVipPersons);
  const personsOptions = useMemo(
    () => persons.map(({ id, name }) => ({ id, name })),
    [persons],
  );
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getPersonsThunk("photoVip"));
  }, [dispatch]);

  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });

  useEffect(() => {
    if (persons && formik.values.person) {
      const person = persons.find((p) => p.id === formik.values.person);
      if (person) {
        formik.setFieldValue("photoUrl", person.photoVip);
      } else {
        formik.setFieldValue("photoUrl", "");
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formik.values.person, formik.setFieldValue, persons]);

  usePreview(
    channel,
    template,
    +formik.values.timing,
    formik.values,
    prepareData,
  );

  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );

  const isDesktop = useIsDesktop();

  const buttonProps = {
    isValid: formik.isValid,
    isSubmitting: formik.isSubmitting,
    prepareData,
    values: formik.values,
    onSaveDraft,
  };
  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="name"
          {...validationUtils.getProps("name")}
        />
        <Field as={TextArea} label="Заголовок" name="title" rows={2} />
        <Field
          as={Select}
          name="person"
          label="Фото"
          options={personsOptions}
          {...validationUtils.getProps("person")}
          required
        />
        <Field
          as={Select}
          name="theme"
          label="Тема оформления"
          options={convertToSelectOptions(THEMES)}
          {...validationUtils.getProps("theme")}
          required
        />
        <Field as={TextField} label="Документ" name="doc" />
        <Field
          as={TextField}
          label="Источник"
          name="source"
          dataList={sources}
        />
        <Checkbox
          checked={formik.values.emblem}
          setChecked={(value) => formik.setFieldValue("emblem", value)}
          label="Герб"
          style={{ marginLeft: isDesktop ? 210 : 0 }}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={[
            { id: "total", name: "Общий" },
            { id: "separate", name: "Для каждого слайда" },
          ]}
        />
        {formik.values.timingType === "total" && (
          <Field
            as={TextField}
            label="Общий хронометраж (сек.)"
            name="timing"
            type="number"
            {...validationUtils.getProps("timing")}
            required
          />
        )}
        <Blocks
          items={formik.values.items}
          updateItems={(items) => formik.setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, formik.touched, formik.setTouched);
          }}
          canChangeLength
          defaultItemConstructor={createItem}
        >
          {(item, index) => (
            <>
              {formik.values.timingType === "separate" && (
                <Field
                  as={TextField}
                  name={`items.${index}.timing`}
                  type="number"
                  {...validationUtils.getDeepProps(`items.${index}.timing`)}
                  label="Хронометраж (сек.)"
                  labelStyle={{ maxWidth: 180 }}
                />
              )}
              <Field
                as={TextArea}
                placeholder="Цитата"
                required
                name={`items.${index}.text`}
                {...validationUtils.getDeepProps(`items.${index}.text`)}
              />
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(VipQuoteForm);
