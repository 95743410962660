import React from "react";

import { FormProps } from "../common/models";
import AtmosphereForm from "./atmosphere/AtmosphereForm";
import AtmosphereInfoForm from "./atmosphere/AtmosphereInfoForm";
import AfficheForm from "./m24/AfficheForm";
import D24InformerForm from "./m24/D24InformerForm";
import D24SlideShowForm from "./m24/D24SlideShowForm";
import ImportantHourForm from "./m24/ImportantHourForm";
import InformerForm from "./m24/InformerForm";
import M24ColumnsForm from "./m24/M24ColumnsForm";
import M24Informer2 from "./m24/M24Informer2Form";
import M24InformerCityForm from "./m24/M24InformerCityForm";
import M24InformerTop3Form from "./m24/M24InformerTop3Form";
import M24InformerTop5Form from "./m24/M24InformerTop5Form";
import M24PieChartForm from "./m24/M24PieChartForm";
import M24QuoteSobyaninForm from "./m24/M24QuoteSobyaninForm";
import M24TableForm from "./m24/M24TableForm";
import MemeForm from "./m24/MemeForm";
import MezhpromForm from "./m24/MezhpromForm";
import NumberForm from "./m24/NumberForm";
import QuoteForm from "./m24/QuoteForm";
import QuoteSpecrepForm from "./m24/QuoteSpecrepForm";
import ReferenceForm from "./m24/ReferenceForm";
import SlideShowForm from "./m24/SlideShowForm";
import WheezeForm from "./m24/WheezeForm";
import WheezeSpecrepForm from "./m24/WheezeSpecrepForm";
import AeMapForm from "./map/AeMapForm";
import TheCityForm from "./map/TheCityForm";
import ChartColumnsForm from "./r24/ChartColumnsForm";
import ChartLinearDoubleForm from "./r24/ChartLinearDoubleForm";
import ChartLinearForm from "./r24/ChartLinearForm";
import ChartPieForm from "./r24/ChartPieForm";
import ChartPieLarge from "./r24/ChartPieLargeForm";
import ChartRowsForm from "./r24/ChartRowsForm";
import ChartTableForm from "./r24/ChartTableForm";
import GeoForm from "./r24/GeoForm";
import Miniwheeze from "./r24/Miniwheeze";
import PersonQuoteForm from "./r24/PersonQuoteForm";
import PrecipitationMapForm from "./r24/PrecipitationMapForm";
import RSlideShowForm from "./r24/RSlideShowForm";
import Site3dForm from "./r24/Site3dForm";
import SiteQuoteForm from "./r24/SiteQuoteForm";
import StatementForm from "./r24/StatementForm";
import TiterForm from "./r24/TiterForm";
import VipQuoteForm from "./r24/VipQuoteForm";

export default function Subform(props: FormProps) {
  switch (props.template) {
    case "geo":
      return <GeoForm {...props} />;
    case "titer":
      return <TiterForm {...props} />;
    case "important_hour":
      return <ImportantHourForm {...props} />;
    case "affiche":
      return <AfficheForm {...props} />;
    case "reference":
      return <ReferenceForm {...props} />;
    case "informer":
      return <InformerForm {...props} />;
    case "mezhprom":
      return <MezhpromForm {...props} />;
    case "wheeze":
      return <WheezeForm {...props} />;
    case "wheeze_specrep":
      return <WheezeSpecrepForm {...props} />;
    case "quote":
      return <QuoteForm {...props} />;
    case "quote_specrep":
      return <QuoteSpecrepForm {...props} />;
    case "slideshow":
      return <SlideShowForm {...props} />;
    case "atmosphere":
      return <AtmosphereForm {...props} />;
    case "atmosphere_info":
      return <AtmosphereInfoForm {...props} />;
    case "meme":
      return <MemeForm {...props} />;
    case "number":
      return <NumberForm {...props} />;
    case "rslideshow":
      return <RSlideShowForm {...props} />;
    case "vipquote":
      return <VipQuoteForm {...props} />;
    case "statement":
      return <StatementForm {...props} />;
    case "miniwheeze":
      return <Miniwheeze {...props} />;
    case "chart_linear_double":
      return <ChartLinearDoubleForm {...props} />;
    case "chart_linear":
      return <ChartLinearForm {...props} />;
    case "chart_pie_large":
      return <ChartPieLarge {...props} />;
    case "chart_pie":
      return <ChartPieForm {...props} />;
    case "chart_columns":
      return <ChartColumnsForm {...props} />;
    case "chart_rows":
      return <ChartRowsForm {...props} />;
    case "chart_table":
      return <ChartTableForm {...props} />;
    case "person_quote":
      return <PersonQuoteForm {...props} />;
    case "site_quote":
      return <SiteQuoteForm {...props} />;
    case "site3d":
      return <Site3dForm {...props} />;
    case "aemap":
      return <AeMapForm {...props} />;
    case "precipitation_map":
      return <PrecipitationMapForm {...props} />;
    case "m24piechart":
      return <M24PieChartForm {...props} />;
    case "d24informer":
      return <D24InformerForm {...props} />;
    case "d24slideshow":
      return <D24SlideShowForm {...props} />;
    case "m24informer2":
      return <M24Informer2 {...props} />;
    case "m24informer_top_3":
      return <M24InformerTop3Form {...props} />;
    case "m24informer_top_5":
      return <M24InformerTop5Form {...props} />;
    case "m24columns":
      return <M24ColumnsForm {...props} />;
    case "the-city":
      return <TheCityForm {...props} />;
    case "m24quote_sobyanin":
      return <M24QuoteSobyaninForm {...props} />;
    case "m24informer_city":
      return <M24InformerCityForm {...props} />;
    case "m24table":
      return <M24TableForm {...props} />;
    default:
      return null;
  }
}
