import React from "react";

import { useIsDesktop } from "../../../common/utils";

interface Props {
  totalTiming: number;
  isTotalTimingCorrect: boolean;
}

export default function TotalTimingInfo({
  totalTiming,
  isTotalTimingCorrect,
}: Props) {
  const isDesktop = useIsDesktop();
  return (
    <div style={{ ...(isDesktop && { marginLeft: 210 }) }}>
      <span>Общий хронометраж: </span>
      <span
        style={
          isTotalTimingCorrect
            ? { color: "var(--green)" }
            : { color: "var(--red)" }
        }
      >
        {totalTiming} сек.
      </span>
      {!isTotalTimingCorrect && (
        <div style={{ color: "var(--red)", fontSize: 16 }}>
          Общий хронометраж должен быть 25 сек
        </div>
      )}
    </div>
  );
}
