import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput, { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getDeepValidationProps,
  getValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
  removeEmptyProps,
  validateAspect,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  validateTimingSum,
  removeTouched,
} from "../util";
import { afficheDesignOptions } from "./constants";

const IMAGE_ASPECT = 16 / 9;
function createItem(): Item {
  return {
    titer: "",
    source: "",
    timing: 5,
    image: undefined,
  };
}

const selectOptions = [
  { id: "total", name: "Общий" },
  { id: "separate", name: "Для каждого слайда" },
];

function initItems(count = 1) {
  return Array.from(Array(count), createItem);
}

type Item = {
  titer: string;
  source: string;
  timing: number;
  image?: FormImage;
};

type Values = ReturnType<typeof getInitialValues>;

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : values.items.reduce((acc, item) => acc + item.timing, 0);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    title: (initialFormData?.title ?? "") as string,
    design: (initialFormData?.design ?? "cinema") as
      | "cinema"
      | "theatre"
      | "exhibition",
    items: (initialFormData?.blocks?.map((block: any) => {
      if (!block.timing) {
        block = { ...block, timing: 5 };
      }
      if (!block.image) {
        block = { ...block, image: undefined };
      }
      return block;
    }) ?? initItems(3)) as Item[],
    timingType: (initialFormData?.equalTimings
      ? "total"
      : "separate") as string,
    timing: (initialFormData?.totalTiming ?? 30) as number,
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    title: validateNotEmpty(values.title),
    items: values.items.map((item) => ({
      image:
        validateNotEmpty(item.image) ??
        validateAspect(item.image!, IMAGE_ASPECT),
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
      titer: validateNotEmpty(item.titer),
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    design: values.design,
    title: values.title,
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    blocks: values.items.map((block) => ({
      image: block.image,
      titer: block.titer,
      source: block.source,
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
  };
}

function AfficheForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isValid,
    isSubmitting,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSubmit,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, calcTotalTiming(values), values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <TextField
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          label="Оформление"
          name="design"
          options={afficheDesignOptions}
        />
        <TextField
          label="Заголовок"
          name="title"
          value={values.title}
          onChange={handleChange}
          onBlur={handleBlur}
          {...getValidationProps("title", touched, errors)}
          required
        />
        <Select
          label="Хронометраж"
          name="timingType"
          options={selectOptions}
          value={values.timingType}
          onChange={handleChange}
          onBlur={handleBlur}
        />
        {values.timingType === "total" && (
          <Field
            as={TextField}
            name="timing"
            type="number"
            label="Общий хронометраж (сек.)"
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              setFieldValue("timing", +event.target.value)
            }
            {...getValidationProps("timing", touched, errors)}
          />
        )}

        <Blocks
          items={values.items}
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          defaultItemConstructor={createItem}
          canChangeLength
        >
          {(item, index, updateItem) => (
            <>
              <ImageInput
                imageTypes={IMAGE_TYPES}
                image={item.image}
                aspect={IMAGE_ASPECT}
                updateImage={(image) => {
                  updateItem({ image });
                }}
                onBlur={() => {
                  setFieldTouched(`items.${index}.image`, true);
                }}
                {...getDeepValidationProps(
                  `items.${index}.image`,
                  touched,
                  errors,
                )}
                required
              />
              <Field
                as={TextArea}
                name={`items.${index}.titer`}
                placeholder="Титр"
                onChange={(event: any) =>
                  updateItem({ titer: event.target.value })
                }
                rows={2}
                {...getDeepValidationProps(
                  `items.${index}.titer`,
                  touched,
                  errors,
                )}
                required
              />
              <Field
                as={TextArea}
                name={`items.${index}.source`}
                placeholder="Источник"
                value={item.source}
                onChange={(event: any) =>
                  updateItem({ source: event.target.value })
                }
                rows={2}
              />
              {values.timingType === "separate" && (
                <Field
                  as={TextField}
                  label="Хронометраж (сек.)"
                  labelStyle={{ maxWidth: 180 }}
                  name={`items.${index}.timing`}
                  type="number"
                  onChange={(event: any) => {
                    updateItem({ timing: +event.target.value });
                  }}
                  {...getDeepValidationProps(
                    `items.${index}.timing`,
                    touched,
                    errors,
                  )}
                />
              )}
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(AfficheForm);
