import React from "react";

import { InfoRow } from "../InfoComponents";

export function ChartTable({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Тема" value={data.theme.name} />
      {data.title && <InfoRow title="Заголовок" value={data.title} />}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      <InfoRow title="Хронометраж (сек.)" value={data.timing} />
      <InfoRow title="Размер шрифта" value={data.fontSize} />
      <InfoRow title="Таблица" value="" />
      <table style={{ borderCollapse: "collapse" }}>
        <tbody>
          {data.tableData.data.map((row: string[], rowIndex: number) => (
            <tr key={rowIndex}>
              {row.map((col, colIndex) => (
                <td key={colIndex} style={{ border: "1px solid", padding: 10 }}>
                  {col}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </>
  );
}
