import Blocks from "emg-ui-kit/components/Blocks";
import ImageInput from "emg-ui-kit/components/ImageInput";
import { FormImage } from "emg-ui-kit/components/ImageInput";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, FormikProvider, useFormik } from "formik";
import React from "react";

import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import Form from "../Form";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  IMAGE_TYPES,
  MAX_TIMING,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  validateMax,
  validateTimingSum,
  removeTouched,
} from "../util";

type Item = {
  image?: FormImage;
  title: string;
  comment: string;
  source: string;
  sourceColor: string;
  timing: number;
};

function createItem(): Item {
  return {
    image: undefined,
    title: "",
    comment: "",
    source: "",
    sourceColor: "white",
    timing: 5,
  };
}

function initItems(count = 3) {
  return Array.from(Array(count), createItem);
}

function calcTotalTiming(values: Values) {
  return values.timingType === "total"
    ? values.timing
    : values.items.reduce((acc, item) => acc + item.timing, 0);
}

function getInitialValues(initialFormData?: Record<string, any>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    timingType: (initialFormData?.equalTimings
      ? "total"
      : "separate") as string,
    timing: (initialFormData?.totalTiming ?? 15) as number,
    source: (initialFormData?.source ?? "") as string,
    sourceColor: (initialFormData?.sourceColor ?? "white") as string,
    sourceType: (initialFormData?.sourceType ?? "separate") as string,
    items: (initialFormData?.blocks?.map((block: any) => {
      return block.timing ? block : { ...block, timing: 5 };
    }) ?? initItems()) as Item[],
  };
}

const selectOptions = [
  { id: "total", name: "Общий" },
  { id: "separate", name: "Для каждого слайда" },
];

const sourceColorOptions = [
  { id: "gray", name: "Серый" },
  { id: "white", name: "Белый" },
];

type Values = ReturnType<typeof getInitialValues>;

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    timing:
      values.timingType === "total"
        ? validatePositive(values.timing) ??
          validateMax(values.timing, MAX_TIMING)
        : undefined,
    items: values.items.map((item) => ({
      image: validateNotEmpty(item.image),
      timing:
        values.timingType === "separate"
          ? validatePositive(item.timing) ??
            validateTimingSum(calcTotalTiming(values), MAX_TIMING)
          : undefined,
    })),
  };
  return removeEmptyProps(errors);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    equalTimings: values.timingType === "total",
    totalTiming: calcTotalTiming(values),
    sourceType: values.sourceType,
    ...(values.sourceType === "total" && {
      source: values.source,
      sourceColor: values.sourceColor,
    }),
    blocks: values.items.map((block) => ({
      image: block.image,
      title: block.title,
      comment: block.comment,
      ...(values.sourceType === "total" && {
        source: values.source,
        sourceColor: values.sourceColor,
      }),
      ...(values.sourceType === "separate" && {
        source: block.source,
        sourceColor: block.sourceColor,
      }),
      ...(values.timingType === "separate" && { timing: block.timing }),
    })),
  };
}

function D24SlideShowForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isValid,
    isSubmitting,
  } = formik;

  const buttonProps = {
    isValid,
    isSubmitting,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  usePreview(channel, template, calcTotalTiming(values), values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          value={values.clipName}
          {...getValidationProps("clipName", touched, errors)}
        />
        <Field
          as={Select}
          label="Хронометраж"
          name="timingType"
          options={selectOptions}
        />
        {values.timingType === "total" && (
          <Field
            as={TextField}
            name="timing"
            type="number"
            label="Общий хронометраж (сек.)"
            {...getValidationProps("timing", touched, errors)}
          />
        )}
        <Field
          as={Select}
          label="Источник"
          name="sourceType"
          options={selectOptions}
        />
        {values.sourceType === "total" && (
          <>
            <Field
              as={TextArea}
              label="Общий источник"
              name="source"
              value={values.source}
              rows={2}
            />
            <Field
              as={Select}
              label="Цвет источника"
              name="sourceColor"
              options={sourceColorOptions}
            />
          </>
        )}

        <Blocks
          items={values.items}
          tooltip={
            "Размер текста: <size=50></size> & Серый цвет: <color=#646464></color> & Синий цвет: <color=#4464E2></color>"
          }
          updateItems={(items) => setFieldValue("items", items)}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          defaultItemConstructor={createItem}
          canChangeLength
        >
          {(item, index, updateItem) => (
            <>
              <ImageInput
                imageTypes={IMAGE_TYPES}
                image={item.image}
                aspect={[null, "16/9", "2/3", "3/4"]}
                updateImage={(image) => {
                  updateItem({ image });
                }}
                onBlur={() => {
                  setFieldTouched(`items.${index}.image`);
                }}
                {...getDeepValidationProps(
                  `items.${index}.image`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextArea}
                name={`items.${index}.title`}
                placeholder="Заголовок"
                rows={2}
              />
              <Field
                as={TextArea}
                name={`items.${index}.comment`}
                placeholder="Комментарий"
                onChange={(event: any) =>
                  updateItem({ comment: event.target.value })
                }
                rows={5}
              />
              {values.sourceType === "separate" && (
                <>
                  <Field
                    as={TextArea}
                    name={`items.${index}.source`}
                    placeholder="Источник"
                    value={item.source}
                    rows={2}
                  />
                  <Field
                    as={Select}
                    placeholder="Цвет источника"
                    name={`items.${index}.sourceColor`}
                    options={sourceColorOptions}
                  />
                </>
              )}
              {values.timingType === "separate" && (
                <Field
                  as={TextField}
                  label="Хронометраж (сек.)"
                  labelStyle={{ maxWidth: 180 }}
                  name={`items.${index}.timing`}
                  type="number"
                  {...getDeepValidationProps(
                    `items.${index}.timing`,
                    touched,
                    errors
                  )}
                />
              )}
            </>
          )}
        </Blocks>
        <br />
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(D24SlideShowForm);
