import classNames from "classnames";
import Button from "emg-ui-kit/components/Button";
import Modal from "emg-ui-kit/components/Modal";
import TextField from "emg-ui-kit/components/TextField";
import { Field, useFormikContext } from "formik";
import { nanoid } from "nanoid";
import React, { useCallback, useState } from "react";
import SunEditor from "suneditor-react";

import { Values } from "../../order/m24/M24TableForm";
import { ColorButton } from "../ColorPicker";
import FlexContainer from "../FlexContainer";
import { convertHtmlforUnity } from "../TagConverter";
import AnimationButton from "../Toolbar/AnimationButton";
import { validateDecimalPlaces } from "../utils";
import "./SunEditor.css";
import styles from "./TableEditorNew.module.scss";
import TableHeightEdit from "./TableHeightEdit";
import TableWidthEdit from "./TableWidthEdit";

const MINCOLUMN = 1;
const MAXCOLUMN = 10;
const MAXROW = 10;

export interface Cell {
  id: string;
  editorsText: string;
  text: string;
  fontColor: string;
  textOrient?: string;
  cellColor?: string;
  image?: string;
  animType: string;
}

export type TableData = {
  cells: Cell[][];
  columnWidth: number[];
  rowHeight: number[];
  generalRows: number[];
};

export function createCell(): Cell {
  return {
    id: nanoid(8),
    editorsText: "",
    text: "",
    textOrient: "left",
    fontColor: "#252525",
    cellColor: "#FFFFFF",
    image: undefined,
    animType: "header",
  };
}

export function createTable(cols = 3, rows = 4): TableData {
  const cells = Array.from({ length: rows }, () =>
    Array.from({ length: cols }, () => ({ ...createCell() }))
  );
  const columnWidth = Array.from({ length: cols }, () =>
    parseFloat((100 / cols).toFixed(2))
  );
  const rowHeight = Array.from({ length: rows }, () =>
    parseFloat((100 / rows).toFixed(2))
  );
  const generalRows: number[] = [];
  return {
    cells,
    columnWidth,
    rowHeight,
    generalRows,
  };
}

function Table({
  name,
  tableData,
  handleDeleteRow,
  editRowActive,
  setEditRowActive,
  isTableEdit,
  handleSetGeneralRow,
}: {
  name: string;
  tableData: TableData;
  handleDeleteRow: (row: number) => void;
  editRowActive: number | null;
  setEditRowActive: (value: number | null) => void;
  isTableEdit: boolean;
  handleSetGeneralRow: (row: number) => void;
}) {
  const { setFieldValue } = useFormikContext<Values>();

  const handleEditRowActive = (row: number) => {
    if (row !== editRowActive) {
      setEditRowActive(row);
    }
  };

  const colCount = tableData.columnWidth.length;

  const checkTextOrient = useCallback((value: string): string => {
    const searchText = {
      center: "text-align: center",
      right: "text-align: right",
    };
    for (const [key, text] of Object.entries(searchText)) {
      if (value.includes(text)) {
        return key;
      }
    }
    return "left";
  }, []);

  const handleUpdateCell = useCallback(
    (row: number, col: number, value: string) => {
      setFieldValue(`${name}.cells.${row}.${col}.editorsText`, value);
      setFieldValue(
        `${name}.cells.${row}.${col}.text`,
        convertHtmlforUnity(value)
      );
      setFieldValue(
        `${name}.cells.${row}.${col}.textOrient`,
        checkTextOrient(value)
      );
    },
    [name, checkTextOrient, setFieldValue]
  );

  return (
    <table className={styles.mainTable}>
      <tbody>
        {tableData.cells.map((row, rowIndex) => {
          const isGeneralRow = tableData.generalRows.includes(rowIndex);
          const isEditActive = isTableEdit && editRowActive === rowIndex;

          return (
            <tr key={rowIndex}>
              <td className={styles.td}>
                <div className={styles.div}>{rowIndex + 1}</div>
              </td>
              <td className={styles.td}>
                <div className={styles.div} style={{ width: 30 }}>
                  <button
                    className={classNames(styles.generalRowButton, {
                      [styles.active]: isGeneralRow,
                    })}
                    onClick={() => handleSetGeneralRow(rowIndex)}
                    title="Объединить ячейки в одну строку"
                  />
                </div>
              </td>
              {row.map((cell, colIndex) => {
                if (isGeneralRow && colIndex !== 0) {
                  return null;
                }
                const columnWidth = isGeneralRow
                  ? 100 * 4.6 + 40 * colCount + 3 * (colCount - 1)
                  : tableData.columnWidth[colIndex] * 4.6 + 40;
                const colSpanCount = isGeneralRow ? colCount : 1;

                return (
                  <td
                    key={colIndex}
                    className={styles.tdCell}
                    colSpan={colSpanCount}
                  >
                    {isEditActive && (
                      <div
                        className={styles.toolBarContainer}
                        style={{
                          width: columnWidth,
                        }}
                      >
                        <ColorButton
                          colors={[
                            "#FFFFFF",
                            "#252525",
                            "#DD2A49",
                            "#B9B9B9",
                            "#ECECEC",
                            "#00FFFF",
                          ]}
                          pickerPosition="left"
                          updateColor={(color: string) =>
                            setFieldValue(
                              `${name}.cells.${rowIndex}.${colIndex}.cellColor`,
                              color
                            )
                          }
                          className={styles.cellColorButton}
                          tooltip="Цвет фона"
                        />
                        <ColorButton
                          colors={["#FFFFFF", "#252525"]}
                          pickerPosition="left"
                          updateColor={(color: string) =>
                            setFieldValue(
                              `${name}.cells.${rowIndex}.${colIndex}.fontColor`,
                              color
                            )
                          }
                          className={styles.fontColorButton}
                          tooltip="Цвет текста"
                        />
                        <AnimationButton
                          selectedOption={cell.animType}
                          handleOptionClick={(option: string) =>
                            setFieldValue(
                              `${name}.cells.${rowIndex}.${colIndex}.animType`,
                              option
                            )
                          }
                          tooltip="Выбор анимации"
                        />
                        {/* <div className={styles.imageAddButton}></div> */}
                      </div>
                    )}
                    <div
                      className={styles.sunEditorContainer}
                      onFocus={() => handleEditRowActive(rowIndex)}
                    >
                      <SunEditor
                        key={cell.id}
                        hideToolbar={!isEditActive}
                        lang="ru"
                        setDefaultStyle={`padding: 0 25px; font-size: 18px;
                      background-color: ${cell.cellColor};
                      color: ${cell.fontColor};
                      width: ${columnWidth}px;
                      height: ${tableData.rowHeight[rowIndex] * 3.55}px;
                      display: flex;
                      flex-direction: column;
                      justify-content: center;
                      overflow: hidden;
                      `}
                        setOptions={{
                          buttonList: [
                            ["fontSize", "bold", "align", "removeFormat"],
                          ],
                          alignItems: ["left", "center", "right"],
                          resizingBar: false,
                          resizeEnable: false,
                        }}
                        onChange={(value: string) => {
                          handleUpdateCell(rowIndex, colIndex, value);
                        }}
                        defaultValue={cell.editorsText}
                      />
                    </div>
                  </td>
                );
              })}
              <td className={styles.td}>
                <div className={styles.div}>
                  <button
                    className={styles.deleteButton}
                    onClick={() => handleDeleteRow(rowIndex)}
                    disabled={tableData.cells.length === 1}
                  />
                </div>
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

interface TableEditorProps {
  name: string;
  tableData: TableData;
  visible: boolean;
  onClose: () => void;
}

export default function TableEditor({
  name,
  tableData,
  visible,
  onClose,
}: TableEditorProps) {
  const { setFieldValue } = useFormikContext<Values>();
  const [isWidthEdit, setIsWidthEdit] = useState(false);
  const [isHeightEdit, setIsHeightEdit] = useState(false);
  const [columnWidth, setColumnWidth] = useState<number[]>(
    tableData.columnWidth
  );
  const [rowHeight, setRowHeight] = useState<number[]>(tableData.rowHeight);
  const [editRowActive, setEditRowActive] = useState<number | null>(null);
  const [isTableEdit, setIsTableEdit] = useState(true);

  const colCount = tableData.columnWidth.length;

  const handleAddRow = useCallback(() => {
    const newRow = Array.from({ length: colCount }, () => ({
      ...createCell(),
    }));
    const updatedTableData = { ...tableData };
    updatedTableData.cells.push(newRow);
    const newRowCount = tableData.rowHeight.length + 1;
    const newRowHeight = Array.from({ length: newRowCount }, () =>
      parseFloat((100 / newRowCount).toFixed(2))
    );
    updatedTableData.rowHeight = newRowHeight;
    setFieldValue(name, updatedTableData);
  }, [tableData, name, colCount, setFieldValue]);

  const handleDeleteRow = (row: number) => {
    const updatedTableData = { ...tableData };

    const filteredRows = updatedTableData.generalRows.filter(
      (filterRow) => filterRow !== row
    );
    updatedTableData.generalRows = filteredRows;

    updatedTableData.cells.splice(row, 1);
    const newRowCount = tableData.rowHeight.length - 1;
    if (newRowCount) {
      const rowHeight = Array.from({ length: newRowCount }, () =>
        parseFloat((100 / newRowCount).toFixed(2))
      );
      updatedTableData.rowHeight = rowHeight;
    }
    setFieldValue(name, updatedTableData);
  };

  const handleSetGeneralRow = (row: number) => {
    const updatedGeneralRows = [...tableData.generalRows];

    const rowExists = updatedGeneralRows.includes(row);

    if (rowExists) {
      const filteredRows = updatedGeneralRows.filter(
        (filterRow) => filterRow !== row
      );
      setFieldValue(`${name}.generalRows`, filteredRows);
    } else {
      updatedGeneralRows.push(row);
      setFieldValue(`${name}.generalRows`, updatedGeneralRows);
    }
  };

  const changeColCount = useCallback(
    (nextColCount: number) => {
      const prevColCount = colCount;
      if (nextColCount < MINCOLUMN || nextColCount > MAXCOLUMN) return;

      const updatedtableData = { ...tableData };

      function getNewWidth(): number {
        return parseFloat((100 / nextColCount).toFixed(2));
      }

      if (nextColCount < prevColCount) {
        const deleteColCount = prevColCount - nextColCount;
        const modifiedColumnWidth = updatedtableData.columnWidth
          .slice(0, -deleteColCount)
          .map(() => getNewWidth());
        updatedtableData.columnWidth = modifiedColumnWidth;
        const modifiedcells = updatedtableData.cells.map((row) =>
          row.slice(0, -deleteColCount)
        );
        updatedtableData.cells = modifiedcells;
      } else {
        const modifiedColumnWidth = Array.from({ length: nextColCount }, () =>
          getNewWidth()
        );
        updatedtableData.columnWidth = modifiedColumnWidth;

        const numCellsToAdd = nextColCount - prevColCount;
        const modifiedcells = updatedtableData.cells.map((row) => [
          ...row,
          ...Array.from({ length: numCellsToAdd }, () => createCell()),
        ]);
        updatedtableData.cells = modifiedcells;
      }
      setFieldValue(name, updatedtableData);
    },
    [tableData, name, colCount, setFieldValue]
  );

  function ChangeDimensions(value: number, index: number, data: number[]) {
    const dimensions = data.slice(0, index);
    const dimensionsSum = dimensions.reduce((acc, curr) => acc + curr, 0);
    if (
      isNaN(value) ||
      value < 1 ||
      value > 95 - dimensionsSum ||
      !validateDecimalPlaces(value, 2)
    )
      return;
    const restDimensionsSum = 100 - value - dimensionsSum;
    const restDimensions = data.length - (index + 1);
    const newRestDimensions = parseFloat(
      (restDimensionsSum / restDimensions).toFixed(2)
    );
    const newData = [...data];
    newData[index] = value;
    for (let i = index + 1; i < data.length; i++) {
      newData[i] = newRestDimensions;
    }
    return newData;
  }

  const changeColumnWidth = (value: number, index: number) => {
    const newWidths = ChangeDimensions(value, index, columnWidth);
    if (newWidths) {
      setColumnWidth(newWidths);
    }
  };

  const handleWidthEdit = (value: boolean) => {
    setEditRowActive(null);
    setIsWidthEdit(value);
    value && setColumnWidth(tableData.columnWidth);
  };

  const сhangeRowHeight = (value: number, index: number) => {
    const newHeights = ChangeDimensions(value, index, rowHeight);
    if (newHeights) {
      setRowHeight(newHeights);
    }
  };

  const handleHeightEdit = (value: boolean) => {
    setEditRowActive(null);
    setIsHeightEdit(value);
    value && setRowHeight(tableData.rowHeight);
  };

  const handleDefaultTable = () => {
    setEditRowActive(null);
    setFieldValue(name, createTable());
  };

  const handleEditWidthSave = () => {
    setFieldValue(`${name}.columnWidth`, columnWidth);
    setIsWidthEdit(false);
  };

  const handleEditHeightSave = () => {
    setFieldValue(`${name}.rowHeight`, rowHeight);
    setIsHeightEdit(false);
  };

  return (
    <Modal visible={visible} close={onClose}>
      <div className={styles.mainContainer}>
        <FlexContainer style={{ gap: 20 }}>
          <Field
            id="Количество столбцов"
            as={TextField}
            label="Количество столбцов"
            labelStyle={{ maxWidth: 180 }}
            style={{ maxWidth: 260 }}
            value={colCount}
            onChange={(event: any) => changeColCount(event.target.value)}
            type="number"
          />
          <button
            className={classNames(styles.tableEditButton, {
              [styles.active]: isTableEdit,
            })}
            onClick={() => setIsTableEdit((prevState) => !prevState)}
          />
          <button
            className={styles.tableControlButton}
            onClick={() => handleWidthEdit(true)}
          >
            Изменить ширину столбцов
          </button>
          <button
            className={styles.tableControlButton}
            onClick={() => handleHeightEdit(true)}
          >
            Изменить высоту строк
          </button>
        </FlexContainer>
        <Modal visible={isWidthEdit} close={() => setIsWidthEdit(false)}>
          <TableWidthEdit
            dimensions={columnWidth}
            handleChange={changeColumnWidth}
            handleSave={handleEditWidthSave}
          />
        </Modal>
        <Modal visible={isHeightEdit} close={() => setIsHeightEdit(false)}>
          <TableHeightEdit
            dimensions={rowHeight}
            handleChange={сhangeRowHeight}
            handleSave={handleEditHeightSave}
          />
        </Modal>

        <Table
          name={name}
          tableData={tableData}
          handleDeleteRow={handleDeleteRow}
          editRowActive={editRowActive}
          setEditRowActive={setEditRowActive}
          isTableEdit={isTableEdit}
          handleSetGeneralRow={handleSetGeneralRow}
        />
        <br />
        <button
          className={styles.addButton}
          onClick={handleAddRow}
          disabled={tableData.cells.length >= MAXROW}
        >
          Добавить строку
        </button>
        <div className={styles.buttonContainer}>
          <Button
            title="Сохранить"
            onClick={onClose}
            style={{ marginRight: 20 }}
          />
          <Button
            color="red"
            title="Сбросить таблицу"
            onClick={() => handleDefaultTable()}
            style={{ marginLeft: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
}
