import { useEffect } from "react";
import { useSelector } from "react-redux";

import { selectTasks } from "./../redux/tasks/selectors";

const baseTitile = "Облачный рендер";

export function useFavicon() {
  const { tasks } = useSelector(selectTasks);

  useEffect(() => {
    const faviconLink = document.querySelector('link[rel="icon"]');

    if (tasks.length) {
      const ordersInProgress = tasks.filter((task) => {
        return task.status === "inProgress" || task.status === "render";
      });
      if (ordersInProgress.length) {
        faviconLink && faviconLink.setAttribute("href", "favicon_orange.svg");
        document.title = `(${ordersInProgress.length}) ${baseTitile}`;
      } else {
        faviconLink && faviconLink.setAttribute("href", "favicon_green.svg");
        document.title = baseTitile;
      }
    }
  }, [tasks]);
}
