import Button from "emg-ui-kit/components/Button";
import Modal from "emg-ui-kit/components/Modal";
import TextField from "emg-ui-kit/components/TextField";
import React, { useCallback, useEffect, useRef, useState } from "react";

import FlexContainer from "./FlexContainer";
import styles from "./TableEditor.module.css";

export interface TableData {
  data: string[][];
  colWidths: number[];
}

function Table({
  tableData,
  updateData,
}: {
  tableData: TableData;
  updateData: (tableData: TableData) => void;
}) {
  const resizeData = useRef<{
    pageX?: number;
    curCol?: HTMLElement;
    nxtCol?: HTMLElement;
    curColWidth?: number;
    nxtColWidth?: number;
  }>({});

  const [tableHeight, setTableHeight] = useState<number>();

  const headerRowRef = useRef<HTMLTableRowElement>(null!);

  const handleMouseDown = (event: React.MouseEvent) => {
    const curCol = (event.target as HTMLElement).parentElement!;
    const nxtCol = curCol.nextElementSibling
      ? (curCol.nextElementSibling as HTMLElement)
      : undefined;
    const pageX = event.pageX;
    const curColWidth = curCol.offsetWidth;
    let nxtColWidth: number | undefined;
    if (nxtCol) {
      nxtColWidth = nxtCol.offsetWidth;
    }
    resizeData.current = {
      curCol,
      nxtCol,
      pageX,
      curColWidth,
      nxtColWidth,
    };
  };

  const handleMouseMove = (event: React.MouseEvent) => {
    if (resizeData.current.curCol) {
      const diffX = event.pageX - resizeData.current.pageX!;

      if (resizeData.current.nxtCol)
        resizeData.current.nxtCol.style.width =
          resizeData.current.nxtColWidth! - diffX + "px";

      resizeData.current.curCol.style.width =
        resizeData.current.curColWidth! + diffX + "px";
    }
  };

  const handleMouseUp = () => {
    resizeData.current = {};
    if (headerRowRef.current) {
      let colWidths = Array.from(
        headerRowRef.current.children,
        (el) => (el as HTMLElement).offsetWidth,
      );
      colWidths = colWidths.slice(1, -1);
      const total = colWidths.reduce((acc, width) => acc + width);
      const colPercents = colWidths.map((width) => (width / total) * 100);
      updateData({ ...tableData, colWidths: colPercents });
    }
  };

  const handleUpdateCell = (row: number, col: number, value: string) => {
    const nextData = tableData.data.slice();
    const nextRow = nextData[row].slice();
    nextRow[col] = value;
    nextData[row] = nextRow;
    updateData({ ...tableData, data: nextData });
  };

  const handleDeleteRow = useCallback(
    (row: number) => {
      const nextData = tableData.data.slice();
      nextData.splice(row + 1, 1);
      updateData({ ...tableData, data: nextData });
    },
    [tableData, updateData],
  );

  const initialRender = useRef(true);
  const prevColCount = useRef(tableData.colWidths.length);
  useEffect(() => {
    if (
      initialRender.current ||
      (prevColCount.current !== tableData.colWidths.length &&
        headerRowRef.current)
    ) {
      const headerElements = headerRowRef.current.children;
      const colElements = Array.from(headerElements).slice(1, -1);
      let colWidths = colElements.map((el) => (el as HTMLElement).offsetWidth);
      const total = colWidths.reduce((acc, width) => acc + width);
      colElements.forEach((el, idx) => {
        (el as HTMLElement).style.width = `${
          total * (tableData.colWidths[idx] / 100)
        }px`;
      });
      initialRender.current = false;
      prevColCount.current = tableData.colWidths.length;
    }
  }, [tableData.colWidths]);

  return (
    <table
      className={styles.table}
      ref={(element) => setTableHeight(element?.offsetHeight)}
      onMouseMove={handleMouseMove}
    >
      <thead>
        <tr ref={headerRowRef} className={styles.headerRow}>
          <th className={styles.th} style={{ width: 20 }}>
            #
          </th>
          {tableData.data[0].map((header, idx) => {
            const maxRowsInValue = Math.max(
              ...tableData.data[0].map((value) => value.split("\n").length),
            );
            return (
              <th key={idx} className={styles.th}>
                <textarea
                  value={header}
                  onChange={(event) =>
                    handleUpdateCell(0, idx, event.target.value)
                  }
                  className={styles.textInput}
                  rows={maxRowsInValue}
                  wrap="off"
                />
                {idx !== tableData.data[0].length - 1 && (
                  <div
                    className={styles.resizeHandler}
                    style={{ height: tableHeight }}
                    onMouseDown={handleMouseDown}
                    onMouseUp={handleMouseUp}
                  />
                )}
              </th>
            );
          })}
          <th className={styles.th} style={{ width: 20 }} />
        </tr>
      </thead>
      <tbody>
        {tableData.data.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            <td className={styles.td}>{rowIndex + 1}</td>
            {row.map((col, colIndex) => {
              const maxRowsInValue = Math.max(
                ...tableData.data[rowIndex + 1].map(
                  (value) => value.split("\n").length,
                ),
              );
              return (
                <td key={colIndex} className={styles.td}>
                  <textarea
                    value={col}
                    onChange={(event) =>
                      handleUpdateCell(
                        rowIndex + 1,
                        colIndex,
                        event.target.value,
                      )
                    }
                    className={styles.textInput}
                    rows={maxRowsInValue}
                    wrap="off"
                  />
                </td>
              );
            })}
            <td className={styles.td}>
              <button
                className={styles.deleteButton}
                onClick={() => handleDeleteRow(rowIndex)}
                disabled={tableData.data.length === 2}
              />
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

interface TableEditorProps {
  tableData: TableData;
  updateData: (tableData: TableData) => void;
  visible: boolean;
  close: () => void;
  defaultTableConstructor: () => TableData;
  additionalInfo?: string;
}

export default function TableEditor({
  tableData,
  updateData,
  visible,
  close,
  defaultTableConstructor,
  additionalInfo,
}: TableEditorProps) {
  const addRow = useCallback(() => {
    const colCount = tableData.data[0].length;
    updateData({
      ...tableData,
      data: [...tableData.data, Array(colCount).fill("")],
    });
  }, [tableData, updateData]);

  const changeColCount = useCallback(
    (nextColCount: number) => {
      const min = 1;
      const max = 10;
      const prevColCount = tableData.data[0].length;

      if (nextColCount < min || nextColCount > max) return;

      const colWidths = Array(nextColCount).fill(100 / nextColCount);

      if (nextColCount < prevColCount) {
        updateData({
          colWidths,
          data: tableData.data.map((row: string[]) =>
            row.slice(0, nextColCount),
          ),
        });
      } else {
        const data = tableData.data.map((row) => [
          ...row,
          ...Array(nextColCount - prevColCount).fill(""),
        ]);
        updateData({ colWidths, data });
      }
    },
    [tableData, updateData],
  );

  const currentRowCount = tableData.data.length;
  const maxRowCount = 10;

  return (
    <Modal visible={visible} close={close}>
      <div className={styles.mainContainer}>
        <FlexContainer>
          <TextField
            type="number"
            value={tableData.colWidths.length.toString()}
            onChange={(evt) => changeColCount(+evt.target.value)}
            label="Количество столбцов"
            style={{ maxWidth: 300 }}
          />
          <div style={{ marginLeft: 20 }}>{additionalInfo}</div>
        </FlexContainer>
        <Table tableData={tableData} updateData={updateData} />
        <br />
        <button
          className={styles.addButton}
          onClick={addRow}
          disabled={currentRowCount >= maxRowCount}
        >
          Добавить строку
        </button>
        <div className={styles.buttonContainer}>
          <Button
            title="Сохранить"
            onClick={close}
            style={{ marginRight: 20 }}
          />
          <Button
            color="red"
            title="Сбросить таблицу"
            onClick={() => updateData(defaultTableConstructor())}
            style={{ marginLeft: 20 }}
          />
        </div>
      </div>
    </Modal>
  );
}
