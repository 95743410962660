import TextField from "emg-ui-kit/components/TextField";
import { useFormikContext, Field } from "formik";
import React from "react";

import { getItemFieldName, ValidationPropsUtils } from "../../../util";
import { FeelsLikeItem } from "../../types";

interface Props {
  item: FeelsLikeItem;
  index: number;
}

export default function FeelsLikeBlock({ index }: Props) {
  const formik = useFormikContext();
  const validationUtils = new ValidationPropsUtils(
    formik.touched,
    formik.errors,
  );
  const temperatureName = getItemFieldName(index, "temperature");
  return (
    <Field
      as={TextField}
      placeholder="Температура"
      name={temperatureName}
      {...validationUtils.getDeepProps(temperatureName)}
      required
    />
  );
}
