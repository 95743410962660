import React from "react";

import { booleanTypes } from "../../../../../common/texts";
import { Item } from "../../../../../order/map/types";
import { blockTypes } from "../../../../../order/map/util";
import { InfoRow } from "../InfoComponents";

export function Map({ data }: { data: Record<string, any> }) {
  return (
    <>
      {data.blocks.map((item: Item, index: number) => (
        <details open>
          <summary>Блок {index + 1}</summary>
          <div>
            <InfoRow title="Тип блока" value={blockTypes[item.type]} />
            <InfoRow title="Хронометраж (сек.)" value={item.timing} />
            {item.type === "country" && (
              <InfoRow
                title="Страны"
                value={item.countries
                  .map(
                    (country) =>
                      `${country.name} [${country.color.toUpperCase()}]`,
                  )
                  .join("\n")}
              />
            )}
            {item.type === "point" && (
              <>
                <InfoRow
                  title="Точки"
                  value={item.points
                    .map(
                      (point) =>
                        `${point.name} [${point.coords.lat}, ${point.coords.long}]`,
                    )
                    .join("\n")}
                />
                <InfoRow
                  title="Линии соединения точек"
                  value={booleanTypes[String(item.connectLines)]}
                />
                <InfoRow
                  title="Цвет плашки"
                  value={`[${item.backgroundColor.toUpperCase()}]`}
                />
                <InfoRow
                  title="Цвет маркера"
                  value={`[${item.markerColor.toUpperCase()}]`}
                />
              </>
            )}
          </div>
        </details>
      ))}
    </>
  );
}
