import Button from "emg-ui-kit/components/Button";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Konva from "konva";
import { KonvaEventObject } from "konva/lib/Node";
import { Vector2d } from "konva/lib/types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Stage, Layer, Image } from "react-konva";
import useImage from "use-image";

import MapControls from "./MapControls";
import PointsControl from "./PointsControl";
import TextLocationPointGroup from "./TextLocationPointGroup";
import { MAP_OFFSET_FIELDS } from "./constants";
import { getBoundedStagePosition, getFrameCoordsArray } from "./helpers";
import { LocationPoint, MapData, MapProps, Size } from "./types";

const initialFramePosition = (
  stageRefCurrent: Konva.Stage,
  scale: number,
  stageSize: Size
): [Vector2d, Vector2d] => [
  {
    x: -Math.round(stageRefCurrent.x() / scale),
    y: -Math.round(stageRefCurrent.y() / scale),
  },
  {
    x: Math.round(stageSize.width / scale),
    y: Math.round(stageSize.height / scale),
  },
];

export default React.memo(
  ({
    maxScale = 5,
    minScale = 0.35,
    stageSize = { width: 1050, height: 576 },
    mapData,
    saveMapData,
    handleClose,
    onDeleteId,
  }: MapProps) => {
    const stageRef = useRef<Konva.Stage>(null);

    const [mapImage, imageStatus] = useImage("./images/the-city/map.png");
    const [safeFrameImg] = useImage("./images/the-city/safe_frame.png");
    const mapImageSize = {
      width: (mapImage?.naturalWidth || 0) + MAP_OFFSET_FIELDS.x,
      height: (mapImage?.naturalHeight || 0) + MAP_OFFSET_FIELDS.y,
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const stageSizeMem = useMemo(() => stageSize, []);

    const [initialMapData] = useState<MapData | undefined>(mapData);

    const [scale, setScale] = useState(initialMapData?.scale || minScale);
    const [points, setPoints] = useState<LocationPoint[]>(
      initialMapData?.points || []
    );
    const [isSafeframeVisible, setIsSafeframeVisible] = useState(
      initialMapData?.safeFrameVisible || false
    );
    const [frame, setFrame] = useState<[Vector2d, Vector2d] | []>([]);

    const stageCenterPos = {
      x: -(mapImageSize.width * minScale - stageSize.width) / 2,
      y: -(mapImageSize.height * minScale - stageSize.height) / 2,
    };

    useEffect(
      function onLoad() {
        if (imageStatus === "loaded" && stageRef.current) {
          if (initialMapData) {
            stageRef.current.position(initialMapData?.stagePos);
            setFrame(initialMapData.frame);
          } else {
            setFrame(
              initialFramePosition(stageRef.current, scale, stageSizeMem)
            );
          }
        }
      },

      [imageStatus, initialMapData, scale, stageSizeMem]
    );

    const handleWheel = (e: KonvaEventObject<WheelEvent>) => {
      const scaleBy = 1.02;
      if (!stageRef.current) {
        return;
      }
      e.evt.preventDefault();

      const oldScale = stageRef.current.scaleX();
      const pointer = stageRef.current.getPointerPosition();

      const mousePointTo = {
        x: (pointer!.x - stageRef.current.x()) / oldScale,
        y: (pointer!.y - stageRef.current.y()) / oldScale,
      };

      let direction = -(e.evt.deltaY > 0 ? 1 : -1);

      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;

      if (newScale < minScale || newScale > maxScale) {
        return;
      }

      setScale(newScale);
      stageRef.current.scale({ x: newScale, y: newScale });

      const newPos = {
        x: pointer!.x - mousePointTo.x * newScale,
        y: pointer!.y - mousePointTo.y * newScale,
      };

      const boundedPos = getBoundedStagePosition(
        newPos,
        newScale,
        stageSize,
        mapImageSize
      );

      setFrame(getFrameCoordsArray(boundedPos, scale, stageSize));

      stageRef.current.position(boundedPos);
    };

    const handleMoveStage = (pos: Vector2d): Vector2d => {
      if (stageRef.current) {
        const currentScale = stageRef.current.scaleX();
        const boundedPos = getBoundedStagePosition(
          pos,
          currentScale,
          stageSize,
          mapImageSize
        );
        setFrame(getFrameCoordsArray(boundedPos, scale, stageSize));
        return boundedPos;
      }
      return pos;
    };

    const memoizedData = useMemo(() => {
      const data: MapData = {
        scale,
        points,
        frame,
        stagePos: {
          x: stageRef.current?.x() || stageCenterPos.x,
          y: stageRef.current?.y() || stageCenterPos.y,
        },
        safeFrameVisible: isSafeframeVisible,
      };
      return data;
    }, [
      scale,
      points,
      frame,
      stageCenterPos.x,
      stageCenterPos.y,
      isSafeframeVisible,
    ]);

    useEffect(() => {
      saveMapData(memoizedData);
    }, [memoizedData, saveMapData]);

    const handleButtonZoom = (
      e: React.MouseEvent<HTMLButtonElement> & { target: { name: string } }
    ) => {
      e?.preventDefault();
      const { name } = e.target;
      let direction = -1;
      if (name === "plus") {
        direction = 1;
      }
      const scaleBy = 1.03;
      if (!stageRef.current) {
        return;
      }
      const oldScale = stageRef.current.scaleX();
      const newScale = direction > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      if (newScale < minScale || newScale > maxScale) {
        return;
      }
      setScale(newScale);
      stageRef.current.scale({ x: newScale, y: newScale });

      const mousePointTo = {
        x: (stageRef.current.width() / 2 - stageRef.current.x()) / oldScale,
        y: (stageRef.current.height() / 2 - stageRef.current.y()) / oldScale,
      };

      const newPos = {
        x: stageRef.current.width() / 2 - mousePointTo.x * newScale,
        y: stageRef.current.height() / 2 - mousePointTo.y * newScale,
      };

      const boundedPos = getBoundedStagePosition(
        newPos,
        newScale,
        stageSize,
        mapImageSize
      );

      setFrame(getFrameCoordsArray(boundedPos, scale, stageSize));
      stageRef.current.position(boundedPos);
    };

    const handleButtonHome = (
      e: React.MouseEvent<HTMLButtonElement> & { target: { name: string } }
    ) => {
      if (!stageRef.current) {
        return;
      }

      stageRef.current.scale({ x: minScale, y: minScale });
      setScale(minScale);

      stageRef.current.position(stageCenterPos);

      setFrame(initialFramePosition(stageRef.current, scale, stageSize));
    };

    if (imageStatus === "loading") {
      return <div>Загрузка...</div>;
    }

    return (
      <div style={{ position: "relative", paddingBottom: "30px" }}>
        <div style={{ position: "relative" }}>
          {isSafeframeVisible && (
            <img
              src={safeFrameImg ? safeFrameImg.src : ""}
              alt="Safe Frame png"
              style={{ position: "absolute", zIndex: 1, pointerEvents: "none" }}
            />
          )}
          <MapControls
            handleHomeClick={handleButtonHome}
            handleZoomClick={handleButtonZoom}
          />
          <Stage
            ref={stageRef}
            {...stageSize}
            onWheel={handleWheel}
            draggable
            dragBoundFunc={handleMoveStage}
            scale={{ x: scale, y: scale }}
            x={stageCenterPos.x}
            y={stageCenterPos.y}
            onDragMove={() => {}}
            onDragEnd={() => {}}
            style={{ background: "black" }}
          >
            <Layer>
              <Image
                image={mapImage}
                x={MAP_OFFSET_FIELDS.x / 2}
                y={MAP_OFFSET_FIELDS.y / 2}
              />
            </Layer>
            <Layer>
              {points?.map((point) => (
                <TextLocationPointGroup
                  point={point}
                  scale={scale}
                  key={point.id}
                />
              ))}
            </Layer>
          </Stage>
        </div>

        <div style={{ padding: "10px 30px" }}>
          <Checkbox
            label="включить SafeFrame"
            checked={isSafeframeVisible}
            setChecked={() => setIsSafeframeVisible((prev) => !prev)}
          />
          <PointsControl
            points={points}
            setPoints={setPoints}
            onDeleteId={onDeleteId}
          />
        </div>
        <Button
          title="Сохранить"
          onClick={() => handleClose && handleClose()}
        />
      </div>
    );
  }
);
