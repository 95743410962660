import { useState, useCallback, useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import {
  DashBoardLocationsData,
  DashBoardData,
  DashBoardInfographicData,
  getDashBoardLocationsData,
  getDashBoardMapData,
  getDashBoardInfographicData,
  getTotalAtmoBroadcast,
  getChannelStatistic,
} from "../common/ApiService";
import { selectUserInfo } from "../redux/auth/selectors";

function getStringExpressionFromDate(date: Date) {
  if (!date) {
    return undefined;
  }
  const yaer = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${yaer}-${month}-${day}`;
}

type DashBoardState = {
  locations: DashBoardLocationsData;
  atmoMaps: DashBoardData;
  atmoInfographic: DashBoardInfographicData;
  m24Orders: DashBoardData;
  r24Orders: DashBoardData;
  mapsOrders: DashBoardData;
  isInfographicLoading: boolean;
  totalBroadcast: number;
  isLocationsLoading: boolean;
  isInteractiveLoading: boolean;
  m24Loading: boolean;
  r24Loading: boolean;
  mapsLoading: boolean;
};

const InitialDashBoardState = {
  locations: {},
  atmoMaps: {},
  atmoInfographic: {},
  m24Orders: {},
  r24Orders: {},
  mapsOrders: {},
  totalBroadcast: 0,
  isLocationsLoading: false,
  isInteractiveLoading: false,
  isInfographicLoading: false,
  m24Loading: false,
  r24Loading: false,
  mapsLoading: false,
};

export function useDashBoardData() {
  const userInfo = useSelector(selectUserInfo);

  const [period, setPeriod] = useState("1");
  const [channel, setChannel] = useState("");
  const [users, setUsers] = useState("");

  const [dashBoardState, setDashBoardState] = useState<DashBoardState>(
    InitialDashBoardState
  );

  const [sentToBroadCast, setSentToBroadCast] = useState({
    interactive: true,
    locations: true,
    infographic: true,
    m24Orders: true,
    r24Orders: true,
    mapsOrders: true,
  });

  const [periodDates, setPeriodDates] = useState<{
    startDate: Date | undefined;
    endDate: Date | undefined;
  }>({
    startDate: undefined,
    endDate: undefined,
  });

  const toggleBroadcast = useCallback(
    (program: keyof typeof sentToBroadCast) => {
      setSentToBroadCast((prev) => ({
        ...prev,
        [program]: !prev[program],
      }));
    },
    []
  );

  const dateString: string = useMemo(() => {
    if (period === "custom") {
      const startDateString =
        periodDates.startDate &&
        getStringExpressionFromDate(periodDates.startDate);
      const endDateString =
        periodDates.endDate && getStringExpressionFromDate(periodDates.endDate);
      let resultString = "";
      resultString += startDateString ? `startDate=${startDateString}` : "";
      resultString += startDateString ? "&" : "";
      resultString += endDateString ? `endDate=${endDateString}` : "";
      return resultString;
    } else if (period !== "-") {
      const currentDate = new Date();
      let year = currentDate.getFullYear();
      const month = String(currentDate.getMonth() + 1).padStart(2, "0");
      const day = String(currentDate.getDate()).padStart(2, "0");
      const endDate = `${year}-${month}-${day}`;
      const currentMonth = currentDate.getMonth() + 1;
      let newMonth = currentMonth - +period;
      if (newMonth < 1) {
        year -= 1;
        newMonth += 12;
      }
      const startMonth = String(newMonth).padStart(2, "0");
      const startDate = `${year}-${startMonth}-${day}`;
      const dataString = `startDate=${startDate}&endDate=${endDate}`;
      return dataString;
    } else {
      return "";
    }
  }, [period, periodDates]);

  const S2BC = "&sentToBroadcast=1";

  function getData<T>(
    fetchFn: (options: string) => Promise<T>,
    options: string,
    dataKey: string,
    loadingKey: string
  ) {
    setDashBoardState((prev) => ({ ...prev, [loadingKey]: true }));
    fetchFn(options)
      .then((data) => {
        setDashBoardState((prev) => ({ ...prev, [dataKey]: data }));
        setDashBoardState((prev) => ({ ...prev, [loadingKey]: false }));
      })
      .finally(() =>
        setDashBoardState((prev) => ({ ...prev, [loadingKey]: false }))
      );
  }

  useEffect(() => {
    if (!!userInfo?.groups[0]) {
      setChannel(userInfo?.groups[0]);
    }
  }, [userInfo?.groups]);

  useEffect(() => {
    if (userInfo?.type) {
      const user = userInfo.type === "admin" ? "all" : userInfo.type;
      setUsers(user);
    }
  }, [userInfo?.type]);

  useEffect(() => {
    const locationsParams =
      dateString + (sentToBroadCast.locations ? S2BC : "");
    getData(
      getDashBoardLocationsData,
      locationsParams,
      "locations",
      "isLocationsLoading"
    );
  }, [dateString, sentToBroadCast.locations]);

  useEffect(() => {
    const interactiveParams =
      dateString + (sentToBroadCast.interactive ? S2BC : "");
    getData(
      getDashBoardMapData,
      interactiveParams,
      "atmoMaps",
      "isInteractiveLoading"
    );
  }, [dateString, sentToBroadCast.interactive]);

  useEffect(() => {
    const infographicParams =
      dateString + (sentToBroadCast.infographic ? S2BC : "");
    getData(
      getDashBoardInfographicData,
      infographicParams,
      "atmoInfographic",
      "isInfographicLoading"
    );
  }, [dateString, sentToBroadCast.infographic]);

  useEffect(() => {
    if (channel && channel !== "atmo") {
      const userType = users === "all" ? "" : `&userType=${users}`;
      const isS2BC = sentToBroadCast[
        `${channel}Orders` as keyof typeof sentToBroadCast
      ]
        ? S2BC
        : "";
      const params = dateString + userType + `&channel=${channel}` + isS2BC;
      getData(
        getChannelStatistic,
        params,
        `${channel}Orders`,
        `${channel}Loading`
      );
    }
  }, [dateString, sentToBroadCast, channel, users]);

  useEffect(() => {
    getTotalAtmoBroadcast(dateString).then((data) =>
      setDashBoardState((prev) => ({ ...prev, totalBroadcast: data.total }))
    );
  }, [dateString]);

  const preparedAtmoLocations = useMemo(() => {
    const entries: [string, { [key: string]: number }][] = Object.entries(
      dashBoardState.locations
    );
    const modifiedObject: { [key: string]: number } = {};
    entries.forEach((entry) => {
      Object.keys(entry[1]).forEach((key) => {
        const modifiedKey = `${entry[0]}: ${key}`;
        modifiedObject[modifiedKey] = entry[1][key];
      });
    });
    return modifiedObject;
  }, [dashBoardState.locations]);

  return {
    dashBoardState,
    period,
    periodDates,
    channel,
    users,
    sentToBroadCast,
    preparedAtmoLocations,
    userInfo,
    setPeriod,
    setPeriodDates,
    setChannel,
    setUsers,
    toggleBroadcast,
  };
}
