import { isAspectWithinRange } from "emg-ui-kit/components/utils";
import * as yup from "yup";

import messages from "../order/messages";
import { hasLessCharsInLine, hasLessLines } from "../order/util";

yup.addMethod(yup.string, "maxLines", function (limit: number) {
  return this.test("maxLines", messages.maxLines(limit), (value) =>
    value ? hasLessLines(value, limit) : true
  );
});

yup.addMethod(yup.string, "maxChars", function (limit: number) {
  return this.test("maxChars", messages.maxCharsInLine(limit), (value) =>
    value ? hasLessCharsInLine(value, limit) : true
  );
});

yup.addMethod(yup.mixed, "aspect", function (aspect: number) {
  return this.test("aspect", messages.aspect, (image) =>
    image ? isAspectWithinRange(aspect, image.aspect) : true
  );
});

yup.setLocale({
  mixed: {
    required: messages.empty,
  },
  number: {
    positive: messages.positive,
    min: (params: any) => messages.min(params.min),
    max: (params: any) => messages.max(params.max),
  },
  string: {
    matches: messages.incorrectFormat,
    email: messages.email,
    url: messages.url,
    min: (params: any) => messages.minChars(params.min),
  },
});

declare module "yup" {
  interface StringSchema {
    maxLines(limit: number): this;
    maxChars(limit: number): this;
  }
  interface BaseSchema {
    aspect(aspect: number): this;
  }
}

export default yup;
