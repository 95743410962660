import React from "react";

import { Task } from "../../common/models";
import { channels, templates } from "../../common/texts";
import { dateFormatter } from "../../common/utils";
import styles from "../../tasks/pages/TaskListPage/TaskList.module.css";
import StatusBadge from "../../tasks/components/StatusBadge/StatusBadge";

const SearchTaskRow = React.forwardRef<HTMLDivElement, { task: Task }>(
  function ({ task }, ref) {
    return (
      <article ref={ref} className={styles.task}>
        <div className={styles.taskHeader}>
          <div className={styles.project}>{channels[task.channel]}</div>
          <div className={styles.time}>
            {dateFormatter.format(Date.parse(task.creationDate))}
          </div>
        </div>
        <div className={styles.title}>
          <div style={{ display: "block" }}>
            {templates[task.channel]?.[task.template] ?? ""}
          </div>
          {task.data.clipName ?? task.data.name ?? ""}
        </div>
        <StatusBadge
          status={task.status}
          progress={task.progress}
          renderProgress={task.renderProgress}
          style={{ marginTop: 4 }}
        />
      </article>
    );
  },
);

export default SearchTaskRow;
