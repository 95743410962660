import React from "react";
import { useHistory } from "react-router-dom";

import { useNewsLoad } from "../redux/news/useNews";
import routes from "../routes";
import styles from "./Header.module.css";

interface Props {
  userGroup?: string;
}

export default function NewsButton({ userGroup = "all" }: Props) {
  const history = useHistory();
  const hasUnread = useNewsLoad(userGroup);

  const className = hasUnread
    ? styles.unreadNotificationButton
    : styles.notificationButton;

  const handleClick = () => {
    history.push(routes.newsArticlesView);
  };

  return <button type="button" className={className} onClick={handleClick} />;
}
