import { AtmosphereFormParams, LocationInfo } from "../../common/ApiService";
import { icons } from "./constants";
import { ItemBase } from "./types";

export function initItems<T extends ItemBase>(itemCreator: () => T, count = 1) {
  const items = Array.from(Array(count), itemCreator);
  if (items.length) {
    items[0].source = "Прогноз предоставлен Центром «ФОБОС»";
  }
  return items;
}

export function findLocation(
  locationId: string,
  formParams: AtmosphereFormParams
): LocationInfo | undefined {
  const [district, idx] = locationId.split("_");
  return formParams.locationTypes[district][+idx];
}

export function findIconName(iconPath: string) {
  const type = iconPath.split("_")[0];
  const icon = iconPath.split("_")[1];
  return icons[type]?.[icon];
}

export function calcTotalTiming(items: { timing: string }[]) {
  return items.reduce((acc, { timing }) => acc + parseInt(timing), 0);
}

export function getSunriseSunsetLocations(formParams: AtmosphereFormParams) {
  type LocationWithId = LocationInfo & { id: string };

  const result: Record<string, Record<string, LocationWithId>> = {
    sunrise: {},
    sunset: {},
  };

  const districts = Object.keys(formParams.locationTypes);

  const findLocIndex = (
    district: string,
    type: "восход" | "заход",
    length: 5 | 10
  ): LocationWithId | undefined => {
    const namePattern = `${length} сек \\(${type}\\)`;
    const locIndex = formParams.locationTypes[district].findIndex(({ name }) =>
      new RegExp(namePattern).test(name)
    );
    if (locIndex >= 0) {
      return {
        ...formParams.locationTypes[district][locIndex],
        id: `${district}_${locIndex}`,
      };
    }
  };

  for (const district of districts) {
    const sunriseFiveSecLocation = findLocIndex(district, "восход", 5);
    if (sunriseFiveSecLocation) result.sunrise["5"] = sunriseFiveSecLocation;

    const sunriseTenSecLocation = findLocIndex(district, "восход", 10);
    if (sunriseTenSecLocation) result.sunrise["10"] = sunriseTenSecLocation;

    const sunsetFiveSecLocation = findLocIndex(district, "заход", 5);
    if (sunsetFiveSecLocation) result.sunset["5"] = sunsetFiveSecLocation;

    const sunsetTenSecLocation = findLocIndex(district, "заход", 10);
    if (sunsetTenSecLocation) result.sunset["10"] = sunsetTenSecLocation;
  }

  return result;
}
