import classNames from "classnames";
import { InputBase } from "emg-ui-kit/components/InputBase";
import React from "react";
import ReactSlider from "react-slider";

import styles from "./RangeInput.module.css";

interface Props {
  name: string;
  value: number;
  onChange: (value: number) => void;
  max: number;
  min: number;
  label?: string;
  style?: React.CSSProperties;
}

export default function RangeInput(props: Props) {
  return (
    <InputBase
      forwardedRef={null}
      label={props.label}
      style={props.style}
      inputComponent={(inputProps) => (
        <ReactSlider
          className={classNames(inputProps.className, styles.slider)}
          thumbClassName={styles.thumb}
          trackClassName={styles.track}
          renderThumb={(props, state) => <div {...props}>{state.valueNow}</div>}
          min={props.min}
          max={props.max}
          value={props.value}
          onChange={props.onChange}
        />
      )}
    />
  );
}
