import React from "react";
import ContentLoader from "react-content-loader";

export default function DashBoardLocationsSkeleton() {
  return (
    <ContentLoader
      speed={1}
      width={"100%"}
      height={366}
      backgroundColor="#f2f2f2"
      foregroundColor="#ffffff"
    >
      <path d="M 0 0 h 520 v 30 H 0 z M 0 38 h 520 v 20 H 0 z M 0 120 h 520 v 20 H 0 z M 0 202 h 520 v 20 H 0 z M 0 66 h 520 v 20 H 0 z M 0 148 h 520 v 20 H 0 z M 0 230 h 520 v 20 H 0 z M 0 284 h 520 v 20 H 0 z M 0 94 h 520 v 20 H 0 z M 0 176 h 520 v 20 H 0 z M 0 258 h 520 v 20 H 0 z" />
    </ContentLoader>
  );
}
