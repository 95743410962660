import TextField from "emg-ui-kit/components/TextField";
import { Field } from "formik";
import { useEffect } from "react";
import React from "react";

interface TimingFieldProps {
  blocksName: string;
  index: number;
  updateItem: (value: { timing: number }) => void;
  touched: any;
  errors: any;
  defaultTiming?: number;
}

export function TimingField({
  blocksName,
  index,
  updateItem,
  touched,
  errors,
  defaultTiming,
}: TimingFieldProps) {
  useEffect(() => {
    if (defaultTiming) {
      updateItem({ timing: defaultTiming });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Field
      as={TextField}
      name={`${blocksName}.${index}.timing`}
      label="Хронометраж (сек.)"
      type="number"
      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
        updateItem({ timing: +event.target.value })
      }
      isValid={!touched[blocksName] || !errors[blocksName]?.[index]?.timing}
      validationMessage={errors[blocksName]?.[index]?.timing}
      labelStyle={{ maxWidth: 180 }}
    />
  );
}
