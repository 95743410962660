import classNames from "classnames";
import React from "react";
import { useSelector } from "react-redux";

import { selectTopOffset } from "../redux/viewParams";
import styles from "./PageContainer.module.css";

const PageContainer: React.FunctionComponent<{ className?: string }> = ({
  children,
  className,
}) => {
  const topOffset = useSelector(selectTopOffset);

  return (
    <main
      className={classNames(styles.main, className)}
      style={{
        position: "relative",
        paddingTop: topOffset + 20,
        marginBottom: 160,
      }}
    >
      {children}
    </main>
  );
};

export default PageContainer;
