import React from "react";

import styles from "./ColorInput.module.css";

interface Props {
  label: string;
  value: string;
  updateValue: (value: string) => void;
  style?: React.CSSProperties;
  labelStyle?: React.CSSProperties;
}

export default function ColorInput(props: Props) {
  return (
    <div className={styles.container} style={props.style}>
      <label className={styles.label} style={props.labelStyle}>
        {props.label}
      </label>
      <input
        type="color"
        value={props.value}
        onChange={(event) => props.updateValue(event.target.value)}
      />
    </div>
  );
}
