import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { socket } from "../../redux/SocketClient";
import { updateNotificationsBySocket } from "../../redux/notifications";
import { getTasksThunk, updateTaskBySocket } from "../../redux/tasks";
import { RootState } from "../../store";

export const eventNames = {
  updateTask: "updateTask",
  addNewTask: "addNewTask",
  updateNotifications: "updateNotifications",
};

export function useSocketTracking() {
  const dispatch = useDispatch();
  const filterState = useSelector((store: RootState) => store.filter);

  useEffect(() => {
    const updateTask = (updateTask: any) => {
      dispatch(updateTaskBySocket(updateTask));
    };

    const updateAllTasks = () => {
      dispatch(getTasksThunk(filterState));
    };

    const updateNotifications = (payload: any) => {
      dispatch(updateNotificationsBySocket(payload));
    };

    socket.on(eventNames.updateTask, updateTask);
    socket.on(eventNames.addNewTask, updateAllTasks);
    socket.on(eventNames.updateNotifications, updateNotifications);
    return () => {
      socket.off(eventNames.updateTask, updateTask);
      socket.off(eventNames.addNewTask, updateAllTasks);
      socket.off(eventNames.updateNotifications, updateNotifications);
    };
  }, [dispatch, filterState]);
}
