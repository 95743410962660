import Blocks from "emg-ui-kit/components/Blocks";
import Checkbox from "emg-ui-kit/components/Checkbox";
import Select from "emg-ui-kit/components/Select";
import TextArea from "emg-ui-kit/components/TextArea";
import TextField from "emg-ui-kit/components/TextField";
import { Field, Form, FormikProvider, useFormik } from "formik";
import React from "react";

import LegendInput from "../../common/LegendInput";
import OrderSavingButtons from "../../common/OrderSavingButtons";
import { FormProps } from "../../common/models";
import styles from "../../common/subBlocks/subBlocks.module.css";
import usePreview from "../usePreview";
import {
  CLIP_NAME_REGEXP,
  getValidationProps,
  getDeepValidationProps,
  removeEmptyProps,
  validateIncorrectFormat,
  validateNotEmpty,
  validatePositive,
  removeTouched,
  validateMax,
  MAX_TIMING,
  validateMaxLines,
  validateNonNegative,
  validateNonPositive,
  validateRange,
} from "../util";

const MAX_SUB_BLOCKS = 6;

const chartColors = [
  "#30CD5B",
  "#1192E8",
  "#244BD5",
  "#747474",
  "#FF0039",
  "#FF8600",
  "#21D8D8",
  "#FF36FB",
];

interface Legend {
  legendText: string;
  legendColor: string;
}

function createLegendItem(index: number): Legend {
  const colorIndex = index % chartColors.length;
  return { legendText: "", legendColor: chartColors[colorIndex] };
}

interface Column {
  columnValue: string;
  columnText: string;
  columnColor: string;
}

function createColumnItem(index: number): Column {
  const colorIndex = index % chartColors.length;
  return {
    columnValue: "",
    columnText: "",
    columnColor: chartColors[colorIndex],
  };
}

interface Category {
  categoryTitle: string;
  columns: Column[];
}

function createCategory(columnsCount: number): Category {
  return {
    categoryTitle: "",
    columns: [
      ...Array.from(Array(columnsCount), (_, index) => createColumnItem(index)),
    ],
  };
}

interface BlockItem {
  timing: number;
  title: string;
  subtitle: string;
  subtitleFont: number;
  source: string;
  columnCount: number;
  columnCountRecomendation?: string;
  isLegendActive: boolean;
  legendFont: number;
  legends: Legend[];
  minValue: number;
  maxValue: number;
  columnFont: number;
  categoryFont: number;
  categoriesMargin: number;
  columnsMargin: number;
  isBgActive: boolean;
  showColumnText: boolean;
  categories: Category[];
}

function createBlock() {
  return {
    timing: 10,
    title: "",
    subtitle: "",
    subtitleFont: 37,
    source: "",
    columnCount: 1,
    columnCountRecomendation: undefined,
    isLegendActive: false,
    legendFont: 40,
    legends: [createLegendItem(0)],
    minValue: 0,
    maxValue: 100,
    columnFont: 60,
    categoryFont: 37,
    categoriesMargin: 10,
    columnsMargin: 10,
    isBgActive: false,
    showColumnText: false,
    categories: [createCategory(1)],
  };
}

function initItems(count: number) {
  return Array.from(Array(count), createBlock);
}

function getInitialValues(initialFormData?: Record<string, BlockItem[]>) {
  return {
    clipName: (initialFormData?.clipName ?? "") as string,
    items: (initialFormData?.items
      ? initialFormData.items.map((item) => ({
          ...item,
          categories: item.categories.map((category) => ({
            ...category,
            columns: category.columns.map((column) => ({
              ...column,
              columnValue: String(column.columnValue),
            })),
          })),
        }))
      : initItems(1)) as BlockItem[],
  };
}

type Values = ReturnType<typeof getInitialValues>;

function calcTotalTiming(values: Values) {
  return values.items.reduce((acc, item) => acc + item.timing, 0);
}

function prepareData(values: Values) {
  return {
    clipName: values.clipName,
    items: values.items.map((item) => ({
      ...item,
      title: item.title.toUpperCase(),
      subtitle: item.subtitle.toUpperCase(),
      minValue: item.minValue > 0 ? 0 : item.minValue,
      maxValue: item.maxValue < 0 ? 0 : item.maxValue,
      categories: item.categories.map((category) => ({
        categoryTitle: category.categoryTitle.toUpperCase(),
        columns: category.columns.map((column) => ({
          ...column,
          columnValue: isNaN(+column.columnValue.replace(",", "."))
            ? 0
            : +column.columnValue.replace(",", "."),
        })),
      })),
    })),
  };
}

function validate(values: Values) {
  const errors = {
    clipName: values.clipName
      ? validateIncorrectFormat(values.clipName, CLIP_NAME_REGEXP)
      : undefined,
    items: values.items.map((item) => ({
      timing:
        validatePositive(item.timing) ??
        validateMax(item.timing, MAX_TIMING / values.items.length),
      title: validateNotEmpty(item.title) ?? validateMaxLines(item.title, 2),
      subtitle: validateMaxLines(item.subtitle, 2),
      subtitleFont: validatePositive(item.subtitleFont),
      legendFont: validatePositive(item.legendFont),
      columnFont: validatePositive(item.columnFont),
      categoryFont: validatePositive(item.categoryFont),
      minValue: validateNonPositive(item.minValue),
      maxValue: validateNonNegative(item.maxValue),
      categoriesMargin: validateNonNegative(item.categoriesMargin),
      columnsMargin: validateNonNegative(item.columnsMargin),
      categories: item.categories.map((category) => ({
        categoryTitle: validateMaxLines(category.categoryTitle, 2),
        columns: category.columns.map((column) => ({
          columnValue:
            validateNotEmpty(column.columnValue) ??
            ((item.minValue < 0 || item.maxValue > 0) &&
              validateRange(+column.columnValue, item.minValue, item.maxValue)),
        })),
      })),
    })),
  };
  return removeEmptyProps(errors);
}

function M24ColumnsForm({
  initialFormData,
  onSubmit,
  onSaveDraft,
  onDeleteDraft,
  channel,
  template,
}: FormProps) {
  const formik = useFormik({
    initialValues: getInitialValues(initialFormData),
    onSubmit: (values) => onSubmit(prepareData(values)),
    validate,
  });
  const {
    values,
    errors,
    touched,
    setFieldValue,
    setFieldTouched,
    setTouched,
    isSubmitting,
    isValid,
  } = formik;

  const buttonProps = {
    isSubmitting,
    isValid,
    prepareData,
    values,
    onSaveDraft,
    onDeleteDraft,
  };

  const changeColumnCount = (index: number, cols: number) => {
    if (values.items[index].categories.length * cols > 12) {
      setFieldValue(
        `items.${index}.columnCountRecomendation`,
        "Не более 12 столбов в слайде"
      );
      return;
    }
    const updatedItem = { ...values.items[index] };
    const legends = values.items[index].legends;
    const columnCount = values.items[index].columnCount;
    const newItems = cols - columnCount;

    const legendsValue =
      cols < columnCount
        ? legends.slice(0, cols)
        : [
            ...legends,
            ...Array.from(Array(newItems), (_, index) =>
              createLegendItem(columnCount + index)
            ),
          ];

    const categoriesValue = values.items[index].categories.map(
      (category, categoryIndex) => {
        category.columns.forEach((_, columnIndex) => {
          if (cols <= columnIndex) {
            setFieldTouched(
              `items.${index}.categories.${categoryIndex}.columns.${columnIndex}.columnValue`,
              false
            );
          }
        });

        const columns =
          cols < columnCount
            ? category.columns.slice(0, cols)
            : [
                ...category.columns,
                ...Array.from(Array(newItems), (_, index) =>
                  createColumnItem(columnCount + index)
                ),
              ];
        return { ...category, columns };
      }
    );

    updatedItem.columnCountRecomendation = undefined;
    updatedItem.columnCount = cols;
    updatedItem.legends = legendsValue;
    updatedItem.categories = categoriesValue;
    setFieldValue(`items.${index}`, updatedItem);
  };

  usePreview(channel, template, +calcTotalTiming(values), values, prepareData);

  return (
    <FormikProvider value={formik}>
      <Form>
        <Field
          as={TextField}
          label="Название ролика"
          name="clipName"
          {...getValidationProps("clipName", touched, errors)}
          value={values.clipName}
        />
        <Blocks
          items={values.items}
          defaultItemConstructor={createBlock}
          updateItems={(items) => setFieldValue("items", items)}
          blockTitle="Слайд"
          canChangeLength
          maxBlocks={5}
          minBlocks={1}
          onDelete={(index) => {
            removeTouched(`items`, index, touched, setTouched);
          }}
          tooltip={`Размер текста заголовка и источника: & <size=50></size> `}
        >
          {(item, index, updateItem) => (
            <div>
              <Field
                as={TextField}
                name={`items.${index}.timing`}
                type="number"
                label="Хронометраж слайда (сек.)"
                {...getDeepValidationProps(
                  `items.${index}.timing`,
                  touched,
                  errors
                )}
                required
              />
              <Field
                as={TextArea}
                placeholder="Заголовок"
                name={`items.${index}.title`}
                textareaStyle={{ textTransform: "uppercase" }}
                {...getDeepValidationProps(
                  `items.${index}.title`,
                  touched,
                  errors
                )}
                rows={2}
                required
              />
              <Field
                as={TextArea}
                placeholder="Подзаголовок"
                name={`items.${index}.subtitle`}
                textareaStyle={{ textTransform: "uppercase" }}
                {...getDeepValidationProps(
                  `items.${index}.subtitle`,
                  touched,
                  errors
                )}
                rows={2}
              />
              <Field
                as={TextField}
                type="number"
                label="Размер шрифта подзаголовка"
                name={`items.${index}.subtitleFont`}
                {...getDeepValidationProps(
                  `items.${index}.subtitleFont`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                placeholder="Источник"
                name={`items.${index}.source`}
              />
              <Field
                as={Select}
                name={`items.${index}.columnCount`}
                label="Столбов в категории"
                options={[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12].map((num) => ({
                  id: num.toString(),
                  name: num.toString(),
                }))}
                onChange={(event: any) =>
                  changeColumnCount(index, +event.target.value)
                }
                recommendationMessage={item.columnCountRecomendation}
              />
              <Checkbox
                label="Панель легенды"
                checked={item.isLegendActive}
                setChecked={(value) =>
                  setFieldValue(`items.${index}.isLegendActive`, value)
                }
              />
              {item.isLegendActive && (
                <>
                  <Field
                    as={TextField}
                    type="number"
                    label="Размер шрифта легенды"
                    name={`items.${index}.legendFont`}
                    {...getDeepValidationProps(
                      `items[${index}].legendFont`,
                      touched,
                      errors
                    )}
                  />
                  {item.legends.map((legend, innerIndex) => (
                    <LegendInput
                      key={`${index}_${innerIndex}`}
                      placeholder={`Легенда ${innerIndex + 1}`}
                      color={legend.legendColor}
                      title={legend.legendText}
                      colors={chartColors}
                      updateColor={(color) =>
                        setFieldValue(
                          `items[${index}].legends[${innerIndex}].legendColor`,
                          color
                        )
                      }
                      updateTitle={(title) =>
                        setFieldValue(
                          `items[${index}].legends[${innerIndex}].legendText`,
                          title
                        )
                      }
                    />
                  ))}
                  <hr />
                </>
              )}
              <Field
                as={TextField}
                type="number"
                label="Мин. значение столба"
                name={`items.${index}.minValue`}
                {...getDeepValidationProps(
                  `items[${index}].minValue`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Макс. значение столба"
                name={`items.${index}.maxValue`}
                {...getDeepValidationProps(
                  `items[${index}].maxValue`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Размер шрифта столбов"
                name={`items.${index}.columnFont`}
                {...getDeepValidationProps(
                  `items[${index}].columnFont`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Размер шрифта категорий"
                name={`items.${index}.categoryFont`}
                {...getDeepValidationProps(
                  `items[${index}].categoryFont`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Отступ категорий"
                name={`items.${index}.categoriesMargin`}
                {...getDeepValidationProps(
                  `items[${index}].categoriesMargin`,
                  touched,
                  errors
                )}
              />
              <Field
                as={TextField}
                type="number"
                label="Отступ столбов"
                name={`items.${index}.columnsMargin`}
                {...getDeepValidationProps(
                  `items[${index}].columnsMargin`,
                  touched,
                  errors
                )}
              />
              <Checkbox
                label="Подсветка категорий"
                checked={item.isBgActive && item.categories.length !== 1}
                setChecked={(value) =>
                  setFieldValue(`items.${index}.isBgActive`, value)
                }
                disabled={item.categories.length === 1}
              />
              <Checkbox
                label="Редактирование подписей столбов"
                checked={item.showColumnText}
                setChecked={(value) =>
                  setFieldValue(`items.${index}.showColumnText`, value)
                }
              />
              {item.categories.map((category, innerIndex) => {
                const blockTitle = "Категория";
                const minSubBlocks = 1;
                const moreThanMin =
                  item.categories.length > (minSubBlocks ?? 1);

                return (
                  <div
                    key={`${blockTitle}_${index}_${innerIndex}`}
                    className={styles.slide}
                  >
                    <section className={styles.titleContainer}>
                      <div className={styles.title}>
                        {blockTitle ?? "Слайд"} {innerIndex + 1}
                      </div>
                      <div
                        style={{
                          display: "flex",
                        }}
                      >
                        {moreThanMin && (
                          <button
                            type="button"
                            className={styles.deleteButton}
                            onClick={() => {
                              removeTouched(
                                `items[${index}].categories`,
                                innerIndex,
                                touched,
                                setTouched
                              );
                              updateItem({
                                categories: item.categories.filter(
                                  (_, i) => i !== innerIndex
                                ),
                              });
                            }}
                          />
                        )}
                      </div>
                    </section>
                    <Field
                      key={`categoryTitle_${innerIndex}`}
                      as={TextArea}
                      name={`items.${index}.categories.${innerIndex}.categoryTitle`}
                      placeholder="Название категории"
                      textareaStyle={{ textTransform: "uppercase" }}
                      {...getDeepValidationProps(
                        `items.${index}.categories.${innerIndex}.categoryTitle`,
                        touched,
                        errors
                      )}
                      rows={2}
                    />
                    {category.columns.map((column, columnIndex) => (
                      <div key={`column_${index}_${innerIndex}_${columnIndex}`}>
                        <LegendInput
                          key={`columnLegend_${index}_${innerIndex}_${columnIndex}`}
                          placeholder={`Значение столба ${columnIndex + 1}`}
                          color={column.columnColor}
                          title={column.columnValue}
                          colors={chartColors}
                          updateColor={(color) =>
                            setFieldValue(
                              `items.${index}.categories.${innerIndex}.columns.${columnIndex}.columnColor`,
                              color
                            )
                          }
                          updateTitle={(value) => {
                            if (/[^\-.,0-9]/.test(value)) return;
                            setFieldValue(
                              `items.${index}.categories.${innerIndex}.columns.${columnIndex}.columnValue`,
                              value
                            );
                          }}
                          onBlur={() =>
                            setFieldTouched(
                              `items.${index}.categories.${innerIndex}.columns.${columnIndex}.columnValue`
                            )
                          }
                          {...getDeepValidationProps(
                            `items.${index}.categories.${innerIndex}.columns.${columnIndex}.columnValue`,
                            touched,
                            errors
                          )}
                          required
                        />
                        {item.showColumnText && (
                          <Field
                            key={`columnText_${index}_${innerIndex}_${columnIndex}`}
                            as={TextField}
                            name={`items.${index}.categories.${innerIndex}.columns.${columnIndex}.columnText`}
                            placeholder={`Подпись столба ${columnIndex + 1}`}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                );
              })}
              {item.categories.length < Math.floor(12 / item.columnCount) &&
                item.categories.length < MAX_SUB_BLOCKS && (
                  <button
                    type="button"
                    onClick={() => {
                      updateItem({
                        categories: item.categories.concat(
                          Array(1).fill(createCategory(item.columnCount))
                        ),
                      });
                    }}
                    className={styles.addButton}
                  >
                    Добавить{" категорию"}
                  </button>
                )}
            </div>
          )}
        </Blocks>
        <OrderSavingButtons {...buttonProps} />
      </Form>
    </FormikProvider>
  );
}

export default React.memo(M24ColumnsForm);
