import React from "react";

type Props = {
  src: string;
  alt?: string;
  style?: React.CSSProperties;
};

export default function PreviewImage({ src, alt, style }: Props ) {
  return (
    <div style={{ marginBottom: 10, ...style }}>
      <img
        style={{ objectFit: "contain", width: "100%" }}
        src={src}
        alt={alt}
      />
    </div>
  );
}
