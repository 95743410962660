import React from "react";

import { ImageRow, InfoRow } from "../InfoComponents";
import { color, titleColor } from "../constants";

export function Slideshow({ data }: { data: Record<string, any> }) {
  return (
    <>
      <InfoRow title="Название ролика" value={data.clipName} />
      <InfoRow title="Хронометраж (сек.)" value={data.totalTiming} />
      {data.title && (
        <>
          <InfoRow title="Общий заголовок" value={data.title} />
          <InfoRow
            title="Цвет общего заголовка"
            value={titleColor[data.titleColor]}
          />
        </>
      )}
      {data.comment && <InfoRow title="Комментарий" value={data.comment} />}
      {data.source && <InfoRow title="Источник" value={data.source} />}
      {data.sourceColor && (
        <InfoRow title="Цвет источника" value={color[data.sourceColor]} />
      )}
      {(data.blocks as Record<string, any>[]).map((block, idx) => (
        <details key={idx}>
          <summary>Блок №{idx + 1}</summary>
          <ImageRow title="Изображение" src={block.image} />
          {block.title && (
            <>
              <InfoRow title="Заголовок" value={block.title} />
              <InfoRow
                title="Цвет заголовка"
                value={titleColor[block.titleColor]}
              />
            </>
          )}
          {block.comment && (
            <InfoRow title="Комментарий" value={block.comment} />
          )}
          {block.source && <InfoRow title="Источник" value={block.source} />}
          {block.sourceColor && (
            <InfoRow title="Цвет источника" value={color[block.sourceColor]} />
          )}
        </details>
      ))}
    </>
  );
}
