import { useCallback, useState } from "react";

import { ApiError } from "../../common/ApiService";
import { GeoResponse } from "./types";

const GEOSERVER_THECITY_API = "/api/v2/geo-api";

type GeoHookReturn = {
  isFetching: boolean;
  fetchGeoPoint: () => Promise<GeoResponse | null>;
};

async function makeRequest<T>(url: string, opts?: RequestInit): Promise<T> {
  const resp = await fetch(url, {
    ...opts,
  });
  if (resp.ok) {
    return resp.json();
  } else {
    const message = await resp.text();
    throw new ApiError(resp.status, message);
  }
}

export default function useGeoPoint(addressString: string): GeoHookReturn {
  const [isFetching, setIsFetching] = useState(false);
  const address = `Москва, ${addressString}`;

  const fetchGeoPoint = useCallback(async () => {
    const options = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ address }),
    };
    setIsFetching(true);
    return await makeRequest<GeoResponse>(
      GEOSERVER_THECITY_API,
      options
    ).finally(() => {
      setIsFetching(false);
    });
  }, [address]);

  return {
    isFetching,
    fetchGeoPoint,
  };
}
