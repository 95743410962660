import {
  createAction,
  createAsyncThunk,
  createReducer,
} from "@reduxjs/toolkit";

import {
  getNotification,
  Notification,
  updateNotification,
} from "../../common/ApiService";

export interface NotificationState {
  isLoading: boolean;
  isUpdating: boolean;
  notification?: Notification["notification"];
  troubleNotification?: Notification["troubleNotification"];
}

const initialState: NotificationState = {
  isLoading: false,
  isUpdating: false,
};

export const getNotificationThunk = createAsyncThunk(
  "notification/get",
  getNotification
);

export const updateNotificationThunk = createAsyncThunk(
  "notification/update",
  updateNotification
);

export const updateNotificationsBySocket = createAction(
  "notifications/initTracking"
);

export const notificationReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(getNotificationThunk.pending, (state) => {
      state.isLoading = true;
    })
    .addCase(getNotificationThunk.fulfilled, (state, action) => {
      state.isLoading = false;
      state.notification = action.payload.notification;
      state.troubleNotification = action.payload.troubleNotification;
    })
    .addCase(getNotificationThunk.rejected, (state) => {
      state.isLoading = false;
    })
    .addCase(updateNotificationThunk.pending, (state) => {
      state.isUpdating = true;
    })
    .addCase(updateNotificationThunk.fulfilled, (state, action) => {
      state.isUpdating = false;
      state.notification = action.payload.notification;
      state.troubleNotification = action.payload.troubleNotification;
    })
    .addCase(updateNotificationThunk.rejected, (state) => {
      state.isUpdating = false;
    })
    .addCase(updateNotificationsBySocket, (state, action: any) => {
      if (action.payload) {
        if (!!action.payload.notification) {
          state.notification = action.payload.notification;
        }
        if (!!action.payload.troubleNotification) {
          state.troubleNotification = action.payload.troubleNotification;
        }
      }
    });
});
