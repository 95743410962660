import Button from "emg-ui-kit/components/Button";
import {
  HtmlEditor,
  validateEditorState,
} from "emg-ui-kit/components/HtmlEditor";
import Select from "emg-ui-kit/components/Select";
import Title from "emg-ui-kit/components/Title";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import { createNewsArticle } from "../common/ApiService";
import BackButton from "../common/BackButton";
import PageContainer from "../common/PageContainer";
import { convertToSelectOptions } from "../common/utils";
import routes from "../routes";
import { groups } from "./userConstants";

const groupOptions = convertToSelectOptions(groups);

export default function NewsCreate() {
  const history = useHistory();
  const [group, setGroup] = useState("all");
  const [content, setContent] = useState("");
  const contentErrorMessage = validateEditorState(content);

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    createNewsArticle({ group, content }).then((result) => {
      if ("error" in result) return;
      history.push(routes.newsArticles);
    });
  };

  return (
    <PageContainer>
      <BackButton to={routes.newsArticles} />
      <Title text="Добавить новость" />
      <form onSubmit={handleSubmit}>
        <Select
          name="group"
          label="Группа"
          options={groupOptions}
          value={group}
          onChange={(event) => setGroup(event.target.value)}
        />
        <HtmlEditor setValue={(content) => setContent(content)} />
        <Button
          type="submit"
          title="Сохранить"
          status={!contentErrorMessage ? "enabled" : "disabled"}
        />
      </form>
    </PageContainer>
  );
}
